import {useDispatch, useSelector} from "react-redux";
import { Page as Document } from "../library";
import { useFunctions } from "../functions";
import { Tasks } from "./datatypes";
import { Type } from "../../components/HotspotChoice";

import {
    setAllowClipboardCopy, setFeedback,
    setOverlay as setCurrentOverlay,
} from "../../redux/slices/appSlice";
import {setTask as setCurrentTask, setTaskSkippable} from "../../redux/slices/taskSlice";
import {RootState} from "../../redux/rootReducer";

export const MissionEight = () => {
    const [{ setOverlay, setImageClozeAnswer, setImageClozePoints, setPage }] = useFunctions();
    const { firstName, lastName } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const currentYear = new Date().getFullYear();

    const missionEightData: Tasks = {
        pleaseShowMeHowToCalculateTheNewPaymentAmount: {
            title: 'Neuen Zahlungsbetrag für zweite Mahnung berechnen',
            src: require('static/images/tasks/mission8/mMe_ZahlungsbetragZweiteMahnungBerechnen.png'),
            hotspots: {
                0: {
                    x: '33%',
                    y: '35%',
                    labelXOffset: '-3%',
                    options: [
                        { label: 'Rechnungsbetrag inkl. USt', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag exkl. USt', onClick: setImageClozeAnswer },
                        { label: 'Mahnspesen', onClick: setImageClozeAnswer },
                        { label: 'Skonto', onClick: setImageClozeAnswer },
                        { label: 'Verzugszinsen', onClick: setImageClozeAnswer },
                        { label: 'Neuer Zahlungsbetrag', onClick: setImageClozeAnswer },
                    ]
                },
                1: {
                    x: '33%',
                    y: '50%',
                    labelXOffset: '-3%',
                    options: [
                        { label: 'Rechnungsbetrag inkl. USt', onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag exkl. USt', onClick: setImageClozeAnswer },
                        { label: 'Mahnspesen', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Skonto', onClick: setImageClozeAnswer },
                        { label: 'Verzugszinsen', onClick: setImageClozeAnswer },
                        { label: 'Neuer Zahlungsbetrag', onClick: setImageClozeAnswer },
                    ]
                },
                2: {
                    x: '17%',
                    y: '50%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ]
                },
                3: {
                    x: '33%',
                    y: '70%',
                    labelXOffset: '-3%',
                    options: [
                        { label: 'Rechnungsbetrag inkl. USt', onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag exkl. USt', onClick: setImageClozeAnswer },
                        { label: 'Mahnspesen', onClick: setImageClozeAnswer },
                        { label: 'Skonto', onClick: setImageClozeAnswer },
                        { label: 'Verzugszinsen', onClick: setImageClozeAnswer },
                        { label: 'Neuer Zahlungsbetrag', correct: true, onClick: setImageClozeAnswer },
                    ]
                },
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('pleaseShowMeHowToCalculateTheNewPaymentAmount'));
                setPage('feedback');
            },
        },
        pleaseWriteTheSecondReminder: {
            title: 'Zweite Mahnung verfassen',
            src: require('static/images/tasks/mission8/mMe_ZweiteMahnungVerfassen.png'),
            hotspots: {
                0: {
                    x: '13%',
                    y: '20%',
                    labelXOffset: '-8%',
                    options: [
                        { label: 'Werner Neumann GmbH',
                            children: [
                                { x: '5%', y: '22.8%', label: 'Alserbachstraße 140'},
                                { x: '4.8%', y: '26%', label: '1090 Wien'},
                            ],
                            onClick: setImageClozeAnswer },
                        { label: 'eRGO GmbH',
                            children: [
                                { x: '5%', y: '22.8%', label: 'Brünner Straße 60'},
                                { x: '4.8%', y: '26%', label: '1210 Wien'},
                            ],
                            onClick: setImageClozeAnswer },
                        { label: 'Zalanka AG',
                            children: [
                                { x: '5%', y: '22.8%', label: 'Wiedner Hauptstraße 118'},
                                { x: '4.8%', y: '26%', label: '1050 Wien'},
                            ],
                            correct: true,
                            onClick: setImageClozeAnswer },
                        { label: 'Christian Neuhofer eU',
                            children: [
                                { x: '5%', y: '22.8%', label: 'Roland-Rainer-Platz 3'},
                                { x: '4.8%', y: '26%', label: '1150 Wien'},
                            ],
                            onClick: setImageClozeAnswer },
                    ],
                },
                1: {
                    x: '13%',
                    y: '34%',
                    labelXOffset: '-8%',
                    title: 'Betreff',
                    options: [
                        { label: 'Zahlungserinnerung', onClick: setImageClozeAnswer },
                        { label: '2. Mahnung', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Rechnung', onClick: setImageClozeAnswer },
                    ],
                },
                2: {
                    x: '13%',
                    y: '41%',
                    labelXOffset: '-8%',
                    options: [
                        { label: 'Re-Nr.: 1685 [Werner Neumann GmbH]',
                            alternativeLabel: `Unsere Rechnung Nr. 1685 vom 22.09.${currentYear} über 9.286,15 Euro ist <br/>bereits seit 06.10.${currentYear} fällig.`,
                            children: [
                                { x: '5%', y: '48%', label: `Am 23.11.${currentYear} erinnerten wir Sie leider erfolglos an die ausständige <br/>Zahlung.`},
                            ],
                            onClick: setImageClozeAnswer },
                        { label: 'Re-Nr.: 1725 [eRGO GmbH]',
                            alternativeLabel: `Unsere Rechnung Nr. 1725 vom 11.10.${currentYear} über 22.517,56 Euro ist <br/>bereits seit 25.10.${currentYear } fällig.`,
                            children: [
                                { x: '5%', y: '48%', label: `Am 24.11.${currentYear} erinnerten wir Sie leider erfolglos an die ausständige <br/>Zahlung.`},
                            ],
                            onClick: setImageClozeAnswer },
                        { label: 'Re-Nr.: 1835 [Zalanka AG]',
                            alternativeLabel: `Unsere Rechnung Nr. 1835 vom 21.10.${currentYear} über 13.668 Euro ist bereits <br/>seit 4.11.${currentYear} fällig.`,
                            children: [
                                { x: '5%', y: '48%', label: `Am 19.11.${currentYear} erinnerten wir Sie leider erfolglos an die ausständige <br/>Zahlung.`},
                            ],
                            onClick: setImageClozeAnswer, correct: true },
                        { label: 'Re-Nr.: 1975 [Christian Neuhofer eU]',
                            alternativeLabel: `Unsere Rechnung Nr. 1975 vom 12.11.${currentYear} über 615,14 Euro ist bereits <br/>seit 26.11.${currentYear} fällig.`,
                            onClick: setImageClozeAnswer },
                        {label: 'In offenen Posten nachsehen', noAnswer: true, onClick: () => {
                                setOverlay(Document.Ausgangsrechnungen)
                            }},
                    ],
                },
                3: {
                    x: '81.7%',
                    y: '54.8%',
                    labelXAlignment: 'center',
                    type: Type.Calendar,
                    hasYear: true,
                    year: currentYear,
                    monthNumber: 11,
                    options: [
                        {label: 'In offenen Posten nachsehen', noAnswer: true, onClick: () => {
                                setOverlay(Document.Ausgangsrechnungen)
                            }},
                        {label: 'Mahnplan öffnen', noAnswer: true, onClick: () => {
                                setOverlay(Document.Mahnplan)
                            }},
                    ],
                    correct: [`18.12.${currentYear}`],
                    onSubmit: setImageClozeAnswer
                },
                4: {
                    x: '85%',
                    y: '68%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    type: Type.MiniCalculator,
                    correct: ['13668,00', '13.668,00', '13.668', '13.668,0'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '85%',
                    y: '71.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    type: Type.MiniCalculator,
                    correct: ['11,00', '11,0', '11'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '85%',
                    y: '75.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    type: Type.Calculator,
                    correct: ['13.679,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                7: {x: '76%', y: '31%',},
                8: {x: '5.5%', y: '84%',}
            },
            initialAnswers: {
                7: {label: `04.12.${currentYear}`, active: false},
                8: {label: `${firstName} ${lastName}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('pleaseWriteTheSecondReminder'));
                setPage('feedback');
            },
        },
        calculatingDefaultInterest1: {
            title: 'Verzugszinsen berechnen - Teil 1',
            subtitle: '<span class="tip-label">Tipp:</span> Schau dir den Mahnplan auf deinem Arbeitsplatz genau an.',
            src: require('static/images/tasks/mission8/mMe_VerzugszinsenBerechnen1.png'),
            hotspots: {
                0: {
                    x: '89%',
                    y: '37.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '8%',
                    type: Type.MiniCalculator,
                    correct: ['13668,00', '13.668,00', '13.668', '13.668,0'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '89%',
                    y: '50%',
                    labelXAlignment: 'right',
                    labelXOffset: '8%',
                    type: Type.MiniCalculator,
                    correct: ['10,00', '10,0', '10'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    hasNoDecimals: true,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '89%',
                    y: '63%',
                    labelXAlignment: 'right',
                    labelXOffset: '8%',
                    type: Type.Calendar,
                    hasYear: true,
                    year: currentYear,
                    monthNumber: 11,
                    options: [
                        {label: 'Mahnplan öffnen', noAnswer: true, onClick: () => {
                                setOverlay(Document.Mahnplan)
                            }},
                    ],
                    correct: [`05.11.${currentYear}`],
                    onSubmit: setImageClozeAnswer
                },
                3: {
                    x: '89%',
                    y: '70%',
                    labelXAlignment: 'right',
                    labelXOffset: '8%',
                    type: Type.Calendar,
                    hasYear: true,
                    year: currentYear,
                    monthNumber: 11,
                    options: [
                        {label: 'In offenen Posten nachsehen', noAnswer: true, onClick: () => {
                                setOverlay(Document.Ausgangsrechnungen2)
                            }},
                    ],
                    correct: [`30.12.${currentYear}`],
                    onSubmit: setImageClozeAnswer
                },
                4: { x: '75%', y: '14%'},
                5: { x: '94%', y: '83%'}
            },
            initialAnswers: {
                4: { label:`Heute: 23.12.${currentYear}`, active: false},
                5: { label:`56`, active: false}
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true))
                dispatch(setFeedback('calculatingDefaultInterest1'));
                setPage('feedback');
            },
        },
        calculatingDefaultInterest2: {
            title: 'Verzugszinsen berechnen - Teil 2',
            subtitle: '<span class="tip-label">Tipp:</span> Schau dir den Mahnplan auf deinem Arbeitsplatz genau an.',
            src: require('static/images/tasks/mission8/mMe_VerzugszinsenBerechnen2.png'),
            hotspots: {
                0: {
                    x: '87%',
                    y: '89%',
                    labelXAlignment: 'right',
                    labelXOffset: '3.5%',
                    title: 'Berechne nun die Verzugszinsen.',
                    type: Type.TapText,
                    options: [
                        //@ts-ignore
                        { label: '13.668,00', subLabel: 'Rechnungsbetrag' },
                        { label: '365' },
                        { label: '360' },
                        //@ts-ignore
                        { label: '56', subLabel: 'Säumnistage' },
                        //@ts-ignore
                        { label: '10 %', subLabel: 'Zinssatz', realValue: '0,1' },
                        { label: '/' },
                        { label: '/' },
                        { label: '*' },
                        { label: '*' },
                        { label: '-' },
                        { label: '-' },
                        { label: '+' },
                        { label: '+' },
                    ],
                    correct: ['212,61'],
                    onSubmit: setImageClozeAnswer,
                },
                1: {x: '74.5%', y: '10%'},
                2: {x: '78.5%', y: '43.5%'},
                3: {x: '78.9%', y: '49%'},
                4: { x: '91.5%', y: '89%'}
            },
            initialAnswers: {
                1: {label: `Heute: 23.12.${currentYear}`, active: false},
                2: {label: `von: 05.11.${currentYear}`, active: false},
                3: {label: `bis: 30.12.${currentYear}`, active: false},
                4: {label: `EUR`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true))
                dispatch(setFeedback('calculatingDefaultInterest2'));
                setPage('feedback');
            },
        },
        calculatingTheNewPaymentAmount: {
            title: 'Neuen Zahlungsbetrag für letzte Mahnung berechnen',
            src: require('static/images/tasks/mission8/mMe_ZahlungsbetragLetzteMahnungBerechnen.png'),
            hotspots: {
                0: {
                    x: '33%',
                    y: '30%',
                    labelXOffset: '-3%',
                    options: [
                        { label: 'Rechnungsbetrag inkl. USt.', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag exkl. USt.', onClick: setImageClozeAnswer },
                        { label: 'Verzugszinsen', onClick: setImageClozeAnswer },
                        { label: 'Neuer Zahlungsbetrag', onClick: setImageClozeAnswer },
                        { label: 'Mahnspesen', onClick: setImageClozeAnswer },
                    ]
                },
                1: {
                    x: '33%',
                    y: '45%',
                    labelXOffset: '-3%',
                    options: [
                        { label: 'Rechnungsbetrag inkl. USt.', onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag exkl. USt.', onClick: setImageClozeAnswer },
                        { label: 'Verzugszinsen', onClick: setImageClozeAnswer },
                        { label: 'Neuer Zahlungsbetrag', onClick: setImageClozeAnswer },
                        { label: 'Mahnspesen', correct: true, onClick: setImageClozeAnswer },
                    ]
                },
                2: {
                    x: '22%',
                    y: '45%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ]
                },
                3: {
                    x: '33%',
                    y: '60%',
                    labelXOffset: '-3%',
                    options: [
                        { label: 'Neuer Zahlungsbetrag', onClick: setImageClozeAnswer },
                        { label: 'Verzugszinsen', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag exkl. USt.', onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag inkl. USt.', onClick: setImageClozeAnswer },
                        { label: 'Skonto', onClick: setImageClozeAnswer },
                    ]
                },
                4: {
                    x: '22%',
                    y: '60%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ]
                },
                5: {
                    x: '33%',
                    y: '75%',
                    labelXOffset: '-3%',
                    options: [
                        { label: 'Rechnungsbetrag inkl. USt.', onClick: setImageClozeAnswer },
                        { label: 'Rechnungsbetrag exkl. USt.', onClick: setImageClozeAnswer },
                        { label: 'Verzugszinsen', onClick: setImageClozeAnswer },
                        { label: 'Neuer Zahlungsbetrag', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Mahnspesen', onClick: setImageClozeAnswer },
                    ]
                },
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('calculatingTheNewPaymentAmount'));
                setPage('feedback');
            },
        },
        pleaseCompleteTheReminderToZalanko: {
            title: 'Letzte Mahnung fertig stellen',
            subtitle: '<span class="tip-label">Tipp:</span> Deine Berechnung der Verzugszinsen kannst du auf deinem Arbeitsplatz einsehen.',
            src: require('static/images/tasks/mission8/mMe_LetzteMahnungVerfassen.png'),
            hotspots: {
                0: {
                    x: '10%',
                    y: '17%',
                    labelXOffset: '-4.7%',
                    title: 'Postvermerk',
                    options: [
                        { label: 'Einschreiben', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Persönlich', onClick: setImageClozeAnswer },
                        { label: 'Vertraulich', onClick: setImageClozeAnswer },
                    ]
                },
                1: {
                    x: '80.25%',
                    y: '53.2%',
                    labelXAlignment: 'center',
                    type: Type.Calendar,
                    hasYear: true,
                    year: currentYear,
                    monthNumber: 11,
                    correct: [`30.12.${currentYear}`],
                    onSubmit: setImageClozeAnswer
                },
                2: {
                    x: '85%',
                    y: '64.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    type: Type.MiniCalculator,
                    correct: ['13668,00', '13.668,00', '13.668', '13.668,0'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '85%',
                    y: '68.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    type: Type.Calculator,
                    correct: ['22,00', '22', '22,0'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '85%',
                    y: '72%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    type: Type.MiniCalculator,
                    correct: ['212,61'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '85%',
                    y: '75.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    type: Type.Calculator,
                    correct: ['13902,61', '13.902,61'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                6: { x: '5%', y: '45%'}
            },
            initialAnswers: {
                6: {label: `Trotz unserer Zahlungserinnerungen vom 19.11.${currentYear} und 4.12.${currentYear} haben 
            <br/>Sie unsere Rechnung Nr. 1835 vom 21.10.${currentYear} über 13.668,00 Euro <br/>noch immer nicht beglichen.`, active: false}
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('pleaseCompleteTheReminderToZalanko'));
                setPage('feedback');
            },
        },
    }

    return missionEightData;
}
