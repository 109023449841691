import React from 'react';
import styles from './MissionChoice.module.scss';

import MissionChoiceBox from "../MissionChoiceBox";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/rootReducer";
import {setMission, setModule, setNotification} from "../../redux/slices/appSlice";

export type Mission = {
  id: number;
  title: string;
  unlocked: boolean;
  onClick?: (e: any) => void;
}

export type Missions = {
  [id: number]: Mission;
}

const MissionChoice = () => {
    const { module } = useSelector((state: RootState) => state.app);
    const { missionCompleted } = useSelector((state: RootState) => state.score);
    const dispatch = useDispatch();
    let data: Missions = [];

    if (module === 1){
        data = [
            {
                id: 1,
                title: 'Angebot',
                unlocked: missionCompleted[1],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 1']);
                    dispatch(setModule(1));
                    dispatch(setMission(1));
                    dispatch(setNotification('mission1'))
                }
            },
            {
                id: 2,
                title: 'Auftragsbestätigung',
                unlocked: missionCompleted[2],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 2']);
                    dispatch(setModule(1));
                    dispatch(setMission(2));
                    dispatch(setNotification('mission2'))
                }
            },
            {
                id: 3,
                title: 'Lieferschein',
                unlocked: missionCompleted[3],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 3']);
                    dispatch(setModule(1));
                    dispatch(setMission(3));
                    dispatch(setNotification('mission3'))
                }
            },
            {
                id: 4,
                title: 'Rechnung',
                unlocked: missionCompleted[4],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 4']);
                    dispatch(setModule(1));
                    dispatch(setMission(4));
                    dispatch(setNotification('mission4'))
                }
            },
        ]} else if (module === 2){
        data =[
            {
                id: 5,
                title: 'Angebote vergleichen',
                unlocked: missionCompleted[5],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 5']);
                    dispatch(setModule(2));
                    dispatch(setMission(5));
                    dispatch(setNotification('mission5'))
                }
            },
            {
                id: 6,
                title: 'Verkaufspreis ermitteln',
                unlocked: missionCompleted[6],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 6']);
                    dispatch(setModule(2));
                    dispatch(setMission(6));
                    dispatch(setNotification('mission6'))
                }
            },
            {
                id: 7,
                title: 'Kennzahlen berechnen',
                unlocked: missionCompleted[7],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 7']);
                    dispatch(setModule(2));
                    dispatch(setMission(7));
                    dispatch(setNotification('mission7'))
                }
            },
            {
                id: 8,
                title: 'Mahnungen erstellen',
                unlocked: missionCompleted[8],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 8']);
                    dispatch(setModule(2));
                    dispatch(setMission(8));
                    dispatch(setNotification('mission8'))
                }
            },
            {
                id: 9,
                title: 'Belege prüfen',
                unlocked: missionCompleted[9],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 9']);
                    dispatch(setModule(2));
                    dispatch(setMission(9));
                    dispatch(setNotification('mission9'))
                }
            },
            {
                id: 10,
                title: 'Belege organisieren',
                unlocked: missionCompleted[10],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 10']);
                    dispatch(setModule(2));
                    dispatch(setMission(10));
                    dispatch(setNotification('mission10'))
                }
            },
            {
                id: 12,
                title: 'Kassabericht erstellen',
                unlocked: missionCompleted[12],
                onClick: () => {
                    window._paq.push(['trackEvent', 'Interaction', 'Mission', 'Start Mission 12']);
                    dispatch(setModule(2));
                    dispatch(setMission(12));
                    dispatch(setNotification('mission12'))
                }
            },
        ]
    }


    return (
        <div className={styles.container}>
            {
                // @ts-ignore
                data?.map((mission: Mission) => <MissionChoiceBox key={mission.id} title={mission.title} missionUnlocked={mission.unlocked}
                                                                     onClick={mission.onClick}/>)
            }
        </div>
    )

};

export default MissionChoice;