import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMenu, Page as Type } from 'hooks/menu';

import { RootState } from 'redux/rootReducer';
import { setOverlay, setNextPage } from 'redux/slices/appSlice';

import Header from 'components/DocumentHeader';

import Highscore from 'pages/Highscore';
import Imprint from 'pages/Imprint';
import Privacy from 'pages/Privacy';

import styles from './MenuPage.module.scss';
import useFunctions from "../../hooks/functions";

const Page = () => {
  const { nextPage } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const [page] = useMenu();
  const [{ setPage }] = useFunctions();

  const close = () => {
    dispatch(setOverlay(null));
    if (nextPage) {
      setPage(nextPage);
      dispatch(setNextPage(null));
    }
  };

  let element = null;

  switch (page.type) {
    case Type.Highscore:
      element = <Highscore />;
      break;
    case Type.Imprint:
      element = <Imprint />;
      break;
    case Type.Privacy:
      element = <Privacy />
      break;
  }

  return (
    <div className={`${styles.container}`} style={page.background ? { backgroundImage: `url('${page.background}')` } : {}}>
      <Header title={page.title} onClose={close} />
      <div className={styles.content}>
        {element}
      </div>
    </div>
  );
}

export default Page;