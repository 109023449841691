import React from 'react';

import styles from './Hotspot.module.scss';

export type Position = {
  x: string;
  y: string;
};

export type Props = {
  className?: string;
  position?: Position;
  onClick?: () => void;
};

const Hotspot = ({
  className = '',
  position,
  onClick,
}: Props) => {
  const containerStyle: React.CSSProperties = {};

  if (position) {
    containerStyle.position = 'absolute';
    containerStyle.left = position.x;
    containerStyle.top = position.y;
    containerStyle.transform = 'translate(-50%, -50%)';
    containerStyle.margin = 0;
  }

  return (
    <section className={`${styles.container} ${className}`} style={containerStyle} onClick={onClick}>
      <span className={styles.ring}/>
      <span className={styles.circle}/>
    </section>
  );
}

export default Hotspot;