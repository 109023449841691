import { Type } from "../../document";
import {currentYear} from "../variables";

export const S32 = () => {

    return {
        type: Type.ImageCloze,
        title: `Beleg S 32`,
        src: require('static/images/tasks/mission12/mKe_S32.png'),
        hotspots: {
            0: {x: '80%', y: '22%'},
        },
        initialAnswers: {
            0: {label: `12.11.${currentYear}`, active: false},
        }
    };
}