import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import {Option} from "../components/Choice";
import {
  setHighlightMenu, setMenu, setMission,
  setNotification as setCurrentNotification,
  setNotification, setOverlay as setCurrentOverlay,
  setWasJokerUsed, setUnlockedMissions,
} from "../redux/slices/appSlice";
import {resetAnsweredTasks, setTask} from "../redux/slices/taskSlice";
import {resetPointsDetails, setPoints, setTime} from "../redux/slices/scoreSlice";
import useFunctions from "./functions";

type Tutorials = {
  [key: string]: Slides;
};

type Slides = {
  [key: number]: Slide;
};

type Slide = {
  image?: string;
  icon?: string;
  text?: string;
  options?: Option[];
  children?: React.ReactNode;
  onBack?: () => void;
  onNext?: () => void;
}

export const useTutorial = () => {
  const { tutorial: currentTutorial} = useSelector((state: RootState) => state.app);
  const { image: userImage, firstName, id: userId } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [{ setPage }] = useFunctions();

  const goToPreviousScreen = () => {
    setPage('user');
  };

  const moduleSelection = () => {
    window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Completed']);
    dispatch(setMission(0));
    dispatch(setNotification('moduleSelection'));
    setPage('notification');
    dispatch(setMenu(['home']))
  };

  const skipTutorial = () => {
    window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Skipped']);
    moduleSelection();
  };

  const options = [{
    label: 'Intro überspringen',
    onClick: skipTutorial,
  }];

  const missionOptions1 = [{
    label: 'Intro überspringen',
    onClick: () => {
      resetMission(1);
      dispatch(setMission(1));
      dispatch(setCurrentNotification('mission1'));
      setPage('notification');
      dispatch(setTask(''));
      clearHighlight();
      setCurrentMenu(['home', 'library', 'notepad', 'joker'])
    },
  }];

  const missionOptions2 = [{
    label: 'Intro überspringen',
    onClick: () => {
      resetMission(5);
      dispatch(setMission(5));
      dispatch(setWasJokerUsed(false));
      dispatch(setCurrentNotification('mission5'));
      dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
      setPage('notification');
      dispatch(setTask(''));
      clearHighlight();
      setCurrentMenu(['home', 'library', 'clipboard', 'joker'])
      window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Skipped']);
    },
  }];

  const clearHighlight = () => {
    dispatch(setHighlightMenu(['']));
  }

  const setCurrentMenu = (items: string[]) => {
    dispatch(setMenu(items))
  }

  const setCurrentHighlightMenu = (items: string[]) => {
    dispatch(setHighlightMenu(items))
  }

  const resetMission = (mission: number) => {
    dispatch(setCurrentOverlay(null));
    dispatch(setWasJokerUsed(false));
    dispatch(setTime({ mission, time: 0 }));
    dispatch(setPoints({ mission, points: 0 }));
    dispatch(resetPointsDetails({ mission }));
    dispatch(resetAnsweredTasks({ mission }));
    dispatch(setUnlockedMissions([mission, 1,5,6,7,8,9,10,11,12,13]));
    dispatch(setTask(''));
    dispatch(setNotification(`mission${mission}`));
    setPage('notification');
    dispatch(setMission(mission));
  };

  const jokerImage = require('static/images/avatars/joker.png');

  const tutorials: Tutorials = {
    'main': {
      1: {
        image: userImage,
        children: (
            <>
              <p>Hallo {firstName}</p>
              <p>Ich brauche kurz deine Aufmerksamkeit, um dir „Kompetent im Office“ näher vorzustellen.</p>
            </>
        ),
        onBack: goToPreviousScreen,
      },
      2: {
        image: userImage,
        text: 'Du arbeitest im Office der Memoboard GmbH. Das Unternehmen handelt mit Pinnwänden, Magnettafeln und Memoboards (das ist eine moderne Ausführung von Pinnwänden).',
        options,
      },
      3: {
        image: require('static/images/avatars/team-lead-happy.png'),
        text: `Deine Teamleiterin betraut dich mit verschiedenen Aufträgen und gibt dir gerne Feedback. Lass alles von ihr checken, bevor es an deine Kunden geht.`,
        options,
      },
      4: {
        image: userImage,
        children: (
            <>
              <p>Für jeden Arbeitsauftrag kannst du eine bestimmte Anzahl an Punkten erreichen. In der Menüleiste siehst
                du,
                wie viele Punkte pro Auftrag maximal zu erreichen sind und wie viele du bereits gesammelt hast.</p>
            </>
        ),
        onNext: () => setCurrentHighlightMenu(['home']),
        options,
      },
      5: {
        icon: 'home',
        text: 'Im Menü kannst du deine Aufträge verwalten und nachsehen, was du schon alles erledigt hast. Klicke auf dieses Symbol in der Menüleiste, um das Menü zu öffnen.',
        onNext: clearHighlight,
        onBack: clearHighlight,
        options,
      },
      6: {
        image: userImage,
        children: (
            <>
              <p>Starte jetzt mit deinem ersten Auftrag.</p>
              <p>Viel Erfolg in deinem Office!</p>
            </>
        ),
        onBack: () => setCurrentHighlightMenu(['home']),
        onNext: moduleSelection,
      },
    },
    'module1': {
      1: {
        icon: 'screen',
        text: 'Auf deinem Arbeitsplatz findest du alle Dokumente (zB Kundenanfrage) und Programme (zB Lagerbestand), die du für die Bearbeitung deiner Aufträge benötigst.',
        options: missionOptions1,
        onBack: moduleSelection,
        onNext: () => {
          setCurrentHighlightMenu(['notepad'])
          setCurrentMenu(['home', 'library', 'notepad'])
        }
      },
      2: {
        icon: 'document',
        text: 'Wichtige Infos kannst du am Notizblock festhalten, zB welches Produkt bestellt wird.',
        options: missionOptions1,
        onBack: () => {
          setCurrentHighlightMenu(['library'])
          setCurrentMenu(['home', 'library'])
        },
        onNext: ()=> {
          clearHighlight()
          setCurrentMenu(['home', 'library', 'notepad', 'joker'])
        }
      },
      3: {
        image: jokerImage,
        children: (
            <>
              <p>Deine Kollegin ist dein Joker. Sie hat aber viel zu tun, deshalb kannst du sie nur einmal pro Auftrag um Rat fragen.</p>
              <p>Klicke auf das entsprechende Symbol im Menü, damit sie dir hilft.</p>
            </>
        ),
        options: missionOptions1,
        onBack: () => {
          setCurrentHighlightMenu(['notepad'])
          setCurrentMenu(['home', 'library', 'notepad'])
        },
      },
      4: {
        image: userImage,
        children: (
            <>
              <p>Du kannst auch eine Pause machen. Deine bis dahin durchgeführten Arbeiten bleiben auf deinem Gerät gespeichert.</p>
            </>
        ),
        onNext: () => {
          window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Completed']);
          resetMission(1);
          dispatch(setMission(1));
          dispatch(setCurrentNotification('mission1'));
          setPage('notification');
          dispatch(setTask(''));
        }
      },
    },
    'module2': {
      1: {
        icon: 'screen',
        text: 'Auf deinem Arbeitsplatz findest du alle Dokumente (zB Kundenanfrage) und Programme (zB Lagerbestand), die du für die Bearbeitung deiner Aufträge benötigst.',
        options: missionOptions2,
        onBack: moduleSelection,
        onNext: () => {
          setCurrentHighlightMenu(['clipboard'])
          setCurrentMenu(['home', 'library', 'clipboard'])
        }
      },
      2: {
        icon: 'clipboard-empty',
        text: 'Gelb markierten Text kannst du in deine Zwischenablage kopieren. So behältst du immer den Überblick. Bei manchen Aufgaben ist es hilfreich, Inhalte aus der Zwischenablage einzufügen.',
        options: missionOptions2,
        onBack: () => {
          setCurrentHighlightMenu(['library'])
          setCurrentMenu(['home', 'library'])
        },
        onNext: ()=> {
          clearHighlight()
          setCurrentMenu(['home', 'library', 'clipboard', 'joker'])
        }
      },
      3: {
        image: jokerImage,
        children: (
            <>
              <p>Deine Kollegin ist dein Joker. Sie hat aber viel zu tun, deshalb kannst du sie nur einmal pro Auftrag um Rat fragen.</p>
              <p>Klicke auf das entsprechende Symbol im Menü, damit sie dir hilft.</p>
            </>
        ),
        options: missionOptions2,
        onBack: () => {
          setCurrentHighlightMenu(['notepad'])
          setCurrentMenu(['home', 'library', 'clipboard'])
        },
      },
      4: {
        image: userImage,
        children: (
            <>
              <p>Du kannst auch eine Pause machen. Deine bis dahin durchgeführten Arbeiten bleiben auf deinem Gerät gespeichert.</p>
            </>
        ),
        onNext: () => {
          window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Completed']);
          resetMission(5);
          dispatch(setMission(5));
          dispatch(setWasJokerUsed(false));
          dispatch(setCurrentNotification('mission5'));
          dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
          setPage('notification');
          dispatch(setTask(''));
        }
      },
    }
  };

  const [tutorial, setTutorial] = useState(tutorials[currentTutorial]);

  useEffect(() => {
    setTutorial(tutorials[currentTutorial]);
  }, [currentTutorial, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return tutorial;
};
