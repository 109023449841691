import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reset, RootState } from 'redux/rootReducer';
import {
  setOverlay as setCurrentOverlay,
  setMission,
  setNotification,
  setWasJokerUsed,
  setUnlockedMissions,
  setModuleScore, setModule, unlockDocument, setMenu
} from 'redux/slices/appSlice';
import { setPoints, resetPointsDetails, setTime } from 'redux/slices/scoreSlice';

import Header from 'components/DocumentHeader';
import List from 'components/List';
import { Page as Document } from 'hooks/library'

import styles from './Menu.module.scss';
import { resetAnsweredTasks, setTask } from 'redux/slices/taskSlice';
import useFunctions from "../../hooks/functions";

const Page = () => {
  const { unlockedMissions } = useSelector((state: RootState) => state.app);
  const { points } = useSelector((state: RootState) => state.score);
  const dispatch = useDispatch();

  const [{ setPage }] = useFunctions();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const setOverlay = (value: string) => {
    dispatch(setCurrentOverlay(`menuPage:${value}`));
  };

  const resetMission = (mission: number) => {
    dispatch(setCurrentOverlay(null));
    dispatch(setWasJokerUsed(false));
    dispatch(setTime({ mission, time: 0 }));
    dispatch(setPoints({ mission, points: 0 }));
    dispatch(resetPointsDetails({ mission }));
    dispatch(resetAnsweredTasks({ mission }));
    dispatch(setUnlockedMissions([mission, 1, 5,6,7,8,9,10,11,12,13]));
    dispatch(setTask(''));
    dispatch(setNotification(`mission${mission}`));
    setPage('notification');
    dispatch(setMission(mission));
  };

  const calculateCorrectPercentage = (mission: number) => {
    return Math.floor((points[mission].points / points[mission].maxPoints) * 100);
  };

  const close = () => {
    dispatch(setCurrentOverlay(null));
  }

  const items = [
    {
      label: 'Modul: Auftragsabwicklung',
      locked: false,
      icon: 'plus',
      accordion: true,
      children: [
        {
          label: 'Angebot',
          caption: `${calculateCorrectPercentage(1)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(1) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(1));
            dispatch(setMenu(['home', 'library', 'notepad', 'joker']));
            resetMission(1);
            },
        },
        {
          label: 'Auftragsbestätigung',
          caption: `${calculateCorrectPercentage(2)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(2) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(1));
            dispatch(setMenu(['home', 'library', 'notepad', 'joker']));
            resetMission(2);
          },
        },
        {
          label: 'Lieferschein',
          caption: `${calculateCorrectPercentage(3)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(3) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(1));
            dispatch(setMenu(['home', 'library', 'notepad', 'joker']));
            resetMission(3);
          },
        },
        {
          label: 'Rechnung',
          caption: `${calculateCorrectPercentage(4)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(4) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            dispatch(setModule(1));
            dispatch(setMenu(['home', 'library', 'notepad', 'joker']));
            resetMission(4);
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
          },
        },
        { label: 'Rangliste',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Opened High Score Scenario 1']);
            dispatch(setModuleScore(1))
            setOverlay('highscore');
          }
        },
      ]
    },
    {
      label: 'Modul: Rechnungswesen',
      locked: false,
      icon: 'plus',
      accordion: true,
      children: [
        {
          label: 'Angebote vergleichen',
          caption: `${calculateCorrectPercentage(5)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(5) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(5);
          },
        },
        {
          label: 'Verkaufspreis ermitteln',
          caption: `${calculateCorrectPercentage(6)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(6) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(unlockDocument(Document.Bezugskalkulation));
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(6);
          },
        },
        {
          label: 'Kennzahlen berechnen',
          caption: `${calculateCorrectPercentage(7)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(7) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(7);
          },
        },
        {
          label: 'Mahnungen erstellen',
          caption: `${calculateCorrectPercentage(8)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(8) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(8);
          },
        },
        {
          label: 'Belege prüfen',
          caption: `${calculateCorrectPercentage(9)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(9) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(9);
          },
        },
        {
          label: 'Belege organisieren',
          caption: `${calculateCorrectPercentage(10)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(10) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(10);
          },
        },
        {
          label: 'Verbuchung der Belege',
          caption: ``,
          locked: unlockedMissions.indexOf(11) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(11);
          },
        },
        {
          label: 'Kassabericht erstellen',
          caption: `${calculateCorrectPercentage(12)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(12) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(12);
          },
        },
        {
          label: 'Zahlung vorbereiten',
          caption: `${calculateCorrectPercentage(13)} % der Aufgaben richtig erledigt.`,
          locked: unlockedMissions.indexOf(13) === -1,
          icon: 'redo2',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Reset Mission']);
            dispatch(setModule(2));
            dispatch(setMenu(['home', 'library', 'clipboard', 'joker']));
            resetMission(13);
          },
        },
        { label: 'Rangliste',
          child: true,
          onClick: () => {
            window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Opened High Score Scenario 1']);
            dispatch(setModuleScore(2))
            setOverlay('highscore');
          }
        },
      ]
    },
    { label: 'Impressum', onClick: () => {
        window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Button Click']);
        setOverlay('imprint'); }
    },
    { label: 'Datenschutz', onClick: () => {
        window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Button Click']);
        setOverlay('privacy'); }
    },
    { 
      label: 'Fortschritt zurücksetzen', 
      caption: 'und mit neuem Charakter neu starten',
      icon: 'redo2',
      onClick: () => {
        window._paq.push(['trackEvent', 'Interaction', 'Menu', 'Restart']);
        setPage('/');
        dispatch(reset());
      } 
    },
  ];

  return (
    <div className={`${styles.container} ${animate ? styles.slideIn : ''}`}>
      <Header title='Menü' onClose={close} />
      <div className={styles.content}>
        <List items={items} key={'list'}/>
      </div>
    </div>
  );
};

export default Page;
