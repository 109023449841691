import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Page as Document} from 'hooks/library'

import {RootState} from 'redux/rootReducer';
import {
  setFeedback,
  setNotification,
  setOverlay as setCurrentOverlay,
} from 'redux/slices/appSlice';
import {addPoints, setPointsDetails} from 'redux/slices/scoreSlice';
import {setAnsweredTasks, setTask as setCurrentTask } from 'redux/slices/taskSlice';

import {Answer, Answers, Props as ImageCloze} from 'components/ImageCloze';
import {Type} from 'components/HotspotChoice';

import {MissionFive} from "./imageCloze/mission5";
import {MissionSix} from "./imageCloze/mission6";
import {MissionSeven} from "./imageCloze/mission7";
import {MissionEight} from "./imageCloze/mission8";
import {MissionNine} from "./imageCloze/mission9";
import {MissionTen} from "./imageCloze/mission10";
import {MissionEleven} from "./imageCloze/mission11";
import {MissionTwelve} from "./imageCloze/mission12";
import {MissionThirteen} from "./imageCloze/mission13";
import useFunctions from "./functions";

type Missions = {
  [key: number]: Tasks;
};

type Tasks = {
  [key: string]: ImageCloze;
};

export const useTask = () => {
  const { mission: currentMission } = useSelector((state: RootState) => state.app);
  const { firstName, lastName } = useSelector((state: RootState) => state.user);
  const { answeredTasks, task: currentTask } = useSelector((state: RootState) => state.task);
  const { pointsDetails } = useSelector((state: RootState) => state.score);
  const dispatch = useDispatch();

  const [{ setPage }] = useFunctions();
  const setPoints = (answers: Answers) => {
    Object.keys(answers).forEach(key => {
      const answer = answers[key];
      if (answer.correct && !pointsDetails[currentMission][currentTask]) {
        dispatch(addPoints({ mission: currentMission, points: answer.points || 1 }));
      }
      dispatch(setPointsDetails({ mission: currentMission, task: currentTask, answer: key, points: answer.correct ? (answer.points || 1) : 0 }))
    });
  };

  const setAnswer = (answer: Answer) => {
    dispatch(setAnsweredTasks({ mission: currentMission, task: currentTask, answer: answer.id, option: answer }));
  };

  const setOverlay = (value: string) => {
    dispatch(setCurrentOverlay(`document:${value}`));
  };

  const priceList : { [identifer: string]: number } = {
    '15714': 14.20 * 500,
    '15715': 19.17 * 1000,
    '15716': 21.30 * 300,
    '15717': 28.70 * 100,
    '15718': 19.30 * 300,
    '15719': 26.70 * 100,
    '15720': 14.20 * 2000,
    '15721': 12.20 * 1500,
  };

  const discountList: { [discount: string]: number } = {
    '5 %': 0.95,
    '10 %': 0.9,
    '12 %': 0.88,
    '13 %': 0.87,
  };

  const taxList: { [tax: string]: number } = {
    '0 %': 1,
    '10 %': 1.1,
    '13 %': 1.13,
    '20 %': 1.2,
  };

  const [price, setPrice] = useState(
    answeredTasks[4]['bill'] && answeredTasks[4]['bill'][0]
      ? priceList[answeredTasks[4]['bill'][0].label]
      : 0
  );
  const [discount, setDiscount] = useState(
    answeredTasks[4]['bill'] && answeredTasks[4]['bill'][2]
      ? discountList[answeredTasks[4]['bill'][2].label]
      : 0
  );
  const [tax, setTax] = useState(
    answeredTasks[4]['bill'] && answeredTasks[4]['bill'][3]
      ? taxList[answeredTasks[4]['bill'][3].label]
      : 1
  );

  const currentYear = new Date().getFullYear();

  const missions : Missions = ({
    1: {
      warehouseRemoval: {
        title: 'Aufgabe: Warenentnahme',
        src: require('static/images/tasks/mission1-4/entnahme.png'),
        hotspots: {
          0: {
            x: '8%',
            y: '22.3%',
            options: [
              { label: '532687', correct: true, onClick: setAnswer },
              { label: '532688', onClick: setAnswer },
              { label: '532689', onClick: setAnswer },
              { label: '532690', onClick: setAnswer },
              { label: '578912', onClick: setAnswer },
              { label: '578913', onClick: setAnswer },
              { label: '578914', onClick: setAnswer },
              { label: '578915', onClick: setAnswer },
            ],
          },
          1: {
            x: '37%',
            y: '22.3%',
            title: 'Anzahl',
            type: Type.Input,
            correct: ['1'],
            onSubmit: setAnswer,
          },
          2: {
            x: '57%',
            y: '22.3%',
            options: [
              { label: 'kostenloses Produktmuster', correct: true, onClick: setAnswer },
              { label: 'Umtausch', onClick: setAnswer },
              { label: 'Kauf', onClick: setAnswer },
            ],
          },
          3: { x: '81.2%', y: '33.8%' },
          4: { x: '81.2%', y: '41.5%' },
          5: { x: '81.2%', y: '49%' },
          6: { x: '81.2%', y: '56.5%' },
          7: { x: '81.2%', y: '64.2%' },
          8: { x: '81.2%', y: '71.5%' },
          9: { x: '81.2%', y: '79.2%' },
          10: { x: '81.2%', y: '86.8%' },
        },
        initialAnswers: {
          3: { label: `20.11.${currentYear}`, active: false },
          4: { label: `15.11.${currentYear}`, active: false },
          5: { label: `20.12.${currentYear}`, active: false },
          6: { label: `15.12.${currentYear}`, active: false },
          7: { label: `10.12.${currentYear}`, active: false },
          8: { label: `15.12.${currentYear}`, active: false },
          9: { label: `20.11.${currentYear}`, active: false },
          10: { label: `15.11.${currentYear}`, active: false },
        },
        onContinue: (answers) => {
          setPoints(answers);
          dispatch(setCurrentTask(''));
          dispatch(setFeedback('warehouseRemoval'));
          setPage('feedback');
        },
      }
    },
    2: {
      orderConfirmation: {
        title: 'Aufgabe: Erstellung der Auftragsbestätigung',
        src: require('static/images/tasks/mission1-4/auftragsbestaetigung.png'),
        hotspots: {
          0: {
            x: '41%',
            y: '61%',
            title: 'Wie berechnest du den Gesamtauftragswert?',
            options: [
              { label: '14,2 x 2.000 / 88 x 76', alternativeLabel: '24.527,27', onClick: setAnswer },
              { label: '14,2 x 2.000 / 100 x 88', alternativeLabel: '24.992,00', correct: true, points: 3, onClick: setAnswer },
              { label: '14,2 x 2.000 / 112 x 100', alternativeLabel: '25.357,14', onClick: setAnswer},
            ],
          },
          1: {
            x: '29%',
            y: '68.7%',
            options: [
              { label: 'Lieferung bis Ende November frei Haus', correct: true, onClick: setAnswer },
              { label: 'Lieferung bis Ende November ab Werk', onClick: setAnswer },
            ],
          },
          2: { x: '2.8%', y: '80.5%' },
          3: { x: '15%', y: '7.4%' },
        },
        initialAnswers: {
          2: { label: `${firstName} ${lastName}`, active: false },
          3: { label: `20. November ${currentYear}`, active: false },
        },
        onContinue: (answers) => {
          setPoints(answers);
          dispatch(setCurrentTask(''));
          dispatch(setFeedback('orderConfirmation'));
          setPage('feedback');
        },
      },
    },
    3: {
      deliveryNote: {
        title: 'Aufgabe: Erstellung des Lieferscheins',
        src: require('static/images/tasks/mission1-4/lieferschein-app.png'),
        hotspots: {
          0: {
            x: '42%',
            y: '20.5%',
            options: [
              { label: 'Lanta AG', onClick: setAnswer },
              { label: 'Nig GmbH', correct: true, onClick: setAnswer },
              { label: 'Peter Grimal', onClick: setAnswer },
            ],
          },
          1: {
            x: '79%',
            y: '20.5%',
            options: [
              { label: `20.11.${currentYear}`, correct: true, onClick: setAnswer  },
              { label: `20.12.${currentYear}`, onClick: setAnswer },
            ],
          },
          2: {
            x: '17%',
            y: '40%',
            options: [
              { label: '533689', children: [{ x: '37%', y: '40%', label: 'MDF-Memoboard weiß, 60 x 90 cm' }], onClick: setAnswer },
              { label: '578912', children: [{ x: '37%', y: '40%', label: 'MDF-Memoboard natur, 42 x 29 cm' }], onClick: setAnswer },
              { label: '532687', correct: true, children: [{ x: '37%', y: '40%', label: 'MDF-Memoboard weiß, 42 x 29 cm' }], onClick: setAnswer },
              { label: 'In Auftragsbestätigung nachsehen', onClick: () => { setOverlay(Document.OrderConfirmation); }, noAnswer: true },
            ],
          },
          3: {
            x: '84%',
            y: '40%',
            title: 'Anzahl',
            type: Type.Input,
            correct: ['2000', '2.000'],
            points: 2,
            onSubmit: setAnswer,
          },
        },
        onContinue: (answers) => {
          setPoints(answers);

          if(!pointsDetails[currentMission][currentTask]) {
            dispatch(setFeedback('deliveryNote'));
            dispatch(setCurrentTask('whatDoYouDoNow'));
            dispatch(setNotification('whatDoYouDoNow'));
            setPage('notification');
          } else {
            dispatch(setCurrentTask(''));
            dispatch(setFeedback('deliveryNote'));
            setPage('feedback');
          }
        },
      }
    },
    4: {
      bill: {
        title: 'Aufgabe: Rechnungserstellung',
        src: require('static/images/tasks/mission1-4/rechnung.png'),
        hotspots: {
          0: {
            x: '37%',
            y: '25.8%',
            options: [
              {
                label: '15714',
                children: [
                  { x: '37%', y: '18.5%', label: 'Lios GmbH' },
                  { x: '4%', y: '35.5%', label: '1          532687           MDF-Memoboard weiß,    EUR 14,20                     1.000' },
                  { x: '27.7%', y: '38.5%', label: '42 x 29 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15714']); }
              },
              {
                label: '15715',
                children: [
                  { x: '37%', y: '18.5%', label: 'Lindner AG' },
                  { x: '4%', y: '35.5%', label: '1          532688           MDF-Memoboard weiß    EUR 19,17                     1.000' },
                  { x: '27.7%', y: '38.5%', label: '45 x 60 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15715']); }
              },
              {
                label: '15716',
                children: [
                  { x: '37%', y: '18.5%', label: 'Manfred Einzelbaum e.U.' },
                  { x: '4%', y: '35.5%', label: '1          532689           MDF-Memoboard weiß    EUR 21,30                     300' },
                  { x: '27.7%', y: '38.5%', label: '60 x 90 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15716']); }
              },
              {
                label: '15717',
                children: [
                  { x: '37%', y: '18.5%', label: 'Klaus Müller GmbH' },
                  { x: '4%', y: '35.5%', label: '1          532690           MDF-Memoboard weiß    EUR 28,70                     100' },
                  { x: '27.7%', y: '38.5%', label: '100 x 200 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15717']); }
              },
              {
                label: '15718',
                children: [
                  { x: '37%', y: '18.5%', label: 'Klaus Kogler e.U.' },
                  { x: '4%', y: '35.5%', label: '1          578914           MDF-Memoboard natur   EUR 19,30                     300' },
                  { x: '27.7%', y: '38.5%', label: '60 x 90 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15718']); }
              },
              {
                label: '15719',
                children: [
                  { x: '37%', y: '18.5%', label: 'Gmaus GmbH' },
                  { x: '4%', y: '35.5%', label: '1          578915           MDF-Memoboard natur    EUR 26,70                     100' },
                  { x: '27.7%', y: '38.5%', label: '100 x 200 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15719']); }
              },
              {
                label: '15720',
                children: [
                  { x: '37%', y: '18.5%', label: 'Nig GmbH' },
                  { x: '4%', y: '35.5%', label: '1          532687           MDF-Memoboard weiß    EUR 14,20                     2.000' },
                  { x: '27.7%', y: '38.5%', label: '42 x 29 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15720']); },
                correct: true,
              },
              {
                label: '15721',
                children: [
                  { x: '37%', y: '18.5%', label: 'Alexander Kranick e.U.' },
                  { x: '4%', y: '34.5%', label: '1          578912           MDF-Memoboard natur   EUR 12,20                     1.500' },
                  { x: '27.7%', y: '38.5%', label: '42 x 29 cm' }
                ],
                onClick: (answers) => { setAnswer(answers); setPrice(priceList['15721']); }
              },
              { label: 'Lieferscheine ansehen...', onClick: () => { setOverlay(Document.DeliveryNotes); }, noAnswer: true },
            ],
          },
          1: {
            x: '37%',
            y: '50%',
            options: [
              { label: `01.11.${currentYear}`, onClick: setAnswer },
              { label: `03.11.${currentYear}`, onClick: setAnswer },
              { label: `14.11.${currentYear}`, onClick: setAnswer },
              { label: `15.11.${currentYear}`, onClick: setAnswer },
              { label: `20.11.${currentYear}`, onClick: setAnswer, correct: true },
              { label: 'Lieferscheine ansehen...', onClick: () => { setOverlay(Document.DeliveryNotes); }, noAnswer: true },
            ],
          },
          2: {
            x: '37%',
            y: '56.5%',
            options: [
              { label: '5 %', onClick: (answers) => { setAnswer(answers); setDiscount(discountList['5 %']); } },
              { label: '10 %', onClick: (answers) => { setAnswer(answers); setDiscount(discountList['10 %']); } },
              { label: '12 %', onClick: (answers) => { setAnswer(answers); setDiscount(discountList['12 %']); }, correct: true },
              { label: '13 %', onClick: (answers) => { setAnswer(answers); setDiscount(discountList['13 %']); } },
              { label: 'Kundendatenbank ansehen...', onClick: () => { setOverlay(Document.ClientDatabase); }, noAnswer: true },
            ],
          },
          3: {
            x: '37%',
            y: '63%',
            options: [
              { label: '0 %', onClick: (answers) => { setAnswer(answers); setTax(taxList['0 %']); }},
              { label: '10 %', onClick: (answers) => { setAnswer(answers); setTax(taxList['10 %']); }},
              { label: '13 %', onClick: (answers) => { setAnswer(answers); setTax(taxList['13 %']); }},
              { label: '20 %', onClick: (answers) => { setAnswer(answers); setTax(taxList['20 %']); }, correct: true },
            ],
          },
          4: {
            x: '37%',
            y: '69.3%',
            options: [
              { label: '7 Tage 2 % Skonto, \n14 Tage netto Kassa', onClick: setAnswer, correct: true },
              { label: '7 Tage 3 % Skonto, \n10 Tage netto Kassa', onClick: setAnswer },
            ],
          },
        },
        onContinue: (answers) => {
          setPoints(answers);

          if(!pointsDetails[currentMission][currentTask]) {
            dispatch(setFeedback('bill'));
            dispatch(setCurrentTask('whatDoYouDoAfterBill'));
            dispatch(setNotification('whatDoYouDoAfterBill'));
            setPage('notification');
          } else {
            dispatch(setCurrentTask(''));
            dispatch(setFeedback('bill'));
            setPage('feedback');
          }
        },
        children:
          <>
            <span style={{ top: '84.7%', left: '37%' }}>{`${(discount * price).toLocaleString('de-DE', { minimumFractionDigits: 2 })}`}</span>
            <span style={{ top: '90.7%', left: '37%' }}>{`${(discount * price * tax).toLocaleString('de-DE', { minimumFractionDigits: 2 })}`}</span>
          </>
      }
    },
    5: MissionFive(),
    6: MissionSix(),
    7: MissionSeven(),
    8: MissionEight(),
    9: MissionNine(),
    10: MissionTen(),
    11: MissionEleven(),
    12: MissionTwelve(),
    13: MissionThirteen()

  });

  const [task, setTask] = useState(missions[currentMission][currentTask]);
  const [initialAnswers, setInitialAnswers] = useState({
    ...answeredTasks[currentMission][currentTask] as Answers,
    ...missions[currentMission][currentTask].initialAnswers
  });

  useEffect(() => {
    setTask(missions[currentMission][currentTask]);
  }, [currentMission, currentTask, answeredTasks]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialAnswers(answeredTasks[currentMission][currentTask] as Answers);
  }, [currentMission, currentTask, answeredTasks]);

  return { task, initialAnswers };
}
