import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Option as TextClozeOption } from 'components/TextCloze';
import { Option as ImageClozeOption } from 'components/ImageCloze';

type AnsweredTasks = {
  [mission: string]: Tasks;
}

type Tasks = {
  [task: string]: Task;
}

type Task = {
  [answer: string]: TextClozeOption | ImageClozeOption | null;
}

type Clipboard = {
  [mission: number]: ClipboardDocuments;
}

type ClipboardDocuments = {
  [document: string]: ClipboardEntries;
}

type ClipboardEntries = {
  [title: string]: ClipboardEntry;
}

type ClipboardEntry = {
  title: string
  value: string
  isEnabled: boolean
  isPercentage: boolean
}

type Attempts = {
  [number: number]: TaskAttempts;
}

type TaskAttempts = {
  [title: string]: Attempt;
}

type Attempt = {
  count: number
}

export const slice = createSlice({
  name: 'task',
  initialState: {
    task: '',
    skippable: false,
    answeredTasks: {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      10: {},
      11: {},
      12: {},
      13: {},
    } as AnsweredTasks,
    clipboard: {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      10: {},
      11: {},
      12: {},
      13: {},
    } as Clipboard,
    attempts: {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      10: {},
      11: {},
      12: {},
      13: {},
    } as Attempts
  },
  reducers: {
    setTask: (state, action: PayloadAction<string>) => { state.task = action.payload },
    setAnsweredTasks: (state, action: PayloadAction<{mission: number; task: string; answer: string; option: TextClozeOption | ImageClozeOption | null;}>) => {
      const entries = { ...state.answeredTasks };
      if (!entries[action.payload.mission][action.payload.task]) {
        entries[action.payload.mission][action.payload.task] = {};
      }
      entries[action.payload.mission][action.payload.task][action.payload.answer] = action.payload.option;

      state.answeredTasks = entries; 
    },
    resetAnsweredTasks: (state, action: PayloadAction<{mission: number;}>) => {
      const entries = { ...state.answeredTasks };
      entries[action.payload.mission] = {};

      state.answeredTasks = entries;
    },

    setTaskSkippable: (state, action: PayloadAction<boolean>) => { state.skippable = action.payload },
    setClipboardEntry: (state, action: PayloadAction<{mission: number; document: string; order: number;
      title: string; value: string; isEnabled: boolean; isPercentage: boolean;}>) => {
      const entries = { ...state.clipboard };

      if (!entries[action.payload.mission][action.payload.document]) {
        entries[action.payload.mission][action.payload.document] = {};
      }

      if (!entries[action.payload.mission][action.payload.document][action.payload.order]) {
        // @ts-ignore
        entries[action.payload.mission][action.payload.document][action.payload.order] = {};
      }

      entries[action.payload.mission][action.payload.document][action.payload.order]['title'] = action.payload.title;
      entries[action.payload.mission][action.payload.document][action.payload.order]['value'] = action.payload.value;
      entries[action.payload.mission][action.payload.document][action.payload.order]['isEnabled'] = action.payload.isEnabled;
      entries[action.payload.mission][action.payload.document][action.payload.order]['isPercentage'] = action.payload.isPercentage;
      state.clipboard = entries;
    },

    setAttempts: (state, action: PayloadAction<{mission: number; task: string;}>) => {
      const entries = { ...state.attempts };

      if (!entries[action.payload.mission][action.payload.task]) {
        entries[action.payload.mission][action.payload.task] = { count: 0};
      }

      entries[action.payload.mission][action.payload.task]['count']++;
      state.attempts = entries;
    },
  },
});

export const { setAnsweredTasks, resetAnsweredTasks, setTask, setClipboardEntry, setAttempts, setTaskSkippable } = slice.actions;

export default slice.reducer;