import React from 'react';

import styles from './Animation.module.scss';

export type Props = {
  src: string;
  className?: string;
  height?: string;
  width?: string;
  maxWidth?: string;
  style?: React.CSSProperties;
}

const Animation = ({
  src,
  className = '',
  style = {},
  height = 'auto',
  width = '100%',
  maxWidth = '365px',
}: Props) => (
  <img
    alt='animation'
    className={`${styles.container} ${className}`}
    style={{width: width, height: height, maxWidth: maxWidth, ...style }}
    src={src}
  />
);

export default Animation;
