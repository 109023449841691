import { Type } from "../../document";
import {currentYear} from "../variables";

export const Verzugszinsenberechnung1 = () => {

    return {
        type: Type.ImageCloze,
        title: `Lösung: Verzugszinsenberechnung - Teil 1`,
        src: require('static/images/tasks/mission8/mMe_VerzugszinsenBerechnen1.png'),
        hotspots: {
            0: {
                x: '89%',
                y: '37.5%',
                labelXAlignment: 'right',
                labelXOffset: '8%',
            },
            1: {
                x: '89%',
                y: '50%',
                labelXAlignment: 'right',
                labelXOffset: '8%',
            },
            2: {
                x: '89.5%',
                y: '63%',
                labelXAlignment: 'right',
                labelXOffset: '8%',
            },
            3: {
                x: '89.5%',
                y: '70%',
                labelXAlignment: 'right',
                labelXOffset: '8%',
            },
            4: { x: '75%', y: '14%'},
            5: { x: '94%', y: '83%'}
        },
        initialAnswers: {
            0: {label: '13.668,00', active: false},
            1: {label: '10,00', active: false},
            2: {label: `05.11.${currentYear}`, active: false},
            3: {label: `30.12.${currentYear}`, active: false},
            4: {label: `Heute: 23.12.${currentYear}`, active: false},
            5: {label: `56`, active: false},
        }
    };
}
