import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { Page as Document } from "../library";
import { Notifications } from "./datatypes";
import { teamleadImage } from "./variables";

import {
    setDialogue,
    setHint,
    setNextPage,
    setMission,
    setNotification as setCurrentNotification,
    setShowDocumentButton,
    setShowHint,
    setWasJokerUsed,
    unlockDocument,
    setUnlockedMissions,
    setModuleScore,
    setFinished2,
    setShowSurveyPopUp
} from "../../redux/slices/appSlice";

import {pauseTimer, setMissionCompleted, startTimer} from "../../redux/slices/scoreSlice";

import { setTask } from "../../redux/slices/taskSlice";
import useFunctions from "../functions";
import {RootState} from "../../redux/rootReducer";

export const MissionFive = () => {
    const dispatch = useDispatch();
    const [{ setOverlay, getMessage, saveHighscoreAnonymous, saveHighscoreWithName, setPage, missionsCompleted,
        missionPoints, missionMaxPoints }] = useFunctions();

    const { isFinishedModule2, reachedModuleScore } = useSelector((state: RootState) => state.app);
    const { anonymous } = useSelector((state: RootState) => state.user);

    const missionFiveData: Notifications = {
        mission5: {
            children: <><p>Auftrag:</p><h2>Angebote vergleichen</h2></>,
            style: {justifyContent: 'center'},
            onContinueLabel: 'Weiter',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Scenario', 'Scenario Started', 'Scenario 2']);
                window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 2_1: Angebote vergleichen']);
                dispatch(setTask('compareMemoBoardOffers'));
                dispatch(setDialogue('compareMemoBoardOffers'));
                setPage('dialogue');
                dispatch(startTimer());
            },
        },
        itStillTakesAWhile: {
            image: teamleadImage,
            text: 'Bis wir uns für ein Produkt entscheiden und es bestellen, dauert es noch. Schau dir zuerst einmal die Anfrage an.',
            onContinueLabel: 'Weiter',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('whatsNext'));
                dispatch(setShowDocumentButton(true));
                dispatch(setDialogue('whatsNext'))
                dispatch(setNextPage('dialogue'))
                dispatch(unlockDocument(Document.Produktanfrage));
                setOverlay(Document.Produktanfrage);
            }
        },
        firstLookAtRequirements: {
            image: teamleadImage,
            text: 'Schau dir zuerst die Anfrage an. Aus ihr kannst du die Anforderungen an das Produkt entnehmen.',
            onContinueLabel: 'Weiter',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('whatsNext'));
                dispatch(setShowDocumentButton(true));
                dispatch(setDialogue('whatsNext'))
                dispatch(setNextPage('dialogue'))
                dispatch(unlockDocument(Document.Produktanfrage));
                setOverlay(Document.Produktanfrage);
            }
        },
        spreadsheetLooksGood: {
            image: teamleadImage,
            text: 'Die tabellarische Aufstellung sieht gut aus. Jetzt kannst du die Bezugskalkulation durchführen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('referenceCalculator'));
                setPage('image-cloze');
                dispatch(setHint(3));
                dispatch(setShowHint(true));
            }
        },
        completedSpreadsheetCalculator: {
            icon: 'screen',
            text: 'Du findest deine Bezugskalkulation nun auf deinem Arbeitsplatz.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('putschOrDober'));
                dispatch(setDialogue('putschOrDober'))
                setPage('dialogue')
            },
        },
        mission5Feedback: {
            image: teamleadImage,
            text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten bei diesem Auftrag erreicht. ${getMessage()}`,
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setCurrentNotification('mission5PauseInfo'));
                setPage('notification');
                window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 2_1: Angebote vergleichen']);
                dispatch(pauseTimer());
            },
        },
        mission5PauseInfo: {
            icon: 'checkmark-circle',
            text: 'Möchtest du gleich mit dem nächsten Arbeitsauftrag weitermachen oder eine Pause einlegen? Dein Fortschritt wird auf diesem Gerät gespeichert, sodass du jederzeit weitermachen kannst.',
            onContinueLabel: 'Nächster Auftrag',
            onContinue: () => {
                dispatch(setMissionCompleted({mission: 5, completed: true}))

                if (reachedModuleScore[2]) {

                    if (missionsCompleted([6,7,8,9,10,12,13])){
                        if (isFinishedModule2) {
                            dispatch(setModuleScore(2));
                            if (anonymous) {
                                saveHighscoreAnonymous(2);
                            } else {
                                saveHighscoreWithName(2);
                            }
                            // dispatch(setUnlockedMissions([5]));
                            dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                        } else {
                            dispatch(setFinished2(true));
                            dispatch(setMission(13))
                            dispatch(setShowSurveyPopUp(true));
                            setPage('end-feedback');
                        }
                        window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);

                    } else {
                        dispatch(setMission(0));
                        dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
                        setPage('notification')
                    }
                } else {
                    dispatch(setMission(6));
                    dispatch(setWasJokerUsed(false));
                    // dispatch(setUnlockedMissions([5]));
                    dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                    dispatch(setCurrentNotification('mission6'));
                }
            },
        },
    }

    return missionFiveData;
}
