import React from 'react';

import styles from './FeedbackList.module.scss';

export enum Status {
  Incorrect,
  Correct,
}

type Item = {
  label: string;
  status: Status;
};

type Props = {
  title: string;
  items: Item[];
  className?: string;
};

const FeedbackList = ({
  title,
  items,
  className = '',
}: Props) => {

  const status = {
    [Status.Correct]: `lnr lnr-thumbs-up ${styles.correct}`,
    [Status.Incorrect]: `lnr lnr-thumbs-down ${styles.incorrect}`,
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <h2>{title}</h2>
      <ul>
        {items.map((item, index) => {
          return (
            <li key={index}>
              <span>{item.label}</span>
              <i className={status[item.status]}/>
            </li>
          )
        })}
      </ul>
    </div>
  );
}

export default FeedbackList;