import React from 'react';

import { useTask } from 'hooks/textCloze';

import Header from 'components/NavigationHeader';
import TextCloze from 'components/TextCloze';

import styles from './TextCloze.module.scss';

const Page = () => {
  const { task, initialAnswers } = useTask();

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <TextCloze
          title={task.title}
          text={task.text}
          hotspots={task.hotspots}
          onContinue={task.onContinue}
          initialAnswers={initialAnswers} 
        />
      </div>
    </div>
  );
}

export default Page;