import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';

import Button from 'components/Button';
import Choice, { Props as ChoiceProps } from 'components/Choice';
import Input, { Props as InputProps } from 'components/Input';

import styles from './InputBox.module.scss';
import {setClipboardCurrent} from "../../redux/slices/appSlice";
import {RootState} from "../../redux/rootReducer";

export type Props = InputProps & Partial<ChoiceProps> & {
  title?: string;
  onClick?: () => void;
  onSubmit?: (value: string) => void;
};

const InputBox = ({
  title,
  hasClipboard,
  onClipboardClick,
  onClick,
  onSubmit,
  onChange,
  options,
}: Props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const { clipboardCurrent } = useSelector((state: RootState) => state.app);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  useEffect(() => {
    setValue(clipboardCurrent || "");
  }, [clipboardCurrent]);

  const handleSubmit = () => {
    onSubmit && onSubmit(value);
    dispatch(setClipboardCurrent(null))
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={onClick}>
      <div className={styles.container} onClick={e => { e.stopPropagation(); }}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.input}>
          <Input hasClipboard={hasClipboard} onClipboardClick={onClipboardClick} onChange={handleChange} onEnter={handleSubmit} autoFocus />
        </div>
        {options && <Choice className={styles.choice} options={options} />}
        <Button className={styles.button} label='Bestätigen' onClick={handleSubmit} locked={!value} />
      </div>
    </div>,
    document.getElementById('container') as HTMLElement
  );
};

export default InputBox;