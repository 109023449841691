import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setAllowClipboardCopy, setOverlay} from 'redux/slices/appSlice';

import Header from 'components/DocumentHeader';

import styles from './Clipboard.module.scss';
import ClipboardList from "../../components/ClipboardList";
import {RootState} from "../../redux/rootReducer";

const Page = () => {
  const dispatch = useDispatch();
    const { mission: currentMission } = useSelector((state: RootState) => state.app);
  const { clipboard } =  useSelector((state: RootState) => state.task);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const close = () => {
    dispatch(setOverlay(null));
    dispatch(setAllowClipboardCopy(false))
  };

  return (
    <div className={`${styles.container} ${animate ? styles.slideIn : ''}`}>
      <Header title='Meine Zwischenablage' onClose={close} />
      <div className={styles.content}>
        <ClipboardList items={clipboard[currentMission]}/>
      </div>
    </div>
  );
}

export default Page;
