import { Type } from "../../document";
import {currentYear} from "../variables";

export const KassaberichtDone = () => {

    return {
        type: Type.ImageCloze,
        title: `Kassabericht 12.11.${currentYear}`,
        src: require('static/images/tasks/mission12/mKe_KassaendbestandErmitteln.png'),
        hotspots: {
            0: {
                x: '85.5%',
                y: '78%',
                labelXAlignment: 'right',
                labelXOffset: '9%',
            },
            1: {
                x: '85.5%',
                y: '84%',
                labelXAlignment: 'right',
                labelXOffset: '9%',
            },
            2: {
                x: '85.5%',
                y: '90%',
                labelXAlignment: 'right',
                labelXOffset: '9%',
            },
            3: {x: '32%', y: '90%'},
            4: {x: '15%', y: '15.2%'},
        },
        initialAnswers: {
            0: {label: `323,38`, active: false},
            1: {label: `321,38`, active: false},
            2: {label: `-2,00`, active: false},
            3: {label: `Überschuss/Manko`, active: false},
            4: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
        },
    };
}
