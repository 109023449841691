import React from 'react';

import Header from 'components/NavigationHeader';
import Infobox from 'components/Infobox';

import styles from './GameOver.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/rootReducer";
import Button from "../../components/Button";
import {
  setMenu,
  setMission,
  setNotification,
} from "../../redux/slices/appSlice";
import useFunctions from "../../hooks/functions";

const Page = () => {
  const { module } = useSelector((state: RootState) => state.app);
  const trackLinkClick = () => {
    window._paq.push(['trackEvent', 'Survey', 'Survey Link Clicked']);
  };
  const dispatch = useDispatch();
  const [{ setPage }] = useFunctions();

  let form;
  if(module === 1){
    form = 'https://forms.gle/dkQWBKw3KduJ1DRHA'
  } else if (module === 2){
    form = 'https://forms.gle/z9XJLDwqJWRsV3R78'
  }

  const goToModuleSelection = () => {
    dispatch(setMission(0));
    dispatch(setNotification('moduleSelection'));
    setPage('notification');
    dispatch(setMenu(['home']));
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Infobox className={styles.infobox} image={require('../../static/images/avatars/team-lead-happy.png')}>
          <p>Du hast das Ende des Moduls erreicht. Gut gemacht!</p>
          <p>
            Du kannst nun die einzelnen Arbeitsaufträge erneut bearbeiten und dabei dein Ranking verbessern.
            Klicke dazu im Menü auf den Namen des jeweiligen Auftrags.
          </p>
          <p><span className={styles.tipLabel}>Tipp:</span> Arbeite konzentriert, denn du kannst dich bei einem erneuten Spieldurchlauf im Ranking auch verschlechtern.</p>
          <strong>Deine Meinung ist uns wichtig.</strong>
          <p>
            Mit diesem <a onClick={trackLinkClick} href={form} rel='noopener noreferrer' target='_blank'>Fragebogen</a> hilfst
            du uns „Kompetent im Office“ noch besser zu machen.
          </p>
        </Infobox>
        <Button className={styles.button} label='Zur Modulauswahl' onClick={goToModuleSelection} />
      </div>
    </div>
  );
}

export default Page;
