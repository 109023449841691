import { useDispatch, useSelector } from "react-redux";

import { Dialogues } from "./datatypes";
import { RootState } from "../../redux/rootReducer";
import { teamleadImage } from "./variables";
import { Page as Document } from "../library";

import {setDialogue, unlockDocument} from "../../redux/slices/appSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";
import useFunctions from "../functions";

export const MissionThirteen = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [{ setPoints, setAnswer, setOverlay, setPage }] = useFunctions();

    const missionThirteenData: Dialogues = {
        prepareTransfer: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Da wir heute bereits den 25.11. haben, sollten wir uns um die Begleichung der noch offenen Eingangsrechnung kümmern. Bitte sieh dir diese an und bereite die Überweisung vor.`,
                    children: [1]
                },
                1: {
                    speaker: 'player',
                    text: `Okay.`,
                    children: [2]
                },
                2: {
                    speaker: 'teamlead',
                    text: `Was machst du als Erstes?`,
                    children: [3,4]
                },
                3: {
                    speaker: 'player',
                    text: 'Ich überweise den Rechnungsbetrag an den Lieferanten.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '3-4', label: 'Ich überweise den Rechnungsbetrag an den Lieferanten.' });
                        setPoints({ answer: '3-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Ich überprüfe die Zahlungsbedingungen der Rechnung.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '3-4', label: 'Ich überprüfe die Zahlungsbedingungen der Rechnung.', correct: true });
                        setPoints({ answer: '3-4', points: 1 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Wir sollten zuerst nachsehen, welche Zahlungsbedingungen bei diesem Lieferanten gelten.',
                    children: [7]
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Richtig. Bitte mach das gleich.',
                    children: [7]
                },
                7: {
                    speaker: 'teamlead',
                    text: 'Die für eine Überweisung relevanten Daten kannst du dir in deiner Zwischenablage speichern.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                setOverlay(Document.ER743Clipboard)
                dispatch(unlockDocument(Document.ER743Clipboard))
                dispatch(setTask('whatToDoNext'))
                dispatch(setDialogue('whatToDoNext'))
            }
        },
        whatToDoNext: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Was ist als Nächstes zu tun?`,
                    children: [1,2]
                },
                1: {
                    speaker: 'player',
                    text: 'Ich berechne den korrekten Überweisungsbetrag und fülle das Überweisungsformular aus.',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Ich berechne den korrekten Überweisungsbetrag und fülle das Überweisungsformular aus.', correct:true });
                        setPoints({ answer: '1-2', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Ich übernehme den Betrag aus der Rechnung und fülle das Online-Überweisungsformular aus.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Ich übernehme den Betrag aus der Rechnung und fülle das Online-Überweisungsformular aus.' });
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: 'Das ist richtig. Der Lieferant gewährt uns einen Skonto, den sollten wir ausnützen.',
                    children: [5]
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Das ist leider falsch. Der Lieferant gewährt uns einen Skonto, den sollten wir ausnützen.',
                    children: [5]
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Berechne also den korrekten Überweisungsbetrag und fülle das Überweisungsformular entsprechend aus.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('fillOutTransferForm'))
                setPage('image-cloze')
            }
        },
    }

    return missionThirteenData;
}
