import { useDispatch } from "react-redux";

import { useFunctions } from "../functions";
import { Tasks } from "./datatypes";

import { setFeedback } from "../../redux/slices/appSlice";
import { setTask as setCurrentTask } from "../../redux/slices/taskSlice";

export const MissionNine = () => {
    const [{ setImageClozeAnswer, setImageClozePoints, getImage, setPage }] = useFunctions();
    const dispatch = useDispatch();

    const currentYear = new Date().getFullYear();

    const correct = getImage('icn_correct.svg', 40)
    const incorrect = getImage('icn_incorrect.svg', 40)

    const missionNineData: Tasks = {
        checkTheInvoiceAndOrderMatch: {
            title: 'Abweichungen auf Rechnung markieren',
            subtitle: '<span class="tip-label">Tipp:</span> Vergleiche die Rechnung mit der Bestell-/Auftragsbestätigung auf deinem Arbeitsplatz.',
            src: require('static/images/tasks/mission9/mBp_AbweichungenMarkieren.png'),
            hotspots: {
                0: {
                    x: '22%',
                    y: '27.3%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Kein Fehler', alternativeLabel: correct, correct: true, onClick: setImageClozeAnswer },
                        { label: 'Falsche Kundennummer', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Falsche Adresse', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Falsche Postleitzahl', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                    ]
                },
                1: {
                    x: '47%',
                    y: '23%',
                    options: [
                        { label: 'Kein Fehler', alternativeLabel: correct, correct: true, onClick: setImageClozeAnswer },
                        { label: 'Falsche Bestellnummer', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Falsches Lieferdatum', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Falsche Lieferschein-Nummer', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                    ]
                },
                2: {
                    x: '9.3%',
                    y: '32%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Kein Fehler', alternativeLabel: correct, onClick: setImageClozeAnswer },
                        { label: 'Pos. 1: Falscher Artikel', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 2: Falscher Artikel', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 3: Falscher Artikel', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 4: Falscher Artikel', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 5: Falscher Artikel', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 6: Falscher Artikel', alternativeLabel: incorrect, correct: true, onClick: setImageClozeAnswer },
                    ]
                },
                3: {
                    x: '62%',
                    y: '32%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Kein Fehler', alternativeLabel: correct, onClick: setImageClozeAnswer },
                        { label: 'Pos. 1: Falsche Menge', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 2: Falsche Menge', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 3: Falsche Menge', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 4: Falsche Menge', alternativeLabel: incorrect, correct: true, onClick: setImageClozeAnswer },
                        { label: 'Pos. 5: Falsche Menge', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 6: Falsche Menge', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                    ]
                },
                4: {
                    x: '79%',
                    y: '32%',
                    options: [
                        { label: 'Kein Fehler', alternativeLabel: correct, onClick: setImageClozeAnswer },
                        { label: 'Pos. 1: Falscher Stückpreis', alternativeLabel: incorrect, correct: true, onClick: setImageClozeAnswer },
                        { label: 'Pos. 2: Falscher Stückpreis', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 3: Falscher Stückpreis', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 4: Falscher Stückpreis', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 5: Falscher Stückpreis', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Pos. 6: Falscher Stückpreis', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                    ]
                },
                5: {
                    x: '58%',
                    y: '69.7%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Kein Fehler', alternativeLabel: correct, onClick: setImageClozeAnswer },
                        { label: 'Zustellpauschale zu niedrig', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                        { label: 'Lieferung sollte gratis erfolgen', alternativeLabel: incorrect, correct: true, onClick: setImageClozeAnswer },
                        { label: 'Zustellpauschale sollte subtrahiert werden', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                    ]
                },
                6: {
                    x: '11%',
                    y: '85%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'Kein Fehler', alternativeLabel: correct, correct: true, onClick: setImageClozeAnswer },
                        { label: 'Falsches Zahlungsziel', alternativeLabel: incorrect, onClick: setImageClozeAnswer },
                    ]
                },
                7: { x:'78%' , y:'19.9%' },
                8: { x:'78%' , y:'22.2%' },
                9: { x:'78%' , y:'24.4%' },
            },
            initialAnswers: {
                7: { label: `<b>13539/${currentYear}</b>`, active: false},
                8: { label: `${currentYear}-11-13`, active: false},
                9: { label: `85464/${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('checkTheInvoiceAndOrderMatch'));
                setPage('feedback');
            },
        },
    }

    return missionNineData;
}
