import React from 'react';

import { useScene } from 'hooks/scene';

import Header from 'components/NavigationHeader';
import HotspotChoice from 'components/HotspotChoice';
import Portrait from 'components/Portrait';

import styles from './Scene.module.scss';

const Page = () => {
  const [scene] = useScene();

  return (
    <div className={styles.container}>
      <Header/>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <img alt='background' src={scene.background} />
          {scene.portrait && <Portrait src={scene.portrait} className={styles.portrait} style={{ left: '35%', top: '100px' }} />}
          <HotspotChoice
            position={scene.hotspotPosition}
            title={scene.title}
            options={scene.options}
          />
        </div>
      </div>
    </div>
  );
};

export default Page;