import React, {useEffect, useState} from 'react';

import styles from './Input.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/rootReducer";

export type Props = {
  className?: string;
  defaultValue?: string;
  label?: string;
  type?: string;
  maxLength?: number;
  autoFocus?: boolean;
  hasClipboard?: boolean;
  onClipboardClick?: () => void;
  onChange?: (value: string) => void;
  onEnter?: () => void;
};

const Input = ({
  className = '',
  defaultValue = '',
  label,
  type,
  maxLength,
  onChange,
  onEnter,
  hasClipboard,
  onClipboardClick,
  autoFocus = false,
}: Props) => {
  const [isFocused, setFocus] = useState(false);
  const [value, setValue] = useState<string>(defaultValue);

  const { clipboardCurrent } = useSelector((state: RootState) => state.app);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onEnter && onEnter();
    }
  }

  const focus = () => {
    setFocus(true);
  }

  const blur = () => {
    setFocus(false);
  }

  useEffect(() => {
    setValue(clipboardCurrent || "");
  }, [clipboardCurrent]);

  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (maxLength && value.length > maxLength) {
      return;
    }

    setValue(e.currentTarget.value);

    if (onChange) {
      onChange(e.currentTarget.value);
    }
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <input type={type} onKeyDown={onEnter && handleKeyDown} autoFocus={autoFocus} onFocus={focus} onBlur={blur} onChange={change}
             value={value} />
      <label className={`${isFocused || value ? styles.slideUp : ''}`}>{label}</label>
      {hasClipboard && <span className={`lnr lnr-clipboard-empty ${styles.icon}`} onClick={onClipboardClick}></span>}
    </div>
  );
};

export default Input;