import { useDispatch } from "react-redux";

import { Dialogues } from "./datatypes";
import { teamleadImage } from "./variables";
import { Page as Document } from "../library";

import {
    setNotification,
    unlockDocument,
    setHint, setShowHint
} from "../../redux/slices/appSlice";
import { pauseTimer } from "../../redux/slices/scoreSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";
import useFunctions from "../functions";

export const MissionEleven = () => {
    const dispatch = useDispatch();
    const [{ setOverlay, setPage }] = useFunctions();

    const missionElevenData: Dialogues = {
        itsGreatThatYouHaveTheConfidence: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Toll, dass du dich über die Verbuchung der Belege traust. Bevor du mit der Verbuchung 
                    beginnst, beachte bitte dazu die Informationen, die ich dir auf deinem Arbeitsplatz abgelegt habe.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(unlockDocument(Document.HinweiseFurBelegverbuchung))
                setOverlay(Document.HinweiseFurBelegverbuchung)
                dispatch(setNotification('IHavePutExcerpt'))
                setPage('notification')
            }
        },
        pleasePostReceiptsNow: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Toll, dass du dich über die Verbuchung der Belege traust. Bevor du mit der Verbuchung 
                    beginnst, beachte bitte dazu die Informationen, die ich dir auf deinem Arbeitsplatz abgelegt habe.`,
                    children: [1]
                },
                1: {
                    speaker: 'teamlead',
                    text: `Verbuche nun bitte die Belege. Trage dazu die richtigen Kontonummern an der richtigen Stelle 
                    am Buchungsstempel ein. Wenn du dir nicht sicher bist, schaue im Kontenplan nach. Auch die Beträge 
                    müssen am jeweiligen Konto in die richtige Spalte eingetragen werden.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setHint(4));
                dispatch(setShowHint(true));
                dispatch(setTask('accountForReceipt'))
                setPage('image-cloze')
            }
        },
        youHaveEnteredAllReceipts: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Danke für deine Hilfe beim Verbuchen der Belege.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setNotification('mission11PauseInfo'));
                setPage('notification');
                window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 2_7: Verbuchung der Belege']);
                dispatch(pauseTimer());
            }
        },
    }

    return missionElevenData;
}
