import { useDispatch } from "react-redux";

import { useFunctions } from "../../functions";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import { Type } from "../../document";
import { currentYear } from "../variables";

export const AngebotPutsch = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: 'Angebot Putsch GmbH',
        src: require('static/images/tasks/mission5/mAV_AngebotPutsch.png'),
        hotspots: {
            0: { x: '5%', y: '12%' },
            1: { x: '5%', y: '16%' },
            2: { x: '5%', y: '19%' },
            3: { x: '5%', y: '22%' },
            4: { x: '36.1%', y: '43.5%' },
            5: { x: '36.1%', y: '52%' },
            6: { x: '36.1%', y: '64%' },
            7: { x: '36.1%', y: '72.5%' },
            8: { x: '36.1%', y: '77.5%' },
            9: { x: '36.1%', y: '82%' },
            10: { x: '36.1%', y: '84.5%' },
            11: { x: '36.1%', y: '87%' },
            12: { x: '36.1%', y: '90.5%' },
            13: { x: '40%', y: '43.2%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Putsch',
                        order: 1,
                        title: 'Artikel: Whiteboard Glas Solid transparent (Anzahl)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '100'}))
                } },
            14: { x: '47.5%', y: '51.8%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Putsch',
                        order: 2,
                        title: 'Artikel: Whiteboard Glas Solid transparent (Preis/Stk.)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '88,60'}))
                } },
            15: { x: '40%', y: '63.8%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Putsch',
                        order: 3,
                        title: 'Artikel: Magnete für Glasboards/Whiteboards (Anzahl)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '100'}))
                } },
            16: { x: '46.5%', y: '72.3%', width: '9%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Putsch',
                        order: 4,
                        title: 'Artikel: Magnete für Glasboards/Whiteboards (Preis/VE)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '6,00'}))
                } },
            17: { x: '41%', y: '77.3%', width: '8.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Putsch',
                        order: 5,
                        title: 'Rabatt bei Abnahme beider Produkte',
                        isEnabled: true,
                        isPercentage: true,
                        value: '10,00 %'}))
                } },
            18: { x: '77.2%', y: '86.8%', width: '7%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Putsch',
                        order: 6,
                        title: 'Zustellpauschale (Euro)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '15,00'}))
                } },
            19: { x: '76.2%', y: '90.2%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Putsch',
                        order: 7,
                        title: 'Skonto',
                        isEnabled: true,
                        isPercentage: true,
                        value: '2,00 %'}))
                } },
        },
        initialAnswers: {
            0: { label: `Sehr geehrte Frau Kastner,`, active: false },
            1: { label: `vielen Dank für Ihre Anfrage. Gerne können wir Ihnen aus unserer`, active: false },
            2: { label: `Produktpalette folgende Artikel anbieten (Angebot gültig bis 31. `, active: false },
            3: { label: `Juli ${currentYear}):`, active: false },
            4: { label: `<b>100</b> <span class="lnr lnr-clipboard-empty"></span> Stück`, active: false },
            5: { label: `EUR <b>88,60</b> <span class="lnr lnr-clipboard-empty"></span> (exkl. 20 % USt)`, active: false },
            6: { label: `<b>100</b> <span class="lnr lnr-clipboard-empty"></span> VPE`, active: false },
            7: { label: `EUR <b>6,00</b> <span class="lnr lnr-clipboard-empty"></span> (exkl. 20 % USt)`, active: false },
            8: { label: `<b>10%</b> <span class="lnr lnr-clipboard-empty"></span> Rabatt bei Abnahme beider Produkte`, active: false },
            9: { label: `Lieferung innerhalb von einer KW ab `, active: false },
            10: { label: `Auftragseingang. Für die Lieferung verrechnen `, active: false },
            11: { label: `wir eine Zustellpauschale von <b>15</b> <span class="lnr lnr-clipboard-empty"></span> Euro. `, active: false },
            12: { label: `30 Tage netto Kassa, 10 Tage <b>2 %</b> <span class="lnr lnr-clipboard-empty"></span> Skonto`, active: false },
        }
    };
}
