import React from 'react';
import ReactDOM from 'react-dom';

import Choice, { Props as ChoiceProps } from 'components/Choice';

import styles from './ChoiceBox.module.scss';

export type Props = ChoiceProps & {
  title?: string;
  onOverlayClick?: () => void;
};

const ChoiceBox = ({
  title,
  options,
  hasClipboard,
  onClipboardClick,
  onOverlayClick,
}: Props) => {
  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={onOverlayClick}>
      <div className={styles.container}>
        {title && <div className={styles.title}>{title}</div>}
        <Choice hasClipboard={hasClipboard} onClipboardClick={onClipboardClick} options={options}/>
      </div>
    </div>,
    document.getElementById('container') as HTMLElement
  );
};

export default ChoiceBox;