import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import CalculatorBox, { Props as CalculatorProps } from "components/CalculatorBox";
import ChoiceBox, { Props as ChoiceBoxProps } from 'components/ChoiceBox';
import Hotspot, { Props as HotspotProps } from 'components/Hotspot';
import { Hotspot as ImageClozeHotspot } from "../ImageCloze";
import InputBox, { Props as InputBoxProps } from 'components/InputBox';
import TapTextBox from 'components/TapTextBox';
import {setClipboardCurrent} from "../../redux/slices/appSlice";
import MiniCalculatorBox from "../MiniCalculatorBox";
import {RootState} from "../../redux/rootReducer";
import CalendarBox from "../CalendarBox";
import { Props as CalendarProps } from "../Calendar";

export enum Type {
  Choice = 'choice',
  Input = 'input',
  TapText = 'tapText',
  Calculator = 'calculator',
  MiniCalculator = 'miniCalculator',
  Calendar = 'calendar'
}

type Props = HotspotProps & ChoiceBoxProps & InputBoxProps & CalculatorProps & CalendarProps & {
  hasNoDecimals? : boolean;
  secondAttempt?: ImageClozeHotspot;
  type?: Type;
};

const HotspotChoice = ({
  className,
  position,
  title,
  hasClipboard,
  onClipboardClick,
  secondAttempt,
  secondAttemptOptions,
  hasYear,
  year,
  monthNumber,
  hasNoDecimals,
  options,
  onSubmit,
  type = Type.Choice,
}: Props) => {
  const dispatch = useDispatch();
  const { mission } = useSelector((state: RootState) => state.app);
  const { task, attempts } = useSelector((state: RootState) => state.task);

  const [isElementVisible, setElementVisibilty] = useState(false);

  const showElement = () => {
    window._paq.push(['trackEvent', 'Interaction', 'Task', 'Opened Hotspot']);
    dispatch(setClipboardCurrent(null))
    setElementVisibilty(true);
  }

  const hideElement = () => {
    dispatch(setClipboardCurrent(null))
    setElementVisibilty(false);
  }

  const handleSubmit = (value: string) => {
    onSubmit && onSubmit(value);
    hideElement();
  }

  let element = null;

  let taskAttempts = 0;
  if(attempts[mission][task]){
    taskAttempts = attempts[mission][task]['count'];
  }

  if(secondAttempt && (taskAttempts > 0)){
    switch (secondAttempt.type) {
      case Type.Input:
        element = <InputBox title={secondAttempt.title} hasClipboard={secondAttempt.hasClipboard} onClipboardClick={secondAttempt.onClipboardClick}
                            options={secondAttempt.options} onSubmit={handleSubmit} onClick={hideElement}/>;
        break;
      case Type.TapText:
        element = <TapTextBox title={secondAttempt.title} options={secondAttempt.options} onOverlayClick={hideElement} onEnter={handleSubmit}/>;
        break;
      case Type.Calculator:
        element = <CalculatorBox onOverlayClick={hideElement}  options={secondAttempt.options} onEnter={handleSubmit}/>;
        break;
      case Type.MiniCalculator:
        element = <MiniCalculatorBox onOverlayClick={hideElement} options={options} hasNoDecimals={hasNoDecimals} onEnter={handleSubmit}/>;
        break;
      case Type.Calendar:
        element = <CalendarBox onOverlayClick={hideElement} year={year} options={options} monthNumber={monthNumber} hasYear={hasYear} onEnter={handleSubmit}/>;
        break;
      default:
        element = <ChoiceBox title={secondAttempt.title} hasClipboard={secondAttempt.hasClipboard}
                             onClipboardClick={secondAttempt.onClipboardClick}
                             options={secondAttemptOptions} onOverlayClick={hideElement}/>;
    }
  } else {
    switch (type) {
      case Type.Input:
        element = <InputBox title={title} hasClipboard={hasClipboard} onClipboardClick={onClipboardClick}
                            options={options} onSubmit={handleSubmit} onClick={hideElement}/>;
        break;
      case Type.TapText:
        element = <TapTextBox title={title} options={options} onOverlayClick={hideElement} onEnter={handleSubmit}/>;
        break;
      case Type.Calculator:
        element = <CalculatorBox onOverlayClick={hideElement}  options={options} onEnter={handleSubmit}/>;
        break;
      case Type.MiniCalculator:
        element = <MiniCalculatorBox onOverlayClick={hideElement} options={options} hasNoDecimals={hasNoDecimals} onEnter={handleSubmit}/>;
        break;
      case Type.Calendar:
        element = <CalendarBox onOverlayClick={hideElement} year={year} hasYear={hasYear} monthNumber={monthNumber} options={options} onEnter={handleSubmit}/>;
        break;
      default:
        element = <ChoiceBox title={title} hasClipboard={hasClipboard} onClipboardClick={onClipboardClick}
                             options={options} onOverlayClick={hideElement}/>;

    }
  }

  return (
    <>
      <Hotspot position={position} onClick={showElement} className={className} />
      {isElementVisible && element}
    </>
  );
}

export default HotspotChoice;