import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { setNotification, setFeedback } from 'redux/slices/appSlice';
import { addPoints, setPointsDetails } from 'redux/slices/scoreSlice';
import { setAnsweredTasks, setTask as setCurrentTask } from 'redux/slices/taskSlice';

import { Props as TextCloze, Answers, Answer } from 'components/TextCloze'
import { Type } from 'components/HotspotChoice';
import useFunctions from "./functions";

type Missions = {
  [key: number]: Tasks;
};

type Tasks = {
  [key: string]: TextCloze;
};

export const useTask = () => {
  const { mission: currentMission } = useSelector((state: RootState) => state.app);
  const { answeredTasks, task: currentTask } = useSelector((state: RootState) => state.task);
  const { firstName, lastName } = useSelector((state: RootState) => state.user);
  const { pointsDetails } = useSelector((state: RootState) => state.score);
  const dispatch = useDispatch();
  const [{ setPage }] = useFunctions();

  const currentYear = new Date().getFullYear();

  const setPoints = (answers: Answers) => {
    Object.keys(answers).forEach(key => {
      const answer = answers[key];
      if (answer.correct && !pointsDetails[currentMission][currentTask]) {
        dispatch(addPoints({ mission: currentMission, points: answer.points || 1 }));
      }
      dispatch(setPointsDetails({ mission: currentMission, task: currentTask, answer: key, points: answer.correct ? (answer.points || 1) : 0 }))
    });
  }

  const setAnswer = (answer: Answer) => {
    dispatch(setAnsweredTasks({ mission: currentMission, task: currentTask, answer: answer.id, option: answer }));
  }

  const savedMissions = useRef<Missions>({
    1: {
      offer: {
        title: 'Aufgabe: Angeboterstellung',
        text: `**An:** %(0-0)
        **Cc:**
        **Betreff:** Re: Anfrage
        ---
        Sehr geehrte $(0) !
        Wir danken für Ihre Anfrage vom $(1).
        Aufgrund von Lieferengpässen beim Produzenten können wir Ihnen die gewünschten $(2) nicht im gewünschten Zeitraum liefern.
        
        Wir bieten Ihnen daher an: $(3)
        Einzelpreis in EUR (exkl. 20 % USt): %(3-0)
        Gesamtpreis in EUR (exkl. 20 % USt): %(3-1)
        Lieferung: frei Haus
        Zahlung: 7 Tage 2 % Skonto, 14 Tage netto Kassa
        
        Die Lieferung können wir zu der von Ihnen gewünschten Lieferzeit bis $(4) durchführen.
        Unser Angebot bleibt 2 Wochen lang gültig.
        
        In den nächsten Tagen erhalten Sie ein kostenloses $(5) als Produktmuster geschickt.
        
        Wir würden uns freuen, Ihren Auftrag zu erhalten.
        
        Freundliche Grüße
        ${firstName} ${lastName}`,
        hotspots: {
          0: [
            { label: 'Frau Normann', children: ['maria.normann@nig.at'], onClick: setAnswer },
            { label: 'Frau Sorger', children: ['claudia.sorger@nig.at'], correct: true, onClick: setAnswer },
          ],
          1: [
            { label: `15. Dezember ${currentYear}`, onClick: setAnswer },
            { label: `15. November ${currentYear}`, correct: true, onClick: setAnswer },
            { label: `5. November ${currentYear}`, onClick: setAnswer },
          ],
          2: [
            { label: `500 Stk. \nMDF-Memoboards natur, \n42 x 29 cm`, correct: true, onClick: setAnswer },
            { label: `1.000 Stk. \nMDF-Memoboards natur, \n42 x 29 cm`, onClick: setAnswer },
            { label: `1.000 Stk. \nMDF-Memoboards weiß, \n42 x 29 cm`, onClick: setAnswer },
          ],
          3: [
            { label: `500 Stk. \nMDF-Memoboards natur, \n42 x 29 cm`, children: ['12,20', '6.100,00'], onClick: setAnswer },
            { label: `1.000 Stk. \nMDF-Memoboards natur, \n42 x 29 cm`, children: ['12,20', '12.200,00'], onClick: setAnswer },
            { label: `1.000 Stk. \nMDF-Memoboards weiß, \n42 x 29 cm`, children: ['14,20', '14.200,00'], correct: true, points: 2, onClick: setAnswer },
          ],
          4: [
            { label: `Ende November`, correct: true, onClick: setAnswer },
            { label: `Ende Dezember`, onClick: setAnswer },
          ],
          5: [
            { label: `MDF-Memoboard natur, 42 x 29 cm`, onClick: setAnswer },
            { label: `MDF-Memoboard weiß, 42 x 29 cm`, correct: true, onClick: setAnswer },
          ],
        },
        onContinue: (answers) => {
          setPoints(answers);

          if(!pointsDetails[currentMission][currentTask]) {
            dispatch(setCurrentTask('whatDoYouDo'));
            dispatch(setFeedback('offer'));
            dispatch(setNotification('whatDoYouDo'));
            setPage('notification');
          } else {
            dispatch(setCurrentTask(''));
            dispatch(setFeedback('offer'));
            setPage('feedback');
          }
        },
      },
    },
    4: {
      reminder: {
        title: 'Aufgabe: Aufforderung zur Nachzahlung',
        text: `**An:** $(0)
        **Cc:** $(1)
        **Betreff:** $(2)
        ---
        Sehr geehrte $(3) !
        wir haben am $(4) einen Zahlungseingang über $(5) EUR für die Re.-Nr. 15720 vom $(6). 
        Nach den auf der Rechnung angeführten Zahlungskonditionen $(7) wäre ein Skontoabzug bei einer Überweisung bis zum $(8) zulässig gewesen.
        Der Skontoabzug ist somit nicht gerechtfertigt.

        Wir bitten Sie den ungerechtfertigt in Abzug gebrachten Skonto von $(9) EUR binnen einer Woche auf unser Konto zu überweisen.
        
        IBAN: AT15 5848 9652 1487 2584
        BIC: BUBKATWW157
        
        Freundliche Grüße
        ${firstName} ${lastName}`,
        hotspots: {
          0: [
            { label: 'claudia.sorger@nig.at', correct: true, onClick: setAnswer },
            { label: 'maria.normann@nig.at', onClick: setAnswer },
            { label: 'kastner@memoboard.at', onClick: setAnswer },
          ],
          1: [
            { label: 'claudia.sorger@nig.at', onClick: setAnswer },
            { label: 'maria.normann@nig.at', onClick: setAnswer },
            { label: 'kastner@memoboard.at', correct: true, onClick: setAnswer },
          ],
          2: [
            { label: `Mahnung`, onClick: setAnswer },
            { label: `Ungerechtfertigter Skontoabzug`, correct: true, onClick: setAnswer },
            { label: `Zahlungserinnerung`, onClick: setAnswer },
          ],
          3: [
            { label: 'Frau Sorger', correct: true, onClick: setAnswer },
            { label: 'Frau Normann', onClick: setAnswer },
            { label: 'Frau Kastner', onClick: setAnswer },
          ],
          4: [
            { label: `29. Dezember ${currentYear}`, onClick: setAnswer },
            { label: `29. November ${currentYear}`, correct: true, onClick: setAnswer },
            { label: `5. November ${currentYear}`, onClick: setAnswer },
          ],
          5: {
            title: 'Betrag',
            type: Type.Input,
            correct: ['29390.59', '29390,59', '29.390,59'],
            onSubmit: setAnswer,
          },
          6: [
            { label: `20. November ${currentYear}`, correct: true, onClick: setAnswer },
            { label: `16. Dezember ${currentYear}`, onClick: setAnswer },
            { label: `5. November ${currentYear}`, onClick: setAnswer },
          ],
          7: [
            { label: '7 Tage 2 % Skonto, 14 Tage netto Kassa', correct: true, onClick: setAnswer },
            { label: '10 Tage netto Kassa, 3 % Skonto bei Zahlung innerhalb von einer Woche', onClick: setAnswer },
          ],
          8: [
            { label: `23. Dezember ${currentYear}`, onClick: setAnswer },
            { label: `27. November ${currentYear}`, correct: true, onClick: setAnswer },
            { label: `29. November ${currentYear}`, onClick: setAnswer },
          ],
          9: {
            title: 'Berechne den ungerechtfertigt in Abzug gebrachten Skonto.',
            type: Type.TapText,
            options: [
              { label: '29.990,40', subLabel: 'Rechnungsbetrag inkl. USt' },
              { label: '24.992,00', subLabel: 'Rechnungsbetrag exkl. USt' },
              { label: '+' },
              { label: '/' },
              { label: '-' },
              { label: '29.390,59', subLabel: 'Zahlungseingang Re-Nr. 15720' },
            ],
            correct: ['599,81'],
            onSubmit: setAnswer,
          },
        },
        onContinue: (answers) => {
          dispatch(setCurrentTask(''));
          dispatch(setFeedback('reminder'));
          setPoints(answers);
          setPage('feedback');
        },
      },
    }
  });

  const missions = savedMissions.current;
  const [task, setTask] = useState(missions[currentMission][currentTask]);
  const [initialAnswers, setInitialAnswers] = useState(answeredTasks[currentMission][currentTask] as Answers);

  useEffect(() => {
    setTask(missions[currentMission][currentTask]);
  }, [currentMission, currentTask, missions]);

  useEffect(() => {
    setInitialAnswers(answeredTasks[currentMission][currentTask] as Answers);
  }, [currentMission, currentTask, answeredTasks]);

  return { task, initialAnswers };
}