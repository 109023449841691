import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Page as Document } from 'hooks/library'

import { RootState } from 'redux/rootReducer';
import {
  setScene,
  setNotification,
  unlockDocument,
  setDialogue as setCurrentDialogue,
  setOverlay as setCurrentOverlay,
  setNextPage,
  setShowDocumentButton,
  setShowHint, setHint,
} from 'redux/slices/appSlice'
import { addPoints, setPointsDetails } from 'redux/slices/scoreSlice';
import { setTask, setAnsweredTasks } from 'redux/slices/taskSlice';

import { Orientation } from 'components/Dialogue/SpeechBubble';
import { Props as Dialogue} from 'components/Dialogue';
import { Answer } from 'components/ImageCloze';
import { Missions } from './dialogue/datatypes'
import { currentYear, teamleadImage, clientImage } from './dialogue/variables'

import { MissionFive} from "./dialogue/mission5";
import {MissionSix} from "./dialogue/mission6";
import {MissionSeven} from "./dialogue/mission7";
import {MissionEight} from "./dialogue/mission8";
import {MissionNine} from "./dialogue/mission9";
import {MissionTen} from "./dialogue/mission10";
import {MissionEleven} from "./dialogue/mission11";
import {MissionTwelve} from "./dialogue/mission12";
import {MissionThirteen} from "./dialogue/mission13";

import useFunctions from "./functions";

export const useDialogue = () => {
  const { mission: currentMission, dialogue: currentDialogue, } = useSelector((state: RootState) => state.app);
  const { image: userImage, firstName, lastName } = useSelector((state: RootState) => state.user);
  const { task, answeredTasks } = useSelector((state: RootState) => state.task);
  const { pointsDetails } = useSelector((state: RootState) => state.score);
  const dispatch = useDispatch();
  const [{ setPage }] = useFunctions();
  const setPoints = ({ answer, points }: { answer: string, points: number }) => {
    dispatch(setPointsDetails({ mission: currentMission, task, answer, points}));

    if (
      !pointsDetails[currentMission][task] ||
      (pointsDetails[currentMission][task] && pointsDetails[currentMission][task][answer] === undefined)
    ) {
      dispatch(addPoints({mission: currentMission, points }));
    }
  }

  const setAnswer = (answer: Answer) => {
    dispatch(setAnsweredTasks({ mission: currentMission, task, answer: answer.id, option: answer }));
  }

  const setOverlay = (value: string) => {
    dispatch(setCurrentOverlay(`document:${value}`));
  };

  const missions: Missions = {
    1: {
      morning: {
        speakers: {
          teamlead: {
            name: 'Teamleiterin',
            image: teamleadImage,
            orientation: Orientation.Left,
          }
        },
        bubbles: {
          0: {
            speaker: 'teamlead',
            text: `Guten Morgen ${firstName}. Sieh bitte in deinem Posteingang nach, ob es neue Anfragen gibt.`,
          }
        },
        onContinueLabel: 'Posteingang öffnen',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setScene('computer'));
          dispatch(setNextPage('scene'));
          dispatch(setTask('computer'));
          dispatch(unlockDocument(Document.Inquiry));
          setOverlay(Document.Inquiry);
        }
      },
      doWeHaveEverything: {
        speakers: {
          teamlead: {
            name: 'Teamleiterin',
            image: teamleadImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          0: {
            speaker: 'teamlead',
            text: `Kannst du die Wünsche der Kundin erfüllen?`,
            children: [1, 2],
          },
          1: {
            speaker: 'player',
            text: `Ja`,
            children: [3],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '1-2', label: 'Ja' });
              setPoints({ answer: '1-2', points: 0 });
            }
          },
          2: {
            speaker: 'player',
            text: `Nein`,
            children: [4],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '1-2', label: 'Nein', correct: true });
              setPoints({ answer: '1-2', points: 2 });
            }
          },
          3: {
            speaker: 'teamlead',
            text: `Es sind nicht genug Memoboards natur lagernd.
            Die nächste Lieferung kommt erst Mitte Dezember, das ist für die Kundin zu spät. 
            Wenn du eine Anfrage erhältst, schau bitte zuerst,
            ob die gewünschten Produkte in ausreichender Menge vorhanden sind.
            Überlege dir, wie du weiter vorgehst.`,
            children: [5, 6],
          },
          4: {
            speaker: 'teamlead',
            text: `Stimmt, wir können die Wünsche der Kundin nicht erfüllen. 
            Es sind nicht genug MDF-Memoboards natur, 42 x 29 cm lagernd. 
            Die nächste Lieferung kommt erst Mitte Dezember, das ist für die Kundin zu spät. 
            Überlege dir, wie du weiter vorgehst.`,
            children: [5, 6],
          },
          5: {
            speaker: 'player',
            text: `Alternatives Angebot erstellen`,
            children: [7],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '5-6', label: 'Alternatives Angebot erstellen', correct: true });
              setPoints({ answer: '5-6', points: 1 });
            }
          },
          6: {
            speaker: 'player',
            text: `Die Kundin informieren, dass wir ihr kein Angebot stellen können`,
            children: [8],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '5-6', label: 'Die Kundin informieren, dass wir ihr kein Angebot stellen können' });
              setPoints({ answer: '5-6', points: 0 });
            }
          },
          7: {
            speaker: 'teamlead',
            text: `Perfekt! Bitte gib mir Bescheid, wenn du fertig bist. Ich sehe es mir dann an.`,
          },
          8: {
            speaker: 'teamlead',
            text: `Vielleicht können wir die Kundin mit einem alternativen Angebot zufriedenstellen. 
            Bitte erstelle ein alternatives Angebot. Ich sehe es mir dann an.`,
          },
        },
        onContinueLabel: 'Angebot schreiben',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setTask('offer'));
          setPage('text-cloze');
          dispatch(setHint(0));
          dispatch(setShowHint(true));
        }
      }
    },
    2: {
      hello: {
        speakers: {
          client: {
            name: 'Claudia Sorger, Firma Nig',
            image: clientImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          0: {
            speaker: 'player',
            text: `Hallo`,
            children: [1],
          },
          1: {
            speaker: 'client',
            text: `Guten Tag. Claudia Sorger von der Firma Nig. Ich wollte zur Memoboard GmbH, bin ich hier richtig?`,
            children: [2],
          },
          2: {
            speaker: 'player',
            text: `Ja, genau.`,
            children: [3],
          },
          3: {
            speaker: 'client',
            text: `Alles klar. Vielen Dank für Ihr Angebot über 1.000 Stück MDF-Memoboards weiß und das Produktmuster. 
            Das Memoboard gefällt uns sehr gut. Aus diesem Grund wollen wir gleich 
            die doppelte Menge der MDF-Memoboards bestellen.`,
          },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setNotification('whatDoYouDo'));
          setPage('notification');
          dispatch(setTask('whatDoYouDo'));
        }
      },
      goodDay: {
        speakers: {
          client: {
            name: 'Claudia Sorger, Firma Nig',
            image: clientImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          0: {
            speaker: 'player',
            text: `Guten Tag. Hier ist die Memoboard GmbH. Mein Name ist ${firstName} ${lastName}`,
            children: [1],
          },
          1: {
            speaker: 'client',
            text: `Guten Tag, Claudia Sorger von der Firma Nig. 
            Vielen Dank für Ihr Angebot über 1.000 Stück MDF-Memoboards weiß und das Produktmuster. 
            Das Memoboard gefällt uns sehr gut. Aus diesem Grund wollen wir gleich 
            die doppelte Menge der MDF-Memoboards bestellen.`,
          }
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setNotification('whatDoYouDo'));
          setPage('notification');
          dispatch(setTask('whatDoYouDo'));
        }
      },
      doWeHaveEverything: {
        speakers: {
          teamlead: {
            name: 'Teamleiterin',
            image: teamleadImage,
            orientation: Orientation.Left,
          },
          client: {
            name: 'Claudia Sorger, Firma Nig',
            image: clientImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          0: {
            speaker: 'teamlead',
            text: `Kannst du die Wünsche der Kundin erfüllen? Wenn du dir nicht sicher bist, schaue im Lagerbestand nach.`,
            children: [1, 2],
          },
          1: {
            speaker: 'player',
            text: `Ja`,
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '1-2', label: 'Ja', correct: true });
              setPoints({ answer: '1-2', points: 1 });
            },
            children: [3],
          },
          2: {
            speaker: 'player',
            text: `Nein`,
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '1-2', label: 'Nein' });
              setPoints({ answer: '1-2', points: 0 });
              dispatch(setTask(''));
              dispatch(setNotification('checkStockAgain'));
              setPage('notification');
            },
          },
          3: {
            speaker: 'client',
            text: `Erhalten wir aufgrund der hohen Bestellmenge einen Rabatt?`,
          }
        },
        onContinueLabel: 'Weiter',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setNotification('canYouGiveDiscount'));
          setPage('notification');
          dispatch(setTask('canYouGiveDiscount'));
        }
      },
      noDiscount: {
        speakers: {
          client: {
            name: 'Claudia Sorger, Firma Nig',
            image: clientImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          0: {
            speaker: 'client',
            text: `Erhalten wir aufgrund der hohen Bestellmenge einen Rabatt?`,
            children: [1],
          },
          1: {
            speaker: 'player',
            text: `Leider ist kein Rabatt möglich.`,
            children: [2],
          },
          2: {
            speaker: 'client',
            text: `Ich bin verärgert. Ich habe bei großer Abnahmemenge immer noch Rabatt erhalten.
            Ich möchte Ihren Vorgesetzten sprechen.`,
          }
        },
        onContinueLabel: 'Weiter',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setNotification('checkPricesAndConditionsAgain'));
          setPage('notification');
        }
      },
      discount: {
        speakers: {
          client: {
            name: 'Claudia Sorger, Firma Nig',
            image: clientImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          0: {
            speaker: 'client',
            text: `Erhalten wir aufgrund der hohen Bestellmenge einen Rabatt?`,
            children: [1],
          },
          1: {
            speaker: 'player',
            text: `Ja, bei der Abnahme von 2.000 Stk. erhalten Sie einen Rabatt von 12 %.`,
            children: [2],
          },
          2: {
            speaker: 'client',
            text: `Danke für das Angebot. Ich bestelle 2.000 Stk. der Memoboards.`,
          }
        },
        onContinueLabel: 'Weiter',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setTask('whatDoYouDoNow'));
          dispatch(setNotification('whatDoYouDoNow'));
          setPage('notification');
        }
      },
    },
    4: {
      bill: {
        speakers: {
          teamlead: {
            name: 'Teamleiterin',
            image: teamleadImage,
            orientation: Orientation.Left,
          }
        },
        bubbles: {
          0: {
            speaker: 'teamlead',
            text: `Bitte erstelle gleich die Rechnung für die Nig GmbH, damit sie der Lieferung beigelegt werden kann.`,
          }
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setTask('computer'));
          dispatch(setScene('computer'));
          setPage('scene');
        }
      },
      openIncomingPayments: {
        speakers: {
          teamlead: {
            name: 'Teamleiterin',
            image: teamleadImage,
            orientation: Orientation.Left,
          }
        },
        bubbles: {
          0: {
            speaker: 'teamlead',
            text: `Heute ist der 1. Dezember. Die Nig GmbH sollte schon die Rechnung bezahlt haben.
            Schau bitte in den Zahlungseingängen nach, ob die Zahlung erfolgt ist.`,
          }
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setTask('hasClientPaid'));
          dispatch(setShowDocumentButton(true));
          dispatch(setCurrentDialogue('hasClientPaid'));
          dispatch(unlockDocument(Document.ClientAccount));
          setOverlay(Document.ClientAccount);
        }
      },
      hasClientPaid: {
        speakers: {
          teamlead: {
            name: 'Teamleiterin',
            image: teamleadImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          0: {
            speaker: 'teamlead',
            text: `Wurde der korrekte Rechnungsbetrag für die RE-Nr. 15720 bereits eingezahlt?`,
            children: [1, 2],
          },
          1: {
            speaker: 'player',
            text: `Ja`,
            children: [3],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '1-2', label: 'Ja' });
              setPoints({ answer: '1-2', points: 0 });
            },
          },
          2: {
            speaker: 'player',
            text: `Nein`,
            children: [4],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '1-2', label: 'Nein', correct: true });
              setPoints({ answer: '1-2', points: 2 });
            },
          },
          3: {
            speaker: 'teamlead',
            text: `Das ist falsch. Vergleiche bitte noch einmal die Rechnung mit dem Zahlungseingang der Nig GmbH.`,
          },
          4: {
            speaker: 'teamlead',
            text: `Stimmt. Hast du einen Vorschlag für die weitere Vorgehensweise?`,
            children: [5, 6, 7],
          },
          5: {
            speaker: 'player',
            text: `gerichtliche Schritte vorschlagen`,
            children: [8],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '5-7', label: 'gerichtliche Schritte vorschlagen' });
              setPoints({ answer: '5-7', points: 0 });
            },
          },
          6: {
            speaker: 'player',
            text: `Nichts unternehmen – der Kunde soll nicht verärgert werden`,
            children: [9],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '5-7', label: 'Nichts unternehmen – der Kunde soll nicht verärgert werden' });
              setPoints({ answer: '5-7', points: 0 });
            },
          },
          7: {
            speaker: 'player',
            text: `Kunden zur Nachzahlung auffordern`,
            children: [10],
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
              setAnswer({ id: '5-7', label: 'Kunden zur Nachzahlung auffordern', correct: true });
              setPoints({ answer: '5-7', points: 1 });
            },
          },
          8: {
            speaker: 'teamlead',
            text: `Es ist wichtig, dass unsere Kunden den Rechnungsbetrag zeitgerecht und in der korrekten Höhe zahlen. 
            Gerichtliche Schritte wären aber voreilig und würden unseren Kunden verärgern.
            Bitte verfasse ein E-Mail, in dem du die Nig GmbH zur Nachzahlung des ungerechtfertigt in Abzug 
            gebrachten Skontos aufforderst.`,
          },
          9: {
            speaker: 'teamlead',
            text: `Das ist falsch. Vergleiche bitte noch einmal die Rechnung mit dem Zahlungseingang der Nig GmbH.`,
          },
          10: {
            speaker: 'teamlead',
            text: `Bitte verfasse ein E-Mail, in dem du die Nig GmbH zur Nachzahlung des ungerechtfertigt in Abzug gebrachten Skontos aufforderst.`,
          },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          const task1 = answeredTasks[currentMission][task]['1-2'];
          const task2 = answeredTasks[currentMission][task]['5-7'];

          if (task1 && !task1.correct) {
            dispatch(setTask(''));
            dispatch(setShowDocumentButton(true));
            dispatch(setCurrentDialogue('whereIsTheError'));
            setOverlay(Document.ClientAccount);
          } else if (task2 && task2.label === 'Nichts unternehmen – der Kunde soll nicht verärgert werden') {
            dispatch(setTask(''));
            dispatch(setShowDocumentButton(true));
            dispatch(setCurrentDialogue('whereIsTheError'));
            setOverlay(Document.ClientAccount);
          } else {
            dispatch(setTask(''));
            dispatch(setNotification('pleaseCCMe'));
            setPage('notification');
          }
        }
      },
      whereIsTheError: {
        speakers: {
          teamlead: {
            name: 'Teamleiterin',
            image: teamleadImage,
            orientation: Orientation.Left,
          },
          player: {
            name: 'Du',
            image: userImage,
            orientation: Orientation.Right,
          }
        },
        bubbles: {
          1: {
            speaker: 'teamlead',
            text: `Wo könnte der Fehler sein?`,
            children: [2, 3, 4],
          },
          2: {
            speaker: 'player',
            text: `Der Skonto wurde ungerechtfertigt in Abzug gebracht`,
            children: [5],
          },
          3: {
            speaker: 'player',
            text: `Ein Rabatt wurde ungerechtfertigt abgezogen`,
            children: [6],
          },
          4: {
            speaker: 'player',
            text: `Zu viel Skonto wurde abgezogen`,
            children: [6],
          },
          5: {
            speaker: 'teamlead',
            text: `Stimmt`,
            children: [7],
          },
          6: {
            speaker: 'teamlead',
            text: `Das ist leider falsch. Die Nig GmbH hat den Rechnungsbetrag abzüglich Skonto an uns überwiesen. 
            Ein Skontoabzug wäre aber nur bei einer Überweisung bis 27.11.${currentYear} zulässig gewesen.`,
            children: [7],
          },
          7: {
            speaker: 'teamlead',
            text: `Bitte verfasse ein E-Mail, in dem du die Nig GmbH zur Nachzahlung des ungerechtfertigt in Abzug
            gebrachten Skontos aufforderst.`,
          },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
          dispatch(setNotification('pleaseCCMe'));
          setPage('notification');
        }
      },
    },
    5: MissionFive(),
    6: MissionSix(),
    7: MissionSeven(),
    8: MissionEight(),
    9: MissionNine(),
    10: MissionTen(),
    11: MissionEleven(),
    12: MissionTwelve(),
    13: MissionThirteen()
  };

  const [dialogue, setDialogue] = useState<Dialogue & { id: string }>({id: currentDialogue, ...missions[currentMission][currentDialogue]});

  useEffect(() => {
    setDialogue({ id: currentDialogue, ...missions[currentMission][currentDialogue]}); 
  }, [currentMission, currentDialogue, answeredTasks]); // eslint-disable-line react-hooks/exhaustive-deps

  return [dialogue];
}