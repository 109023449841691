import { Type } from "../../document";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";

export const HinweiseFurBelegverbuchung = () => {
    const { firstName } = useSelector((state: RootState) => state.user);

    return {
        type: Type.ImageCloze,
        title: `Hinweise für die Belegverbuchung`,
        src: require('static/images/tasks/mission11/mBv_BelegverbuchungNotiz.png'),
        hotspots: {
            0: {x: '6.5%', y: '15%'},
        },
        initialAnswers: {
            0: {label: `<b>Hallo ${firstName}, ich habe dir ein paar Hinweise für die Belegverbuchung <br/>notiert:</b>`, active: false},
        }
    };
}
