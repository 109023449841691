import { Type } from "../../document";
import {currentYear} from "../variables";

export const LetzteMahnungLetterDone = () => {

    return {
        type: Type.ImageCloze,
        title: 'Lösung: Letzte Mahnung verfassen',
        src: require('static/images/tasks/mission8/mMe_LetzteMahnungVerfassen.png'),
        hotspots: {
            0: {
                x: '10%',
                y: '17%',
                labelXOffset: '-4.7%',
            },
            1: {
                x: '80.25%',
                y: '53.2%',
                labelXAlignment: 'center',
            },
            2: {
                x: '85%',
                y: '64.7%',
                labelXAlignment: 'right',
                labelXOffset: '3%',
            },
            3: {
                x: '85%',
                y: '68.5%',
                labelXAlignment: 'right',
                labelXOffset: '3%',
            },
            4: {
                x: '85%',
                y: '72%',
                labelXAlignment: 'right',
                labelXOffset: '3%',
            },
            5: {
                x: '85%',
                y: '75.5%',
                labelXAlignment: 'right',
                labelXOffset: '3%',
            },
            6: { x: '5%', y: '45%'}
        },
        initialAnswers: {
            0: {label: 'Einschreiben', active: false},
            1: {label: `30.12.${currentYear}`, active: false},
            2: {label: '13.668,00', active: false},
            3: {label: `22,00`, active: false},
            4: {label: `212,61`, active: false},
            5: {label: `13.902,61`, active: false},
            6: {label: `Trotz unserer Zahlungserinnerungen vom 19.11.${currentYear} und 4.12.${currentYear} haben 
            <br/>Sie unsere Rechnung Nr. 1835 vom 21.10.${currentYear} über 13.668,00 Euro <br/>noch immer nicht beglichen.`, active: false}
        },
    };
}
