import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { Notifications } from "./datatypes";
import { clockImage, teamleadImage } from "./variables";

import {
    setDialogue,
    setMission,
    setWasJokerUsed,
    setNotification as setCurrentNotification,
    unlockDocument,
    setUnlockedMissions,
    setModuleScore, setFinished2, setShowSurveyPopUp,
} from "../../redux/slices/appSlice";

import {pauseTimer, setMissionCompleted, startTimer} from "../../redux/slices/scoreSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Page as Document } from "../library";
import useFunctions from "../functions";
import {RootState} from "../../redux/rootReducer";

export const MissionSix = () => {
    const dispatch = useDispatch();

    const { isFinishedModule2, reachedModuleScore } = useSelector((state: RootState) => state.app);
    const { anonymous } = useSelector((state: RootState) => state.user);
    const [{ getMessage, saveHighscoreAnonymous, saveHighscoreWithName, setPage, missionsCompleted,
        missionPoints, missionMaxPoints }] = useFunctions();

    const missionSixData: Notifications = {
        mission6: {
            children: <><p>Auftrag:</p><h2>Verkaufspreis ermitteln</h2></>,
            style: {justifyContent: 'center'},
            onContinueLabel: 'Weiter',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 2_2: Verkaufspreis ermitteln']);
                dispatch(setTask('weMustDetermineSalesPrice'));
                dispatch(setDialogue('weMustDetermineSalesPrice'));
                setPage('dialogue');
                dispatch(startTimer());
            },
        },
        profitCalculationLooksGood: {
            image: teamleadImage,
            text: 'Die tabellarische Aufstellung sieht gut aus. Jetzt kannst du die Bezugskalkulation durchführen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('calculationProfits'));
                setPage('image-cloze');
            }
        },
        profitCalculationIsNowSaved: {
            image: teamleadImage,
            text: 'Deine Gewinnberechnung ist nun auf deinem Arbeitsplatz  gespeichert.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('canWeCoverOurProfitClaims'));
                dispatch(setDialogue('canWeCoverOurProfitClaims'));
                setPage('dialogue');
            }
        },
        weGetAdditionalDiscount: {
            image: clockImage,
            text: 'Etwas später an diesem Tag...',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setDialogue('weGetAdditionalDiscount'));
                setPage('dialogue');
            }
        },
        priceCalculationIsNowSaved: {
            image: teamleadImage,
            text: 'Deine Verkaufspreisberechnung ist auf deinem Arbeitsplatz  gespeichert.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(unlockDocument(Document.Verkaufspreis))
                dispatch(setTask('canWeNowMeetSalesPriceAndProfitTargets'));
                dispatch(setDialogue('canWeNowMeetSalesPriceAndProfitTargets'));
                setPage('dialogue');
            }
        },
        mission6Feedback: {
            image: teamleadImage,
            text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten bei diesem Auftrag erreicht. ${getMessage()}`,
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setCurrentNotification('mission6PauseInfo'));
                setPage('notification');
                window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 2_2: Verkaufspreis ermitteln']);
                dispatch(pauseTimer());
            },
        },
        mission6PauseInfo: {
            icon: 'checkmark-circle',
            text: 'Möchtest du gleich mit dem nächsten Arbeitsauftrag weitermachen oder eine Pause einlegen? Dein Fortschritt wird auf diesem Gerät gespeichert, sodass du jederzeit weitermachen kannst.',
            onContinueLabel: 'Nächster Auftrag',
            onContinue: () => {
                dispatch(setMissionCompleted({mission: 6, completed: true}))
                if (reachedModuleScore[2]) {

                    if (missionsCompleted([5,7,8,9,10,12,13])){
                        if (isFinishedModule2) {
                            dispatch(setModuleScore(2));
                            if (anonymous) {
                                saveHighscoreAnonymous(2);
                            } else {
                                saveHighscoreWithName(2);
                            }
                            // dispatch(setUnlockedMissions([5]));
                            dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                        } else {
                            dispatch(setFinished2(true));
                            dispatch(setMission(13))
                            dispatch(setShowSurveyPopUp(true));
                            setPage('end-feedback');
                        }
                        window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);

                    } else {
                        dispatch(setMission(0));
                        dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
                        setPage('notification')
                    }
                } else {
                    dispatch(setMission(7));
                    dispatch(setWasJokerUsed(false));
                    // dispatch(setUnlockedMissions([1,5,6]));
                    dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                    dispatch(setCurrentNotification('mission7'));
                }
            },
        },
    }

    return missionSixData;
}
