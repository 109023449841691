import { useDispatch, useSelector } from "react-redux";

import { Dialogues } from "./datatypes";
import { RootState } from "../../redux/rootReducer";
import { teamleadImage } from "./variables";

import { setDialogue as setCurrentDialogue, setNotification, unlockDocument } from "../../redux/slices/appSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";
import { Page as Document } from "../library";
import useFunctions from "../functions";

export const MissionNine = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [{ setPoints, setAnswer, setOverlay, setPage }] = useFunctions();

    const missionNineData: Dialogues = {
        todayWeReceivedFromMesiner: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Wir haben heute eine Lieferung der Mesiner GmbH erhalten. Die gelieferten Produkte wurden kontrolliert und sind qualitativ in Ordnung. Setze  bitte die nächsten Schritte.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setCurrentDialogue('whatDoYouDoFirst'))
                dispatch(setTask('whatDoYouDoFirst'))
                dispatch(unlockDocument(Document.MesinerRechnung))
                setOverlay(Document.MesinerRechnung)
            }
        },
        whatDoYouDoFirst: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Wir haben heute eine Lieferung der Mesiner GmbH erhalten. Die gelieferten Produkte wurden kontrolliert und sind qualitativ in Ordnung. Setze  bitte die nächsten Schritte.`,
                    children: [1]
                },
                1: {
                    speaker: 'teamlead',
                    text: `Was machst du als Erstes?`,
                    children: [2,3,4]
                },
                2: {
                    speaker: 'player',
                    text: `Die Rechnung mit Bestellung abgleichen.`,
                    alternativeText: 'Die Rechnung muss mit der Bestellung abgeglichen werden.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Die Rechnung mit Bestellung abgleichen.', correct: true});
                        setPoints({ answer: '2-4', points: 1 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: `Den Buchungsvermerk anbringen.`,
                    alternativeText: 'Ich werden den Buchungsvermerk anbringen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Den Buchungsvermerk anbringen.'});
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: `Die Zahlung durchführen.`,
                    alternativeText: 'Nun muss die Zahlung durchgeführt werden.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Die Zahlung durchführen.'});
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: `Genau! Die zugehörige Bestell- bzw. Auftragsbestätigung findest du auf deinem Arbeitsplatz.`,
                },
                6: {
                    speaker: 'teamlead',
                    text: `Du bist zu voreilig. Zuerst muss die Rechnung geprüft werden. Die zugehörige Bestell- bzw. Auftragsbestätigung findest du auf deinem Arbeitsplatz.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setCurrentDialogue('doTheInvoiceAndOrderMatch'))
                dispatch(setTask('doTheInvoiceAndOrderMatch'))
                dispatch(unlockDocument(Document.MesinerConfirmation))
                setOverlay(Document.MesinerConfirmation)
            }
        },
        doTheInvoiceAndOrderMatch: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Stimmen Rechnung und Bestell- bzw. Auftragsbestätigung überein? Du kannst dir beide Dokumente auf deinem Arbeitsplatz ansehen.`,
                    children: [1, 2]
                },
                1: {
                    speaker: 'player',
                    text: `Ja, alles korrekt.`,
                    alternativeText: `Ja, die Dokumente stimmen überein. Es gibt keine Fehler.`,
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Ja, alles korrekt.'});
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: `Nein, es gibt Abweichungen.`,
                    alternativeText: `Nein, es gibt Abweichungen zwischen Rechnung und Bestell- bzw. Auftragsbestätigung.`,
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Nein, es gibt Abweichungen.', correct: true});
                        setPoints({ answer: '1-2', points: 1 });
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: `Das ist falsch. Rechnung und Bestell- bzw. Auftragsbestätigung stimmen nicht überein. Schau noch einmal genau und markiere die Abweichnung auf der Rechnung.`,
                },
                4: {
                    speaker: 'teamlead',
                    text: `Das hast du richtig erkannt. Markiere die Abweichungen bitte auf der Rechnung.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('checkTheInvoiceAndOrderMatch'))
                setPage('image-cloze')
            }
        },
        whatAreYouDoingNow: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Was machst du jetzt?`,
                    children: [1, 2]
                },
                1: {
                    speaker: 'player',
                    text: `Eine korrigierte Rechnung anfordern.`,
                    alternativeText: 'Wir müssen eine korrigierte Rechnung anfordern.',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Eine korrigierte Rechnung anfordern.', correct: true});
                        setPoints({ answer: '1-2', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: `Den korrekten Rechnungsbetrag überweisen.`,
                    alternativeText: 'Wir müssen jetzt den korrekten Rechnungsbetrag überweisen.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Den korrekten Rechnungsbetrag überweisen.' });
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: `Genau, das ist richtig. Zuerst brauchen wir die korrigierte Rechnung, dann überweisen wir.`,
                },
                4: {
                    speaker: 'teamlead',
                    text: `Nein, das wäre gar nicht gut. Zuerst brauchen wir die korrigierte Rechnung, dann überweisen wir.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setNotification('mission9Feedback'))
                setPage('notification')
                dispatch(setTask(''));
            }
        }
    }

    return missionNineData;
}
