import React, { useState, useRef, useEffect } from 'react';

import Button from 'components/Button';
import Choice, { Option } from 'components/Choice';
import SpeechBubble, { Orientation } from 'components/Dialogue/SpeechBubble';

import styles from './Dialogue.module.scss';

type Speaker = {
  name: string;
  image: string;
  orientation: Orientation;
}

type Speakers = {
  [name: string]: Speaker
};

type Bubble = {
  speaker: string;
  text: string;
  alternativeText?: string;
  children?: number[];
  onClick?: () => void;
}

type Bubbles = {
  [id: number]: Bubble;
}

export type Props = {
  speakers: Speakers;
  bubbles?: Bubbles;
  initialBubbles?: Bubble[];
  delay?: number;
  onContinueLabel?: string;
  onContinue?: () => void;
}

const Dialogue = ({
  speakers,
  bubbles,
  initialBubbles = [],
  delay = 1500,
  onContinueLabel = 'Okay',
  onContinue,
}: Props) => {
  const [ids, setIds] = useState([bubbles ? parseInt(Object.keys(bubbles)[0], 10) : 0]);
  const [bubblesToRender, setBubblesToRender]= useState<Bubble[]>(initialBubbles);
  const [choice, setChoice] = useState<Option[] | null>(null);
  const [end, setEnd] = useState(false);

  const $scrollableContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    renderElement();

    const timeout = setTimeout(setNextElement, delay);
    return () => clearTimeout(timeout);
  }, [ids]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { current } = $scrollableContainer;

    if (current && current.scrollHeight > current.offsetHeight) {
      current.scrollTo({
        top: current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [bubblesToRender, choice, end]);

  const renderElement = () => {
    if (bubbles) {
      if (ids.length > 1) {
        setChoice(ids.map(id => {
          const bubble = bubbles[id];

          return (
            {
              label: bubble.text,
              onClick: () => {
                if (bubble.children) {
                  setIds([id]);
                }

                if (bubble.onClick) {
                  bubble.onClick();
                }
              }
            }
          );
        }));
      } else {
        const bubble = bubbles[ids[0]];
        setBubblesToRender([...bubblesToRender, bubble]);
        setChoice(null);
      }
    }
  }

  const setNextElement = () => {
    if(ids.length === 1 && bubbles) {
      const bubble = bubbles[ids[0]];

      if (bubble.children) {
        setIds(bubble.children);
      } else {
        setEnd(true);
      }
    }
  }

  return (
    <div className={styles.container} onClick={setNextElement}>
      <div className={styles.list} ref={$scrollableContainer}>
      {bubblesToRender.map((bubble, i) => {
        const speaker = speakers[bubble.speaker];
        return (
          <SpeechBubble
            key={i}
            speaker={speaker}
            text={bubble.alternativeText? bubble.alternativeText : bubble.text}
            animate={bubblesToRender.length > initialBubbles.length}
          />
        );
      })}
      </div>
      <div className={`${styles.choice} ${!choice && !end ? styles.hide : ''}`}>
        {choice && <Choice options={choice}/>}
        {end && <Button label={onContinueLabel} onClick={onContinue} /> }
      </div>
    </div>
  );
}

export default Dialogue;