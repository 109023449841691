import React, { useState } from 'react';

import Button from 'components/Button';
import ButtonLabel from 'components/ButtonLabel';

import styles from './TapText.module.scss';

export type Props = {
  className?: string;
  title?: string;
  options?: Option[];
  onEnter?: (value: string) => void;
};

export type Option = {
  label: string;
  subLabel?: string;
  realValue?: string;
};

const TapText = ({
  className = '',
  title,
  options,
  onEnter
}: Props) => {
  const [value, setValue] = useState('');
  const [tries, setTries] = useState(0);

  const handleClick = (label: string) => {
    setValue(`${value} ${label}`);
  };

  const handleCalculate = () => {
    const calculatedValue = calculate(value !== '' ? value : '0');
    setValue(calculatedValue); 
    onEnter && onEnter(calculatedValue);
  };

  const calculate = (value: string) => {
    try {
      return parseFloat(Function(`return ${formatText(value)}`)().toFixed(2)) // eslint-disable-line no-new-func
        .toLocaleString('de-DE', { minimumFractionDigits: 2 });
    } catch {
      return 'ungültige Eingabe';
    }
  };

  const formatText = (text: string) => {
    return text.replace(/\./g, '').replace(/,/g, '.');
  };

  const clear = () => {
    setValue('');
    setTries(tries + 1);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {title && <h2>{title}</h2>}
      <input value={value} readOnly />
      <div>
        {options?.length && options.map(({ label, subLabel, realValue }, i) =>
            <ButtonLabel key={`${tries}-${i}`} className={styles.label} label={label} subLabel={subLabel} onClick={ ()=> handleClick(realValue ?? label)} />)}
      </div>
      <div className={styles.buttonContainer}>
        <Button className={styles.button} label='Löschen' onClick={clear} />
        <Button className={styles.button} label='Berechnen' onClick={handleCalculate} />
      </div>
    </div>
  );
};

export default TapText;