import { Type } from "../../document";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import {currentYear} from "../variables";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const Ausgangsrechnungen = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Offene Posten 4.12.${currentYear}`,
        src: require('static/images/tasks/mission8/mMe_Ausgangsrechnungen1.png'),
        hotspots: {
            0: { x: '83.5%', y: '6%' },
            1: { x: '19%', y: '16.1%' },
            2: { x: '19%', y: '20%' },
            3: { x: '78.3%', y: '16.1%' },
            4: { x: '78.3%', y: '24.5%' },
            5: { x: '78.3%', y: '28.7%' },
            6: { x: '85.5%', y: '28.5%', width: '15%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Offene Posten 4.12`,
                        order: 1,
                        title: 'Werner Neumann GmbH (offener Betrag)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '9286,15'}))
                } },
            7: { x: '19%', y: '38.2%' },
            8: { x: '19%', y: '42.2%' },
            9: { x: '78.3%', y: '38.2%' },
            10: { x: '78.3%', y: '46%' },
            11: { x: '78.3%', y: '50.8%' },
            12: { x: '86.2%', y: '50.4%', width: '16.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Offene Posten 4.12`,
                        order: 2,
                        title: 'eRGO GmbH (offener Betrag)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '22517,56'}))
                } },
            13: { x: '19%', y: '60.3%' },
            14: { x: '19%', y: '64.3%' },
            15: { x: '78.3%', y: '60.3%' },
            16: { x: '78.3%', y: '68.5%' },
            17: { x: '78.3%', y: '72.8%' },
            18: { x: '86.2%', y: '72.5%', width: '16%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Offene Posten 4.12`,
                        order: 3,
                        title: 'Zalanka AG (offener Betrag)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '13668,00'}))
                } },
            19: { x: '19%', y: '82.5%' },
            20: { x: '19%', y: '86.3%' },
            21: { x: '78.3%', y: '82.5%' },
            22: { x: '78.3%', y: '95%' },
            23: { x: '84.5%', y: '94.8%', width: '12.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Offene Posten 4.12`,
                        order: 4,
                        title: 'Christian Neuhofer eU (offener Betrag)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '615,14'}))
                } },
        },
        initialAnswers: {
            0: { label: `<b>04.12.${currentYear}</b>` , active: false },
            1: { label: `22.09.${currentYear}` , active: false },
            2: { label: `1685` , active: false },
            3: { label: `06.10.${currentYear}` , active: false },
            4: { label: `23.11.${currentYear}` , active: false },
            5: { label: `<b>9.286,15</b> <i class="lnr lnr-clipboard-empty"></i>` , active: false },
            7: { label: `11.10.${currentYear}` , active: false },
            8: { label: `1725` , active: false },
            9: { label: `25.10.${currentYear}` , active: false },
            10: { label: `24.11.${currentYear}` , active: false },
            11: { label: `<b>22.517,56</b> <i class="lnr lnr-clipboard-empty"></i>` , active: false },
            13: { label: `21.10.${currentYear}` , active: false },
            14: { label: `1835` , active: false },
            15: { label: `04.11.${currentYear}` , active: false },
            16: { label: `19.11.${currentYear}` , active: false },
            17: { label: `<b>13.668,00</b> <i class="lnr lnr-clipboard-empty"></i>` , active: false },
            19: { label: `21.10.${currentYear}` , active: false },
            20: { label: `1975` , active: false },
            21: { label: `26.11.${currentYear}` , active: false },
            22: { label: `<b>615,14</b> <i class="lnr lnr-clipboard-empty"></i>` , active: false },
        }
    };
}
