import React from 'react';

import styles from './List.module.scss';
import ListItem from "../ListItem";
import { toggleChildren } from '../../helpers'

export type Item = {
  label: string;
  caption?: string;
  locked?: boolean;
  isSeperator?: boolean;
  icon?: string;
  accordion?: boolean;
  child?: boolean;
  onClick?: () => void;
  children?: Item[];
}

type Props = {
  items: Item[];
};

const List = ({
  items
}: Props) => {
  return (
    <div className={styles.list}>
      {
        items.map((item, index) => (
          <div>

            <div onClick={toggleChildren}>
              <ListItem item={item} key={index.toString()} />
            </div>

            <div className={`${styles.list} ${item.accordion ? styles.panel : ''} `}>
              {item.accordion ?
                item.children?.map((children, childIndex)=> (
                  <ListItem item={children} key={index + " " + childIndex} />
                )) : ''
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default List;