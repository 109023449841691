import React from "react";

export const toggleChildren = (event: React.MouseEvent<HTMLInputElement>) => {
    let sibling = event.currentTarget.nextElementSibling
    if(sibling) {
        let icon = event.currentTarget.querySelector('i')
        let display = window.getComputedStyle(sibling).maxHeight;
        if(display === '0px'){
            sibling.setAttribute('style', 'max-height: ' + sibling.scrollHeight + "px");
            if(icon) icon.className = 'lnr lnr-minus'
        } else {
            sibling.setAttribute('style', 'max-height: 0px');
            if(icon) icon.className = 'lnr lnr-plus'
        }
    }
}

export const computeEnglishValue = (value: string) => {
    // @ts-ignore
    return parseFloat(value.replaceAll('.', '').replace(/,/, '.').replaceAll(',', ''));
}

export const computeCalculatorEnglishValue = (value: string) => {
    // @ts-ignore
    return value.replaceAll('.', '').replaceAll(',', '.');
}