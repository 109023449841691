import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';

type Item = {
  title: string;
  type: string;
  background?: string;
}

type Pages = {
  [key: string]: Item;
}

export enum Page {
  Highscore = 'highscore',
  Imprint = 'imprint',
  Privacy = 'privacy',
};

export const useMenu = () => {
  const { overlay } = useSelector((state: RootState) => state.app);

  const currentPage = overlay ? overlay.split(':')[1] : '';

  const savedPages = useRef<Pages>({
    [Page.Highscore]: {
      title: 'Rangliste',
      type: Page.Highscore,
      background: require('../static/images/background-blurred.jpg'),
    },
    [Page.Imprint]: {
      title: 'Impressum',
      type: Page.Imprint,
    },
    [Page.Privacy]: {
      title: 'Datenschutz',
      type: Page.Privacy,
    }
  });

  const pages = savedPages.current;
  const [page, setPage] = useState(pages[currentPage]);

  useEffect(() => {
    setPage(pages[currentPage])
  }, [currentPage, pages]);

  return [page];
};