import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import {setShowHint} from 'redux/slices/appSlice';

import PopUp from 'components/PopUp';

const HintOverlay = () => {
  const { hint, showHint } = useSelector((state: RootState) => state.app)
  const dispatch = useDispatch();

  const confirm = () => {
    dispatch(setShowHint(false));
  };

  type Hints = {
    [id: number]: string
  }

  type Icons = {
    [id: number]: string
  }

  type Animations = {
    [id: number]: string
  }

  const icons : Icons = {
    0: 'screen',
    1: 'clipboard-empty',
    2: 'screen',
    3: 'clipboard-empty',
  }

  // if both are specified for a hint, animations are used in favor of symbols
  const animations : Animations = {
    0: require('../../static/images/animations/documents_kundenanfrage.gif'),
    1: require('../../static/images/animations/copy_to_clipboard.gif'),
    2: require('../../static/images/animations/documents_anfrage_angebote.gif'),
    3: require('../../static/images/animations/copy_from_clipboard.gif'),
    4: require('../../static/images/animations/account_example.gif'),
  }

  const texts : Hints = {
    0: `Gehe auf deinen Arbeitsplatz, um dir die Kundenanfrage nochmal anzusehen.`,
    1: `Gelb markierten Text kannst du in deine Zwischenablage kopieren. Du wirst diese Beträge später nochmal brauchen.`,
    2: `Gehe auf deinen Arbeitsplatz, um dir die Anfrage und die Angebote nochmal anzusehen.`,
    3: `Inhalte aus der Zwischenablage, kannst du direkt in den Taschenrechner einfügen. Du kannst den geöffneten Rechner auch ausblenden, um dir die Aufgabe erneut anzusehen.`,
    4: `Bitte beginne immer mit der Buchung auf der Soll-Seite. Falls mehrere Konten auf einer Seite stehen, beginne bitte mit dem Konto mit dem höheren Betrag.`
  };

  if (showHint) {
    return <PopUp icon={icons[hint]} animation={animations[hint]} title="Tipp" text={texts[hint]} onClick={confirm} />;
  }

  return null;
};

export default HintOverlay;
