import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { setOverlay, setNextPage, setShowDocumentButton } from 'redux/slices/appSlice';

import { useDocument, Type } from 'hooks/document';

import Button from 'components/Button';
import Header from 'components/DocumentHeader';
import TextCloze from 'components/TextCloze';
import ImageCloze from 'components/ImageCloze';

import styles from './Document.module.scss';
import useFunctions from "../../hooks/functions";

const Page = () => {
  const { nextPage, showDocumentButton } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();
  const [document] = useDocument();
  const [{ setPage }] = useFunctions();
  let element = null;

  const close = () => {
    dispatch(setOverlay(null));
    if (nextPage) {
      setPage(nextPage);
      dispatch(setNextPage(null));
    }

    showDocumentButton && dispatch(setShowDocumentButton(false));
  };

  switch (document.type) {
    case Type.TextCloze:
      if (document.text) {
        element = <TextCloze text={document.text} hideContinueButton />;
      }
      break;
    case Type.ImageCloze:
      if (document.src) {
        element = (
          <ImageCloze
            src={document.src}
            hotspots={document.hotspots}
            initialAnswers={document.initialAnswers}
            hideContinueButton
          />
        );
      }
  }

  return (
    <div className={styles.container}>
      <Header title={document.title} onClose={close} />
      <div className={styles.content}>
        {element}
        {(nextPage || showDocumentButton) && <Button className={styles.button} label='Weiter' onClick={close} />}
      </div>
    </div>
  );
}

export default Page;