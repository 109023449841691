import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { Notifications } from "./datatypes";
import { teamleadImage } from "./variables";

import {
    setDialogue, setFinished2, setMission, setModuleScore, setNotification as setCurrentNotification,
    setNotification,
    setShowSurveyPopUp,
    setUnlockedMissions
} from "../../redux/slices/appSlice";
import {pauseTimer, setMissionCompleted, startTimer} from "../../redux/slices/scoreSlice";
import { setTask } from "redux/slices/taskSlice";
import useFunctions from "../functions";
import {RootState} from "../../redux/rootReducer";

export const MissionThirteen = () => {
    const dispatch = useDispatch();
    const { isFinishedModule2 } = useSelector((state: RootState) => state.app);
    const { firstName, lastName, anonymous } = useSelector((state: RootState) => state.user);
    const [{ getMessage, saveHighscoreAnonymous, saveHighscoreWithName, missionsCompleted, setPage, missionPoints, missionMaxPoints }] = useFunctions();

    const missionThirteenData: Notifications = {
        mission13: {
            children: <><p>Auftrag:</p><h2>Zahlung vorbereiten</h2></>,
            style: {justifyContent: 'center'},
            onContinueLabel: 'Weiter',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 2_9: Zahlung vorbereiten']);
                dispatch(setTask('prepareTransfer'));
                dispatch(setDialogue('prepareTransfer'));
                setPage('dialogue');
                dispatch(startTimer());
            },
        },

        thankYouForYourSupport: {
            image: teamleadImage,
            text: `Vielen Dank für deine Unterstützung. Ich werde die Überweisung später freigeben.`,
            onContinue: () => {
                window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 2_9: Zahlung vorbereiten']);
                dispatch(setNotification('mission13Feedback'));
                setPage('notification');
            },
        },

        mission13Feedback: {
            image: teamleadImage,
            text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten bei diesem Auftrag erreicht. ${getMessage()}`,
            onContinueLabel: 'Weiter',
            onContinue: () => {
                dispatch(setMissionCompleted({mission: 13, completed: true}))
                if (missionsCompleted([5, 6, 7, 8, 9, 10, 12])){
                    if (isFinishedModule2) {
                        dispatch(setModuleScore(2));
                        if (anonymous) {
                            saveHighscoreAnonymous(2);
                        } else {
                            saveHighscoreWithName(2);
                        }
                    } else {
                        dispatch(setShowSurveyPopUp(true));
                        setPage('end-feedback');
                    }

                    window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);
                    window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 1_4: Eine Rechnung erstellen']);
                    dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                    dispatch(pauseTimer())
                    dispatch(setFinished2(true));
                } else {
                    dispatch(setMission(0));
                    dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
                    setPage('notification')
                }
            },
        },

        useNameInHighscore: {
            image: teamleadImage,
            text: `Dürfen wir deinen Namen in die Rangliste eintragen? Bitte beachte, dass andere Spieler deinen Namen sehen können.`,
            options: [
                {
                    label: `Ja, ${firstName} ${lastName} verwenden`,
                    onClick: () => {
                        dispatch(setModuleScore(2));
                        saveHighscoreWithName();
                    }
                },
                {
                    label: 'Nein, anonym eintragen',
                    onClick: () => {
                        dispatch(setModuleScore(2));
                        saveHighscoreAnonymous();
                    }
                }
            ],
        },
    }

    return missionThirteenData;
}
