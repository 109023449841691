import React from 'react';

import {FeedbackType, useFeedback} from 'hooks/feedback';

import Button from 'components/Button';
import FeedbackList, { Status } from 'components/FeedbackList';
import Header from 'components/NavigationHeader';
import Infobox from 'components/Infobox';

import styles from './Feedback.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/rootReducer";
import SimpleFeedbackList from "../../components/SimpleFeedbackList";

const Page = () => {
  const [feedback] = useFeedback();

  const showBack = feedback.items.find(item => item.status === Status.Incorrect);
  const { skippable } = useSelector((state: RootState) => state.task);

  let feedbackResult = <FeedbackList className={styles.feedback} title='Bewertung' items={feedback.items}/>
  if (feedback.feedbackType && feedback.feedbackType === FeedbackType.Simple){
      feedbackResult= <SimpleFeedbackList className={styles.feedback} title='Bewertung' items={feedback.items}/>
  }
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Infobox className={styles.infobox} image={require('static/images/avatars/team-lead-happy.png')} text={feedback.label} />
        {feedbackResult}
        {!showBack && <Button label='Weiter' onClick={feedback.onContinue} />}
        {showBack && <Button label='Verbessern' onClick={feedback.onBack} />}

        {(showBack && skippable) && <Button className={styles.skipTask} label='Überspringen' onClick={feedback.onContinue} />}
      </div>
    </div>
  );
}

export default Page;