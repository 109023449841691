import React, { useState } from 'react';
import ReactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from 'react-redux';

import ChoiceBox, { Props as ChoiceBoxProps } from 'components/ChoiceBox';
import { Type } from 'components/HotspotChoice';
import InputBox, { Props as InputBoxProps } from 'components/InputBox';
import TapTextBox from 'components/TapTextBox';
import { Props as CalendarProps } from "../Calendar";

import styles from './LabelChoice.module.scss';
import {setClipboardCurrent} from "../../redux/slices/appSlice";

import CalculatorBox from "../CalculatorBox";
import MiniCalculatorBox from "../MiniCalculatorBox";
import {RootState} from "../../redux/rootReducer";
import CalendarBox from "../CalendarBox";

type Position = {
  x: string;
  y: string;
};

type Props = ChoiceBoxProps & InputBoxProps & CalendarProps & {
  label: string;
  className?: string;
  position?: Position;
  labelXAlignment?: string;
  labelXOffset?: string;
  active?: boolean;
  hasNoDecimals?: boolean;
  type?: Type;
};

const LabelChoice = ({
  active = true,
  className = '',
  title,
  options,
  hasNoDecimals,
  label,
  position,
  labelXAlignment,
  labelXOffset,
  type,
  hasClipboard,
  onClipboardClick,
  secondAttempt,
  secondAttemptOptions,
  year,
  hasYear,
  monthNumber,
  onSubmit,
}: Props) => {
  const dispatch = useDispatch();
  const { mission } = useSelector((state: RootState) => state.app);
  const { task, attempts } = useSelector((state: RootState) => state.task);
  const [isElementVisible, setElementVisibilty] = useState(false);
  const showElement = () => {
    window._paq.push(['trackEvent', 'Interaction', 'Task', 'Opened Hotspot']);
    dispatch(setClipboardCurrent(null))
    setElementVisibilty(true);
  }

  const hideElement = () => {
    setElementVisibilty(false);
  }

  const handleSubmit = (value: string) => {
    onSubmit && onSubmit(value);
    hideElement();
  }

  let element = null;

  let taskAttempts = 0;
  if(attempts[mission][task]){
    taskAttempts = attempts[mission][task]['count'];
  }

  if(secondAttempt && (taskAttempts > 0)){
    switch (secondAttempt.type) {
      case Type.Input:
        element = <InputBox title={secondAttempt.title} hasClipboard={secondAttempt.hasClipboard} onClipboardClick={secondAttempt.onClipboardClick}
                            options={secondAttempt.options} onSubmit={handleSubmit} onClick={hideElement}/>;
        break;
      case Type.TapText:
        element = <TapTextBox title={secondAttempt.title} options={secondAttempt.options} onOverlayClick={hideElement} onEnter={handleSubmit}/>;
        break;
      case Type.Calculator:
        element = <CalculatorBox onOverlayClick={hideElement} options={secondAttempt.options} onEnter={handleSubmit}/>;
        break;
      case Type.MiniCalculator:
        element = <MiniCalculatorBox onOverlayClick={hideElement} options={options} hasNoDecimals={hasNoDecimals} onEnter={handleSubmit}/>;
        break;
      case Type.Calendar:
        element = <CalendarBox onOverlayClick={hideElement} year={year} hasYear={hasYear} monthNumber={monthNumber} options={options} onEnter={handleSubmit}/>;
        break;
      default:
        element = <ChoiceBox title={secondAttempt.title} hasClipboard={secondAttempt.hasClipboard}
                             onClipboardClick={secondAttempt.onClipboardClick}
                             options={secondAttemptOptions} onOverlayClick={hideElement}/>;
    }
  } else {
    switch (type) {
      case Type.Input:
        element = <InputBox title={title} hasClipboard={hasClipboard} onClipboardClick={onClipboardClick}
                            options={options} onSubmit={handleSubmit} onClick={hideElement}/>;
        break;
      case Type.TapText:
        element = <TapTextBox title={title} options={options} onOverlayClick={hideElement} onEnter={handleSubmit}/>;
        break;
      case Type.Calculator:
        element = <CalculatorBox onOverlayClick={hideElement} options={options} onEnter={handleSubmit}/>;
        break;
      case Type.MiniCalculator:
        element = <MiniCalculatorBox onOverlayClick={hideElement} options={options} hasNoDecimals={hasNoDecimals} onEnter={handleSubmit}/>;
        break;
      case Type.Calendar:
        element = <CalendarBox onOverlayClick={hideElement} year={year} hasYear={hasYear} monthNumber={monthNumber} options={options} onEnter={handleSubmit}/>;
        break;
      default:
        element = <ChoiceBox title={title} hasClipboard={hasClipboard} onClipboardClick={onClipboardClick}
                             options={options} onOverlayClick={hideElement}/>;

    }
  }
  const labelStyle: React.CSSProperties = {};

  if (position) {
    labelStyle.position = 'absolute';
    labelStyle.left = position.x;
    labelStyle.top = position.y;
    labelStyle.marginLeft = labelXOffset ? labelXOffset : '';
    let transform = 'translateY(-50%)';
    switch(labelXAlignment) {
      case 'center':
        transform = 'translate(-50%,-50%)';
        break;
      case 'right':
        transform = 'translate(-100%,-50%)';
        break;
      default:
        break;
    }
    labelStyle.transform = transform;
  }

  return (
    <>
      <span
        className={`${styles.label} ${className} ${!active ? styles.inactive : ''}`}
        style={labelStyle}
        onClick={showElement}
      >
        {active && ReactHtmlParser(label)}
        {!active && ReactHtmlParser(label)}
      </span>
      {isElementVisible && element}
    </>
  );
}

export default LabelChoice;
