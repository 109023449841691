import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Page } from 'hooks/library'

import { RootState } from 'redux/rootReducer';

import { Props as ImageClozeProps } from 'components/ImageCloze';
import { Props as TextClozeProps } from 'components/TextCloze';
import {AngebotDober} from "./document/mission5/angebotDober";
import {ProducktAnfrage} from "./document/mission5/produktAnfrage";
import {AngebotPutsch} from "./document/mission5/angebotPutsch";
import {AngebotMeller} from "./document/mission5/angebotMeller";
import {AufstellungBezugskalkulationDone} from "./document/mission5/aufstellungBezugskalkulationDone";
import {Bezugskalkulation} from "./document/mission5/bezugskalkulation";
import {AufstellungZurGewinnberechnungDone} from "./document/mission6/aufstellungZurGewinnberechnungDone";
import {GewinnBerechnenDone} from "./document/mission6/gewinnBerechnenDone";
import {Verkaufspreis} from "./document/mission6/verkaufspreis";
import {EinkaufArtikelubersicht} from "./document/mission7/einkaufArtikelubersicht";
import {Absatzstatistik} from "./document/mission7/absatzstatistik";
import {DeckungsbeitragMDF} from "./document/mission7/deckungsbeitragMDF";
import {Gesamtdeckungsbeitrag} from "./document/mission7/gesamtdeckungsbeitrag";
import {Ausgangsrechnungen} from "./document/mission8/ausgangsrechnungen";
import {Mahnplan} from "./document/mission8/mahnplan";
import {NeuerZahlungsbetragZweiteMahnungDone} from "./document/mission8/neuerZahlungsbetragZweiteMahnungDone";
import {ZweiteMahnungLetterDone} from "./document/mission8/zweiteMahnungLetterDone";
import {Ausgangsrechnungen2} from "./document/mission8/ausgangsrechnungen2";
import {Verzugszinsenberechnung1} from "./document/mission8/verzugszinsenberechnung1";
import {Verzugszinsenberechnung2} from "./document/mission8/verzugszinsenberechnung2";
import {NeuerZahlungsbetragLetzteMahnungDone} from "./document/mission8/neuerZahlungsbetragLetzteMahnungDone";
import {LetzteMahnungLetterDone} from "./document/mission8/letzteMahnungLetterDone";
import {MesinerRechnung} from "./document/mission9/mesinerRechnung";
import {MesinerConfirmation} from "./document/mission9/mesinerConfirmation";
import {CheckTheInvoiceAndOrderMatchDone} from "./document/mission9/checkTheInvoiceAndOrderMatchDone";
import {HinweiseFurBelegverbuchung} from "./document/mission11/hinweiseFurBelegverbuchung";
import {Kontenplan} from "./document/mission11/kontenplan";
import {Musterbeleg} from "./document/mission11/musterbeleg";
import {AccountForReceiptDone} from "./document/mission11/accountForReceiptDone";
import {AccountForReceiptWurmDone} from "./document/mission11/accountForReceiptWurmDone";
import {AccountForReceiptMemoboardDone} from "./document/mission11/accountForReceiptMemoboardDone";
import {AccountForReceiptFinanzbankDone} from "./document/mission11/accountForReceiptFinanzbankDone";
import {AccountForReceiptEarthMagDone} from "./document/mission11/accountForReceiptEarthMagDone";
import {ER743} from "./document/mission12/ER743";
import {K87} from "./document/mission12/K87";
import {K87Clipboard} from "./document/mission12/K87Clipboard";
import {AR703} from "./document/mission12/AR703";
import {K88} from "./document/mission12/K88";
import {K88Clipboard} from "./document/mission12/K88Clipboard";
import {B112} from "./document/mission12/B112";
import {K89} from "./document/mission12/K89";
import {K89Clipboard} from "./document/mission12/K89Clipboard";
import {S32} from "./document/mission12/S32";
import {K90} from "./document/mission12/K90";
import {K90Clipboard} from "./document/mission12/K90Clipboard";
import {Kassabericht} from "./document/mission12/Kassabericht";
import {KassaberichtDone} from "./document/mission12/KassaberichtDone";
import {ER743Clipboard} from "./document/mission13/ER743Clipboard";
import {FillOutTransferFormDone} from "./document/mission13/FillOutTransferFormDone";

type Document = Partial<ImageClozeProps> & Partial<Omit<TextClozeProps, 'hotspots'>> & {
  type: string;
  title: string;
};

type Documents = {
  [key: string]: Document;
};

export enum Type {
  Calculator = 'calculator',
  TextCloze = 'textCloze',
  ImageCloze = 'imageCloze'
};

export const useDocument = () => {
  const { overlay } = useSelector((state: RootState) => state.app);
  const { firstName, lastName } = useSelector((state: RootState) => state.user);

  const page = overlay ? overlay.split(':')[1] : '';
  const currentYear = new Date().getFullYear();

  // @ts-ignore
  const savedDocuments = useRef<Documents>({
    [Page.Inquiry]: {
      type: Type.TextCloze,
      title: 'Anfrage',
      text: `**Von:** maria.normann@nig.at
      **Datum:** 15. November ${currentYear}
      **An:** office@memoboard.at
      **Betreff:** Anfrage
      ---
      Guten Tag,
      
      wir beabsichtigen MDF-Memoboards in unser Sortiment aufzunehmen.
      
      Bitte schicken Sie uns ein Angebot über 500 Stk. MDF-Memoboards natur und 500 Stk. MDF-Memoboards weiß (jeweils in der Größe 42 x 29 cm). Die Lieferung müsste unbedingt noch im November erfolgen. Teillieferungen kommen für uns nicht in Frage. 
      
      Darüber hinaus bitten wir um entsprechende Produktmuster, damit wir diese testen können. Sollten Produktqualität, Preise und Konditionen unseren Erwartungen entsprechen, sind wir an einer langfristigen Geschäftsbeziehung interessiert. 
      
      Ihr Angebot richten Sie bitte an die Leiterin unserer Einkaufsabteilung, Frau Sorger (claudia.sorger@nig.at). 
      
      Vielen Dank und freundliche Grüße
      Maria Normann
      Assistentin Einkauf`,
    },
    [Page.Stocklist]: {
      type: Type.ImageCloze,
      title: 'Lagerbestand', 
      src: require('static/images/tasks/mission1-4/lagerliste.png'),
      hotspots: {
        0: { x: '4%', y: '13%' },
        1: { x: '81%', y: '24.8%' },
        2: { x: '81%', y: '34.3%' },
        3: { x: '81%', y: '43.3%' },
        4: { x: '81%', y: '52.5%' },
        5: { x: '81%', y: '62%' },
        6: { x: '81%', y: '71%' },
        7: { x: '81%', y: '80.5%' },
        8: { x: '81%', y: '90%' },
      },
      initialAnswers: {
        0: { label: `15. November ${currentYear}`, active: false },
        1: { label: `20.11.${currentYear}`, active: false },
        2: { label: `25.11.${currentYear}`, active: false },
        3: { label: `20.12.${currentYear}`, active: false },
        4: { label: `15.12.${currentYear}`, active: false },
        5: { label: `10.12.${currentYear}`, active: false },
        6: { label: `15.12.${currentYear}`, active: false },
        7: { label: `20.11.${currentYear}`, active: false },
        8: { label: `15.11.${currentYear}`, active: false },
      }
    },
    [Page.Offer]: {
      type: Type.TextCloze,
      title: 'Angebot',
      text: `**An:** claudia.sorger@nig.at
      **Cc:**
      **Betreff:** Re: Anfrage
      ---
      Sehr geehrte Frau Sorger !
      Wir danken für Ihre Anfrage vom 15. November ${currentYear}.
      Aufgrund von Lieferengpässen beim Produzenten können wir Ihnen die gewünschten 500 Stk. MDF-Memoboards natur, 42 x 29 cm nicht im gewünschten Zeitraum liefern.

      Wir bieten Ihnen daher an: 1.000 Stk. MDF-Memoboards weiß, 42 x 29 cm
      Einzelpreis in EUR (exkl. 20 % USt): 14,20
      Gesamtpreis in EUR (exkl. 20 % USt): 14.200,00
      Lieferung: frei Haus
      Zahlung: 7 Tage 2 % Skonto, 14 Tage netto Kassa
      
      Die Lieferung können wir zu der von Ihnen gewünschten Lieferzeit bis Ende November durchführen.
      Unser Angebot bleibt 2 Wochen lang gültig.
      
      In den nächsten Tagen erhalten Sie ein kostenloses MDF-Memoboard weiß, 42 x 29 cm als Produktmuster geschickt.
      
      Wir würden uns freuen, Ihren Auftrag zu erhalten.
      
      Freundliche Grüße
      ${firstName} ${lastName}`,
    },
    [Page.Pricelist] : {
      type: Type.ImageCloze,
      title: 'Artikel', 
      src: require('static/images/tasks/mission1-4/preis-kondition-liste.png'),
    },
    [Page.ClientDatabase] : {
      type: Type.ImageCloze,
      title: 'Kundendatenbank', 
      src: require('static/images/tasks/mission1-4/kundendatenbank.png'),
    },
    [Page.OrderConfirmation]: {
      type: Type.ImageCloze,
      title: 'Auftragsbestätigung',
      src: require('static/images/tasks/mission1-4/auftragsbestaetigung.png'),
      hotspots: {
        0: { x: '41%', y: '61%' },
        1: { x: '29%', y: '68.7%' },
        2: { x: '2.8%', y: '80.5%' },
        3: { x: '15%', y: '7.4%' },
      },
      initialAnswers: {
        0: { label: '24.992,00', active: false },
        1: { label: 'Lieferung bis Ende November frei Haus', active: false },
        2: { label: `${firstName} ${lastName}`, active: false },
        3: { label: `20. November ${currentYear}`, active: false },
      }
    },
    [Page.DeliveryNote]: {
      type: Type.ImageCloze,
      title: 'Lieferschein Nig GmbH',
      src: require('static/images/tasks/mission1-4/lieferschein.png'),
      hotspots: {
        0: { x: '79%', y: '22%', },
        1: { x: '79%', y: '43.8%', },
      },
      initialAnswers: {
        0: { label: `20.11.${currentYear}`, active: false },
        1: { label: `20.11.${currentYear}`, active: false },
      }
    },
    [Page.DeliveryNotes]: {
      type: Type.ImageCloze,
      title: 'Lieferscheine',
      src: require('static/images/tasks/mission1-4/lieferscheine.png'),
      hotspots: {
        0: { x: '71%', y: '24%', },
        1: { x: '71%', y: '33%', },
        2: { x: '71%', y: '42%', },
        3: { x: '71%', y: '51%', },
        4: { x: '71%', y: '60%', },
        5: { x: '71%', y: '69%', },
        6: { x: '71%', y: '78%', },
        7: { x: '71%', y: '87%', },
      },
      initialAnswers: {
        0: { label: `01.11.${currentYear}`, active: false },
        1: { label: `01.11.${currentYear}`, active: false },
        2: { label: `03.11.${currentYear}`, active: false },
        3: { label: `14.11.${currentYear}`, active: false },
        4: { label: `14.11.${currentYear}`, active: false },
        5: { label: `15.11.${currentYear}`, active: false },
        6: { label: `20.11.${currentYear}`, active: false },
        7: { label: `16.11.${currentYear}`, active: false },
      }
    },
    [Page.Bill] : {
      type: Type.ImageCloze,
      title: 'Rechnung', 
      src: require('static/images/tasks/mission1-4/kunden-rechnung.png'),
      hotspots: { 
        0: { x: '79%', y: '34%' },
        1: { x: '66%', y: '52.5%' },
      },
      initialAnswers: { 
        0: { label: `20.11.${currentYear}`, active: false },
        1: { label: `20.11.${currentYear}`, active: false } 
      }
    },
    [Page.ClientAccount] : {
      type: Type.ImageCloze,
      title: 'Zahlungseingänge', 
      src: require('static/images/tasks/mission1-4/kunden-konto.png'),
      hotspots: { 
        0: { x: '46.5%', y: '24%' },
        1: { x: '46.5%', y: '33.3%' },
        2: { x: '46.5%', y: '42.3%' },
        3: { x: '46.5%', y: '51.3%' },
        4: { x: '46.5%', y: '60.3%' },
        5: { x: '46.5%', y: '69.3%' },
        6: { x: '46.5%', y: '78.3%' },
        7: { x: '46.5%', y: '87.3%' },
      },
      initialAnswers: { 
        0: { label: `08.11.${currentYear}`, active: false },
        1: { label: `17.11.${currentYear}`, active: false },
        2: { label: `19.11.${currentYear}`, active: false },
        3: { label: `20.11.${currentYear}`, active: false },
        4: { label: `23.11.${currentYear}`, active: false },
        5: { label: `28.11.${currentYear}`, active: false },
        6: { label: `29.11.${currentYear}`, active: false },
        7: { label: `01.12.${currentYear}`, active: false },
      }
    },
    [Page.BackPayment]: {
      type: Type.TextCloze,
      title: 'Aufforderung zur Nachzahlung',
      text: `**An:** claudia.sorger@nig.at
      **Cc:** kastner@memoboard.at
      **Betreff:** Ungerechtfertigter Skontoabzug
      ---
      Sehr geehrte Frau Sorger !
      wir haben am 29. November ${currentYear} einen Zahlungseingang über 29.390,59 EUR für die Re.-Nr. 15720 vom 20. November ${currentYear}. 
      Nach den auf der Rechnung angeführten Zahlungskonditionen 7 Tage 2 % Skonto, 14 Tage netto Kassa wäre ein Skontoabzug bei einer Überweisung bis zum 27. November ${currentYear} zulässig gewesen.
      Der Skontoabzug ist somit nicht gerechtfertigt.
      
      Wir bitten Sie den ungerechtfertigt in Abzug gebrachten Skonto von 599,81 EUR binnen einer Woche auf unser Konto zu überweisen.
      
      IBAN: AT15 5848 9652 1487 2584
      BIC: BUBKATWW157
      
      Freundliche Grüße
      ${firstName} ${lastName}`,
    },
    [Page.Produktanfrage]: ProducktAnfrage(),
    [Page.AngebotPutsch] : AngebotPutsch(),
    [Page.AngebotDober] : AngebotDober(),
    [Page.AngebotMeller] : AngebotMeller(),
    [Page.AufstellungBezugskalkulationDone] : AufstellungBezugskalkulationDone(),
    [Page.Bezugskalkulation] : Bezugskalkulation(),
    [Page.AufstellungZurGewinnberechnungDone] : AufstellungZurGewinnberechnungDone(),
    [Page.GewinnBerechnenDone] : GewinnBerechnenDone(),
    [Page.Verkaufspreis] : Verkaufspreis(),
    [Page.EinkaufArtikelubersicht] : EinkaufArtikelubersicht(),
    [Page.Absatzstatistik] : Absatzstatistik(),
    [Page.Ausgangsrechnungen] : Ausgangsrechnungen(),
    [Page.Mahnplan] : Mahnplan(),
    [Page.NeuerZahlungsbetragZweiteMahnungDone] : NeuerZahlungsbetragZweiteMahnungDone(),
    [Page.ZweiteMahnungLetterDone] : ZweiteMahnungLetterDone(),
    [Page.Ausgangsrechnungen2] : Ausgangsrechnungen2(),
    [Page.Verzugszinsenberechnung1] : Verzugszinsenberechnung1(),
    [Page.Verzugszinsenberechnung2] : Verzugszinsenberechnung2(),
    [Page.NeuerZahlungsbetragLetzteMahnungDone] : NeuerZahlungsbetragLetzteMahnungDone(),
    [Page.LetzteMahnungLetterDone] : LetzteMahnungLetterDone(),
    [Page.MesinerRechnung] : MesinerRechnung(),
    [Page.MesinerConfirmation] : MesinerConfirmation(),
    [Page.CheckTheInvoiceAndOrderMatchDone] : CheckTheInvoiceAndOrderMatchDone(),
    [Page.HinweiseFurBelegverbuchung] : HinweiseFurBelegverbuchung(),
    [Page.Kontenplan] : Kontenplan(),
    [Page.Musterbeleg] : Musterbeleg(),
    [Page.AccountForReceiptDone] : AccountForReceiptDone(),
    [Page.AccountForReceiptWurmDone] : AccountForReceiptWurmDone(),
    [Page.AccountForReceiptMemoboardDone] : AccountForReceiptMemoboardDone(),
    [Page.AccountForReceiptFinanzbankDone] : AccountForReceiptFinanzbankDone(),
    [Page.AccountForReceiptEarthMagDone] : AccountForReceiptEarthMagDone(),
    [Page.ER743] : ER743(),
    [Page.ER743Clipboard] : ER743Clipboard(),
    [Page.K87] : K87(),
    [Page.K87Clipboard] : K87Clipboard(),
    [Page.AR703] : AR703(),
    [Page.K88] : K88(),
    [Page.K88Clipboard] : K88Clipboard(),
    [Page.B112] : B112(),
    [Page.K89] : K89(),
    [Page.K89Clipboard] : K89Clipboard(),
    [Page.S32] : S32(),
    [Page.K90] : K90(),
    [Page.K90Clipboard] : K90Clipboard(),
    [Page.Kassabericht] : Kassabericht(),
    [Page.KassaberichtDone] : KassaberichtDone(),
    [Page.FillOutTransferFormDone] : FillOutTransferFormDone,
    [Page.DeckungsbeitragMDF] : DeckungsbeitragMDF(),
    [Page.Gesamtdeckungsbeitrag] : Gesamtdeckungsbeitrag(),
  });

  const documents = savedDocuments.current;
  const [document, setDocument] = useState(documents[page]);

  useEffect(() => {
    setDocument(documents[page])
  }, [page, documents]);

  return [document];
};
