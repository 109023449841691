import React from 'react';

import styles from './Portrait.module.scss';

export type Props = {
  src: string;
  className?: string;
  size?: string;
  style?: React.CSSProperties;
}

const Portrait = ({
  src,
  className = '',
  style = {},
  size = '96px',
}: Props) => (
  <img
    alt='portrait'
    className={`${styles.container} ${className}`}
    style={{width: size, height: size, ...style }}
    src={src}
  />
);

export default Portrait;