import React, { useState } from 'react';

import styles from './ButtonLabel.module.scss';

type Props = {
  label: string;
  subLabel?: string;
  className?: string;
  onClick?: (label: string) => void;
};

const ButtonLabel = ({
  label,
  subLabel,
  className = '',
  onClick,
}: Props) => {

  const [wasClicked, setWasClicked] = useState(false);

  const handleClick = () => {
    if (!wasClicked) {
      onClick && onClick(label);
      setWasClicked(true);
    }
  };

  return (
    <div className={`${styles.container} ${className} ${wasClicked ? styles.inactive : ''}`} onClick={handleClick}>
      <span>{label}</span>
      {subLabel && <p>{subLabel}</p>}
    </div>
  );
};

export default ButtonLabel;