import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { setOverlay, setShowNotepad } from 'redux/slices/appSlice';

import styles from './NavigationHeader.module.scss';

type Props = {
  className?: string;
};

const Header = ({
  className = '',
}: Props) => {
  const dispatch = useDispatch();
  const { mission, overlay, wasJokerUsed, showNotepad, unlockedMenu, highlightMenu } = useSelector((state: RootState) => state.app);
  const { points } = useSelector((state: RootState) => state.score);
  const { task } = useSelector((state: RootState) => state.task)

  const openMenu = () => {
    window._paq.push(['trackEvent', 'Interaction', 'Navbar', 'Button Click']);
    dispatch(setOverlay('menu'));
  };

  const openLibrary = () => {
    window._paq.push(['trackEvent', 'Interaction', 'Navbar', 'Button Click']);
    dispatch(setOverlay('library'));
  };

  const openNotepad = () => {
    window._paq.push(['trackEvent', 'Interaction', 'Navbar', 'Button Click']);
    dispatch(setShowNotepad(true));
  };

  const openJoker = () => {
    window._paq.push(['trackEvent', 'Interaction', 'Navbar', 'Button Click']);
    dispatch(setOverlay('joker'));
  };

  const openClipboard = () => {
    window._paq.push(['trackEvent', 'Interaction', 'Navbar', 'Button Click']);
    dispatch(setOverlay('clipboard'));
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.iconContainer}>
        {unlockedMenu.includes('home') && <span className={styles.iconContainerItem} data-item='menu'><i className={`lnr lnr-home ${highlightMenu.includes('home') ? styles.selected : ''}`} onClick={openMenu}/></span>}
        {unlockedMenu.includes('library') && <span className={styles.iconContainerItem} data-item='library'><i className={`lnr lnr-screen ${highlightMenu.includes('library') ? styles.selected : ''}`} onClick={openLibrary}/></span>}
        {unlockedMenu.includes('notepad') && <span className={styles.iconContainerItem} data-item='notepad'><i className={`lnr lnr-document ${highlightMenu.includes('notepad') ? styles.selected : ''}` } onClick={openNotepad}/></span>}
        {mission !== 0 && unlockedMenu.includes('clipboard') && <span className={styles.iconContainerItem} data-item='clipboard'><i className={`lnr lnr-clipboard-empty ${highlightMenu.includes('clipboard') ? styles.selected : ''}` } onClick={openClipboard} id={'menu-clipboard-item'}/></span>}
        {unlockedMenu.includes('joker') && <span className={styles.iconContainerItem} data-item='joker'><img className={((overlay !== null && overlay !== 'joker') || wasJokerUsed || !task || showNotepad) ? styles.noUse : ''}
                                                 alt='joker' src={require('../../static/images/avatars/joker.png')} onClick={openJoker} /></span>}
      </div>

      {mission !== 11 && mission !== 0 && <span>{points[mission].points} | {points[mission].maxPoints} Punkte</span> }
    </div>
  );
};

export default Header;
