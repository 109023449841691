import { useDispatch } from "react-redux";

import { Page as Document } from "../library";
import {setTask as setCurrentTask, setTaskSkippable} from "../../redux/slices/taskSlice";

import { useFunctions } from "../functions";
import { Type } from "../../components/HotspotChoice";

import {
    setAllowClipboardCopy, setFeedback,
    setOverlay as setCurrentOverlay,
} from "../../redux/slices/appSlice";
import { Tasks } from "./datatypes";

export const MissionSix = () => {
    const [{ setOverlay, setImageClozeAnswer, setImageClozePoints, setPage }] = useFunctions();
    const dispatch = useDispatch();

    const missionSixData: Tasks = {
        prepareCalculationProfits: {
            title: 'Aufstellung zur Gewinnberechnung',
            src: require('static/images/tasks/mission6/mVe_AufstellungZurGewinnberechnung.png'),
            hotspots: {
                0: {
                    x: '30%',
                    y: '18%',
                    labelXOffset: '-2%',
                    options: [
                        { label: 'Einstandspreis pro Stk.',  correct: true,  onClick: setImageClozeAnswer },
                        { label: 'Selbstkosten', onClick: setImageClozeAnswer },
                        { label: 'Kassapreis', onClick: setImageClozeAnswer },
                        { label: 'Nettoverkaufspreis', onClick: setImageClozeAnswer },
                    ],
                },
                1: {
                    x: '17.8%',
                    y: '24.5%',
                    options: [
                        { label: '30,00 % Regien (Gemeinkosten)',  correct: true,  onClick: setImageClozeAnswer },
                        { label: '2,00 % Kundenskonto', alternativeLabel: '2,00 %   Kundenskonto', onClick: setImageClozeAnswer },
                        { label: '10,00 % Kundenrabatt', onClick: setImageClozeAnswer },
                        { label: '20,00 % USt', onClick: setImageClozeAnswer },
                    ],
                },

                2: {
                    x: '10%',
                    y: '24.5%',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ],
                },
                3: {
                    x: '30%',
                    y: '32.5%',
                    labelXOffset: '-2%',
                    options: [
                        { label: 'Einstandspreis pro Stk.', onClick: setImageClozeAnswer },
                        { label: 'Selbstkosten', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Kassapreis', onClick: setImageClozeAnswer },
                        { label: 'Nettoverkaufspreis', onClick: setImageClozeAnswer },
                    ],
                },
                4: {
                    x: '30%',
                    y: '46.5%',
                    labelXOffset: '-2%',
                    options: [
                        { label: 'Einstandspreis pro Stk.', onClick: setImageClozeAnswer },
                        { label: 'Selbstkosten', onClick: setImageClozeAnswer },
                        { label: 'Kassapreis', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Nettoverkaufspreis', onClick: setImageClozeAnswer },
                    ],
                },
                5: {
                    x: '17.8%',
                    y: '52.5%',
                    options: [
                        { label: '30,00 % Regien (Gemeinkosten)', onClick: setImageClozeAnswer },
                        { label: '2,00 % Kundenskonto', alternativeLabel: '2,00 %   Kundenskonto', correct: true, onClick: setImageClozeAnswer },
                        { label: '10,00 % Kundenrabatt', onClick: setImageClozeAnswer },
                        { label: '20,00 % USt', onClick: setImageClozeAnswer },
                    ],
                },
                6: {
                    x: '10%',
                    y: '52.5%',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ],
                },
                7: {
                    x: '17.8%',
                    y: '66.5%',
                    options: [
                        { label: '30,00 % Regien (Gemeinkosten)', onClick: setImageClozeAnswer },
                        { label: '2,00 % Kundenskonto', alternativeLabel: '2,00 %   Kundenskonto',onClick: setImageClozeAnswer },
                        { label: '10,00 % Kundenrabatt', correct: true, onClick: setImageClozeAnswer },
                        { label: '20,00 % USt', onClick: setImageClozeAnswer },
                    ],
                },
                8: {
                    x: '10%',
                    y: '66.5%',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ],
                },
                9: {
                    x: '30%',
                    y: '75%',
                    labelXOffset: '-2%',
                    options: [
                        { label: 'Einstandspreis pro Stk.', onClick: setImageClozeAnswer },
                        { label: 'Selbstkosten', onClick: setImageClozeAnswer },
                        { label: 'Kassapreis', onClick: setImageClozeAnswer },
                        { label: 'Nettoverkaufspreis',  correct: true, onClick: setImageClozeAnswer },
                    ],
                },
                10: {
                    x: '17.8%',
                    y: '80.5%',
                    options: [
                        { label: '30,00 % Regien (Gemeinkosten)', onClick: setImageClozeAnswer },
                        { label: '2,00 % Kundenskonto', alternativeLabel: '2,00 %   Kundenskonto', onClick: setImageClozeAnswer },
                        { label: '10,00 % Kundenrabatt', onClick: setImageClozeAnswer },
                        { label: '20,00 % USt', correct: true, onClick: setImageClozeAnswer },
                    ],
                },

                11: {
                    x: '10%',
                    y: '80.5%',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ],
                },
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('prepareCalculationProfits'));
                setPage('feedback');
            },
        },
        calculationProfits: {
            title: 'Gewinn berechnen',
            subtitle: '<span class="tip-label">Tipp:</span> Starte mit dem Einstandspreis der Firma Dober. Du findest ihn in der Bezugskalkulation auf deinem Arbeitsplatz.',
            src: require('static/images/tasks/mission6/mVe_GewinnBerechnen.png'),
            hotspots: {
                0: {
                    x: '85%',
                    y: '11%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.MiniCalculator,
                    correct: ['8187,28', '8.187,28'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    children: [{ x: '88%', y: '16.5%', labelXAlignment: 'right', equation: '/ 100' }],
                    options: [
                        {label: 'Bezugskalkulation ansehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Bezugskalkulation)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '85%',
                    y: '25%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['24,56'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '25%',
                        options: [
                            { label: 'Einstandspreis / 100 * 30 = 24,56', alternativeLabel: '24,56', value: '24,56', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Einstandspreis / 130 * 100 = 62,98', alternativeLabel: '62,98', value: '62,98', onClick: setImageClozeAnswer },
                            { label: 'Einstandspreis / 100 * 70 = 57,31', alternativeLabel: '57,31', value: '57,31', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '85%',
                    y: '80.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['25', '25,0', '25,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '80.5%',
                        options: [
                            { label: 'Bruttoverkaufspreis / 100 * 20 = 30,00', alternativeLabel: '30,00', value: '30,00', onClick: setImageClozeAnswer },
                            { label: 'Bruttoverkaufspreis / 120 * 20 = 25,00', alternativeLabel: '25,00', value: '25,00', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Bruttoverkaufspreis / 80 * 20 = 37,50', alternativeLabel: '37,50', value: '37,50', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '85%',
                    y: '74.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['125', '125,0', '125,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '74.5%',
                        options: [
                            { label: 'Bruttoverkaufspreis - (Bruttoverkaufspreis / 80 * 20) = 112,50', alternativeLabel: '112,50', value: '112,50', onClick: setImageClozeAnswer },
                            { label: 'Bruttoverkaufspreis / 120 * 100 = 125,00', alternativeLabel: '125,00', value: '125,00', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Bruttoverkaufspreis / 100 * 80 = 120,00', alternativeLabel: '120,00', value: '120,00', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '85%',
                    y: '66.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['12,5', '12,50'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '66.5%',
                        options: [
                            { label: 'Nettoverkaufspreis / 100 * 10 = 12,50', alternativeLabel: '12,50', value: '12,50', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Nettoverkaufspreis / 110 * 10 = 11,36', alternativeLabel: '11,36', value: '11,36', onClick: setImageClozeAnswer },
                            { label: 'Nettoverkaufspreis / 110 * 90 = 102,27', alternativeLabel: '102,27', value: '102,27', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '85%',
                    y: '53%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['2,25'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '53%',
                        options: [
                            { label: 'Zwischenergebnis / 98 * 2 = 2,29', alternativeLabel: '2,29', value: '2,29', onClick: setImageClozeAnswer },
                            { label: 'Zwischenergebnis / 102 * 2 = 2,21', alternativeLabel: '2,21', value: '2,21', onClick: setImageClozeAnswer },
                            { label: 'Zwischenergebnis / 100 * 2 = 2,25', alternativeLabel: '2,25', value: '2,25', correct: true, onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '85%',
                    y: '46.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['110,25'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '46.5%',
                        options: [
                            { label: 'Zwischenergebnis / 100 * 98 = 110,25', alternativeLabel: '110,25', value: '110,25', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Zwischenergebnis / 102 * 98 = 117,09', alternativeLabel: '117,09', value: '117,09', onClick: setImageClozeAnswer },
                            { label: 'Zwischenergebnis / 102 * 100 = 110,29', alternativeLabel: '110,29', value: '110,29', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '85%',
                    y: '38.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['3,82'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '38.7%',
                        options: [
                            { label: 'Nettoverkaufspreis - Selbstkosten = 24,56', alternativeLabel: '24,56', value: '24,56', onClick: setImageClozeAnswer },
                            { label: 'Bruttoverkaufspreis - Einstandspreis = 68,13', alternativeLabel: '68,13', value: '68,13', onClick: setImageClozeAnswer },
                            { label: 'Kassapreis - Selbstkosten = 3,82', alternativeLabel: '3,82', value: '3,82', correct: true, onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '21%',
                    y: '39%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['3,58'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '21%',
                        y: '39%',
                        options: [
                            { label: 'Gewinn / Bruttoverkaufspreis * 100 = 2,55', alternativeLabel: '2,55', value: '2,55', onClick: setImageClozeAnswer },
                            { label: 'Gewinn / Selbstkosten * 100 = 3,58', alternativeLabel: '3,58', value: '3,58', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Gewinn / Einstandspreis * 100 = 4,67', alternativeLabel: '4,67', value: '4,67', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
            },
            autogeneratedChildren: [
                {
                    x: '88%',
                    y: '32%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 0,
                            sign: '+',
                            equation: '/100'
                        },
                        {
                            id: 1,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '88%',
                    y: '60%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 4,
                            sign: '-',
                            equation: ''
                        },
                    ]
                },
            ],
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true));
                dispatch(setFeedback('calculationProfits'));
                setPage('feedback');
            },
        },
        sellingPriceCalculation: {
            title: 'Verkaufspreis berechnen',
            subtitle: '<span class="tip-label">Tipp:</span> Achte darauf, dass du korrekt von, in oder auf Hundert rechnest.',
            src: require('static/images/tasks/mission6/mVe_VerkaufspreisBerechnen.png'),
            hotspots: {
                0: {
                    x: '22%',
                    y: '23.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.2%',
                    title: 'Wir erhalten auf das ursprüngliche Angebot noch einen zusätzlichen Rabatt von 5 %.',
                    options: [
                        { label: '10 %', alternativeLabel: '10,00 %', onClick: setImageClozeAnswer },
                        { label: '15 %', alternativeLabel: '15,00 %', onClick: setImageClozeAnswer },
                        { label: '20 %', alternativeLabel: '20,00 %', correct: true, onClick: setImageClozeAnswer },
                        { label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober);
                            }},
                    ],
                },
                1: {
                    x: '85%',
                    y: '23.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: '',
                    type: Type.Calculator,
                    correct: ['19,86'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '23.5%',
                        options: [
                            { label: 'Einkaufspreis / 100 * 20 = 19,86', alternativeLabel: '19,86', value: '19,86', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Einkaufspreis / 120 * 100 = 82,75', alternativeLabel: '82,75', value: '82,75', onClick: setImageClozeAnswer },
                            { label: 'Einkaufspreis / 100 * 80 = 79,44', alternativeLabel: '57,31', value: '57,31', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '85%',
                    y: '33.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: '',
                    type: Type.Calculator,
                    correct: ['2,38'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '33.5%',
                        options: [
                            { label: '"Zwischenergebnis 1" / 97 * 3 = 2,46', alternativeLabel: '2,46', value: '2,46', onClick: setImageClozeAnswer },
                            { label: '"Zwischenergebnis 1" / 100 * 3 = 2,38', alternativeLabel: '2,38', value: '2,38', correct: true, onClick: setImageClozeAnswer },
                            { label: '"Zwischenergebnis 1" / 103 * 3 = 2,31', alternativeLabel: '2,31', value: '2,31', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '85%',
                    y: '44%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['23,12'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '44%',
                        options: [
                            { label: 'Einstandspreis / 130 * 30 = 17,78', alternativeLabel: '17,78', value: '17,78', onClick: setImageClozeAnswer },
                            { label: 'Einstandspreis / 130 * 70 = 41,49', alternativeLabel: '41,49', value: '41,49', onClick: setImageClozeAnswer },
                            { label: 'Einstandspreis / 100 * 30 = 23,12', alternativeLabel: '23,12', value: '23,12', correct: true, onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '22%',
                    y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.2%',
                    options: [
                        { label: '10 %', alternativeLabel: '10,00 %', correct: true, onClick: setImageClozeAnswer },
                        { label: '15 %', alternativeLabel: '15,00 %', onClick: setImageClozeAnswer },
                        { label: '20 %', alternativeLabel: '20,00 %', onClick: setImageClozeAnswer },
                        { label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober);
                            }},
                    ],
                },
                5: {
                    x: '85%',
                    y: '54.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: '',
                    type: Type.Calculator,
                    correct: ['10,02'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '33.5%',
                        options: [
                            { label: 'Selbstkosten / 100 * 10 = 10,02', alternativeLabel: '10,02', value: '10,02', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Bruttoverkaufspreis - Einkaufspreis = 50,62', alternativeLabel: '50,62', value: '50,62', onClick: setImageClozeAnswer },
                            { label: 'Einkaufspreis - Einstandspreis = 22,24', alternativeLabel: '22,24', value: '22,24', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '85%',
                    y: '64.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: '',
                    type: Type.Calculator,
                    correct: ['2,25'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '33.5%',
                        options: [
                            { label: 'Kassapreis / 100 * 2 = 2,20', alternativeLabel: '2,20', value: '2,20', onClick: setImageClozeAnswer },
                            { label: 'Kassapreis / 98 * 2 = 2,25', alternativeLabel: '2,25', value: '2,25', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Kassapreis / 102 * 2 = 2,16', alternativeLabel: '2,16', value: '2,16', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '85%',
                    y: '75.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: '',
                    type: Type.Calculator,
                    correct: ['12,49'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '33.5%',
                        options: [
                            { label: '"Zwischenergebnis 2" / 90 * 10 = 12,49', alternativeLabel: '12,49', value: '12,49', correct: true, onClick: setImageClozeAnswer },
                            { label: '"Zwischenergebnis 2" / 100 * 10 = 12,24', alternativeLabel: '12,24', value: '12,24', onClick: setImageClozeAnswer },
                            { label: '"Zwischenergebnis 2" / 110 * 10 = 10,22', alternativeLabel: '10,22', value: '10,22', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '85%',
                    y: '85.6%',
                    labelXAlignment: 'right',
                    labelXOffset: '3%',
                    title: '',
                    type: Type.Calculator,
                    correct: ['24,99'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    secondAttempt: {
                        x: '85%',
                        y: '33.5%',
                        options: [
                            { label: 'Nettoverkaufspreis / 80 * 20 = 31,23', alternativeLabel: '31,23', value: '31,23', onClick: setImageClozeAnswer },
                            { label: 'Nettoverkaufspreis / 100 * 20 = 24,99', alternativeLabel: '24,99', value: '24,99', correct: true, onClick: setImageClozeAnswer },
                            { label: 'Nettoverkaufspreis / 120 * 20 = 20,82', alternativeLabel: '20,82', value: '20,82', onClick: setImageClozeAnswer },
                        ],
                    },
                    onSubmit: setImageClozeAnswer,
                },
            },
            autogeneratedChildren: [
                {
                    x: '88%',
                    y: '29%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 1,
                            sign: '-',
                            equation: '+ 99.3'
                        },
                    ]
                },
                {
                    x: '88%',
                    y: '39%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 1,
                            sign: '-',
                            equation: '+ 99.3'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '88%',
                    y: '49.5%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 1,
                            sign: '-',
                            equation: '+ 99.3'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '88%',
                    y: '59.5%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 1,
                            sign: '-',
                            equation: '+ 99.3'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 5,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '88%',
                    y: '70%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 1,
                            sign: '-',
                            equation: '+ 99.3'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 5,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 6,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '88%',
                    y: '80.5%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 1,
                            sign: '-',
                            equation: '+ 99.3'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 5,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 6,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 7,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '88%',
                    y: '91%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 1,
                            sign: '-',
                            equation: '+ 99.3'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 5,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 6,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 7,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 8,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
            ],
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true));
                dispatch(setFeedback('sellingPriceCalculation'));
                setPage('feedback');
            },
        }
    }

    return missionSixData;
}
