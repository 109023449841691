import React from 'react';

import Portrait from 'components/Portrait';
import Animation from 'components/Animation';

import styles from './Infobox.module.scss';

export type Props = {
  image?: string;
  animation?: string;
  icon?: string;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  text?: string;
  children?: React.ReactNode;
};

const Infobox = ({
  image,
  animation,
  icon,
  title,
  className = '',
  text,
  children,
  style,
}: Props) => (
  <div className={`${styles.container} ${className}`} style={style}>
    {
      (animation && <Animation className={styles.animation} src={animation} />) ||
      (image && <Portrait className={styles.portrait} src={image} />) ||
      (icon && <i className={`lnr lnr-${icon}`} />)
    }
    {title && <h2>{title}</h2>}
    {text && <p>{text}</p>}
    {children}
  </div>
);

export default Infobox;
