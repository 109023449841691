import React from 'react';

import { useTask } from 'hooks/imageCloze';

import Header from 'components/NavigationHeader';
import ImageCloze from 'components/ImageCloze';

import styles from './ImageCloze.module.scss';

const Page = () => {
  const { task, initialAnswers } = useTask();

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <ImageCloze
          title={task.title}
          subtitle={task.subtitle}
          src={task.src}
          hotspots={task.hotspots}
          autogeneratedChildren={task.autogeneratedChildren}
          onContinue={task.onContinue}
          initialAnswers={initialAnswers}
        >
          {task.children}
        </ImageCloze>
      </div>
    </div>
  );
}

export default Page;