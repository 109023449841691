import { useDispatch } from "react-redux";

import { useFunctions } from "../functions";
import { Tasks } from "./datatypes";

import {
    setAllowClipboardCopy,
    setFeedback,
    setOverlay as setCurrentOverlay,
} from "../../redux/slices/appSlice";
import {setTask as setCurrentTask, setTaskSkippable} from "../../redux/slices/taskSlice";
import { Type } from "../../components/HotspotChoice";

import { Page as Document } from "../library";
import { emptyAnswerValue } from "../../components/MiniCalculator";
import {currentYear} from "../document/variables";

export const MissionTwelve = () => {
    const [{ setImageClozeAnswer, setImageClozePoints, setOverlay, setPage }] = useFunctions();
    const dispatch = useDispatch();

    const missionTwelveData: Tasks = {
        beleg87: {
            title: 'Beleg 87 in den Kassabericht übertragen',
            src: require('static/images/tasks/mission12/mKe_KassaberichtBeleg87.png'),
            hotspots: {
                0: {
                    x: '65.5%',
                    y: '37%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K87Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '85.5%',
                    y: '37%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: ['6,97'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K87Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                2: {x: '15%', y: '15.2%'},
                3: {x: '32%', y: '90%'},
            },
            initialAnswers: {
                2: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
                3: {label: `Überschuss/Manko`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('beleg87'));
                setPage('feedback');
            },
        },
        beleg88: {
            title: 'Beleg 88 in den Kassabericht übertragen',
            src: require('static/images/tasks/mission12/mKe_KassaberichtBeleg88.png'),
            hotspots: {
                0: {
                    x: '65.5%',
                    y: '43%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K88Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '85.5%',
                    y: '43%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: ['208,00', '208,0', '208'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K88Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                2: {x: '15%', y: '15.2%'},
                3: {x: '32%', y: '90%'},
            },
            initialAnswers: {
                2: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
                3: {label: `Überschuss/Manko`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('beleg88'));
                setPage('feedback');
            },
        },
        beleg89: {
            title: 'Beleg 89 in den Kassabericht übertragen',
            src: require('static/images/tasks/mission12/mKe_KassaberichtBeleg89.png'),
            hotspots: {
                0: {
                    x: '65.5%',
                    y: '49%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: ['500,00', '500,0', '500'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K89Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '85.5%',
                    y: '49%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K89Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                2: {x: '15%', y: '15.2%'},
                3: {x: '32%', y: '90%'},
            },
            initialAnswers: {
                2: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
                3: {label: `Überschuss/Manko`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('beleg89'));
                setPage('feedback');
            },
        },
        beleg90: {
            title: 'Beleg 90 in den Kassabericht übertragen',
            src: require('static/images/tasks/mission12/mKe_KassaberichtBeleg90.png'),
            hotspots: {
                0: {
                    x: '65.5%',
                    y: '54.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K90Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '85.5%',
                    y: '54.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: ['132,00', '132,0', '132'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Am Beleg nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.K90Clipboard)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                2: {x: '15%', y: '15.2%'},
                3: {x: '32%', y: '90%'},
            },
            initialAnswers: {
                2: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
                3: {label: `Überschuss/Manko`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('beleg90'));
                setPage('feedback');
            },
        },
        saldoBberechnen: {
            title: 'Saldo berechnen',
            src: require('static/images/tasks/mission12/mKe_KassaberichtSaldo.png'),
            hotspots: {
                0: {
                    x: '65.5%',
                    y: '60.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.Calculator,
                    correct: ['500,00', '500,0', '500'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '85.5%',
                    y: '60.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.Calculator,
                    correct: ['346,97'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '85.5%',
                    y: '66.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.Calculator,
                    correct: ['153,03'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                3: {x: '15%', y: '15.2%'},
                4: {x: '32%', y: '90%'},
            },
            initialAnswers: {
                3: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
                4: {label: `Überschuss/Manko`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('saldoBberechnen'));
                setPage('feedback');
            },
        },
        balancePreviousDays: {
            title: 'Saldo des Vortages übertragen',
            src: require('static/images/tasks/mission12/mKe_KassaberichtSaldoVortag.png'),
            hotspots: {
                0: {
                    x: '85.5%',
                    y: '72.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.MiniCalculator,
                    correct: ['170,35'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    options: [
                        {label: 'Kassabericht vom Vortag ansehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kassabericht)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {x: '15%', y: '15.2%'},
                2: {x: '32%', y: '90%'},
            },
            initialAnswers: {
                1: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
                2: {label: `Überschuss/Manko`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('balancePreviousDays'));
                setPage('feedback');
            },
        },

        currentCashBalance: {
            title: 'Kassabericht abschließen',
            subtitle: 'Kassastand am Ende des heutigen Tages: <span class="tip-label">EUR 321,38</span>',
            src: require('static/images/tasks/mission12/mKe_KassaendbestandErmitteln.png'),
            hotspots: {
                0: {
                    x: '85.5%',
                    y: '78%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.Calculator,
                    correct: ['323,38'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '85.5%',
                    y: '84%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.Calculator,
                    correct: ['321,38'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '85.5%',
                    y: '90%',
                    labelXAlignment: 'right',
                    labelXOffset: '9%',
                    type: Type.Calculator,
                    correct: ['-2,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                3: {x: '32%', y: '90%'},
                4: {x: '15%', y: '15.2%'},
            },
            initialAnswers: {
                3: {label: `Überschuss/Manko`, active: false},
                4: {label: `<span style="color:white">12.11.${currentYear}</span>`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true));
                dispatch(setFeedback('currentCashBalance'));
                setPage('feedback');
            },
        },
    }

    return missionTwelveData;
}
