import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Answer } from 'components/ImageCloze';

import { Page as Document } from 'hooks/library'

import { RootState } from 'redux/rootReducer';
import {
  setNotification as setCurrentNotification,
  setScene,
  setMission,
  setDialogue,
  unlockDocument,
  setOverlay as setCurrentOverlay,
  setNextPage,
  setUnlockedMissions,
  setWasJokerUsed,
  setShowDocumentButton,
  setFinished,
  setShowSurveyPopUp,
  setTutorial,
  setTutorialSlide,
  setMenu,
  setHighlightMenu,
  setModuleScore,
  setModule,
  setReachedModuleScore,
} from 'redux/slices/appSlice'

import { Missions, Message, HighscoreEntry} from './notification/datatypes'
import { addPoints, setPointsDetails, startTimer, pauseTimer, setMissionCompleted } from 'redux/slices/scoreSlice';
import { setTask, setAnsweredTasks } from 'redux/slices/taskSlice';
import { setId as setUserId, setAnonymous } from 'redux/slices/userSlice';

import useFunctions from "./functions";

import {MissionFive} from './notification/mission5'
import {teamleadImage} from "./notification/variables";
import {MissionSix} from "./notification/mission6";
import {MissionSeven} from "./notification/mission7";
import {MissionEight} from "./notification/mission8";
import {MissionNine} from "./notification/mission9";
import {MissionTen} from "./notification/mission10";
import {MissionEleven} from "./notification/mission11";
import {MissionTwelve} from "./notification/mission12";
import {MissionThirteen} from "./notification/mission13";
import {Others} from "./notification/others";

export const useNotification = () => {
  const { mission: currentMission, notification: currentNotification, reachedModuleScore,isFinished, module: currentModule } = useSelector((state: RootState) => state.app);
  const { image: userImage, firstName, lastName, id: userId, anonymous } = useSelector((state: RootState) => state.user);
  const { points: currentPoints, time, pointsDetails } = useSelector((state: RootState) => state.score);
  const { task } = useSelector((state: RootState) => state.task);
  const [{ missionsCompleted, setPage }] = useFunctions();
  const dispatch = useDispatch();
  const _ = require("lodash");

  const missionPoints = currentPoints[currentMission].points;
  const missionMaxPoints = currentPoints[currentMission].maxPoints;

  const messages : Message = {
    0: 'Das kannst du besser!',
    61: 'Gar nicht so schlecht!',
    76: 'Das ist schon recht gut!',
    90: 'Super, das hast du gut gemacht!'
  };

  const getMessage = () => {
    const missionPercentage = (missionPoints / missionMaxPoints) * 100;

    const breakpoints = Object.keys(messages);
    for (let i = breakpoints.length - 1; i >= 0; i--) {
      if (missionPercentage >= parseInt(breakpoints[i], 10)) {
        return messages[breakpoints[i]];
      }
    }
  };

  const setPoints = ({ task, answer, points }: { task: string, answer: string, points: number }) => {
    dispatch(setPointsDetails({ mission: currentMission, task, answer, points}));

    if (
      !pointsDetails[currentMission][task] ||
      (pointsDetails[currentMission][task] && pointsDetails[currentMission][task][answer] === undefined)
    ) {
      dispatch(addPoints({ mission: currentMission, points }));
    }
  };

  const setOverlay = (value: string) => {
    dispatch(setCurrentOverlay(`document:${value}`));
  };

  const setAnswer = (answer: Answer) => {
    dispatch(setAnsweredTasks({ mission: currentMission, task, answer: answer.id, option: answer }));
  };

  const saveHighscoreEntry = async (user: HighscoreEntry) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVICE_URL}/highscore`,
        {
          headers: {'Content-Type': 'application/json'},
          method: 'PATCH',
          body: JSON.stringify([user])
        }
      );

      const json = await response.json();

      dispatch(setUserId(json.data[0].id));
    } catch (error) {
      console.error('Could not save highscore:', error);
    }
  };

  const saveHighscoreAnonymous = (module?: number) => {
    let user;
    if(module) {
      user = {
        id: userId || undefined,
        username: 'anonym',
        score: getTotalPoints(),
        time: getTotalTime(),
        module: module,
      };
    } else {
      user = {
        id: userId || undefined,
        username: 'anonym',
        score: getTotalPoints(),
        time: getTotalTime(),
      }
    }
    saveHighscoreEntry(user)
    .then(() => {
      dispatch(setAnonymous(true));
      setPage('game-over');
      dispatch(setCurrentOverlay('menuPage:highscore'));
    });
  }

  const saveHighscoreWithName = (module?: number) => {
    let user;
    if(module) {
      user = {
        id: userId || undefined,
        username: `${firstName} ${lastName}`,
        score: getTotalPoints(),
        time: getTotalTime(),
        module: module,
      };
    } else {
      user = {
        id: userId || undefined,
        username: `${firstName} ${lastName}`,
        score: getTotalPoints(),
        time: getTotalTime(),
      }
    }

    saveHighscoreEntry(user)
    .then(() => {
      dispatch(setAnonymous(false));
      setPage('game-over');
      dispatch(setCurrentOverlay('menuPage:highscore'));
    });
  }

  const getTotalPoints = () => {
    let points;
    if(currentModule === 1){
      points = _.pick(currentPoints ,'1', '2', '3', '4');
    } else if (currentModule === 2){
      points = _.pick(currentPoints ,'5', '6', '7', '8', '9', '10', '12', '13');
    }

    return Object.keys(points).reduce((acc, value) => {
      return acc + currentPoints[value].points
    }, 0);
  };

  const getTotalTime = () => {
    let points;
    if(currentModule === 1){
      points = _.pick(currentPoints ,'1', '2', '3', '4');
    } else if (currentModule === 2){
      points = _.pick(currentPoints ,'5', '6', '7', '8', '9', '10', '12', '13');
    }

    return Object.keys(points).reduce((acc, value) => {
      return acc + time[value];
    }, 0);
  };

  const missions: Missions = {
    0: Others(),
    1: {
      mission1: {
        children: <><p>Auftrag:</p><h2>Ein Angebot erstellen</h2></>,
        style: { justifyContent: 'center' },
        onContinueLabel: 'Weiter',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Scenario', 'Scenario Started', 'Scenario 1']);
          window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 1_1: Ein Angebot erstellen']);
          dispatch(setDialogue('morning'));
          setPage('dialogue');
          dispatch(startTimer());
        },
      },
      checkStockList: {
        image: teamleadImage,
        text: 'Schau bitte zuerst, ob die gewünschten Produkte in ausreichender Zahl vorhanden sind, wenn du eine Anfrage erhältst.',
        onContinueLabel: 'Lagerbestand einsehen',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('doWeHaveEverything'));
          dispatch(setDialogue('doWeHaveEverything'));
          dispatch(setNextPage('dialogue'));
          dispatch(unlockDocument(Document.Stocklist));
          setOverlay(Document.Stocklist);
        },
      },
      whatDoYouDo: {
        image: userImage,
        text: 'Was machst du jetzt?',
        options: [
          {
            label: 'Angebot an die Kundin schicken',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setPoints({ task: 'whatDoYouDo', answer: '0', points: 0 });
              dispatch(setTask(''));
              dispatch(setCurrentNotification('firstTalkToTeamlead'));
            }
          },
          {
            label: 'Angebot mit Teamleiterin besprechen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setPoints({ task: 'whatDoYouDo', answer: '0', points: 1 });
              dispatch(setTask(''));
              setPage('feedback');
            }
          }
        ],
      },
      firstTalkToTeamlead: {
        image: teamleadImage,
        text: 'Angebote bitte immer mit mir besprechen!',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          setPage('feedback');
        },
      },
      sendSample: {
        image: teamleadImage,
        text: `Das Angebot stimmt und kann so an die Kundin geschickt werden!
        Bitte vergiss nicht, ein Produktmuster zu verschicken.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('warehouseRemoval'));
          setPage('image-cloze');
        },
      },
      mission1Feedback: {
        image: teamleadImage,
        text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten 
        bei diesem Auftrag erreicht. ${getMessage()}`,
        onContinue: () => {
          dispatch(setMissionCompleted({mission: 1, completed: true}))
          // dispatch(setUnlockedMissions([1,2,3,4]));
          if (reachedModuleScore[1]) {

            if (missionsCompleted([2,3,4])){
              if (isFinished) {
                dispatch(setModuleScore(1));
                if (anonymous) {
                  saveHighscoreAnonymous();
                } else {
                  saveHighscoreWithName();
                }
              } else {
                dispatch(setFinished(true));
                dispatch(setMission(4))
                dispatch(setShowSurveyPopUp(true));
                setPage('end-feedback');
              }
              window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);

            } else {
              dispatch(setMission(0));
              dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
              setPage('notification')
            }
          }  else {
            dispatch(setMission(2));
            dispatch(setWasJokerUsed(false));
            dispatch(setCurrentNotification('mission2'));
          }
          window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 1_1: Ein Angebot erstellen']);
          dispatch(pauseTimer());
        },
      },
    },
    2: {
      mission2: {
        children: <><p>Auftrag:</p><h2>Eine Auftragsbestätigung verfassen</h2></>,
        style: { justifyContent: 'center' },
        onContinueLabel: 'Weiter',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 1_2: Eine Auftragsbestätigung verfassen']);
          dispatch(setCurrentNotification('coupleOfDaysLater'));
          dispatch(startTimer());
        },
      },
      coupleOfDaysLater: {
        icon: 'calendar-full',
        text: 'Einige Tage später...',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('phone'));
          dispatch(setCurrentNotification('yourPhoneRings'));
        },
      },
      yourPhoneRings: {
        image: userImage,
        text: 'Dein Telefon läutet. Wie meldest du dich?',
        options: [
          {
            label: 'Hallo.',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Hallo' });
              setPoints({ task: 'phone', answer: '0', points: 0 }); 
              dispatch(setTask(''));
              dispatch(setCurrentNotification('tellYourNameInCalls'));
            }
          },
          {
            label: `Guten Tag. Hier ist die Memoboard GmbH. Mein Name ist ${firstName} ${lastName}.`,
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: `Guten Tag. Hier ist die Memoboard GmbH. Mein Name ist ${firstName} ${lastName}.`, correct: true });
              setPoints({ task: 'phone', answer: '0', points: 1 }); 
              dispatch(setTask(''));
              dispatch(setDialogue('goodDay'));
              setPage('dialogue');
            }
          },
        ],
      },
      tellYourNameInCalls: {
        image: teamleadImage,
        text: `Bitte melde dich immer mit deinem Namen und dem Firmenwortlaut,
        damit die Anruferin bzw. der Anrufer weiß, dass sie/er richtig ist.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setDialogue('hello'));
          setPage('dialogue');
        },
      },
      whatDoYouDo: {
        image: userImage,
        text: 'Was machst du jetzt?',
        options: [
          {
            label: 'Verfügbarkeit prüfen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Verfügbarkeit prüfen', correct: true });
              setPoints({ task: 'whatDoYouDo', answer: '0', points: 1 }); 
              dispatch(setTask('doWeHaveEverything'));
              dispatch(setDialogue('doWeHaveEverything'));
              dispatch(setNextPage('dialogue'));
              setOverlay(Document.Stocklist);
            }
          },
          {
            label: 'Lieferung vorbereiten',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Lieferung vorbereiten'});
              setPoints({ task: 'whatDoYouDo', answer: '0', points: 0 }); 
              dispatch(setCurrentNotification('checkStock'));
            }
          },
          {
            label: 'Angebot einsehen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Angebot einsehen', correct: true });
              setPoints({ task: 'whatDoYouDo', answer: '0', points: 1 }); 
              dispatch(setTask('doWeHaveEverything'));
              dispatch(setDialogue('doWeHaveEverything'));
              dispatch(setNextPage('dialogue'));
              setOverlay(Document.Offer);
            }
          }
        ],
      },
      checkStock: {
        image: teamleadImage,
        text: `Schau lieber mal nach, ob ausreichend Ware auf Lager ist.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('doWeHaveEverything'));
          dispatch(setDialogue('doWeHaveEverything'));
          dispatch(setNextPage('dialogue'));
          setOverlay(Document.Stocklist);
        },
      },
      checkStockAgain: {
        image: teamleadImage,
        text: `Schau noch einmal genau!`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('doWeHaveEverything'));
          dispatch(setDialogue('doWeHaveEverything'));
          dispatch(setNextPage('dialogue'));
          setOverlay(Document.Stocklist);
        },
      },
      canYouGiveDiscount: {
        image: userImage,
        text: 'Was machst du jetzt?',
        options: [
          {
            label: 'Teamleiterin fragen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Teamleiterin fragen'});
              setPoints({ task: 'canYouGiveDiscount', answer: '0', points: 0 }); 
              dispatch(setCurrentNotification('checkPricesAndConditions'));
            }
          },
          {
            label: 'Preise und Konditionen im System ansehen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Preise und Konditionen im System ansehen', correct: true });
              setPoints({ task: 'canYouGiveDiscount', answer: '0', points: 1 }); 
              dispatch(setTask('canYouGiveDiscountAlready'));
              dispatch(setShowDocumentButton(true));
              dispatch(setCurrentNotification('canYouGiveDiscountAlready'));
              dispatch(unlockDocument(Document.Pricelist));
              setOverlay(Document.Pricelist);
            }
          }
        ],
      },
      checkPricesAndConditions: {
        image: teamleadImage,
        text: `Bitte schau dir die Preise und Konditionen im System an.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('canYouGiveDiscountAlready'));
          dispatch(setShowDocumentButton(true));
          dispatch(setCurrentNotification('canYouGiveDiscountAlready'));
          dispatch(unlockDocument(Document.Pricelist));
          setOverlay(Document.Pricelist);
        },
      },
      canYouGiveDiscountAlready: {
        image: userImage,
        text: 'Was machst du jetzt?',
        options: [
          {
            label: 'Kundin informieren, dass kein Rabatt möglich ist',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Kundin informieren, dass kein Rabatt möglich ist' });
              setPoints({ task: 'canYouGiveDiscountAlready', answer: '0', points: 0 }); 
              dispatch(unlockDocument(Document.ClientDatabase));
              dispatch(setTask(''));
              dispatch(setDialogue('noDiscount'));
              setPage('dialogue');
            }
          },
          {
            label: 'Kundin informieren, dass sie bei Abnahme von 2.000 Stk. einen Rabatt von 10 % erhält',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Kundin informieren, dass sie bei Abnahme von 2.000 Stk. einen Rabatt von 10 % erhält' });
              setPoints({ task: 'canYouGiveDiscountAlready', answer: '0', points: 0 }); 
              dispatch(setCurrentNotification('checkClientDatabase'));
            }
          },
          {
            label: 'Kundendatenbank öffnen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Kundendatenbank öffnen', correct: true });
              setPoints({ task: 'canYouGiveDiscountAlready', answer: '0', points: 1 }); 
              dispatch(setTask('whatDoYouTellTheClient'));
              dispatch(setShowDocumentButton(true));
              dispatch(setCurrentNotification('whatDoYouTellTheClient'));
              dispatch(unlockDocument(Document.ClientDatabase));
              setOverlay(Document.ClientDatabase);
            }
          }
        ],
      },
      checkClientDatabase: {
        image: teamleadImage,
        text: 'Schaue in der Kundendatenbank nach speziellen Rabatten.',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('whatDoYouTellTheClient'));
          dispatch(setShowDocumentButton(true));
          dispatch(setCurrentNotification('whatDoYouTellTheClient'));
          dispatch(unlockDocument(Document.ClientDatabase));
          setOverlay(Document.ClientDatabase);
        },
      },
      checkPricesAndConditionsAgain: {
        image: teamleadImage,
        text: `Bitte schau dir die Preise und Konditionen im System an.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('whatDoYouTellTheClient'));
          dispatch(setShowDocumentButton(true));
          dispatch(setCurrentNotification('whatDoYouTellTheClient'));
          setOverlay(Document.Pricelist);
        },
      },
      whatDoYouTellTheClient: {
        image: userImage,
        text: 'Was sagst du der Kundin?',
        options: [
          {
            label: 'Bei der Abnahme von 2.000 Stk. erhalten Sie einen Rabatt von 10 %.',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setPoints({ task: 'whatDoYouTellTheClient', answer: '0', points: 0 }); 
              dispatch(setCurrentNotification('checkClientDatabase'));
            }
          },
          {
            label: 'Bei der Abnahme von 2.000 Stk. erhalten Sie einen Rabatt von 12 %.',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Bei der Abnahme von 2.000 Stk. erhalten Sie einen Rabatt von 12 %', correct: true });
              setPoints({ task: 'whatDoYouTellTheClient', answer: '0', points: 1 }); 
              dispatch(setTask(''));
              dispatch(setDialogue('discount'));
              setPage('dialogue');
            }
          }
        ],
      },
      whatDoYouDoNow: {
        image: userImage,
        text: 'Was machst du jetzt?',
        options: [
          {
            label: 'Auftragsbestätigung erstellen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Auftragsbestätigung erstellen', correct: true });
              setPoints({ task: 'whatDoYouDoNow', answer: '0', points: 1 }); 

              dispatch(setTask('orderConfirmation'));
              setPage('image-cloze');
            }
          },
          {
            label: 'Angebot erstellen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setAnswer({ id: '0', label: 'Angebot erstellen' });
              setPoints({ task: 'whatDoYouDoNow', answer: '0', points: 0 }); 
              dispatch(setTask(''));
              dispatch(setCurrentNotification('noOfferNecessary'));
            }
          }
        ],
      },
      noOfferNecessary: {
        image: teamleadImage,
        text: `Es braucht kein Angebot mehr, die Kundin hat telefonisch bestellt, 
        schicke ihr bitte noch die Auftragsbestätigung.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('orderConfirmation'));
          setPage('image-cloze');
        },
      },
      mission2Feedback: {
        image: teamleadImage,
        text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten 
        bei diesem Auftrag erreicht. ${getMessage()}`,
        onContinueLabel: 'Weiter',
        onContinue: () => {
          dispatch(setMissionCompleted({mission: 2, completed: true}))
          // dispatch(setUnlockedMissions([1,2,3,4]));
          if (reachedModuleScore[1]) {
            if (missionsCompleted([1,3,4])){
              if (isFinished) {
                dispatch(setModuleScore(1));
                if (anonymous) {
                  saveHighscoreAnonymous();
                } else {
                  saveHighscoreWithName();
                }
              } else {
                dispatch(setFinished(true));
                dispatch(setMission(4))
                dispatch(setShowSurveyPopUp(true));
                setPage('end-feedback');
              }
              window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);

            } else {
              dispatch(setMission(0));
              dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
              setPage('notification')
            }
          } else {
            dispatch(setMission(3));
            dispatch(setWasJokerUsed(false));
            dispatch(setCurrentNotification('mission3'));
          }
          window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 1_2: Eine Auftragsbestätigung verfassen']);
          dispatch(pauseTimer());
        },
      },
    },
    3: {
      mission3: {
        children: <><p>Auftrag:</p><h2>Einen Lieferschein erstellen</h2></>,
        style: { justifyContent: 'center' },
        onContinueLabel: 'Weiter',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 1_3: Einen Lieferschein erstellen']);
          dispatch(setTask('postitCloseup'));
          dispatch(setScene('postitCloseup'));
          setPage('scene');
          dispatch(startTimer());
        },
      },
      createDeliveryNote: {
        image: teamleadImage,
        text: `Wolltest du nicht gerade den Lieferschein für die Fa. Nig GmbH erstellen?`,
        onContinueLabel: 'Ach ja',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('deliveryNote'));
          setPage('image-cloze');
        },
      },
      whatDoYouDoNow: {
        image: userImage,
        text: 'Was machst du jetzt?',
        options: [
          {
            label: 'Lieferschein ins Lager geben',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setPoints({ task: 'whatDoYouDoNow', answer: '0', points: 0 }); 
              dispatch(setTask(''));
              dispatch(setCurrentNotification('firstTalkToTeamlead'));
            }
          },
          {
            label: 'Lieferschein mit Teamleiterin besprechen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setPoints({ task: 'whatDoYouDoNow', answer: '0', points: 1 }); 
              dispatch(setTask(''));
              setPage('feedback');
            }
          }
        ],
      },
      firstTalkToTeamlead: {
        image: teamleadImage,
        text: 'Bitte zeige mir zuerst den Lieferschein, bevor du ihn ins Lager gibst.',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          setPage('feedback');
        },
      },
      deliveryNoteCreated: {
        image: teamleadImage,
        text: 'Gut, der Lieferschein ist fertig. Du findest ihn ab jetzt bei deinen Dokumenten.',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setCurrentNotification('mission3Feedback'));
        },
      },
      mission3Feedback: {
        image: teamleadImage,
        text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten 
        bei diesem Auftrag erreicht. ${getMessage()}`,
        onContinueLabel: 'Weiter',
        onContinue: () => {
          dispatch(setMissionCompleted({mission: 3, completed: true}))

          if (reachedModuleScore[1]) {
            if (missionsCompleted([1,2,4])){
              if (isFinished) {
                dispatch(setModuleScore(1));
                if (anonymous) {
                  saveHighscoreAnonymous();
                } else {
                  saveHighscoreWithName();
                }
              } else {
                dispatch(setFinished(true));
                dispatch(setMission(4))
                dispatch(setShowSurveyPopUp(true));
                setPage('end-feedback');
              }
              window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);

            } else {
              dispatch(setMission(0));
              dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
              setPage('notification')
            }
          } else {
            dispatch(setMission(4));
            dispatch(setWasJokerUsed(false));
            dispatch(setCurrentNotification('mission4'));
          }
          window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 1_3: Einen Lieferschein erstellen']);
          dispatch(pauseTimer());
        },
      },
    },
    4: {
      mission4: {
        children: <><p>Auftrag:</p><h2>Eine Rechnung erstellen</h2></>,
        style: { justifyContent: 'center' },
        onContinueLabel: 'Weiter',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 1_4: Eine Rechnung erstellen']);
          dispatch(setTask(''));
          dispatch(setDialogue('bill'));
          setPage('dialogue');
          dispatch(startTimer());
        },
      },
      createBill: {
        image: teamleadImage,
        text: `Den Lieferschein hast du gerade erstellt, im Lager wird nun die Bestellung für den Versand vorbereitet. 
        Jetzt fehlt noch die Rechnung.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('computer'));
          dispatch(setScene('computer'));
          setPage('scene');
        },
      },
      whatDoYouDoAfterBill: {
        image: userImage,
        text: 'Was machst du jetzt?',
        options: [
          {
            label: 'Rechnung versenden',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setPoints({ task: 'whatDoYouDoAfterBill', answer: '0', points: 0 }); 
              dispatch(setTask(''));
              dispatch(setCurrentNotification('billHasToBeAccepted'));
            }
          },
          {
            label: 'Rechnung von Teamleiterin freigeben lassen',
            onClick: () => {
              window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
              setPoints({ task: 'whatDoYouDoAfterBill', answer: '0', points: 1 }); 
              dispatch(setTask(''));
              setPage('feedback');
            }
          }
        ],
      },
      billHasToBeAccepted: {
        icon: 'warning',
        text: 'Die Rechnung kann erst fertig gestellt werden, wenn sie freigegeben wurde.',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          setPage('feedback');
        },
      },
      billIsFinished: {
        image: teamleadImage,
        text: 'Gut, die Rechnung ist fertig. Du findest sie ab jetzt bei deinen Dokumenten.',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setShowDocumentButton(true));
          dispatch(setCurrentNotification('billToWarehouse'));
          setOverlay(Document.Bill);
        },
      },
      billToWarehouse: {
        image: teamleadImage,
        text: 'Jetzt kann die Rechnung an das Lager weitergegeben werden.',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setCurrentNotification('coupleOfDaysLater'));
        },
      },
      coupleOfDaysLater: {
        icon: 'calendar-full',
        text: 'Einige Tage später...',
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setDialogue('openIncomingPayments'));
          setPage('dialogue');
        },
      },
      pleaseCCMe: {
        image: teamleadImage,
        text: `Bitte schicke mir die E-Mail in CC (kastner@memoboard.at)`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setTask('reminder'));
          setPage('text-cloze');
        },
      },
      nigPaid: {
        image: teamleadImage,
        text: `Die Nig GmbH hat auch noch den Restbetrag von 599,81 Euro überwiesen. Der Auftrag ist somit abgeschlossen.`,
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
          dispatch(setCurrentNotification('mission4Feedback'));
        },
      },
      mission4Feedback: {
        image: teamleadImage,
        text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten 
        bei diesem Auftrag erreicht. ${getMessage()}`,
        onContinueLabel: 'Weiter',
        onContinue: () => {
          dispatch(setMissionCompleted({mission: 4, completed: true}))
          window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 1_4: Eine Rechnung erstellen']);
          dispatch(setUnlockedMissions([1,2,3,4,5,6,7,8,9,10,11,12,13]));
          dispatch(setReachedModuleScore({module: 1}))
          dispatch(pauseTimer());

          if (missionsCompleted([1,2,3])){
            if (isFinished) {
              dispatch(setModuleScore(1));
              if (anonymous) {
                saveHighscoreAnonymous();
              } else {
                saveHighscoreWithName();
              }
            } else {
              dispatch(setFinished(true));
              dispatch(setShowSurveyPopUp(true));
              setPage('end-feedback');
            }
            window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);


          } else {
            dispatch(setMission(0));
            dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
            setPage('notification')
          }

        },
      },
      useNameInHighscore: {
        image: teamleadImage,
        text: `Dürfen wir deinen Namen in die Rangliste eintragen? Bitte beachte, dass andere Spieler deinen Namen sehen können.`,
        options: [
          {
            label: `Ja, ${firstName} ${lastName} verwenden`,
            onClick: () => {
              dispatch(setModuleScore(1));
              saveHighscoreWithName();
            }
          },
          {
            label: 'Nein, anonym eintragen',
            onClick: () => {
              dispatch(setModuleScore(1));
              saveHighscoreAnonymous();
            }
          }
        ],
      },
    },
    5: MissionFive(),
    6: MissionSix(),
    7: MissionSeven(),
    8: MissionEight(),
    9: MissionNine(),
    10: MissionTen(),
    11: MissionEleven(),
    12: MissionTwelve(),
    13: MissionThirteen(),
  };

  const [notification, setNotification] = useState(missions[currentMission][currentNotification]);

  useEffect(() => {
    setNotification(missions[currentMission][currentNotification]);
  }, [currentMission, currentNotification, userId, pointsDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return [notification];
};
