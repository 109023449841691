import { useDispatch } from "react-redux";

import { Page as Document } from "../library";
import { setTask as setCurrentTask, setTaskSkippable } from "../../redux/slices/taskSlice";

import { useFunctions } from "../functions";
import { Type } from "../../components/HotspotChoice";

import {
    setAllowClipboardCopy, setClipboardType, setFeedback,
    setOverlay as setCurrentOverlay,
} from "../../redux/slices/appSlice";
import { Tasks } from "./datatypes";
import {emptyAnswerValue} from "../../components/MiniCalculator";

export const MissionFive = () => {
    const [{ setOverlay, setImageClozeAnswer, setImageClozePoints, setPage }] = useFunctions();
    const dispatch = useDispatch();

    const missionFiveData: Tasks = {
        referenceSpreadsheet: {
            title: 'Aufstellung zur Bezugskalkulation',
            subtitle: '<span class="tip-label">Tipp:</span> Du kannst dir alle wichtigen Dokumente nochmal auf deinem Arbeitsplatz ansehen.',
            src: require('static/images/tasks/mission5/mAV_AufstellungZurBezugskalkulation.png'),
            hotspots: {
                0: {
                    x: '80%',
                    y: '23.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '2.5%',
                    title: 'Anzahl',
                    type: Type.MiniCalculator,
                    correct: ['100', '100,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                    hasNoDecimals: true,
                },
                1: {
                    x: '30%',
                    y: '40%',
                    labelXOffset: '-2%',
                    options: [
                        { label: 'Rabatt',  correct: true,  onClick: setImageClozeAnswer },
                        { label: 'USt', onClick: setImageClozeAnswer },
                        { label: 'Zinsen', onClick: setImageClozeAnswer },
                    ],
                },

                2: {
                    x: '23%',
                    y: '40%',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', correct: true, onClick: setImageClozeAnswer },
                    ],
                },
                3: {
                    x: '30%',
                    y: '57%',
                    labelXOffset: '-2%',
                    options: [
                        { label: 'USt', onClick: setImageClozeAnswer },
                        { label: 'Zinsen', onClick: setImageClozeAnswer },
                        { label: 'Bezugsspesen', correct: true, onClick: setImageClozeAnswer },
                    ],
                },
                4: {
                    x: '23%',
                    y: '57%',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', onClick: setImageClozeAnswer },
                    ],
                },
                5: {
                    x: '30%',
                    y: '74%',
                    labelXOffset: '-2%',
                    options: [
                        { label: 'USt', onClick: setImageClozeAnswer },
                        { label: 'Skonto', correct: true, onClick: setImageClozeAnswer },
                        { label: 'Zinsen', onClick: setImageClozeAnswer },
                    ],
                },
                6: {
                    x: '23%',
                    y: '74%',
                    options: [
                        { label: 'Addition [+]', alternativeLabel: '+',  onClick: setImageClozeAnswer },
                        { label: 'Subtraktion [-]', alternativeLabel: '-', correct: true, onClick: setImageClozeAnswer },
                    ],
                },
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('referenceSpreadsheet'));
                setPage('feedback');
            },
        },
        referenceCalculator: {
            title: 'Bezugskalkulation',
            subtitle: '<span class="tip-label">Tipp:</span> Du findest alle Dokumente auf deinem Arbeitsplatz. Dort kannst du wichtige Beträge in die Zwischenablage kopieren.',
            src: require('static/images/tasks/mission5/mAV_Bezugskalkulation.png'),
            hotspots: {
                0: {
                    x: '70%',
                    y: '16.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    title: 'Anzahl',
                    type: Type.Calculator,
                    correct: ['94,60'],
                    points: 1,
                    hasClipboard: true,
                    options: [
                        {label: 'In Angebot Putsch nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotPutsch)
                            }},
                    ],
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                        dispatch(setClipboardType(Type.MiniCalculator))
                    },
                    children: [{ x: '90%', y: '16.8%', labelXAlignment: 'right', equation: '* 100' }],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '22.9%',
                    y: '21.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    options: [
                        { label: '2 %', alternativeLabel: '2,00 %', onClick: setImageClozeAnswer },
                        { label: '3 %', alternativeLabel: '3,00 %', onClick: setImageClozeAnswer },
                        { label: '10 %', alternativeLabel: '10,00 %', correct: true, onClick: setImageClozeAnswer },
                        { label: '15 %', alternativeLabel: '15,00 %', onClick: setImageClozeAnswer },
                        { label: 'In Angebot Putsch nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotPutsch);
                            }},
                    ],
                },
                2: {
                    x: '85.5%',
                    y: '21%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    type: Type.Calculator,
                    correct: ['946', '946,00'],
                    options: [
                        {label: 'In Angebot Putsch nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotPutsch)
                            }},
                    ],
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '85.5%',
                    y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    type: Type.Calculator,
                    correct: ['15', '15,00'],
                    options: [
                        {label: 'In Angebot Putsch nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotPutsch)
                            }},
                    ],
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '22.9%',
                    y: '39.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    options: [
                        { label: '2 %', alternativeLabel: '2,00 %', correct: true,  onClick: setImageClozeAnswer },
                        { label: '3 %', alternativeLabel: '3,00 %', onClick: setImageClozeAnswer },
                        { label: '10 %', alternativeLabel: '10,00 %', onClick: setImageClozeAnswer },
                        { label: '15 %', alternativeLabel: '15,00 %', onClick: setImageClozeAnswer },
                        { label: 'In Angebot Putsch nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotPutsch);
                            }},
                    ],
                },
                5: {
                    x: '85.5%',
                    y: '39%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    type: Type.Calculator,
                    correct: ['170,58'],
                    options: [
                        {label: 'In Angebot Putsch nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotPutsch)
                            }},
                    ],
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '70%',
                    y: '64.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    title: 'Anzahl',
                    type: Type.MiniCalculator,
                    correct: ['99,30'],
                    points: 1,
                    hasClipboard: true,
                    options: [
                        {label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober)
                            }},
                    ],
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                        dispatch(setClipboardType(Type.MiniCalculator))
                    },
                    children: [{ x: '90%', y: '64.5%', labelXAlignment: 'right', equation: '* 100' }],
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '22.9%',
                    y: '69%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    options: [
                        { label: '2 %', alternativeLabel: '2,00 %', onClick: setImageClozeAnswer },
                        { label: '3 %', alternativeLabel: '3,00 %', onClick: setImageClozeAnswer },
                        { label: '10 %', alternativeLabel: '10,00 %', onClick: setImageClozeAnswer },
                        { label: '15 %', alternativeLabel: '15,00 %', correct: true, onClick: setImageClozeAnswer },
                        { label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober);
                            }},
                    ],
                },
                8: {
                    x: '85.5%',
                    y: '69%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    type: Type.Calculator,
                    correct: ['1.489,50', '1489,50'],
                    options: [
                        {label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober)
                            }},
                    ],
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                9: {
                    x: '85.5%',
                    y: '78%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    type: Type.Calculator,
                    correct: ['0', '0,00', emptyAnswerValue],
                    options: [
                        {label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober)
                            }},
                    ],
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                10: {
                    x: '22.9%',
                    y: '87%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    options: [
                        { label: '2 %', alternativeLabel: '2,00 %', onClick: setImageClozeAnswer },
                        { label: '3 %', alternativeLabel: '3,00 %', correct: true, onClick: setImageClozeAnswer },
                        { label: '10 %', alternativeLabel: '10,00 %', onClick: setImageClozeAnswer },
                        { label: '15 %', alternativeLabel: '15,00 %', onClick: setImageClozeAnswer },
                        { label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober);
                            }},
                    ],
                },
                11: {
                    x: '85.5%',
                    y: '87%',
                    labelXAlignment: 'right',
                    labelXOffset: '4.5%',
                    type: Type.Calculator,
                    correct: ['253,22'],
                    options: [
                        {label: 'In Angebot Dober nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.AngebotDober)
                            }},
                    ],
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
            },
            autogeneratedChildren: [
                {
                    x: '90%',
                    y: '25.3%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 0,
                            sign: '+',
                            equation: '* 100'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '90%',
                    y: '34.5%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 0,
                            sign: '+',
                            equation: '* 100'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '90%',
                    y: '44%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 0,
                            sign: '+',
                            equation: '* 100'
                        },
                        {
                            id: 2,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 3,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 5,
                            sign: '-',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '90%',
                    y: '73%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 6,
                            sign: '+',
                            equation: '* 100'
                        },
                        {
                            id: 8,
                            sign: '-',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '90%',
                    y: '82%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 6,
                            sign: '+',
                            equation: '* 100'
                        },
                        {
                            id: 8,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 9,
                            sign: '+',
                            equation: ''
                        },
                    ]
                },
                {
                    x: '90%',
                    y: '91%',
                    labelXAlignment: 'right',
                    hotspots: [
                        {
                            id: 6,
                            sign: '+',
                            equation: '* 100'
                        },
                        {
                            id: 8,
                            sign: '-',
                            equation: ''
                        },
                        {
                            id: 9,
                            sign: '+',
                            equation: ''
                        },
                        {
                            id: 11,
                            sign: '-',
                            equation: ''
                        },
                    ]
                },
            ],
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('referenceCalculator'));
                setPage('feedback');
                dispatch(setTaskSkippable(true))
            },
        }
    }

    return missionFiveData;
}
