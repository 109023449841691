import { Type } from "../../document";
import { currentYear } from "../variables";

export const ProducktAnfrage = () => {
    return {
        type: Type.TextCloze,
        title: 'Produktanfrage',
        text: `**Von:** kastner@memoboards.at
      **Datum:** 15. Juni ${currentYear}
      **An:** office@memoboards.at
      **BCC:** office@putsch.at; verkauf@dober.at; office@meller.at
      ---
      Guten Tag, 

      wir sind ein Unternehmen, das sich hauptsächlich mit dem Vertrieb von Memoboards und Pinnwänden beschäftigt. Da unsere Kundschaft in letzter Zeit verstärkt nach eleganten Glasboards nachfragt, wollen wir unser Sortiment durch ein entsprechendes Produkt erweitern.
      
      **Wir benötigen daher**
        - 100 Stück magnetische Glasboards mit geeigneten Magneten
      bis **spätestens** Anfang August. 
      
      Sollte das Produkt von unseren Kunden gut angenommen werden, würden wir es zukünftig in größeren Mengen bestellen. 
      
      Wir bitten um Übermittlung eines entsprechenden Angebots.
      
      Danke vorab!
      
      Freundliche Grüße
      Karin Kastner
      ______________________
      Memoboard GmbH
      Erdbergstraße 21, 1030 Wien
      +43 1 512 685-0
      kastner@memobard.at
      `,
    };
}
