import { useDispatch } from "react-redux";

import { useFunctions } from "../../functions";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import { Type } from "../../document";
import { currentYear } from "../variables";

export const AngebotDober = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: 'Angebot Dober GmbH',
        src: require('static/images/tasks/mission5/mAV_AngebotDober.png'),
        hotspots: {
            0: {x: '8%', y: '15.5%'},
            1: {x: '8%', y: '21%'},
            2: {x: '8%', y: '24%'},
            3: {x: '8%', y: '27%'},
            4: {x: '36.5%', y: '33%'},
            5: {x: '36.5%', y: '49%'},
            6: {x: '5%', y: '75%'},
            7: {x: '5%', y: '78%'},
            8: {x: '5%', y: '81%'},
            9: {x: '5%', y: '86%'},
            10: {x: '5%', y: '89%'},
            11: {x: '5%', y: '92%'},
            12: {
                x: '40.2%', y: '32.4%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Dober',
                        order: 1,
                        title: 'Artikel: Dober Mavic Glasboard mit Magnet-& Stiftset (Anzahl)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '100'
                    }))
                }
            },
            13: {
                x: '47.5%', y: '48.7%', width: '9%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Dober',
                        order: 2,
                        title: 'Artikel: Dober Mavic Glasboard mit Magnet-& Stiftset (Preis/Stk.)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '99,30'
                    }))
                }
            },
            14: {
                x: '63.9%', y: '48.7%', width: '9%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Dober',
                        order: 3,
                        title: 'Artikel: Dober Mavic Glasboard mit Magnet-& Stiftset (USt)',
                        isEnabled: true,
                        isPercentage: true,
                        value: '20,00 %'
                    }))
                }
            },
            15: {
                x: '23%', y: '77.5%', width: '9%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Dober',
                        order: 4,
                        title: 'Rabatt bei Abnahme von 100 Stück',
                        isEnabled: true,
                        isPercentage: true,
                        value: '15,00 %'
                    }))
                }
            },
            16: {
                x: '82.5%', y: '80.8%', width: '9.8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Dober',
                        order: 5,
                        title: 'Rabatt bei Abhname von 500 Stück',
                        isEnabled: true,
                        isPercentage: true,
                        value: '20,00 %'
                    }))
                }
            },
            17: {
                x: '60.5%', y: '88.8%', width: '8.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Dober',
                        order: 6,
                        title: 'Skonto',
                        isEnabled: true,
                        isPercentage: true,
                        value: '3,00 %'
                    }))
                }
            },
        },

        initialAnswers: {
            0: {label: `Sehr geehrte Frau Kastner,`, active: false},
            1: {label: `vielen Dank für Ihr Interesse an unseren Produkten. Gerne `, active: false},
            2: {label: `unterbreiten wir Ihnen folgendes Angebot (gültig bis 31. August`, active: false},
            3: {label: `${currentYear}):`, active: false},
            4: {label: `<b>100</b> <span class="lnr lnr-clipboard-empty"></span> Stück`, active: false},
            5: {
                label: `EUR <b>99,30</b><span class="lnr lnr-clipboard-empty"></span> (exkl <b>20 %</b><span class="lnr lnr-clipboard-empty"></span> USt)`,
                active: false
            },
            6: {label: `Ab einer Abnahmemenge von 100 Stück gewähren wir Ihnen einen`, active: false},
            7: {label: `Rabatt von <b>15 %</b><span class="lnr lnr-clipboard-empty"></span>.`, active: false},
            8: {
                label: `Ab einer Abnahmemenge von 500 Stück beträgt der Rabatt <b>20 %</b> <span class="lnr lnr-clipboard-empty"></span>.`,
                active: false
            },
            9: {label: `Die Lieferung erfolgt innerhalb von zehn Tagen ab Auftragseingang`, active: false},
            10: {
                label: `frei Haus. Zahlungsbedingungen: 10 Tage <b>3 %</b> <span class="lnr lnr-clipboard-empty"></span> Skonto, 30 Tage netto `,
                active: false
            },
            11: {label: `Kassa `, active: false},
        }
    };
}
