import { Type } from "../../document";
import styles from "../../../pages/ImageCloze/ImageCloze.module.scss";

export const DeckungsbeitragMDF = () => {

    return {
        type: Type.ImageCloze,
        title: `Berechnung der Stückdeckungsbeiträge`,
        src: require('static/images/tasks/mission7/mKb_DeckungsbeitragMemoboardBerechnen2.png'),
        hotspots: {
            0: { x: '36.6%',
                y: '30%',
                labelXAlignment: 'right',
                labelXOffset: '6%', className: `${styles.gochi}` },
            1: { x: '36.6%',
                y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            2: { x: '36.6%',
                y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            3: { x: '52.4%',
                y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            4: { x: '52.4%',
                y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            5: { x: '52.4%',
                y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            6: { x: '68.3%',
                y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            7: { x: '68.3%',
                y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            8: { x: '68.3%',
                y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            9: { x: '85.5%',
                y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            10: { x: '85.5%',
                y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
            11: { x: '85.5%',
                y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '6%', className: `${styles.gochi}` },
        },
        initialAnswers: {
            0: {label: `12,20`, active: false},
            1: {label: `8,20`, active: false},
            2: {label: `4,00`, active: false},
            3: {label: `17,17`, active: false},
            4: {label: `12,60`, active: false},
            5: {label: `4,57`, active: false},
            6: {label: `19,30`, active: false},
            7: {label: `8,70`, active: false},
            8: {label: `10,60`, active: false},
            9: {label: `26,70`, active: false},
            10: {label: `16,30`, active: false},
            11: {label: `10,40`, active: false},
        }
    };
}
