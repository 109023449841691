import React, { useState, useRef, useEffect } from 'react';

import Choice, { Option } from 'components/Choice';
import Infobox from 'components/Infobox';

import styles from './Slider.module.scss';

type Slide = {
  image?: string;
  icon?: string;
  text?: string;
  options?: Option[];
  children?: React.ReactNode;
  onBack?: () => void;
  onNext?: () => void;
}

type Props = {
  className?: string;
  slides: Slide[];
  onChange?: (index: number) => void;
};

const Slider = ({
  className = '',
  slides,
  onChange,
}: Props) => {

  const back = () => {
    const slide = slides[savedIndex.current];
    if (slide.onBack) {
      slide.onBack();
    }
    
    if (savedIndex.current - 1 >= 0) {
      const newIndex = savedIndex.current - 1;
      setIndex(newIndex);
      onChange && onChange(newIndex);
    }
  }

  const next = () => {
    const slide = slides[savedIndex.current];
    if (slide.onNext) {
      slide.onNext();
    }

    if (savedIndex.current + 1 < slides.length) {
      const newIndex = savedIndex.current + 1;
      setIndex(newIndex);
      onChange && onChange(newIndex);
    }
  }

  const savedIndex = useRef<number>(0);
  const [index, setIndex] = useState(0);

  const savedDefaultOptions = useRef([
    { label: 'zurück', onClick: back },
    { label: 'weiter', onClick: next },
  ]);
  const defaultOptions = savedDefaultOptions.current;
  const [options, setOptions] = useState<Option[]>(defaultOptions);


  useEffect(() => {
    savedIndex.current = index;
    const slide = slides[index];

    if (slide.options) {
      setOptions([...defaultOptions, ...slide.options])
    } else {
      setOptions(defaultOptions);
    }
  }, [index, slides, defaultOptions]);

  return (
    <div className={className}>
      <Infobox image={slides[index].image} icon={slides[index].icon} text={slides[index].text}>
        {slides[index].children}
      </Infobox>
      <Choice className={styles.choice} options={options}/>
    </div>
  );
}

export default Slider;