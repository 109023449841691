import { Type } from "../../document";
import {currentYear} from "../variables";

export const MesinerRechnung = () => {

    return {
        type: Type.ImageCloze,
        title: `Mesiner Rechnung/Lieferschein`,
        src: require('static/images/tasks/mission9/mBp_AbweichungenMarkieren.png'),
        hotspots: {
            0: {x: '77%', y: '19.8%'},
            1: {x: '77%', y: '22.1%'},
            2: {x: '77%', y: '24.3%'},
        },
        initialAnswers: {
            0: {label: `13539/${currentYear}`, active: false},
            1: {label: `${currentYear}-11-13`, active: false},
            2: {label: `85464/${currentYear}`, active: false},
        }
    };
}