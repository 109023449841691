import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { Points } from 'redux/slices/scoreSlice';

import Button from 'components/Button';
import Header from 'components/NavigationHeader';
import Infobox from 'components/Infobox';

import styles from './EndFeedback.module.scss';
import { setNotification } from 'redux/slices/appSlice';
import useFunctions from "../../hooks/functions";
import _ from "lodash";

type Feedback = {
  [quality: string]: Info;
};

type KeyValue = {
  [key: string]: string;
};

type Info = {
  image: string;
  text: string;
  missions: string[];
};

const Page = () => {
  const { module: currentModule } = useSelector((state: RootState) => state.app);
  const { points: currentPoints } = useSelector((state: RootState) => state.score);
  const _ = require("lodash");
  const [{ setPage }] = useFunctions();

  const missionTitles: KeyValue = {
    1: 'Angebot',
    2: 'Auftragsbestätigung',
    3: 'Lieferschein',
    4: 'Rechnung',
    5: 'Angebote vergleichen',
    6: 'Verkaufspreis ermitteln',
    7: 'Kennzahlen berechnen',
    8: 'Mahnungen erstellen',
    9: 'Belege prüfen',
    10: 'Belege organisieren',
    11: 'Verbuchung der Belege',
    12: 'Kassabericht erstellen',
    13: 'Zahlung vorbereiten',
  };

  const messages: KeyValue = {
    0: 'Es liegt noch Arbeit vor dir. Packen wir es gemeinsam an!',
    51: 'Du hast deine Aufgaben schon recht gut gelöst. Ein bisschen Unterstützung brauchst du noch.',
    75: 'Du kannst deine Aufgaben zukünftig selbst bearbeiten.'
  };

  const feedbackThreshold: KeyValue = {
    0: 'bad',
    51: 'neutral',
    75: 'good',
  };

  const feedback: Feedback = {
    good: {
      image: require('static/images/avatars/team-lead-happy.png'),
      text: 'Das war sehr gut:',
      missions: [],
    },
    neutral: {
      image: require('static/images/avatars/team-lead-neutral.png'),
      text: 'Hier solltest du noch genauer arbeiten:',
      missions: [],
    },
    bad: {
      image: require('static/images/avatars/team-lead-sad.png'),
      text: 'Das erarbeiten wir noch einmal:',
      missions: [],
    }
  };

  const calculatePoints = (type: keyof Points) => (
    Object.keys(points).reduce((acc, value) => {
      return acc += points[value][type];
    }, 0)
  );

  const calculateMissionQuality = () => {
    let points: any;
    if(currentModule === 1){
      points = _.pick(currentPoints ,'1', '2', '3', '4');
    } else if (currentModule === 2){
      points = _.pick(currentPoints ,'5', '6', '7', '8', '9', '10', '12', '13');
    }

    Object.keys(points).forEach(mission => {
      const percentage = (points[mission].points / points[mission].maxPoints) * 100;
      const keys = Object.keys(feedbackThreshold);
      for (let i = keys.length - 1; i >= 0; i--) {
        if (percentage >= parseInt(keys[i], 10)) {
          feedback[feedbackThreshold[keys[i]]].missions.push(missionTitles[mission]);
          return;
        }
      }
    });
  };

  const getMessage = () => {
    let points: any;

    if(currentModule === 1){
      points = _.pick(currentPoints ,'1', '2', '3', '4');
    } else if (currentModule === 2){
      points = _.pick(currentPoints ,'5', '6', '7', '8', '9', '10', '12', '13');
    }

    let achievedPoints = 0;
    let maxPoints = 0;
    Object.keys(points).forEach(mission => {
      achievedPoints += points[mission]['points'];
      maxPoints += points[mission]['maxPoints'];
    });

    const totalPercentage = (achievedPoints / maxPoints) * 100;

    const keys = Object.keys(messages);
    for (let i = keys.length - 1; i >= 0; i--) {
      if (totalPercentage >= parseInt(keys[i], 10)) {
        return messages[keys[i]];
      }
    }
  };

  const goToPage = () => {
    dispatch(setNotification('useNameInHighscore'));
    setPage('notification');
  };

  const { points } = useSelector((state: RootState) => state.score);
  const dispatch = useDispatch();

  calculateMissionQuality();
  
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Infobox className={styles.infobox} title='Mitarbeitergespräch' text={getMessage()} style={{ justifyContent: 'center' }} />
        {Object.keys(feedback).map((key, index) => {
          const info = feedback[key];
          if (info.missions.length > 0) {
            return (
              <Infobox className={styles.infobox} key={index} image={info.image} text={info.text}>
                <ul className={styles.list}>{info.missions.map((mission, index) => <li key={index}>{mission}</li>)}</ul>
              </Infobox>
            )
          }
          return null;
        })}
        <Button label='Weiter' onClick={goToPage} />
      </div>
    </div>
  );
}

export default Page;