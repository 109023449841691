import { Type } from "../../document";
import styles from "../../../pages/ImageCloze/ImageCloze.module.scss";

export const AccountForReceiptEarthMagDone = () => {
    const currentYear = new Date().getFullYear();

    return {
        type: Type.ImageCloze,
        title: `Lösung: Beleg S 32 vorkontieren`,
        src: require('static/images/tasks/mission11/mBv_EarthMag.png'),
        hotspots: {
            0: {
                x: '48.5%',
                y: '78.3%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            1: {
                x: '65.5%',
                y: '78.3%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            2: {
                x: '82.5%',
                y: '78.3%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            3: {
                x: '48.5%',
                y: '80.9%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            4: {
                x: '65.5%',
                y: '80.9%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            5: {
                x: '82.5%',
                y: '80.9%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            6: {
                x: '48.5%',
                y: '83.4%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            7: {
                x: '65.5%',
                y: '83.4%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            8: {
                x: '82.5%',
                y: '83.4%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            9: {x: '78%', y: '21.6%'},
            10: {x: '7.3%', y: '53.7%'},
        },
        initialAnswers: {
            0: { label: '5010', active: false},
            1: { label: '370,00', active: false},
            2: { label: '', active: false},
            3: { label: '2500', active: false},
            4: { label: '74,00', active: false},
            5: { label: '', active: false},
            6: { label: '3190', active: false},
            7: { label: '', active: false},
            8: { label: '444,00', active: false},
            9: { label: `12.11.${currentYear}`, active: false},
            10: { label: `Lieferdatum: 15.11.${currentYear}`, active: false},
        },
    };
}
