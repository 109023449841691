import { Type } from "../../document";
import {currentYear} from "../variables";

export const Ausgangsrechnungen2 = () => {

    return {
        type: Type.ImageCloze,
        title: `Offene Posten 23.12.${currentYear}`,
        src: require('static/images/tasks/mission8/mMe_Ausgangsrechnungen2.png'),
        hotspots: {
            0: { x: '19%', y: '21%' },
            1: { x: '78.3%', y: '21%' },
            2: { x: '78.3%', y: '31.5%' },
            3: { x: '19%', y: '49%' },
            4: { x: '78.3%', y: '49%' },
            5: { x: '78.3%', y: '59.8%' },
            6: { x: '19%', y: '77.4%' },
            7: { x: '78.3%', y: '77.4%' },
            8: { x: '78.3%', y: '88%' },
            9: { x: '84%', y: '8%' },
        },
        initialAnswers: {
            0: { label: `11.10.${currentYear}` , active: false },
            1: { label: `25.10.${currentYear}` , active: false },
            2: { label: `12.12.${currentYear}` , active: false },
            3: { label: `21.10.${currentYear}` , active: false },
            4: { label: `04.11.${currentYear}` , active: false },
            5: { label: `04.12.${currentYear}` , active: false },
            6: { label: `12.11.${currentYear}` , active: false },
            7: { label: `26.11.${currentYear}` , active: false },
            8: { label: `11.12.${currentYear}` , active: false },
            9: { label: `<b>23.12.${currentYear}</b>` , active: false },
        }
    };
}
