import { useDispatch } from "react-redux";

import { useFunctions } from "../../functions";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import { Type } from "../../document";

export const Bezugskalkulation = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: 'Bezugskalkulation',
        src: require('static/images/tasks/mission5/mAV_BezugskalkulationDone.png'),
        hotspots: {
            0: { x: '74%', y: '45%' },
            1: { x: '74%', y: '92%' },
            2: { x: '81.2%', y: '45%', width: '15.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Bezugskalkulation',
                        order: 1,
                        title: 'Putsch Einstandspreis für 100 Stück',
                        isEnabled: true,
                        isPercentage: false,
                        value: '8358,42'}))
                } },
            3: { x: '81.2%', y: '92%', width: '15.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Bezugskalkulation',
                        order: 2,
                        title: 'Dober Einstandspreis für 100 Stück',
                        isEnabled: true,
                        isPercentage: false,
                        value: '8187,28'}))
                } },
        },
        initialAnswers: {
            0: { label: `<b>8.358,42</b> <i class="lnr lnr-clipboard-empty"></i>` , active: false },
            1: { label: `<b>8.187,28</b> <i class="lnr lnr-clipboard-empty"></i>` , active: false },
        }
    };
}
