import { useDispatch } from "react-redux";

import { useFunctions } from "../functions";
import { Tasks } from "./datatypes";

import { setFeedback } from "../../redux/slices/appSlice";
import { setTask as setCurrentTask } from "../../redux/slices/taskSlice";

export const MissionTen = () => {
    const [{ setImageClozeAnswer, setImageClozePoints, getImage, setPage }] = useFunctions();
    const dispatch = useDispatch();

    const currentYear = new Date().getFullYear();

    const missionTenData: Tasks = {
        lehnerFurnitureDocument: {
            title: 'Beleg sortieren: Lehner Büromöbel',
            src: require('static/images/tasks/mission10/mBo_sortierenLehner.png'),
            hotspots: {
                0: {
                    x: '75%',
                    y: '22%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'AR', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_AR_1.png', 120), onClick: setImageClozeAnswer },
                        { label: 'B', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_B_1.png', 120), onClick: setImageClozeAnswer },
                        { label: 'ER', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_ER_1.png', 120), correct: true, onClick: setImageClozeAnswer },
                        { label: 'K', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_K_1.png', 120), onClick: setImageClozeAnswer },
                        { label: 'S', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_S_1.png', 120), onClick: setImageClozeAnswer },
                    ]
                },
                1: {x: '74.8%', y: '35.7%'},
                2: {x: '72.1%', y: '40%'},
                3: {x: '79%', y: '47.5%'},
            },
            initialAnswers: {
                1: { label: `${currentYear}-11-12`, active: false},
                2: { label: `1.November ${currentYear}`, active: false},
                3: { label: `${currentYear}-11-12`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('lehnerFurnitureDocument'));
                setPage('feedback');
            },
        },
        wurmDocument: {
            title: 'Beleg sortieren: WURM Büroprofi GmbH',
            src: require('static/images/tasks/mission10/mBo_sortierenWurm.png'),
            hotspots: {
                0: {
                    x: '50%',
                    y: '18%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'AR', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_AR_1.png', 120), onClick: setImageClozeAnswer },
                        { label: 'B', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_B_1.png', 120), onClick: setImageClozeAnswer },
                        { label: 'ER', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_ER_2.png', 120), onClick: setImageClozeAnswer },
                        { label: 'K', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_K_1.png', 120), correct: true, onClick: setImageClozeAnswer },
                        { label: 'S', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_S_1.png', 120), onClick: setImageClozeAnswer },
                    ]
                },
                1: {x: '22.8%', y: '26%'},
            },
            initialAnswers: {
                1: { label: `Datum: 12.11.${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('wurmDocument'));
                setPage('feedback');
            },
        },
        memoboardDocument: {
            title: 'Beleg sortieren: Memoboard GmbH',
            src: require('static/images/tasks/mission10/mBo_sortierenMemoboard.png'),
            hotspots: {
                0: {
                    x: '80%',
                    y: '25%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'AR', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_AR_1.png', 120), correct: true, onClick: setImageClozeAnswer },
                        { label: 'B', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_B_1.png', 120), onClick: setImageClozeAnswer },
                        { label: 'ER', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_ER_2.png', 120), onClick: setImageClozeAnswer },
                        { label: 'K', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_K_2.png', 120), onClick: setImageClozeAnswer },
                        { label: 'S', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_S_1.png', 120), onClick: setImageClozeAnswer },
                    ]
                },
                1: {x: '5%', y: '35%'},
            },
            initialAnswers: {
                1: { label: `<b>Rechnung Nr. 15718-${currentYear.toString().substr(-2)}</b> vom ${currentYear}-11-12 (Rechnungsdatum = Lieferdatum)`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('memoboardDocument'));
                setPage('feedback');
            },
        },
        finanzbankDocument: {
            title: 'Beleg sortieren: Finanzbank AG',
            src: require('static/images/tasks/mission10/mBo_sortierenFinanzbank.png'),
            hotspots: {
                0: {
                    x: '80%',
                    y: '15%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'AR', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_AR_2.png', 120), onClick: setImageClozeAnswer },
                        { label: 'B', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_B_1.png', 120), correct: true, onClick: setImageClozeAnswer },
                        { label: 'ER', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_ER_2.png', 120), onClick: setImageClozeAnswer },
                        { label: 'K', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_K_2.png', 120), onClick: setImageClozeAnswer },
                        { label: 'S', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_S_1.png', 120), onClick: setImageClozeAnswer },
                    ]
                },
                1: {x: '40%', y: '31.5%'},
            },
            initialAnswers: {
                1: { label: `13.11.${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('finanzbankDocument'));
                setPage('feedback');
            },
        },
        earthMagDocument: {
            title: 'Beleg sortieren: EarthMag GmbH',
            src: require('static/images/tasks/mission10/mBo_sortierenEarthMag.png'),
            hotspots: {
                0: {
                    x: '80%',
                    y: '13%',
                    labelXAlignment: 'center',
                    options: [
                        { label: 'AR', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_AR_2.png', 140), onClick: setImageClozeAnswer },
                        { label: 'B', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_B_2.png', 140), onClick: setImageClozeAnswer },
                        { label: 'ER', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_ER_2.png', 140), onClick: setImageClozeAnswer },
                        { label: 'K', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_K_2.png', 140), onClick: setImageClozeAnswer },
                        { label: 'S', alternativeLabel: getImage('tasks/mission10/answers/mBo_Answer_S_1.png', 140), correct: true, onClick: setImageClozeAnswer },
                    ]
                },
                1: {x: '80%', y: '22%'},
                2: {x: '7.3%', y: '53.5%'},
            },
            initialAnswers: {
                1: { label: `12.11.${currentYear}`, active: false},
                2: { label: `Lieferdatum: 15.11.${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('earthMagDocument'));
                setPage('feedback');
            },
        },
    }

    return missionTenData;
}
