import React from 'react';

import { useTutorial } from 'hooks/tutorial';

import Header from 'components/NavigationHeader';
import Slider from 'components/Slider';

import styles from './Tutorial.module.scss';

const Page = () => {
  const slide = useTutorial();

  return (
    <div className={styles.container}>
      <Header/>
      <div className={styles.content}>
        <Slider slides={Object.values(slide)} />
      </div>
    </div>
  );
};

export default Page;