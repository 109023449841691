import React from 'react';
import ReactDOM from 'react-dom';

import MiniCalculator, { Props as CalculatorProps } from 'components/MiniCalculator';

import styles from './MiniCalculatorBox.module.scss';

export type Props = CalculatorProps & {
  onOverlayClick?: () => void;
};

const CalculatorBox = ({
  onOverlayClick,
  hasNoDecimals,
  options,
  onEnter,
}: Props) => {

  const hideCalculator = () => {
    const calculator = document.querySelector<HTMLElement>(`.${styles.calculator}`);
    const overlay = document.querySelector<HTMLElement>(`.${styles.overlay}`);
    if(calculator && overlay){
      if(calculator.style.visibility === 'hidden'){
        calculator.style.removeProperty('visibility');
        calculator.style.removeProperty('overflow');
        calculator.style.removeProperty('height');
        calculator.style.removeProperty('padding');
        overlay.style.backgroundColor = 'rgba(51, 51, 51, 0.5)'
      } else {
        calculator.style.visibility = 'hidden';
        calculator.style.overflow = 'hidden';
        calculator.style.height = '0';
        calculator.style.padding = '0';
        overlay.style.backgroundColor = 'rgba(51, 51, 51, 0.1)'
      }
    }
  }

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={onOverlayClick}>
      <div onClick={e => { e.stopPropagation(); }}>
        <MiniCalculator onEnter={onEnter} options={options} hasNoDecimals={hasNoDecimals} className={styles.calculator} />
        <button onClick={hideCalculator} className={styles.hideButton}><i className={'lnr lnr-eye'}></i></button>
      </div>
    </div>,
    document.getElementById('container') as HTMLElement
  );
};

export default CalculatorBox;
