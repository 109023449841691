import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import { Option as ChoiceOption } from 'components/Choice';
import HotspotChoice, { Type } from 'components/HotspotChoice';
import LabelChoice from 'components/LabelChoice';
import CopyHotspot from "../CopyHotspot";
import {computeEnglishValue} from 'helpers'


import styles from './ImageCloze.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/rootReducer";
import {setAttempts} from "../../redux/slices/taskSlice";
import ReactHtmlParser from "react-html-parser";

export type Option = ChoiceOption & {
  alternativeLabel?: string;
  value?: string;
  correct?: boolean;
  active?: boolean;
  children?: Child[];
  noAnswer?: boolean;
  points?: number;
};

type Child = {
  label?: string;
  x: string;
  y: string;
  labelXAlignment?: string;
  equation?: string;
  className?: string;
}

export type Answers = {
  [id: string]: Option;
};

export type Answer = Option & {
  id: string;
}

export type Equation = {
  id: number,
  sign: string,
  equation?: string
}

export type Sum = {
  hotspots?: Equation[];
  x: string;
  y: string;
  labelXAlignment?: string;
  sum?: number;
}

export type Hotspot = {
  x: string;
  y: string;
  title?: string;
  type?: Type;
  options?: Option[];
  correct?: string[];
  points?: number;
  onSubmit?: (answer: Answer) => void;
  children?: Child[];

  hasNoDecimals? : boolean;
  className?: string;

  //for clipboard hotspot
  isClipboard?: boolean;
  clipBoardTitle?: string;
  clipBoardValue?: string;
  width?: string;
  onClick?: () => void;

  hasClipboard?: boolean;
  onClipboardClick?: () => void

  secondAttempt?: Hotspot;
  labelXAlignment?: string;
  labelXOffset?: string;

  //for calendar hotspot
  hasYear?: boolean;
  year?: number;
  monthNumber?: number;
};

type Hotspots = {
  [id: string]: Hotspot;
};

export type Props = {
  title?: string;
  subtitle?: string;
  src: string;
  hotspots?: Hotspots;
  initialAnswers?: Answers;
  hideContinueButton?: boolean;
  autogeneratedChildren? : Sum[];
  onContinue?: (answers: Answers) => void;
  children?: React.ReactNode;
}

const calculate = (value: string) => {
  try {
    return parseFloat(Function(`return ${value}`)()).toFixed(2); // eslint-disable-line no-new-func
  } catch {
    return parseFloat(value).toFixed(2);
  }
}

const ImageCloze = ({
  title,
  subtitle,
  src,
  hotspots = {},
  initialAnswers = {},
  autogeneratedChildren,
  hideContinueButton = false,
  onContinue,
  children,
}: Props) => {
  const [answers, setAnswers] = useState<Answers>(initialAnswers);
  const { mission } = useSelector((state: RootState) => state.app);
  const { task } = useSelector((state: RootState) => state.task);

  const dispatch = useDispatch();
  const isEverythingAnswered = () => {
    const answered = Object.values(answers).filter(answer => answer !== null);
    return Object.keys(hotspots).length === answered.length;
  }

  const hashCode = (s: string) => {
    return s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)
  }

  const evaluateAnswers = () => {
    dispatch(setAttempts({mission, task}))
    onContinue && onContinue(answers);
  }

  return (
    <div className={styles.container}>
      {title && <h2>{title}</h2>}
      {subtitle && <h3 className={styles.subtitle}>{ReactHtmlParser(subtitle)}</h3>}
      <div className={styles.imgContainer}>
        <img src={src} alt='cloze background' />
        {autogeneratedChildren && autogeneratedChildren.map((child, i) => {
          let sum = 0;
          // eslint-disable-next-line
          {child.hotspots && child.hotspots.map((equation) => {
            if(answers[equation.id]) {
              let actualValue = answers[equation.id].label;
              if(answers[equation.id].value){
                // @ts-ignore
                actualValue = answers[equation.id].value;
              }

              if(!isNaN(computeEnglishValue(actualValue))){
                sum += parseFloat(calculate(equation.sign + computeEnglishValue(actualValue) + equation.equation))
              }
            }
          })}
          let transform = 'translateY(-50%)';
          switch(child.labelXAlignment) {
            case 'center':
              transform = 'translate(-50%,-50%)';
              break;
            case 'right':
              transform = 'translate(-100%,-50%)';
              break;
            default:
              break;
          }
          return <span key={i} className={styles.child} style={{left: child.x, top: child.y, transform: transform}}>
            {Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 })
                .format(parseFloat(sum.toString().replace(/ /g, '\u00a0')))}
          </span>
        })}

        {Object.keys(hotspots).map((id, i) => {
          const hotspot = hotspots[id];

          if(hotspot.isClipboard && hotspot.isClipboard ===  true){
            return (
                <CopyHotspot
                    position={{x: hotspot.x, y: hotspot.y}}
                    width={hotspot.width}
                    onClick= {hotspot.onClick}
                    key={hashCode(hotspot.x + hotspot.y)}
                />
            )
          }

          const choices = hotspot.options?.map(option => {
            return ({
              ...option,
              onClick: () => {
                if (!option.noAnswer) {
                  setAnswers({
                    ...answers,
                    [id]: option,
                  });
                }
                option.onClick && option.onClick({
                  id,
                  alternativeLabel: option.alternativeLabel,
                  active: option.active,
                  children: option.children,
                  correct: option.correct,
                  label: option.label,
                  points: option.points,
                });
              },
            });
          })

          const secondChoices = hotspot.secondAttempt?.options?.map(option => {
            return ({
              ...option,
              onClick: () => {
                if (!option.noAnswer) {
                  setAnswers({
                    ...answers,
                    [id]: option,
                  });
                }
                option.onClick && option.onClick({
                  id,
                  alternativeLabel: option.alternativeLabel,
                  active: option.active,
                  children: option.children,
                  correct: option.correct,
                  label: option.label,
                  value: option.value,
                  points: option.points,
                });
              },
            });
          })
          const onSubmit = (value: string) => {
            setAnswers({
              ...answers,
              [id]: {
                label: value,
                correct: hotspot.correct && hotspot.correct.indexOf(value) > -1,
                points: hotspot.points,
              }
            });

            hotspot.onSubmit && hotspot.onSubmit({
              id,
              label: value,
              correct: hotspot.correct && hotspot.correct.indexOf(value) > -1,
              points: hotspot.points,
            });
          };

          const answer = answers[id];
          if (answer) {
            const { children } = answer;
            return (
              <React.Fragment key={i} >
                <LabelChoice
                  className={`${styles.label} ${hotspot.className ? hotspot.className : ''}`}
                  active={answer.active}
                  title={hotspot.title}
                  type={hotspot.type}
                  options={choices}
                  hasClipboard={hotspot.hasClipboard}
                  onClipboardClick={hotspot.onClipboardClick ? hotspot.onClipboardClick: ()=>{}}
                  secondAttempt={hotspot.secondAttempt}
                  secondAttemptOptions={secondChoices}
                  position={{x: hotspot.x, y: hotspot.y}}
                  labelXAlignment={hotspot.labelXAlignment}
                  labelXOffset={hotspot.labelXOffset}
                  hasYear={hotspot.hasYear}
                  year={hotspot.year}
                  hasNoDecimals={hotspot.hasNoDecimals}
                  monthNumber={hotspot.monthNumber}
                  label={answer.alternativeLabel || answer.label}
                  onSubmit={onSubmit}
                />
                {children && children.map((child, i) => {
                  let transform = 'translateY(-50%)';
                  switch(child.labelXAlignment) {
                    case 'center':
                      transform = 'translate(-50%,-50%)';
                      break;
                    case 'right':
                      transform = 'translate(-100%,-50%)';
                      break;
                    default:
                      break;
                  }
                  return <span key={i} className={`${styles.child} ${child.className ? child.className : ''}`} style={{left: child.x, top: child.y, transform: transform}}>{ReactHtmlParser(child.label ? child.label?.replace(/ /g, '\u00a0'): '')}</span>
                })}

                {hotspot.children && hotspot.children.map((child, i) => {
                  if(child.equation && !isNaN(parseFloat(answer.label))){
                    child.label = calculate(computeEnglishValue(answer.label) + child.equation).toString()
                  }
                  let transform = 'translateY(-50%)';
                  switch(child.labelXAlignment) {
                    case 'center':
                      transform = 'translate(-50%,-50%)';
                      break;
                    case 'right':
                      transform = 'translate(-100%,-50%)';
                      break;
                    default:
                      break;
                  }
                  return <span key={i} className={`${styles.child} ${child.className ? child.className : ''}`} style={{left: child.x, top: child.y, transform: transform}}>
                    {child.label ? Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 })
                        .format(parseFloat(child.label?.replace(/ /g, '\u00a0'))): child.label?.replace(/ /g, '\u00a0')}
                  </span>
                })}
              </React.Fragment>
            );
          }
          return (
              <HotspotChoice
                  key={i}
                  className={styles.hotspot}
                  title={hotspot.title}
                  type={hotspot.type}
                  hasClipboard={hotspot.hasClipboard}
                  onClipboardClick={hotspot.onClipboardClick ? hotspot.onClipboardClick: ()=>{}}
                  secondAttempt={hotspot.secondAttempt}
                  secondAttemptOptions={secondChoices}
                  options={choices}
                  hasYear={hotspot.hasYear}
                  year={hotspot.year}
                  monthNumber={hotspot.monthNumber}
                  hasNoDecimals={hotspot.hasNoDecimals}
                  position={{x: hotspot.x, y: hotspot.y}}
                  onSubmit={onSubmit}
              />
          );
        })}
        {children}
      </div>
      {
        isEverythingAnswered() && !hideContinueButton
        && <Button className={styles.button} label='Weiter' onClick={evaluateAnswers} portalId='container' />
      }
    </div>
  );
}

export default ImageCloze;
