import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { setOverlay } from 'redux/slices/appSlice';

import Button from 'components/Button';
import Header from 'components/DocumentHeader';
import Infobox from 'components/Infobox';

import styles from './Joker.module.scss';

type Solutions = {
  [mission: number]: Solution;
};

type Solution = {
  [task: string]: Answer;
};

type Answer = {
  [answer: string]: string;
};

const Page = () => {
  const { mission, wasJokerUsed } = useSelector((state: RootState) => state.app); 
  const { firstName } = useSelector((state: RootState) => state.user);
  const { task, answeredTasks } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch();

  const [showAnswer, setShowAnswer] = useState(false);

  const currentYear = new Date().getFullYear();

  const solutions: Solutions = {
    1: {
      computer: { 0: 'Checke zuerst den Lagerstand.' },
      doWeHaveEverything: {
        '1-2': 'Nein, du kannst die Wünsche der Kundin nicht erfüllen, denn es sind nicht genug MDF-Memoboards natur, 42 x 29 cm, lagernd.',
        '5-6': 'Erstelle ein alternatives Angebot und zeige es anschließend der Teamleiterin.'
      },
      offer: {
        0: 'Die E-Mail geht an Frau Sorger.',
        1: `Die Anfrage ist vom 15. November ${currentYear}`,
        2: 'Die gewünschten 500 Stk. MDF-Memoboards natur, 42 x 29 cm, können nicht geliefert werden.',
        3: 'Alternativ dazu solltest du 1.000 Stk. MDF-Memoboards weiß, 42 x 29 cm, anbieten.',
        4: 'Das gewünschte Lieferdatum ist Ende November.',
        5: 'Du solltest ein kostenloses MDF-Memoboard weiß, 42 x 29 cm, schicken.',
      },
      whatDoYouDo: { 0: 'Sprich zuerst das Angebot mit der Teamleiterin ab.' },
      warehouseRemoval: {
        0: 'Du sollst MDF-Memoboards weiß, 42 x 29 cm, verschicken.',
        1: 'Die Stückzahl beträgt 1.',
        2: 'Der richtige Entnahmegrund ist „kostenloses Produktmuster”.',
      }
    },
    2: {
      phone: { 0: 'Melde dich immer mit deinem Namen und dem Firmenwortlaut, damit der Anrufer bzw. die Anruferin weiß, dass er/sie richtig ist.' },
      whatDoYouDo: { 0: 'Checke erst mal die Verfügbarkeit des gewünschten Artikels. Wenn du dir nicht sicher bist, welcher Artikel gewünscht ist, kannst du dir nochmal das Angebot ansehen.' },
      doWeHaveEverything: { '1-2': 'Ja, du kannst die Wünsche der Kundin erfüllen, denn der gewünschte Artikel ist in ausreichender Menge lagernd.' },
      canYouGiveDiscount: { 0: 'Schau dir erst mal die Preise und Konditionen im System an.' },
      canYouGiveDiscountAlready: { 0: 'Stammkunden/Stammkundinnen erhalten meist spezielle Rabatte. In der Kundendatenbank kannst du nachsehen, ob die Nig GmbH spezielle Rabattkonditionen hat.' },
      whatDoYouTellTheClient: { 0: 'Die Kundin bekommt einen Rabatt von 12 % bei Abnahme von 2.000 Stk.' },
      whatDoYouDoNow: { 0: 'Erstelle eine Auftragsbestätigung.' },
      orderConfirmation: {
        0: 'Die richtige Antwort ist 14,20 x 2.000 / 100 * 88.',
        1: 'Die korrekte Lieferkondition lautet: Lieferung bis Ende November frei Haus.',
      },
    },
    3: {
      postitCloseup: { 0: 'Erstelle einen Lieferschein. Um sicher zu gehen, dass du den Lieferschein richtig bearbeitest, kannst du dir auch nochmal die Auftragsbestätigung ansehen.' },
      deliveryNote: {
        0: 'Der Kunde ist die Nig GmbH.',
        1: `Das Datum lautet 20.11.${currentYear}`,
        2: 'Die Artikelnummer lautet 532687.',
        3: 'Die korrekte Anzahl sind 2.000 Stk.',
      },
      whatDoYouDoNow: { 0: 'Besprich den Lieferschein mit deiner Teamleiterin.' },
    },
    4: {
      computer: { 0: 'Erstelle jetzt eine neue Rechnung.' },
      bill: {
        0: 'Die Lieferschein-Nr. lautet 15720.',
        1: `Die Lieferung erfolgte am 20.11.${currentYear}`,
        2: 'Der Kunde hat Anspruch auf 12 % Rabatt.',
        3: 'Unsere Artikel werden mit 20 % USt verrechnet.',
        4: 'Die Zahlungsbedingungen lauten: 7 Tage 2 % Skonto, 14 Tage netto Kassa.',
      },
      whatDoYouDoAfterBill: { 0: 'Rechnungen müssen immer erst freigegeben werden. Lass deine Teamleiterin einen Blick darauf werfen.' },
      hasClientPaid: {
        '1-2': 'Nein, der korrekte Betrag wurde nicht eingezahlt.',
        '5-7': 'Du solltest den Kunden zur Nachzahlung auffordern.',
      },
      reminder: {
        0: 'Die Empfängerin ist Claudia Sorger von der Nig GmbH.',
        1: 'Setze kastner@memoboard.at in CC.',
        2: 'Der Betreff sollte lauten: Ungerechtfertigter Skontoabzug',
        3: 'Die E-Mail geht an Claudia Sorger.',
        4: `Die Zahlung ist am 29.11.${currentYear} eingegangen.`,
        5: 'Der Betrag der Zahlung lautet: 29.390,59 Euro',
        6: `Die richtige Rechnung ist Re.-Nr. 15720 vom 20.11.${currentYear}`,
        7: 'Die Zahlungskonditionen lauten: 7 Tage 2 % Skonto, 14 Tage netto Kassa',
        8: `Der Skontoabzug wäre bis zum 27.11.${currentYear} zulässig gewesen.`,
        9: 'Ziehe vom Rechnungsbetrag inkl. USt den Zahlungseingang ab.',
      },
    },
    5: {
      compareMemoBoardOffers: {
        '1-3': 'Schau dir zuerst die Anfrage an, um ein Verständnis für die Anforderungen an das Produkt zu bekommen.'
      },
      whatsNext: {
        '1-2': 'Um die Einstandspreise kalkulieren zu können, musst du dir zuerst die Angebote ansehen.'
      },
      doesPutschMeetTheRequirements: {
        '4-5': 'Das Angebot der Firma Putsch erfüllt alle Anforderungen aus unserer Anfrage.'
      },
      doesDoberMeetTheRequirements: {
        '2-3': 'Das Angebot der Firma Dober erfüllt alle Kriterien aus unserer Anfrage.'
      },
      doesMellerMeetTheRequirements: {
        '1-2': 'Das Angebot der Firma Meller erfüllt  leider nicht unsere Anforderungen.',
        '6-8': 'Die Firma Meller kann die Boards frühestens ab September liefern. Wir benötigen die Produkte allerdings schon Anfang August.',
        '13-14': 'Als Nächstes solltest du den Einstandspreis kalkulieren, um zu sehen, welches Angebot günstiger ist.'
      },
      referenceSpreadsheet: {
        0: 'Die Bezugskalkulation sollte für 100 Stück erfolgen.',
        1: 'Zuerst muss der Rabatt berücksichtigt werden.',
        2: 'Der Rabatt wird subtrahiert.',
        3: 'Als Zweites müssen die Bezugsspesen mitkalkuliert werden.',
        4: 'Die Bezugsspesen werden addiert.',
        5: 'Im dritten Schritt wird das Skonto mitkalkuliert.',
        6: 'Das Skonto wird addiert.'
      },
      referenceCalculator: {
        0: 'Der Einkaufspreis für ein Glasboard inkl. Magnete beträgt € 94,60 im Angebot der Firma Putsch.',
        1: 'Die Firma Putsch gewährt einen Rabatt von 10 %.',
        2: 'Der Rabatt lässt sich wie folgt berechnen: Einkaufspreis * Rabatt (in Prozent). ',
        3: 'Die Firma Putsch veranschlagt € 15,00 Bezugsspesen',
        4: 'Bei der Firma Putsch gibt es ein Skonto von 2 %.',
        5: 'Das Skonto lässt sich wie folgt berechnen: Einkaufspreis (nach Berücksichtigung von Rabatt und Bezugspesen) * Skonto (in Prozent).',
        6: 'Der Einkaufspreis pro Stück beträgt € 99,30 im Angebot der Firma Putsch.',
        7: 'Die Firma Dober gewährt einen Rabatt von 15 %.',
        8: 'Der Rabatt lässt sich wie folgt berechnen: Einkaufspreis * Rabatt (in Prozent).',
        9: 'Die Firma Dober veranschlagt keine Bezugsspesen (€ 0,00)',
        10: 'Bei der Firma Dober gibt es ein Skonto von 3 %.',
        11: 'Das Skonto lässt sich wie folgt berechnen: Einkaufspreis (nach Berücksichtigung von Rabatt und Bezugspesen) * Skonto (in Prozent).',
      },
      putschOrDober: {
        '2-3': 'Der Einstandspreis des Angebots der Firma Dober ist günstiger.'
      }
    },
    6: {
      weMustDetermineSalesPrice: {
        '2-3': 'Frag deine Teamleiterin erst nach den Kalkulationsgrundlagen, bevor du mit der Berechnung beginnst.'
      },
      prepareCalculationProfits: {
        0: 'Erst muss der Einstandspreis pro Stück berechnet werden.',
        1: 'Nach dem Einstandspreis müssen die Regien (Gemeinkosten) berücksichtigt werden.',
        2: 'Die Regien (Gemeinkosten) werden addiert.',
        3: 'Aus Einstandspreis und Regien (Gemeinkosten) ergeben sich die Selbstkosten.',
        4: 'Selbstkosten plus Gewinn ergeben den Kassapreis.',
        5: 'Nach der Berechnung des Kassapreises wird der Kundenskonto miteinkalkuliert.',
        6: 'Der Kundenskonto muss addiert werden.',
        7: 'Nach der Berücksichtigung des Kundenskontos muss der Kundenrabatt eingerechnet werden.',
        8: 'Der Kundenrabatt wird addiert.',
        9: 'Der Kassapreis unter Berücksichtigung des Kundenskontos und Kundenrabatts ergibt den Nettoverkaufspreis.',
        10: 'Um vom Nettoverkaufspreis zum Bruttoverkaufspreis zu kommen, muss die USt einberechnet werden.',
        11: 'Die USt wird zum Nettoverkaufspreis addiert.',
      },
      calculationProfits: {
        0: 'Der Einstandspreis für 100 Stk. beträgt 8.187,28. Du kannst ihn aus deiner Bezugskalkulation kopieren und einfügen.',
        1: 'Die Regien (Gemeinkosten) berechnest du so: Einstandspreis / 100 * 30 = 24,56',
        2: 'Um die USt. zu berechnen, musst du von unten nach oben rechnen: Bruttoverkaufspreis / 120 * 20 = 25,00',
        3: 'Den Nettoverkaufspreis berechnest du so: Bruttoverkaufspreis / 120 * 100 = 125,00. Oder so: Bruttoverkaufspreis - USt = 125,00',
        4: 'Auch beim Kundenrabatt muss von unten nach oben gerechnet werden: Nettoverkaufspreis / 100 * 10 = 12,50',
        5: 'Das Kundenskonto ergibt sich wie folgt: Zwischenergebnis / 100 * 2 = 2,25',
        6: 'Den Kassapreis berechnet man so: Zwischenergebnis / 100 * 98 = 110,25',
        7: 'Der Gewinn in Euro wird so berechnet: Kassapreis - Selbskosten = 3,82',
        8: 'Der Gewinn in Prozent wird als letztes berechnet: Gewinn / Selbstkosten * 100 = 3,58',
      },
      canWeCoverOurProfitClaims: {
        '1-2': 'Laut deiner Berechnung beträgt der Gewinn bei diesem Produkt 3,58 %. Wir können damit unsere Gewinnansprüche von 10 % also nicht erreichen.',
        '6-8': 'Es wäre möglich unser Gewinnziel zu reduzieren. Noch besser wäre es allerdings mit dem Lieferanten günstigere Lieferbedingungen auszuhandeln.'
      },
      weGetAdditionalDiscount: {
        '2-4': 'Bevor eine Entscheidung getroffen werden kann, muss erst mal der Verkaufspreis berechnet werden.',
      },
      sellingPriceCalculation: {
        0: 'Die Firma Dober gewährt uns zu den ursprünglichen 15 % noch 5 % zusätzlichen Rabatt. Somit ergibt sich ein Rabatt von 20 %.',
        1: 'Den Rabatt in Euro berechnest du wie folgt: Einkaufspreis / 100 * 20 = 19,86',
        2: 'Das Skonto berechnest du so: Zwischenergebnis 1 / 100 * 3 = 2,38',
        3: 'Die Regien (Gemeinkosten) berechnest du so: Einstandspreis / 100 * 30 = 23,12',
        4: 'In dieser Berechnung verwenden wir die Gewinnvorgabe von 10 %.',
        5: 'Den Gewinn in Euro kann nun so berechnet werden: Selbstkosten / 100 * 10 = 10,02',
        6: 'Das Kundenskonto wird so berechnet: Kassapreis/ 98 * 2 = 2,25',
        7: 'Den Kundenrabatt kannst du so berechnen: Zwischenergebnis 2 / 90 * 10 = 12,49',
        8: 'So berechnest du die USt.: Nettoverkaufspreis / 100 * 20 = 24,99',
      },
      canWeNowMeetSalesPriceAndProfitTargets: {
        '1-2': 'Ja, die Vorgaben werden nun erfüllt, denn der neue Verkaufspreis liegt unter 150,00 Euro und der neue Gewinn beträgt 10 %.'
      }
    },
    7: {
      weShouldCleanUpOurAssortment: {
        '2-4': 'Bei der Sortimentsbereinigung werden Produkte auf der Basis von bestimmten Leistungsmerkmalen aus dem Sortiment genommen.',
        '8-10': 'Die am besten geeignete Kennzahl zur Bewertung eines Produktes für die Sortimentsbereinigung ist der Deckungsbeitrag.'
      },
      enterItemList: {
        0: 'Aus der Artikelübersicht geht hervor, dass die Memoboards natur von der Meller OG geliefert werden.',
      },
      calculateContributionMargin: {
        0: 'Der Stückerlös in Euro des Memoboards 42 x 29 cm ist 12,20',
        1: 'Die Selbstkosten je Stück in Euro für das Memoboard 42 x 29 cm ist 8,20',
        2: 'Der Deckungsbeitrag je Stück in Euro für das Memoboard 42 x 29 cm ist 4,00',
        3: 'Der Stückerlös in Euro des Memoboards 45 x 60 cm ist 17,17',
        4: 'Die Selbstkosten je Stück in Euro für das Memoboard 45 x 60 cm ist 12,60',
        5: 'Der Deckungsbeitrag je Stück in Euro für das Memoboard 45 x 60 cm ist 4,57',
        6: 'Der Stückerlös in Euro des Memoboards 60 x 90 cm ist 19,30',
        7: 'Die Selbstkosten je Stück in Euro für das Memoboard 60 x 90 cm ist 8,70',
        8: 'Der Deckungsbeitrag je Stück in Euro für das Memoboard 60 x 90 cm ist 10,60',
        9: 'Der Stückerlös in Euro des Memoboards 100 x 200 cm ist 26,70',
        10: 'Die Selbstkosten je Stück in Euro für das Memoboard 100 x 200 cm ist 16,30',
        11: 'Der Deckungsbeitrag je Stück in Euro für das Memoboard 100 x 200 cm ist 10,40',
      },
      whoDoYouSuggestDelisting: {
        '1-4': 'Das MDF-Memoboard natur, 42 x 29 cm mit der Art.-Nr. 578912 hat den geringsten Stückdeckungsbeitrag.',
        '8-9': 'Du solltest dir die Absatzzahlen der Produkte ansehen. Sie geben Aufschluss über den Gesamtdeckungsbeitrag. Dieser ist aussagekräftiger als der Deckungsbeitrag pro Stück.'
      },
      calculateTotalContributionMargin: {
        4: 'Der Vorjahresabsatz des Memoboards 42 x 29 cm waren 5.560 Stück',
        5: 'Der Gesamtdeckungsbeitrag in Euro des Memoboards 42 x 29 cm ist 22.240,00',
        6: 'Der Vorjahresabsatz des Memoboards 45 x 60 cm waren 3.859 Stück',
        7: 'Der Gesamtdeckungsbeitrag in Euro des Memoboards 45 x 60 cm ist 17.635,63',
        8: 'Der Vorjahresabsatz des Memoboards 60 x 90 cm waren 1.315 Stück',
        9: 'Der Gesamtdeckungsbeitrag in Euro des Memoboards 60 x 90 cm ist 13.939,00',
        10: 'Der Vorjahresabsatz des Memoboards 100 x 200 cm waren 1.327 Stück',
        11: 'Der Gesamtdeckungsbeitrag in Euro des Memoboards 100 x 200 cm ist 13.800,80',
      },
      whichMdfDoYouSuggestForListing: {
        '1-4': 'Das MDF-Memoboard natur, 100 x 200 cm mit der Art.-Nr. 578915 hat den geringsten Gesamtdeckungsbeitrag.'
      }
    },
    8: {
      whatDoYouDoNext: {
        '2-3': 'Du solltest erst im Mahnplan nachsehen, wann Kunden mit offenen Zahlungen zu mahnen sind.',
      },
      doWeHaveToTakeExtraSteps: {
        '1-2': 'Ja, bei einem Kunden besteht Handlungsbedarf.',
        '6-9': 'Bei der Zalanka AG besteht Handlungsbedarf.',
        '13-15': 'Wir müssen der Zalanka AG eine zweite Mahnung schicken.',
        '17-19': 'Wir müssen der Zalanka AG eine zweite Mahnung schicken.',
      },
      pleaseShowMeHowToCalculateTheNewPaymentAmount: {
        0: 'Die Basis der Berechnung ist der Rechnungsbetrag inklusive Umsatzsteuer.',
        1: 'Die Mahnspesen müssen einberechnet werden.',
        2: 'Es handelt sich um eine Addition.',
        3: 'Das Ergebnis ist der neue Zahlungsbetrag.',
      },
      pleaseWriteTheSecondReminder: {
        0: 'Der Empfänger ist die Zalanka AG.',
        1: 'Der Betreff sollte “2. Mahnung” lauten.',
        2: 'Wähle die Rechnung Nr. 1835 der Zalanka AG.',
        3: `Der ausstehende Betrag ist bis spätestens 18.12.${currentYear} zu bezahlen.`,
        4: 'Der Rechnungsbetrag inkl. USt. beträgt EUR 13.668,00.',
        5: 'Laut Mahnplan betragen die Mahnspesen EUR 11,00.',
        6: 'Der neue Zahlungsbetrag lautet EUR 13.679,00.',
      },
      isThereANeedForAction: {
        '2-4': 'Die Zalanka AG hat den offenen Betrag noch nicht bezahlt und die zweite Mahnungsfrist ist bereits überschritten.',
        '8-10': 'Eine letzte Mahnung erhält die Zalanka AG noch von uns.',
      },
      calculatingDefaultInterest1: {
        0: 'Der Rechnungsbetrag beträgt EUR 13.668,00.',
        1: 'Der Zinssatz beträgt 10%.',
        2: `Die Verzugszinsen sind laut Mahnplan ab dem auf die Fälligkeit der Rechnung folgenden Tag zu bezahlen. Das ist der 5.11.${currentYear}`,
        3: `Die Verzugszinsen sind laut Mahnplan bis zur letzten Zahlungsfrist zu bezahlen. Der Zeitraum endet somit mit 30.12.${currentYear}`,
      },
      calculatingDefaultInterest2: {
        0: 'Die Berechnung der Verzugszinsen funktioniert so: Rechnungsbetrag * Zinssatz / 360 * Säumnistage'
      },
      calculatingTheNewPaymentAmount: {
        0: 'Die Basis der Berechnung ist der Rechnungsbetrag inklusive Umsatzsteuer.',
        1: 'Ziehe zuerst Mahnspesen in die Kalkulation mit ein.',
        2: 'Die Mahnspesen müssen addiert werden.',
        3: 'Ziehe als Nächstes die Verzugszinsen in die Kalkulation mit ein.',
        4: 'Die Verzugszinsen müssen addiert werden.',
        5: 'Das Ergebnis ist der neue Zahlungsbetrag.',
      },
      pleaseCompleteTheReminderToZalanko: {
        0: 'Das Schreiben soll per Einschreiben verschickt werden.',
        1: `Der neue Zahlungsbetrag muss bis spätestens 30.12.${currentYear} überwiesen werden.`,
        2: 'Der Rechnungsbetrag ist EUR 13.668,00.',
        3: 'Die Mahnspesen betragen mittlerweile insgesamt EUR 22,00.',
        4: 'Die Verzugszinsen hast du ja bereits berechnet. Es sind EUR 212,61.',
        5: 'Der neue Zahlungsbetrag beträgt EUR 13.902,61.'
      }

    },
    9: {
      whatDoYouDoFirst: {
        '2-4': 'Zuerst sollte die Rechnung mit der Bestellung abgeglichen werden. Es könnten sich schließlich Fehler eingeschlichen haben.',
      },
      doTheInvoiceAndOrderMatch: {
        '1-2': 'Nein, die Rechnung und die Bestell- bzw. Auftragsbestätigung weisen Unterschiede auf.'
      },
      checkTheInvoiceAndOrderMatch: {
        0: 'Der Adressblock weist keine Fehler auf.',
        1: 'Rechnungsnummer, Datum und Bestellnummer sind korrekt.',
        2: 'Bei den Posten stimmt etwas nicht: Den Artikel auf Pos. 6 haben wir laut Bestell- bzw. Auftragsbestätigung gar nicht bestellt.',
        3: 'Die Menge des Artikels auf Pos. 4 weicht von der Bestell- bzw. Auftragsbestätigung ab.',
        4: 'Der Stückpreis des Artikels auf Pos. 1 ist falsch.',
        5: 'Die Verrechnung einer Zustellpauschale ist unzulässig, denn die Lieferung sollte frei Haus und somit kostenlos erfolgen.',
        6: 'Bei den Zahlungskonditionen gibt es keinen Fehler.',
      },
      whatAreYouDoingNow: {
        '1-2': 'Bevor wir den Betrag überweisen können, brauchen wir unbedingt erste eine korrigierte Rechnung.',
      }
    },
    10: {
      pleasePrepareTheReceipts: {
        '2-4': 'Zunächst muss das Datum des Belegeingangs vermerkt werden.',
        '8-10': 'Bevor die Verbuchung beginnen kann, müssen die Belege noch geprüft werden. Schließlich könnten sich Fehler eingeschlichen haben.',
        '15-17': 'Jetzt sollten die Belege nach Belegkreis sortiert und fortlaufend nummeriert werden.'
      },
      lehnerFurnitureDocument: {
        0: 'Das ist eine Eingangsrechnung (ER).'
      },
      wurmDocument: {
        0: 'Hierbei handelt es sich um einen Kassabeleg (K).'
      },
      memoboardDocument: {
        0: 'Diese Rechnung haben wir (Memoboard GmbH) ausgestellt. Es handelt sich also um eine Ausgangsrechnung (AR).'
      },
      finanzbankDocument: {
        0: 'Das ist ein Kontoauszug mit Bankkontotransaktionen. Es handelt sich also um einen Bankbeleg (B) .'
      },
      earthMagDocument: {
        0: 'Diesen Einkauf haben wir bereits per Kreditkarte bezahlt. Daher handelt es sich um einen Sonderbeleg (S).'
      },
      whatToDoNext: {
        '1-3': 'Nun müssen die Belege vorkontiert werden.'
      }
    },
    11: {
      accountForReceipt: {},
      accountForReceiptWurm: {},
      accountForReceiptMemoboard: {},
      accountForReceiptFinanzbank: {},
      accountForReceiptEarthMag: {},
      earthMagDocument: {},
    },
    12: {
      pleaseManagePettyCashRegister: {
        '2-4': 'Bevor wir den neuen Kassabericht eröffnen können, müssen wir erst überprüfen, welche Beträge überhaupt in den Kassabericht übernommen werden sollen.'
      },
      canER743BeIncluded: {
        0: 'ER 743 ist eine Eingangsrechnung und das Kassakonto kommt hier nicht zum Einsatz. Dieser Beleg ist ' +
            'also nicht relevant für den Kassabericht.'
      },
      canK87BeIncluded: {
        0: 'Diese Rechnung wurde bar bezahlt. Der Beleg muss daher in den Kassabericht übertragen werden.'
      },
      canAR703BeIncluded: {
        0: 'Bei diesem Beleg kam es zu keiner Transaktion am Kassakonto. Wir tragen ihn also nicht im Kassabuch ein.'
      },
      canK88BeIncluded: {
        0: 'K 88 ist ein Kassabeleg und muss daher ins Kassabuch übertragen werden.'
      },
      canB112BeIncluded: {
        0: 'Bei B 112 ist das Kassakonto nicht involviert, daher ist dieser Beleg nicht ins Kassabuch einzutragen.'
      },
      canK89BeIncluded: {
        0: 'Ja, auch dieser Kassabeleg muss in den Kassabericht eingetragen werden.'
      },
      canS32BeIncluded: {
        0: 'Nein, dieser Sonderbeleg muss nicht ins Kassabuch eingetragen werden.'
      },
      canK90BeIncluded: {
        0: 'Ja, beiK 90 kam es zu einer Barzahlung, daher muss der Beleg ins Kassabuch übertragen werden.'
      },
      whatAreYouDoingNext: {
        '1-3': 'Im nächsten Schritt muss ein neuer Kassabericht für den heutigen Tag eröffnet werden.',
        '7-9': 'Jetzt müssen die Beträge der markierten Belege in den Kassabericht übertragen werden.'
      },
      beleg87: {
        0: 'Bei diesem Beleg gibt es keinen Eingang am Kassakonto. Lasse die Eingang-Zelle bitte leer.',
        1: 'Der Kassausgang in der Höhe von € 6,97 muss in die Ausgang-Zelle eingetragen werden.',
      },
      beleg88: {
        0: 'Bei diesem Beleg gibt es keinen Eingang am Kassakonto. Lasse die Eingang-Zelle bitte leer.',
        1: 'Der Kassausgang in der Höhe von € 208,00 muss in die Ausgang-Zelle eingetragen werden.'
      },
      beleg89: {
        0: 'Der Kassaeingang in der Höhe von € 500,00 muss in die Eingang-Zelle eingetragen werden.',
        1: 'Bei diesem Beleg gibt es keinen Ausgang am Kassakonto. Lasse die Ausgang-Zelle bitte leer.'
      },
      beleg90: {
        0: 'Bei diesem Beleg gibt es keinen Eingang am Kassakonto. Lasse die Eingang-Zelle bitte leer.',
        1: 'Der Kassausgang in der Höhe von € 132,00 muss in die Ausgang-Zelle eingetragen werden.'
      },
      nowWeCanStartConclusion: {
        '2-4': 'Drei Schritte sind nun notwendig: Den Saldo des Kassabestands berechnen, den Saldo des Vortages ' +
            'in den heutigen Kassabricht übertragen und das Geld in der Kassa zählen.'
      },
      saldoBberechnen: {
        0: 'Die Summe der Eingänge ist € 500,00.',
        1: 'Die Summe der Ausgänge beträgt € 346,97.',
        2: 'Der Saldo ist die Differenz zwischen der Summe der Eingänge und der Summe der Ausgänge, also € 153,03.'
      },
      pleaseTransferBalanceFromPreviousDay: {},
      balancePreviousDays: {
        0: 'Trage hier den Ist-Endbestand vom Kassabericht vom 11.11. ein. Du findest den Bericht in den Dokumenten auf deinem Arbeitsplatz.'
      },
      currentCashBalance: {
        0: 'Der Soll-Endbestand ist die Summe des Gesamtsaldos und des Übertrags.',
        1: 'Ist-Endbestand ist der tatsächliche Betrag in der Kassa. Am Ende des Tages waren EUR 321,38 in der Kassa.',
        2: 'Die Differenz von Soll- und Ist-Endbestand beträgt EUR -2,00.',
        3: 'Es handelt sich um ein Manko.'
      },
      howIsItGoingNow: {
        '1-2': 'Jetzt kannst du den Kassaendbestand ermitteln. Daraus ergibt sich dann eventuell ein Überschuss oder ein Manko.'
      },
      currentCashBalance2: {
        0: 'Der Soll-Endbestand ergibt sich aus dem Saldo Gesamt und dem Übertrag des Kassabestands vom Vortag.',
        1: 'Ob es einen Überschuss bzw. ein Manko gibt, findest du heraus, wenn du den Soll-Endbestand vom Ist-Endbestand subtrahierst.'
      },
      whatDoYouSee: {
        '1-3': 'Es fehlt Geld in der Kassa. Es handelt sich also um ein Manko.'
      },
    },
    13: {
      prepareTransfer: {
        '3-4': 'Bevor der Rechnungsbetrag überwiesen werden kann, müssen die Zahlungsbedingungen überprüft werden.'
      },
      whatToDoNext: {
        '1-2': 'Der Lieferant gewährt uns einen Skonto. Du musst also nun den korrekten Überweisungsbetrag berechnen.'
      },
      fillOutTransferForm: {
        0: 'Bei Empfängername muss der Firmenname des Lieferanten eingetragen werden: Karl Lehner & Co',
        1: 'Der IBAN ist die lange Zahl die mit dem Länderkennzeichen beginnt.',
        2: 'Den Überweisungsbetrag berechnest du so: Gesamtbetrag (inkl. USt) abzüglich 3% Skonto.',
        3: 'Das Feld bei Zahlungsreferenz muss leer gelassen werden.',
        4: 'Bei Verwendungszweck sollte die Rechnungsnummer eingetragen werden.',
        5: 'Auftraggeber sind wir, die Memoboard GmbH',
      },
    }
  };

  const helpMe = () => {
    window._paq.push(['trackEvent', 'Joker', 'Joker Attempt', 'Successful']);
    // dispatch(setWasJokerUsed(true));
    setShowAnswer(true);
  };

  const getCurrentAnswer = () => {
    let currentAnswer = null;

    if (task) {
      const keys = Object.keys(solutions[mission][task]);

      if (!answeredTasks[mission][task]) {
        currentAnswer = keys[0];
      } else {
        keys.some((key) => {
          if (!answeredTasks[mission][task][key]) {
            currentAnswer = key;
            return true;
          }
          return false;
        });
      }
    }

    return currentAnswer;
  };

  const close = () => {
    dispatch(setOverlay(null));
  };

  const currentAnswer = getCurrentAnswer();

  let text = '';
  if (task && currentAnswer && !wasJokerUsed) {
    text = `Hallo ${firstName}! Brauchst du Hilfe?`;
  } else if (wasJokerUsed) {
    text = 'Ich kann dir nur einmal pro Auftrag helfen.'
  } else {
    text = 'Ich kann dir gerade nicht helfen.';
  }

  const correctAnswer = currentAnswer ? solutions[mission][task][currentAnswer] : '';

  return (
    <div className={styles.container}>
    <Header title='Joker' onClose={close}/>
    <div className={styles.content}>
      <Infobox text={showAnswer ? correctAnswer : text} image={require('static/images/avatars/joker.png')} />
      {task && currentAnswer && !showAnswer && !wasJokerUsed && <Button className={styles.button} label='Hilf mir' onClick={helpMe} />}
    </div>
  </div>
  );
}

export default Page;
