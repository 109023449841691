import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { 
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import localForage from 'localforage';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'kompetent-im-office',
  storage: localForage,
}

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

window.store = store;
window.localforage = localForage;
window.__clear = localForage.clear;

export type AppDispatch = typeof store.dispatch;

export {
  store,
  persistor,
};