import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

import styles from './Button.module.scss';

import { useFunctions } from "hooks/functions";

export type Props = {
  className?: string;
  label?: string;
  onClick?: () => void;
  onEnter?: () => void;
  to?: string;
  portalId?: string;
  locked?: boolean;
};

const Button = ({
  className = '',
  label = 'I am a button',
  onClick,
  onEnter,
  to,
  portalId,
  locked = false,
}: Props) => {
  const [{ setPage }] = useFunctions();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (to) {
      setPage(to);
    }
  }

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onEnter && onEnter();
    }
  }, [onEnter]);

  useEffect(() => {
    onEnter && document.addEventListener('keydown', handleKeyDown);

    return () => { onEnter && document.removeEventListener('keydown', handleKeyDown); }
  }, [onEnter, handleKeyDown]);

  const button = (
    <div className={`${styles.container} ${locked ? styles.locked : ''} ${className}`}>
      <button onClick={handleClick}>{label}</button>
    </div>
  );

  if (portalId && document.getElementById(portalId)) {
    return ReactDOM.createPortal(
      button,
      document.getElementById(portalId) as HTMLElement,
    );
  }

  return button;
}


export default React.memo(Button);