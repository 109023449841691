import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { addTime } from 'redux/slices/scoreSlice';

const Timer = () => {
  const { mission } = useSelector((state: RootState) => state.app);
  const { isTimerRunning } = useSelector((state: RootState) => state.score);
  const dispatch = useDispatch();

  const [isFocused, setIsFocused] = useState(true);

  const pause = () => {
    setIsFocused(false);
  };

  const start = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    window.addEventListener('focus', start);
    window.addEventListener('blur', pause);

    return () => {
      window.removeEventListener('focus', start);
      window.removeEventListener('blur', pause);
    }
  }, []);

  useEffect(() => {
    const interval = 3000;
    const id = window.setInterval(() => {
      if (isFocused && isTimerRunning) {
        dispatch(addTime({ mission, time: interval / 1000 }));
      }
    }, interval);

    return () => {
      window.clearInterval(id);
    }
  }, [mission, dispatch, isFocused, isTimerRunning]);

  return null;
};

export default Timer;