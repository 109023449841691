import React from 'react';

import styles from './ModuleChoiceBox.module.scss';

export type Props = {
  title?: string;
  description?: string;
  onClick?: (e: any) => void;
};

const ModuleChoiceBox = ({
  title,
  description,
  onClick,
}: Props) => (
  <div className={styles.container} onClick={onClick}>

    <div className={styles.content}>
      <div className={styles.module}><small>Modul</small></div>
      {title && <div className={styles.title}><strong>{title}</strong></div>}
      {description && <div className={styles.description}><small>{description}</small></div>}
    </div>

    <div className={styles.arrow}>
      <span className="lnr lnr-chevron-right" />
    </div>
  </div>
);

export default ModuleChoiceBox;