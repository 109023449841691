import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

import history from 'lib/history';
import { RootState } from 'redux/rootReducer';

type Props = {
  children: React.ReactNode;
};

const Container = ({
  children
}: Props) => {
  const [isLoading, setLoading] = useState(true);
  const { history: entries } = useSelector((state: RootState) => state.app);

  const initHistory = () => {
    entries.forEach(entry => { history.push(entry); });
  };

  useEffect(() => {
    initHistory();
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router history={history}>
      {!isLoading && children}
    </Router>
  );
};

export default Container;