import { useRef, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Page as Document } from 'hooks/library'

import { RootState } from 'redux/rootReducer';
import {setNotification, unlockDocument, setDialogue} from 'redux/slices/appSlice';
import {setAnsweredTasks, setTask, setTaskSkippable} from 'redux/slices/taskSlice';

import { Status } from 'components/FeedbackList';
import useFunctions from "./functions";

type Missions = {
  [key: number]: Tasks;
};

type Tasks = {
  [key: string]: Task;
};

type Task = {
  label: string;
  feedbackType?: string;
  items: Items;
  onContinue: () => void;
  onBack: () => void;
};

type Items = {
  [key: string]: Item;
}

type Item = {
  label: string;
  status?: Status;
}

export enum FeedbackType {
  Normal = 'normal',
  Simple = 'simple'
}

export const useFeedback = () => {
  const { mission: currentMission, feedback: currentFeedback } = useSelector((state: RootState) => state.app);
  const { answeredTasks: currentAnsweredTasks } = useSelector((state: RootState) => state.task);

  const dispatch = useDispatch();
  const [{ setOverlay, setPage }] = useFunctions();

  const resetWrongAnswers = () => {
    feedback.items.forEach((item) => {
      if (item.status === Status.Incorrect) {
        dispatch(setAnsweredTasks({ mission: currentMission, task: currentFeedback, answer: item.id, option: null }));
      }
    });
  }

  const savedMissions = useRef<Missions>({
    1: {
      offer: {
        label: 'Ich habe mir dein Angebot angesehen:',
        items: {
          0: { label: 'Kontaktperson' },
          1: { label: 'Datum Anfrage' },
          2: { label: 'Artikel / Anfrage' },
          3: { label: 'Artikel / tats. Lieferung' },
          4: { label: 'Lieferzeit' },
          5: { label: 'Muster' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.Offer));
          dispatch(setNotification('sendSample'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('offer'));
          setPage('text-cloze');
        },
      },
      warehouseRemoval: {
        label: 'Ich habe mir die Warenentnahme angesehen:',
        items: {
          0: { label: 'Artikel' },
          1: { label: 'Anzahl' },
          2: { label: 'Entnahmegrund' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setNotification('mission1Feedback'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('warehouseRemoval'));
          setPage('image-cloze');
        },
      },
    },
    2: {
      orderConfirmation: {
        label: 'Ich habe mir die Auftragsbestätigung angesehen:',
        items: {
          0: { label: 'Gesamtauftragswert' },
          1: { label: 'Lieferkonditionen' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.OrderConfirmation));
          dispatch(setNotification('mission2Feedback'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('orderConfirmation'));
          setPage('image-cloze');
        },
      },
    },
    3: {
      deliveryNote: {
        label: 'Ich habe mir den Lieferschein angesehen:',
        items: {
          0: { label: 'Kunde' },
          1: { label: 'Datum Lieferschein' },
          2: { label: 'Artikelnummer' },
          3: { label: 'Liefermenge' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.DeliveryNote));
          dispatch(setNotification('deliveryNoteCreated'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('deliveryNote'));
          setPage('image-cloze');
        },
      },
    },
    4: {
      bill: {
        label: 'Ich habe mir die Rechnung angesehen:',
        items: {
          0: { label: 'Lieferscheinnummer' },
          1: { label: 'Lieferdatum' },
          2: { label: 'Rabatt' },
          3: { label: 'Umsatzsteuer' },
          4: { label: 'Zahlungsbedingungen' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.Bill));
          dispatch(setNotification('billIsFinished'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('bill'));
          setPage('image-cloze');
        },
      },
      reminder: {
        label: 'Ich habe mir die Aufforderung zur Nachzahlung angesehen:',
        items: {
          0: { label: 'E-Mail-Adresse der Kundin' },
          1: { label: 'E-Mail-Adresse der Teamleiterin' },
          2: { label: 'Betreff' },
          3: { label: 'Anrede' },
          4: { label: 'Datum des Zahlungseingangs' },
          5: { label: 'Höhe des Zahlungseingangs' },
          6: { label: 'Rechnungsdatum' },
          7: { label: 'Zahlungskonditionen' },
          8: { label: 'Überweisungsdatum' },
          9: { label: 'offener Betrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.BackPayment));
          dispatch(setNotification('nigPaid'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('reminder'));
          setPage('text-cloze');
        },
      },
    },
    5: {
      referenceSpreadsheet: {
        label: 'Ich habe mir deine tabellarische Aufstellung zur Bezugskalkulation angesehen.',
        items: {
          0: { label: 'Stückanzahl' },
          1: { label: 'Zeile 1' },
          2: { label: 'Zeile 1 Rechenzeichen (+/-)' },
          3: { label: 'Zeile 2' },
          4: { label: 'Zeile 2 Rechenzeichen (+/-)' },
          5: { label: 'Zeile 3' },
          6: { label: 'Zeile 3 Rechenzeichen (+/-)' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.AufstellungBezugskalkulationDone);
          dispatch(setNotification('spreadsheetLooksGood'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('referenceSpreadsheet'));
          setPage('image-cloze');
        },
      },
      referenceCalculator:{
        label: 'Ich habe mir deine Bezugskalkulation angesehen.',
        items: {
          0: { label: 'Angebot Putsch: Stückpreis' },
          1: { label: 'Angebot Putsch: Rabatt in Prozent' },
          2: { label: 'Angebot Putsch: Rabatt' },
          3: { label: 'Angebot Putsch: Bezugsspesen' },
          4: { label: 'Angebot Putsch: Skonto in Prozent' },
          5: { label: 'Angebot Putsch: Skonto' },
          6: { label: 'Angebot Dober: Stückpreis' },
          7: { label: 'Angebot Dober: Rabatt in Prozent' },
          8: { label: 'Angebot Dober: Rabatt' },
          9: { label: 'Angebot Dober: Bezugsspesen' },
          10: { label: 'Angebot Dober: Skonto in Prozent' },
          11: { label: 'Angebot Dober: Skonto' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.Bezugskalkulation));
          setOverlay(Document.Bezugskalkulation);
          dispatch(setNotification('completedSpreadsheetCalculator'));
          setPage('notification');
          dispatch(setTaskSkippable(false))
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('referenceCalculator'));
          setPage('image-cloze');
        },
      },
    },
    6: {
      prepareCalculationProfits: {
        label: 'Ich habe mir die tabellarische Aufstellung zur Gewinnberechnung angesehen.',
        items: {
          0: { label: 'Einstandspreis pro Stk.' },
          1: { label: 'Regien (Gemeinkosten)' },
          2: { label: 'Regien Rechenzeichen (+/-)' },
          3: { label: 'Selbstkosten' },
          4: { label: 'Kassapreis' },
          5: { label: 'Kundenskonto' },
          6: { label: 'Kundenskonto Rechenzeichen (+/-)' },
          7: { label: 'Kundenrabatt' },
          8: { label: 'Kundenrabatt Rechenzeichen (+/-)' },
          9: { label: 'Nettoverkaufspreis' },
          10: { label: 'USt' },
          11: { label: 'USt Rechenzeichen (+/-)' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.AufstellungZurGewinnberechnungDone);
          dispatch(setNotification('profitCalculationLooksGood'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('prepareCalculationProfits'));
          setPage('image-cloze');
        },
      },
      calculationProfits: {
        label: 'Ich habe mir die tabellarische Aufstellung zur Gewinnberechnung angesehen.',
        items: {
          0: { label: 'Einstandspreis für 100 Stk.' },
          1: { label: 'Regien (Gemeinkosten)' },
          2: { label: 'USt' },
          3: { label: 'Nettoverkaufspreis' },
          4: { label: 'Kundenrabatt' },
          5: { label: 'Kundenskonto' },
          6: { label: 'Kassapreis' },
          7: { label: 'Gewinn in Euro' },
          8: { label: 'Gewinn in Prozent' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTaskSkippable(false));
          dispatch(unlockDocument(Document.GewinnBerechnenDone));
          setOverlay(Document.GewinnBerechnenDone);
          dispatch(setTask('canWeCoverOurProfitClaims'));
          dispatch(setDialogue('canWeCoverOurProfitClaims'));
          setPage('dialogue');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('calculationProfits'));
          setPage('image-cloze');
        },
      },
      sellingPriceCalculation: {
        label: 'Ich habe mir die tabellarische Aufstellung zur Gewinnberechnung angesehen.',
        items: {
          0: { label: 'Rabatt in Prozent' },
          1: { label: 'Rabatt in Euro' },
          2: { label: 'Skonto' },
          3: { label: 'Regien (Gemeinkosten)' },
          4: { label: 'Gewinn in Prozent' },
          5: { label: 'Gewinn in Euro' },
          6: { label: 'Kundenskonto' },
          7: { label: 'Kundenrabatt' },
          8: { label: 'Nettoverkaufspreis' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTaskSkippable(false));
          dispatch(unlockDocument(Document.Verkaufspreis))
          setOverlay(Document.Verkaufspreis);
          dispatch(setNotification('priceCalculationIsNowSaved'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('sellingPriceCalculation'));
          setPage('image-cloze');
        },
      },
    },
    7: {
      enterItemList: {
        label: 'Ich habe mir angesehen, welche Artikel du gewählt hast.',
        items: {
          0: { label: 'Artikel' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('calculateContributionMargin'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('enterItemList'));
          setPage('image-cloze');
        },
      },
      calculateContributionMargin: {
        label: 'Ich habe mir deine Tabelle mit der Berechnung der Stückdeckungsbeiträge angesehen.',
        items: {
          0: { label: 'MDF-Memoboard Natur 42 x 29 cm: Stückerlöse' },
          1: { label: 'MDF-Memoboard Natur 42 x 29 cm: Selbstkosten' },
          2: { label: 'MDF-Memoboard Natur 42 x 29 cm: Deckungsbeitrag' },
          3: { label: 'MDF-Memoboard Natur 45 x 60 cm: Stückerlöse' },
          4: { label: 'MDF-Memoboard Natur 45 x 60 cm: Selbstkosten' },
          5: { label: 'MDF-Memoboard Natur 45 x 60 cm: Deckungsbeitrag' },
          6: { label: 'MDF-Memoboard Natur 60 x 90 cm: Stückerlöse' },
          7: { label: 'MDF-Memoboard Natur 60 x 90 cm: Selbstkosten' },
          8: { label: 'MDF-Memoboard Natur 60 x 90 cm: Deckungsbeitrag' },
          9: { label: 'MDF-Memoboard Natur 100 x 200 cm: Stückerlöse' },
          10: { label: 'MDF-Memoboard Natur 100 x 200 cm: Selbstkosten' },
          11: { label: 'MDF-Memoboard Natur 100 x 200 cm: Deckungsbeitrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setNotification('youCanFindTheOverviewOfTheContributionMargin'));
          setPage('notification');
          dispatch(setTaskSkippable(false))
          dispatch(unlockDocument(Document.DeckungsbeitragMDF))
          setOverlay(Document.DeckungsbeitragMDF);
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('calculateContributionMargin'));
          setPage('image-cloze');
        },
      },
      calculateTotalContributionMargin: {
        label: 'Ich habe mir deine Berechnung des Gesamtdeckungsbeitrags angesehen:',
        items: {
          4: { label: 'MDF-Memoboard Natur 42 x 29 cm: Vorjahresabsatz' },
          5: { label: 'MDF-Memoboard Natur 42 x 29 cm: Gesamtdeckungsbeitrag' },
          6: { label: 'MDF-Memoboard Natur 45 x 60 cm: Vorjahresabsatz' },
          7: { label: 'MDF-Memoboard Natur 45 x 60 cm: Gesamtdeckungsbeitrag' },
          8: { label: 'MDF-Memoboard Natur 60 x 90 cm: Vorjahresabsatz' },
          9: { label: 'MDF-Memoboard Natur 60 x 90 cm: Gesamtdeckungsbeitrag' },
          10: { label: 'MDF-Memoboard Natur 100 x 200 cm: Vorjahresabsatz' },
          11: { label: 'MDF-Memoboard Natur 100 x 200 cm: Gesamtdeckungsbeitrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setNotification('youCanFindTheOverviewOfTheTotalContributionMargin'));
          dispatch(unlockDocument(Document.Gesamtdeckungsbeitrag))
          setOverlay(Document.Gesamtdeckungsbeitrag);
          dispatch(setTaskSkippable(false))
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('calculateTotalContributionMargin'));
          setPage('image-cloze');
        },
      },
    },
    8: {
      pleaseShowMeHowToCalculateTheNewPaymentAmount: {
        label: 'Ich habe mir angesehen, wie du den neuen Zahlungsbetrag für die zweite Mahnung berechnen möchtest.',
        items: {
          0: { label: 'Rechnungsbetrag inkl. USt' },
          1: { label: 'Rechenzeichen' },
          2: { label: 'Mahnspesen' },
          3: { label: 'Neuer Zahlungsbetrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.NeuerZahlungsbetragZweiteMahnungDone);
          dispatch(setNotification('pleaseWriteTheSecondReminder'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('pleaseShowMeHowToCalculateTheNewPaymentAmount'));
          setPage('image-cloze');
        },
      },
      pleaseWriteTheSecondReminder: {
        label: 'Ich habe mir die zweite Mahnung angesehen.',
        items: {
          0: { label: 'Kunde' },
          1: { label: 'Betreff' },
          2: { label: 'Ausgangsrechnung' },
          3: { label: 'Datum der Zahlungsfrist' },
          4: { label: 'Rechnungsbetrag inkl. USt.' },
          5: { label: 'Mahnspesen' },
          6: { label: 'Neuer Zahlungsbetrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.ZweiteMahnungLetterDone);
          dispatch(setNotification('theSecondReminderAFewDaysLater'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('pleaseWriteTheSecondReminder'));
          setPage('image-cloze');
        },
      },
      calculatingDefaultInterest1: {
        label: 'Ich habe mir die Berechnungsgrundlagen angesehen.',
        items: {
          0: { label: 'Rechnungsbetrag' },
          1: { label: 'Zinssatz in Prozent' },
          2: { label: 'Zeitraum (von)' },
          3: { label: 'Zeitraum (bis)' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.Verzugszinsenberechnung1);
          dispatch(setTask('calculatingDefaultInterest2'));
          dispatch(setTaskSkippable(false))
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('calculatingDefaultInterest1'));
          setPage('image-cloze');
        },
      },
      calculatingDefaultInterest2: {
        label: 'Ich habe mir deine Berechnung der Verzugszinsen angesehen.',
        items: {
          0: { label: 'Verzugszinsen' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.Verzugszinsenberechnung2))
          setOverlay(Document.Verzugszinsenberechnung2);
          dispatch(setNotification('theDefaultInterestCalculationIsSaved'));
          dispatch(setTaskSkippable(false))
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('calculatingDefaultInterest2'));
          setPage('image-cloze');
        },
      },
      calculatingTheNewPaymentAmount: {
        label: 'Ich habe mir die Aufstellung zur Berechnung des neuen Zahlungsbetrags angesehen.',
        items: {
          0: { label: 'Rechnungsbetrag' },
          1: { label: 'Mahnspesen' },
          2: { label: 'Mahnspesen Rechenzeichen' },
          3: { label: 'Verzugszinsen' },
          4: { label: 'Verzugszinsen Rechenzeichen' },
          5: { label: 'Neuer Zahlungsbetrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.NeuerZahlungsbetragLetzteMahnungDone);
          dispatch(setDialogue('pleaseCompleteTheReminderToZalanko'));
          setPage('dialogue');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('calculatingTheNewPaymentAmount'));
          setPage('image-cloze');
        },
      },
      pleaseCompleteTheReminderToZalanko: {
        label: 'Ich habe mir die letzte Mahnung angesehen.',
        items: {
          0: { label: 'Postvermerk' },
          1: { label: 'Datum der Zahlungsfrist' },
          2: { label: 'Rechnungsbetrag' },
          3: { label: 'Mahnspesen' },
          4: { label: 'Verzugszinsen' },
          5: { label: 'Neuer Zahlungsbetrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.LetzteMahnungLetterDone);
          dispatch(setNotification('mission8Feedback'));
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('pleaseCompleteTheReminderToZalanko'));
          setPage('image-cloze');
        },
      },
    },
    9: {
      checkTheInvoiceAndOrderMatch: {
        label: 'Ich habe mir angesehen, welche Abweichungen du entdecken konntest.',
        items: {
          0: { label: 'Kundeninformationen: Kein Fehler' },
          1: { label: 'Rechnungs- und Lieferdaten: Kein Fehler' },
          2: { label: 'Posten: Pos. 6: Nicht bestellt' },
          3: { label: 'Artikelstückzahl: Pos. 4: Falsche Menge' },
          4: { label: 'Artikelpreis: Pos.1: Falscher Stückpreis' },
          5: { label: 'Zustellpauschale: Lieferung sollte gratis erfolgen' },
          6: { label: 'Zahlungskonditionen: Kein Fehler' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.CheckTheInvoiceAndOrderMatchDone);
          dispatch(setDialogue('whatAreYouDoingNow'));
          dispatch(setTask('whatAreYouDoingNow'));
          setPage('dialogue');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('checkTheInvoiceAndOrderMatch'));
          setPage('image-cloze');
        },
      },
    },
    10: {
      lehnerFurnitureDocument: {
        label: 'Ich habe mir angesehen, welchem Buchungskreis du den Beleg zugeordnet hast.',
        items: {
          0: { label: 'Buchungskreis' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('wurmDocument'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('lehnerFurnitureDocument'));
          setPage('image-cloze');
        },
      },
      wurmDocument: {
        label: 'Ich habe mir angesehen, welchem Buchungskreis du den Beleg zugeordnet hast.',
        items: {
          0: { label: 'Buchungskreis' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('memoboardDocument'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('wurmDocument'));
          setPage('image-cloze');
        },
      },
      memoboardDocument: {
        label: 'Ich habe mir angesehen, welchem Buchungskreis du den Beleg zugeordnet hast.',
        items: {
          0: { label: 'Buchungskreis' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('finanzbankDocument'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('memoboardDocument'));
          setPage('image-cloze');
        },
      },
      finanzbankDocument: {
        label: 'Ich habe mir angesehen, welchem Buchungskreis du den Beleg zugeordnet hast.',
        items: {
          0: { label: 'Buchungskreis' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('earthMagDocument'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('finanzbankDocument'));
          setPage('image-cloze');
        },
      },
      earthMagDocument: {
        label: 'Ich habe mir angesehen, welchem Buchungskreis du den Beleg zugeordnet hast.',
        items: {
          0: { label: 'Buchungskreis' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('whatToDoNext'));
          dispatch(setDialogue('whatToDoNext'));
          setPage('dialogue');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('earthMagDocument'));
          setPage('image-cloze');
        },
      },
    },
    11: {
      accountForReceipt: {
        label: 'Ich habe mir angesehen, wie du den Beleg ER 743 verbucht hast.',
        feedbackType: FeedbackType.Simple,
        items: {
          0: { label: 'p1' },
          1: { label: 'p2' },
          2: { label: 'p3' },
          3: { label: 'p4' },
          4: { label: 'p5' },
          5: { label: 'p6' },
          6: { label: 'p7' },
          7: { label: 'p8' },
          8: { label: 'p9' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.AccountForReceiptDone);
          dispatch(setTask('accountForReceiptWurm'));
          setPage('image-cloze');
          dispatch(setTaskSkippable(false))
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('accountForReceipt'));
          setPage('image-cloze');
        },
      },
      accountForReceiptWurm: {
        label: 'Ich habe mir angesehen, wie du den Kassabeleg der WURM Büroprofi GmbH verbucht hast.',
        feedbackType: FeedbackType.Simple,
        items: {
          0: { label: 'p1' },
          1: { label: 'p2' },
          2: { label: 'p3' },
          3: { label: 'p4' },
          4: { label: 'p5' },
          5: { label: 'p6' },
          6: { label: 'p7' },
          7: { label: 'p8' },
          8: { label: 'p9' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.AccountForReceiptWurmDone);
          dispatch(setTask('accountForReceiptMemoboard'));
          setPage('image-cloze');
          dispatch(setTaskSkippable(false))
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('accountForReceiptWurm'));
          setPage('image-cloze');
        },
      },
      accountForReceiptMemoboard: {
        label: 'Ich habe mir angesehen, wie du unsere Ausgangsrechnung vorkontiert hast.',
        feedbackType: FeedbackType.Simple,
        items: {
          0: { label: 'p1' },
          1: { label: 'p2' },
          2: { label: 'p3' },
          3: { label: 'p4' },
          4: { label: 'p5' },
          5: { label: 'p6' },
          6: { label: 'p7' },
          7: { label: 'p8' },
          8: { label: 'p9' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.AccountForReceiptMemoboardDone);
          dispatch(setTask('accountForReceiptFinanzbank'));
          setPage('image-cloze');
          dispatch(setTaskSkippable(false))
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('accountForReceiptMemoboard'));
          setPage('image-cloze');
        },
      },
      accountForReceiptFinanzbank: {
        label: 'Ich habe mir angesehen, wie du unsere Ausgangsrechnung vorkontiert hast.',
        feedbackType: FeedbackType.Simple,
        items: {
          0: { label: 'p1' },
          1: { label: 'p2' },
          2: { label: 'p3' },
          3: { label: 'p4' },
          4: { label: 'p5' },
          5: { label: 'p6' },
          6: { label: 'p7' },
          7: { label: 'p8' },
          8: { label: 'p9' },
          9: { label: 'p10' },
          10: { label: 'p11' },
          11: { label: 'p12' },
          12: { label: 'p13' },
          13: { label: 'p14' },
          14: { label: 'p15' },
          15: { label: 'p16' },
          16: { label: 'p17' },
          17: { label: 'p18' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.AccountForReceiptFinanzbankDone);
          dispatch(setTask('accountForReceiptEarthMag'));
          setPage('image-cloze');
          dispatch(setTaskSkippable(false))
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('accountForReceiptFinanzbank'));
          setPage('image-cloze');
        },
      },
      accountForReceiptEarthMag: {
        label: 'Ich habe mir angesehen, wie du den Beleg der EarthMag GmbH vorkontiert hast.',
        feedbackType: FeedbackType.Simple,
        items: {
          0: { label: 'p1' },
          1: { label: 'p2' },
          2: { label: 'p3' },
          3: { label: 'p4' },
          4: { label: 'p5' },
          5: { label: 'p6' },
          6: { label: 'p7' },
          7: { label: 'p8' },
          8: { label: 'p9' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.AccountForReceiptEarthMagDone);
          dispatch(setDialogue('youHaveEnteredAllReceipts'))
          setPage('dialogue');
          dispatch(setTaskSkippable(false))
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('accountForReceiptEarthMag'));
          setPage('image-cloze');
        },
      },
    },
    12: {
      beleg87: {
        label: 'Ich habe mir angesehen, wie du den Betrag des Belegs 87 in den Kassabericht übertragen hast.',
        items: {
          0: { label: 'Kassaeingang bzw. -ausgang erkannt' },
          1: { label: 'Betrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('beleg88'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('beleg87'));
          setPage('image-cloze');
        },
      },
      beleg88: {
        label: 'Ich habe mir angesehen, wie du den Betrag des Belegs 88 in den Kassabericht übertragen hast.',
        items: {
          0: { label: 'Kassaeingang bzw. -ausgang erkannt' },
          1: { label: 'Betrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('beleg89'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('beleg88'));
          setPage('image-cloze');
        },
      },
      beleg89: {
        label: 'Ich habe mir angesehen, wie du den Betrag des Belegs 89 in den Kassabericht übertragen hast.',
        items: {
          0: { label: 'Kassaeingang bzw. -ausgang erkannt' },
          1: { label: 'Betrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('beleg90'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('beleg89'));
          setPage('image-cloze');
        },
      },
      beleg90: {
        label: 'Ich habe mir angesehen, wie du den Betrag des Belegs 90 in den Kassabericht übertragen hast.',
        items: {
          0: { label: 'Kassaeingang bzw. -ausgang erkannt' },
          1: { label: 'Betrag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('nowWeCanStartConclusion'));
          dispatch(setDialogue('nowWeCanStartConclusion'));
          setPage('dialogue');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('beleg90'));
          setPage('image-cloze');
        },
      },
      saldoBberechnen: {
        label: 'Ich habe mir deine Berechnung des Saldos angesehen.',
        items: {
          0: { label: 'Summe Eingänge' },
          1: { label: 'Summe Ausgänge' },
          2: { label: 'Saldo Gesamt' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('pleaseTransferBalanceFromPreviousDay'));
          dispatch(setDialogue('pleaseTransferBalanceFromPreviousDay'));
          setPage('dialogue');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('saldoBberechnen'));
          setPage('image-cloze');
        },
      },
      balancePreviousDays: {
        label: 'Ich habe mir angesehen, ob du den korrekten Betrag vom Vortag übernommen hast.',
        items: {
          0: { label: 'Übertrag Vortag' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(setTask('currentCashBalance'));
          setPage('image-cloze');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('balancePreviousDays'));
          setPage('image-cloze');
        },
      },
      currentCashBalance: {
        label: 'Ich habe mir angesehen, ob du den Kassabericht korrekt abgeschlossen hast.',
        items: {
          0: { label: 'Soll-Endbestand' },
          1: { label: 'Ist-Endbestand' },
          2: { label: 'Endbestanddifferenz' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          dispatch(unlockDocument(Document.KassaberichtDone))
          setOverlay(Document.KassaberichtDone);
          dispatch(setDialogue('whatDoYouSee'));
          dispatch(setTask('whatDoYouSee'));
          dispatch(setTaskSkippable(false))
          setPage('dialogue');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('currentCashBalance'));
          setPage('image-cloze');
        },
      },
    },
    13: {
      fillOutTransferForm: {
        label: 'Ich habe mir angesehen, wie du das Überweisungsformular ausgefüllt hast.',
        items: {
          0: { label: 'Empfängername' },
          1: { label: 'IBAN' },
          2: { label: 'Überweisungsbetrag' },
          3: { label: 'Zahlungsreferenz' },
          4: { label: 'Verwendungszweck' },
          5: { label: 'Auftraggeber' },
        },
        onContinue: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          setOverlay(Document.FillOutTransferFormDone);
          dispatch(setNotification('thankYouForYourSupport'));
          dispatch(setTaskSkippable(false))
          setPage('notification');
        },
        onBack: () => {
          window._paq.push(['trackEvent', 'Interaction', 'Feedback', 'Button Click']);
          resetWrongAnswers();
          dispatch(setTask('fillOutTransferForm'));
          setPage('image-cloze');
        },
      },
    },
  });

  const missions = savedMissions.current;
  const answeredTasks = currentAnsweredTasks[currentMission][currentFeedback];

  const prepareFeedback = useCallback(() => {
    const task = missions[currentMission][currentFeedback];
    const type = task.feedbackType;
    const items = Object.keys(task.items).map((key) => ({
      id: key, 
      label: task.items[key].label,
      status: answeredTasks[key]?.correct ? Status.Correct : Status.Incorrect }
    ));

    return { ...task, items, type };
  }, [currentMission, currentFeedback, missions, answeredTasks]);

  const [feedback, setFeedback] = useState(prepareFeedback()); 

  useEffect(() => {
    setFeedback(prepareFeedback());
  }, [prepareFeedback]);

  return [feedback];
};
