import { Type } from "../../document";
import styles from "../../../pages/ImageCloze/ImageCloze.module.scss";

export const AccountForReceiptMemoboardDone = () => {
    const currentYear = new Date().getFullYear();

    return {
        type: Type.ImageCloze,
        title: `Lösung: Beleg AR 703 vorkontieren`,
        src: require('static/images/tasks/mission11/mBv_Memoboard.png'),
        hotspots: {
            0: {
                x: '32%',
                y: '81%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            1: {
                x: '50%',
                y: '81%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            2: {
                x: '67%',
                y: '81%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            3: {
                x: '32%',
                y: '83.7%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            4: {
                x: '50%',
                y: '83.7%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            5: {
                x: '67%',
                y: '83.7%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            6: {
                x: '32%',
                y: '86.4%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            7: {
                x: '50%',
                y: '86.4%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            8: {
                x: '67%',
                y: '86.4%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            9: {x: '4%', y: '30%'},
        },
        initialAnswers: {
            0: { label: '2000', active: false},
            1: { label: '3752,40', active: false},
            2: { label: '', active: false},
            3: { label: '4000', active: false},
            4: { label: '', active: false},
            5: { label: '3127,00', active: false},
            6: { label: '3500', active: false},
            7: { label: '', active: false},
            8: { label: '625,40', active: false},
            9: { label: `<b>Rechnung Nr. 15718-${currentYear.toString().substr(-2)}</b> vom ${currentYear}-11-12 (Rechnungsdatum = Lieferdatum)`, active: false},
        },
    };
}
