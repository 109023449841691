import React, { useEffect, useState } from 'react';

import Bubble, { Props as BubbleProps } from 'components/Dialogue/Bubble';
import Portrait from 'components/Portrait';

import styles from './SpeechBubble.module.scss';

export enum Orientation {
  Left,
  Right,
};

type Speaker = {
  name: string;
  image: string;
  orientation: Orientation;
}

type Props = BubbleProps & {
  speaker: Speaker;
  animate?: boolean;
};

const SpeechBubble = ({
  speaker,
  text,
  animate = false,
}: Props) => {
  const [isVisible, setVisibilty] = useState(!animate);

  useEffect(() => {
    setVisibilty(true);
  }, []);

  return (
    <div
      className={`
        ${styles.container}
        ${speaker.orientation === Orientation.Left ? styles.left : styles.right}
        ${isVisible ? styles.visible : ''}
      `}
    >
      <Portrait src={speaker.image} className={styles.portrait} size='32px' />
      <Bubble className={styles.bubble} title={speaker.name} text={text} />
    </div>
  );
};

export default SpeechBubble;