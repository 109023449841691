import { Type } from "../../document";
import {currentYear} from "../variables";

export const Musterbeleg = () => {

    return {
        type: Type.ImageCloze,
        title: `Musterbeleg`,
        src: require('static/images/tasks/mission11/mBv_Musterbeleg.png'),
        hotspots: {
            0: {x: '22.3%', y: '24%'},
        },
        initialAnswers: {
            0: {label: `Datum: 12.11.${currentYear}`, active: false},
        }
    };
}