import React, {useCallback, useEffect, useState} from 'react';
import ReactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";

import styles from './MiniCalculator.module.scss';
import {
  setAllowClipboardCopy,
  setClipboardCurrent,
  setClipboardType,
  setOverlay as setCurrentOverlay
} from "../../redux/slices/appSlice";
import {Type} from "../HotspotChoice";
import {RootState} from "../../redux/rootReducer";

import {computeEnglishValue} from '../../helpers';
import {Option} from "../Calendar";

export type Props = {
  className?: string;
  options?: Option[];
  hasNoDecimals?: boolean;
  onEnter?: (value: string) => void;
};

export const emptyAnswerValue = `<pre style="margin: 0">    </pre>`

const MiniCalculator = ({
  className,
  options,
  hasNoDecimals,
  onEnter,
}: Props) => {

  const dispatch = useDispatch();
  const clipboard = 'aus <i class="lnr lnr-clipboard-empty"></i> einfügen'

  const { clipboardCurrent } = useSelector((state: RootState) => state.app);

  const decimalDigits = hasNoDecimals ? 0 : 2;

  const buttons = [
    { label: 'AC', style: { gridArea: 'g' }, className: styles.dense },
    { label: clipboard, style: { gridArea: 'f' }, className: styles.dense },

    { label: '7' },
    { label: '8' },
    { label: '9' },

    { label: '4' },
    { label: '5' },
    { label: '6' },

    { label: '1' },
    { label: '2' },
    { label: '3' },

    { label: '0',  style: { gridArea: 'e' } },
    { label: ',' },
  ];

  const [value, setValue] = useState('');
  const valueRef = React.useRef(value);

  /**
   * Function for the equals button/enter button
   */
  const handleEquals = useCallback(() => {
    window._paq.push(['trackEvent', 'Interaction', 'Mini-Calculator', 'Pressed Button']);
    const sanitized = valueRef.current.replace(/[^-\d/,]/g, '');
    const lastSanitizedChar = sanitized.slice(-1);

    if (!(lastSanitizedChar === ',')) {
      const englishValue = computeEnglishValue(sanitized);
      let actualValue = Intl.NumberFormat('de-DE', { minimumFractionDigits: decimalDigits })
          .format(parseFloat(englishValue.toString().replace(/ /g, '\u00a0')))

      actualValue = hasNoDecimals ? actualValue.replace('.', ''): actualValue;

      if(!isNaN(englishValue)) {
        setValue(actualValue);
        valueRef.current = actualValue;
        onEnter && onEnter(actualValue);
      } else {
        setValue('0,00')
        valueRef.current = '0,00'
        onEnter && onEnter('0,00');
      }
    }

    dispatch(setClipboardCurrent(null))
  }, [decimalDigits, dispatch, hasNoDecimals, onEnter])

  const handleClick = (newValue: string) => {
    switch (newValue) {
      case 'AC':
        window._paq.push(['trackEvent', 'Interaction', 'Mini-Calculator', 'Pressed Button']);
        valueRef.current = '';
        setValue('');
        dispatch(setClipboardCurrent(null))
        break;

      case 'Betrag einfügen':
        handleEquals()
        break;

      case clipboard:
        window._paq.push(['trackEvent', 'Interaction', 'Mini-Calculator', 'Opened Clipboard']);
        dispatch(setClipboardType(Type.MiniCalculator));
        dispatch(setCurrentOverlay('clipboard'));
        dispatch(setClipboardCurrent(value))
        dispatch(setAllowClipboardCopy(true))
        break;

      default:
        window._paq.push(['trackEvent', 'Interaction', 'Mini-Calculator', 'Pressed Button']);
        if (value === 'Infinity') {
          valueRef.current = '' + newValue
          setValue('' + newValue);
        } else {
          valueRef.current = value + newValue
          setValue(value + newValue);
        }
    }
  }

  useEffect(() => {
    if(clipboardCurrent !== null) {
      valueRef.current = clipboardCurrent
    }
    setValue(clipboardCurrent || "");
  }, [clipboardCurrent]);

  const addEmptyAnswers = () => {
    onEnter && onEnter(emptyAnswerValue)
  }

  const keyboardFunction = useCallback((event: KeyboardEvent) => {
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ','].includes(event.key)) {
      valueRef.current = valueRef.current + event.key;
      setValue(valueRef.current)

    } else if(event.key === 'Enter') {
      handleEquals()

    } else if(event.key === 'Backspace') {
      valueRef.current = valueRef.current.slice(0, -1);
      setValue(valueRef.current)
    }
  }, [handleEquals]);

  useEffect(() => {
    document.addEventListener("keyup", keyboardFunction, false);

    return () => {
      document.removeEventListener("keyup", keyboardFunction, false);
    };
  }, [keyboardFunction]);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.display}>
        <input placeholder='0' value={value} readOnly />
      </div>
      <div className={styles.buttonsContainer}>
        {buttons.map(({label, className, style}, i) =>
          <button
            key={i}
            className={className || ''}
            style={style || {}}
            onClick={() => { handleClick(label); }}
          >
            {ReactHtmlParser(label)}
          </button>
        )}
      </div>
      <div className={styles.optionButtonsContainer}>
        {options?.map(({label, onClick}, i) =>
            <button
                key={`option-${i}`}
                className={styles.dense}
                style={{}}
                onClick={onClick}
            >
              {ReactHtmlParser(label)}
            </button>
        )}
        <button
            key={`option-empty`}
            className={styles.dense}
            style={{}}
            onClick={addEmptyAnswers}
        >
          Zelle leer lassen
        </button>
      </div>
      <button
          key={`option-submit`}
          className={styles.primary}
          style={{}}
          onClick={handleEquals}
      >
        Betrag einfügen
      </button>
    </div>
  );
};

export default MiniCalculator;
