import React from 'react';

import styles from './HighscoreList.module.scss';

export type Item = {
  label: string;
  score: number;
  time: number;
  rank?: number;
  highlight?: boolean;
};

type Props = {
  title: string;
  items: Item[];
  className?: string;
  unit?: string;
};

const HighscoreList = ({
  title,
  items,
  className = '',
  unit = 'Punkte',
}: Props) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <h2>{title}</h2>
      <ol>
        {items.map((item, index) => {
          return (
            <li key={index} className={item.highlight ? styles.active : ''}>
              <span>
                <strong className={styles.rank}>{`#${item.rank || index + 1}`}</strong>
                {item.label}
              </span>
              <span>
                <span className={styles.score}>
                  <strong>{`${item.score} `}</strong>
                  {unit}
                </span>
                <br/>
                <span className={styles.score}>{ `${item.time }`}</span>
              </span>
            </li>
          )
        })}
      </ol>
    </div>
  );
}

export default HighscoreList;