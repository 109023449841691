import React from 'react';

import styles from './ListItem.module.scss';

export type Item = {
  label: string;
  caption?: string;
  locked?: boolean;
  isSeperator?: boolean;
  icon?: string;
  accordion?: boolean;
  child?: boolean;
  onClick?: () => void;
  children?: Item[];
}

type Props = {
  item: Item;
};

const ListItem = ({
  item
}: Props) => {
  return (
    <div>
      <div className={`${styles.item} ${item.locked ? styles.locked : ''} 
        ${item.isSeperator ? styles.separator : ''}
        ${item.accordion ? styles.accordion : ''}`}
        onClick={item.onClick}>

        <div>
          <span>{item.label}{item.accordion}</span>
          {item.caption && <span className={styles.caption}>{item.caption}</span>}
        </div>
        <i className={`lnr lnr-${item.locked ? 'lock' : item.icon || 'chevron-right'}`}/>
      </div>
    </div>
  );
}

export default ListItem;