import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { Notifications } from "./datatypes";
import { teamleadImage } from "./variables";

import {
    setDialogue, setFinished2, setMission,
    setModuleScore, setNotification as setCurrentNotification, setShowSurveyPopUp,
    setWasJokerUsed,
    setUnlockedMissions
} from "../../redux/slices/appSlice";
import {pauseTimer, setMissionCompleted, startTimer} from "../../redux/slices/scoreSlice";
import { setTask } from "redux/slices/taskSlice";
import useFunctions from "../functions";
import {RootState} from "../../redux/rootReducer";

export const MissionTen = () => {
    const dispatch = useDispatch();

    const { isFinishedModule2, reachedModuleScore } = useSelector((state: RootState) => state.app);
    const { anonymous } = useSelector((state: RootState) => state.user);
    const [{ getMessage, saveHighscoreAnonymous, saveHighscoreWithName, setPage, missionsCompleted,
        missionPoints, missionMaxPoints }] = useFunctions();

    const missionTenData: Notifications = {
        mission10: {
            children: <><p>Auftrag:</p><h2>Belege organisieren</h2></>,
            style: {justifyContent: 'center'},
            onContinueLabel: 'Weiter',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 2_6: Belege organisieren']);
                dispatch(setTask('pleasePrepareTheReceipts'));
                dispatch(setDialogue('pleasePrepareTheReceipts'));
                setPage('dialogue');
                dispatch(startTimer());
            },
        },
        mission10Feedback: {
            image: teamleadImage,
            text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten bei diesem Auftrag erreicht. ${getMessage()}`,
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setCurrentNotification('mission10PauseInfo'));
                setPage('notification');
                window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 2_6: Belege organisieren']);
                dispatch(pauseTimer());
            },
        },
        mission10PauseInfo: {
            icon: 'checkmark-circle',
            text: 'Möchtest du gleich mit dem nächsten Arbeitsauftrag weitermachen oder eine Pause einlegen? Dein Fortschritt wird auf diesem Gerät gespeichert, sodass du jederzeit weitermachen kannst.',
            onContinueLabel: 'Nächster Auftrag',
            onContinue: () => {
                dispatch(setMissionCompleted({mission: 10, completed: true}))
                if (reachedModuleScore[2]) {

                    if (missionsCompleted([5,6,7,8,9,12,13])){
                        if (isFinishedModule2) {
                            dispatch(setModuleScore(2));
                            if (anonymous) {
                                saveHighscoreAnonymous(2);
                            } else {
                                saveHighscoreWithName(2);
                            }
                            // dispatch(setUnlockedMissions([5]));
                            dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                        } else {
                            dispatch(setFinished2(true));
                            dispatch(setMission(13))
                            dispatch(setShowSurveyPopUp(true));
                            setPage('end-feedback');
                        }
                        window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);

                    } else {
                        dispatch(setMission(0));
                        dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
                        setPage('notification')
                    }
                } else {
                    dispatch(setWasJokerUsed(false));
                    // dispatch(setUnlockedMissions([1,5,6,7,8,9,10]));
                    dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                    setPage('dialogue');
                    dispatch(setDialogue('youCanPreAssignAndPost'));
                }
            },
        },
    }

    return missionTenData;
}
