import React from 'react';

import ModuleChoiceBox from "../ModuleChoiceBox";

import styles from './ModuleChoice.module.scss';

export type Module = {
  title: string;
  description: string;
  onClick?: (e: any) => void;
}

export type Props = {
  modules?: Module[];
}

const ModuleChoice = ({
  modules,
}: Props) => (
  <div className={styles.container}>
    {modules?.map((module, index) => <ModuleChoiceBox key={index} title={module.title} description={module.description} onClick={module.onClick}/>)}
  </div>
);

export default ModuleChoice;