import React from 'react';

import { useNotification } from 'hooks/notification';

import Button from 'components/Button';
import Choice from 'components/Choice';
import Header from 'components/NavigationHeader';
import Infobox from 'components/Infobox';
import ModuleChoice from "components/ModuleChoice";

import styles from './Notification.module.scss';
import MissionChoice from "../../components/MissionChoice";

const Page = () => {
  const [notification] = useNotification();
  const { icon, image, onContinue,
      onContinueLabel, options,
      text, children, showHighscore,
      style, modules, missions, minified } = notification;

  return (
    <div className={styles.container}>
      <Header className={`${showHighscore === false ? styles.hideHighscore : ''}`} />
      <div className={`${styles.content} ${minified ? styles.minifiedContent: ''}`}>
        <Infobox className={styles.infobox} style={style} text={text} image={image} icon={icon}>
          {children}
        </Infobox>
        {modules && <ModuleChoice modules={modules}/>}
        {missions && <MissionChoice />}
        {onContinue && <Button label={onContinueLabel || 'Okay'} onClick={onContinue} />}
        {options && <Choice options={options} />}
      </div>
    </div>
  );
};

export default Page;