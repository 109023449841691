import { Type } from "../../document";
import {currentYear} from "../variables";

export const K90 = () => {

    return {
        type: Type.ImageCloze,
        title: `Beleg K 90`,
        src: require('static/images/tasks/mission12/mKe_K90.png'),
        hotspots: {
            0: {x: '22.6%', y: '25%'},
        },
        initialAnswers: {
            0: {label: `Datum: 12.11.${currentYear}`, active: false},
        }
    };
}