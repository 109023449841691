import React from 'react';
import ReactDOM from 'react-dom';

import Button, { Props as ButtonProps } from 'components/Button';
import Infobox, { Props as InfoboxProps } from 'components/Infobox';

import styles from './PopUp.module.scss';

type Props = InfoboxProps & ButtonProps;

const PopUp = ({
  icon,
  animation,
  title,
  text,
  label = 'Okay',
  onClick,
  portalId,
  children,
}: Props) => {
  const popUp = (
    <div className={styles.container}>
      <div className={styles.content}>
        <Infobox className={styles.infobox} icon={icon} animation={animation} title={title} text={text} />
        <Button className={styles.button} label={label} onClick={onClick} />
        {children}
      </div>
    </div>
  );

  const container = portalId ? document.getElementById(portalId) : null;

  if (container) {
    return ReactDOM.createPortal(popUp, container as HTMLElement);
  }

  return popUp;
};

export default PopUp;
