import { Type } from "../../document";
import styles from "../../../pages/ImageCloze/ImageCloze.module.scss";

export const AccountForReceiptDone = () => {
    const currentYear = new Date().getFullYear();

    return {
        type: Type.ImageCloze,
        title: `Lösung: Beleg ER 743 vorkontieren`,
        src: require('static/images/tasks/mission11/mBv_Lehner.png'),
        hotspots: {
            0: {
                x: '32%',
                y: '78.3%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            1: {
                x: '50%',
                y: '78.3%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            2: {
                x: '67%',
                y: '78.3%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            3: {
                x: '32%',
                y: '81.3%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            4: {
                x: '50%',
                y: '81.3%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            5: {
                x: '67%',
                y: '81.3%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            6: {
                x: '32%',
                y: '84.2%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            7: {
                x: '50%',
                y: '84.2%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            8: {
                x: '67%',
                y: '84.2%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            9: {x: '74.8%', y: '30%'},
            10: {x: '72.4%', y: '33.5%'},
            11: {x: '79%', y: '40%'},
        },
        initialAnswers: {
            0: { label: '7030', active: false},
            1: { label: '282,00', active: false},
            2: { label: '', active: false},
            3: { label: '2500', active: false},
            4: { label: '56,40', active: false},
            5: { label: '', active: false},
            6: { label: '3300', active: false},
            7: { label: '', active: false},
            8: { label: '338,40', active: false},
            9: { label: `${currentYear}-11-12`, active: false},
            10: { label: `1.November ${currentYear}`, active: false},
            11: { label: `${currentYear}-11-12`, active: false},
        },
    };
}
