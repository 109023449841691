import { Type } from "../../document";

export const Gesamtdeckungsbeitrag = () => {

    return {
        type: Type.ImageCloze,
        title: `Berechnung der Gesamtdeckungsbeiträge`,
        src: require('static/images/tasks/mission7/mKb_GesamtdeckungsbeitragBerechnenDone.png'),
    };
}
