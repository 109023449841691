import { Type } from "../../document";
import {currentYear} from "../variables";

export const ER743 = () => {

    return {
        type: Type.ImageCloze,
        title: `Beleg ER 743`,
        src: require('static/images/tasks/mission12/mKe_ER743.png'),
        hotspots: {
            0: {x: '74.5%', y: '30%'},
            1: {x: '71.2%', y: '33.6%'},
            2: {x: '78.5%', y: '40%'},
        },
        initialAnswers: {
            0: {label: `${currentYear}-11-12`, active: false},
            1: {label: `1.November ${currentYear}`, active: false},
            2: {label: `${currentYear}-11-12`, active: false},
        }
    };
}