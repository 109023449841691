import {useDispatch, useSelector} from "react-redux";

import {Dialogues} from "./datatypes";
import {RootState} from "../../redux/rootReducer";
import { teamleadImage } from "./variables";

import { setDialogue as setCurrentDialogue, setNotification, unlockDocument } from "../../redux/slices/appSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";
import { Page as Document } from "../library";
import useFunctions from "../functions";

export const MissionEight = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [{ setPoints, setAnswer, setOverlay, setPage }] = useFunctions();

    const missionEightData: Dialogues = {
        pleaseCheckTheOpenItems: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Bitte kontrolliere die offenen Posten (offenen Ausgangsrechnungen) und überprüfe, ob wir weitere Schritte setzen müssen.`,
                },

            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setCurrentDialogue('whatDoYouDoNext'))
                dispatch(setTask('whatDoYouDoNext'))
                dispatch(unlockDocument(Document.Ausgangsrechnungen))
                setOverlay(Document.Ausgangsrechnungen)
            }
        },
        whatDoYouDoNext: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Bitte kontrolliere die offenen Posten (offenen Ausgangsrechnungen) und überprüfe, ob wir weitere Schritte setzen müssen.`,
                    children: [1]
                },
                1: {
                    speaker: 'teamlead',
                    text: `Was machst du als Nächstes, nachdem du nun einen Überblick über die Ausgangsrechnungen bekommen hast?`,
                    children: [2, 3]
                },
                2: {
                    speaker: 'player',
                    text: 'Den Mahnplan einsehen.',
                    alternativeText: 'Ich werfe einen Blick auf unseren Mahnplan.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '2-3', label: 'Den Mahnplan einsehen.', correct: true});
                        setPoints({ answer: '2-3', points: 1 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Jene Kunden mit offenen Zahlungen mahnen.',
                    alternativeText: 'Wir sollten jene Kunden mit offenen Zahlungen mahnen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-3', label: 'Jene Kunden mit offenen Zahlungen mahnen.'});
                        setPoints({ answer: '2-3', points: 0 });
                    }
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Genau, mit Hilfe des Mahnplans kannst du herausfinden, ob es bei einem Kunden Handlungsbedarf gibt.'
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Das wäre gar nicht gut. Manche Kunden haben noch Zeit ihren Zahlungen nachzukommen. Öffne zuerst den Mahnplan, um herauszufinden, ob wir Schritte setzen müssen.'
                }

            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                setOverlay(Document.Mahnplan)
                dispatch(unlockDocument(Document.Mahnplan))
                dispatch(setTask('doWeHaveToTakeExtraSteps'))
                dispatch(setCurrentDialogue('doWeHaveToTakeExtraSteps'))
            }
        },
        doWeHaveToTakeExtraSteps: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Müssen wir weitere Schritte setzen?`,
                    children: [1,2]
                },
                1: {
                    speaker: 'player',
                    text: 'Ja',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Ja', correct: true});
                        setPoints({ answer: '1-2', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Nein',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Nein'});
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: 'Ja genau. Bei einem Kunden gibt es Handlungsbedarf.',
                    children: [5],
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Doch, schau noch einmal genau. Bei einem Kunden gibt es Handlungsbedarf.',
                    children: [5],
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Bei welchem Kunden gibt es Handlungsbedarf?',
                    children: [6,7,8,9],
                },
                6: {
                    speaker: 'player',
                    text: 'Werner Neumann GmbH',
                    children: [10],
                    onClick: () => {
                        setAnswer({ id: '6-9', label: 'Werner Neumann GmbH'});
                        setPoints({ answer: '6-9', points: 0 });
                    }
                },
                7: {
                    speaker: 'player',
                    text: 'eRGO GmbH',
                    children: [10],
                    onClick: () => {
                        setAnswer({ id: '6-9', label: 'eRGO GmbH'});
                        setPoints({ answer: '6-9', points: 0 });
                    }
                },
                8: {
                    speaker: 'player',
                    text: 'Christian Neuhofer eU',
                    children: [10],
                    onClick: () => {
                        setAnswer({ id: '6-9', label: 'Christian Neuhofer eU'});
                        setPoints({ answer: '6-9', points: 0 });
                    }
                },
                9: {
                    speaker: 'player',
                    text: 'Zalanka AG',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '6-9', label: 'Zalanka AG', correct: true});
                        setPoints({ answer: '6-9', points: 1 });
                    }
                },
                10: {
                    speaker: 'teamlead',
                    text: 'Nein, der Kunde hat noch Zeit seiner Zahlung nachzukommen. Aber bei der Zalanka AG müssen wir etwas tun.',
                    children: [12],
                },
                11: {
                    speaker: 'teamlead',
                    text: 'Richtig, bei der Zalanka AG müssen wir etwas tun.',
                    children: [12],
                },
                12: {
                    speaker: 'teamlead',
                    text: 'Was sollten wir deiner Meinung nach als Nächstes tun?',
                    children: [13,14,15],
                },
                13: {
                    speaker: 'player',
                    text: 'Ein Erinnerungsschreiben verfassen.',
                    children: [16],
                    onClick: () => {
                        setAnswer({ id: '13-15', label: 'Ein Erinnerungsschreiben verfassen.'});
                        setPoints({ answer: '13-15', points: 0 });
                        dispatch(setNotification('ourReminderPlanShowsThatTheSecondReminderIsNowToBeSent'))
                        setPage('notification')
                    }
                },
                14: {
                    speaker: 'player',
                    text: 'Unseren Rechtsanwalt einschalten.',
                    onClick: () => {
                        setAnswer({ id: '13-15', label: 'Unseren Rechtsanwalt einschalten.'});
                        setPoints({ answer: '13-15', points: 0 });
                        dispatch(setNotification('ourReminderPlanShowsThatTheSecondReminderIsNowToBeSent'))
                        setPage('notification')
                    }
                },
                15: {
                    speaker: 'player',
                    text: 'Eine zweite Mahnung verfassen.',
                    onClick: () => {
                        dispatch(setTask(''))
                        setAnswer({ id: '13-15', label: 'Eine zweite Mahnung verfassen.', correct: true});
                        setPoints({ answer: '13-15', points: 1 });
                        dispatch(setNotification('pleaseShowMeHowToCalculateTheNewPaymentAmount'))
                        setPage('notification')
                    }
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('pleaseShowMeHowToCalculateTheNewPaymentAmount'))
                setPage('image-cloze')
            }
        },
        whatDoYouThinkWeShouldDoNextFailed: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                16: {
                    speaker: 'teamlead',
                    text: 'Was sollten wir deiner Meinung nach als Nächstes tun?',
                    children: [17,18,19],
                },
                17: {
                    speaker: 'player',
                    text: 'Ein Erinnerungsschreiben verfassen.',
                    onClick: () => {
                        setAnswer({ id: '17-19', label: 'Ein Erinnerungsschreiben verfassen.'});
                        setPoints({ answer: '17-19', points: 0 });
                        dispatch(setNotification('ourReminderPlanShowsThatTheSecondReminderIsNowToBeSent'))
                        setPage('notification')
                    }
                },
                18: {
                    speaker: 'player',
                    text: 'Unseren Rechtsanwalt einschalten.',
                    onClick: () => {
                        setAnswer({ id: '17-19', label: 'Unseren Rechtsanwalt einschalten.'});
                        setPoints({ answer: '17-19', points: 0 });
                        dispatch(setNotification('ourReminderPlanShowsThatTheSecondReminderIsNowToBeSent'))
                        setPage('notification')
                    }
                },
                19: {
                    speaker: 'player',
                    text: 'Eine zweite Mahnung verfassen.',
                    onClick: () => {
                        dispatch(setTask(''))
                        setAnswer({ id: '17-19', label: 'Eine zweite Mahnung verfassen.'});
                        setPoints({ answer: '17-19', points: 0 });
                        dispatch(setNotification('pleaseShowMeHowToCalculateTheNewPaymentAmount'))
                        setPage('notification')
                    }
                },
            }
        },
        itsTimeAgainToCheckOpenItems: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Heute ist der 23.12. Es ist wieder an der Zeit, die offenen Posten zu kontrollieren.`,
                },

            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setCurrentDialogue('isThereANeedForAction'))
                dispatch(setTask('isThereANeedForAction'))
                dispatch(unlockDocument(Document.Ausgangsrechnungen2))
                setOverlay(Document.Ausgangsrechnungen2)
            }
        },
        isThereANeedForAction: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Heute ist der 23.12. Es ist wieder an der Zeit, die offenen Posten zu kontrollieren.`,
                    children: [1]
                },
                1: {
                    speaker: 'teamlead',
                    text: `Was stellst du fest? Gibt es Handlungsbedarf?`,
                    children: [2,3,4]
                },
                2: {
                    speaker: 'player',
                    text: 'Die Zalanka AG hat die in der zweiten Mahnung vorgegebene Zahlungsfrist bereits überschritten und es gibt keinen Zahlungseingang.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Die Zalanka AG hat die in der zweiten ' +
                                'Mahnung vorgegebene Zahlungsfrist bereits überschritten und es gibt keinen Zahlungseingang.', correct: true});
                        setPoints({ answer: '2-4', points: 1 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Die Zalanka AG hat die Rechnung innerhalb der in der zweiten Mahnung vorgegebenen Zahlungsfrist beglichen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Die Zalanka AG hat die Rechnung innerhalb der in der zweiten Mahnung vorgegebenen Zahlungsfrist beglichen.'});
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Die Zalanka AG hat noch bis zum Ende der in der zweiten Mahnung vorgegebenen Zahlungsfrist Zeit, die Rechnung zu begleichen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Die Zalanka AG hat noch bis zum Ende der in der ' +
                                'zweiten Mahnung vorgegebenen Zahlungsfrist Zeit, die Rechnung zu begleichen.'});
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: `Ja, genau.`,
                    children: [7]
                },
                6: {
                    speaker: 'teamlead',
                    text: `Nein, die Zahlungsfrist ist bereits abgelaufen und die Zalanka AG hat noch nicht gezahlt.`,
                    children: [7]
                },
                7: {
                    speaker: 'teamlead',
                    text: `Welchen Schritt schlägst du als Nächstes vor?`,
                    children: [8,9,10]
                },
                8: {
                    speaker: 'player',
                    text: 'Ein Erinnerungsschreiben verfassen.',
                    alternativeText: 'Jetzt müssen wir ein Erinnerungsschreiben verfassen.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Ein Erinnerungsschreiben verfassen.'});
                        setPoints({ answer: '8-10', points: 0 });
                    }
                },
                9: {
                    speaker: 'player',
                    text: 'Unseren Rechtsanwalt einschalten.',
                    alternativeText: 'Nun ist es an der Zeit die Rechtsanwaltskanzlei zu informieren.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Unseren Rechtsanwalt einschalten.'});
                        setPoints({ answer: '8-10', points: 0 });
                    }
                },
                10: {
                    speaker: 'player',
                    text: 'Eine letzte Mahnung verfassen.',
                    alternativeText: 'Die Zalanka AG wird noch eine letzte Mahnung von uns erhalten.',
                    children: [12],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Eine letzte Mahnung verfassen.', correct: true});
                        setPoints({ answer: '8-10', points: 1 });
                    }
                },
                11: {
                    speaker: 'teamlead',
                    text: `Das ist nicht richtig. Die Zalanka AG bekommt von uns noch eine letzte Mahnung.`,
                    children: [13]
                },
                12: {
                    speaker: 'teamlead',
                    text: `Ja, genau. Die Zalanka AG bekommt von uns noch eine letzte Mahnung.`,
                    children: [13]
                },
                13: {
                    speaker: 'teamlead',
                    text: `Deine Kollegin Mia hat die letzte Mahnung schon vorbereitet. Sie ist aber noch nicht dazu 
                    gekommen, die Verzugszinsen zu ermitteln. Bitte übernimm du das.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('calculatingDefaultInterest1'))
                setPage('image-cloze')
            }
        },
        pleaseCalculateTheNewPaymentAmount: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Bitte berechne auch gleich den neuen Zahlungsbetrag und stelle anschließend die Mahnung fertig.`,
                },

            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('calculatingTheNewPaymentAmount'))
                setPage('image-cloze')
            }
        },
        pleaseCompleteTheReminderToZalanko: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Danke für die Berechnung der Verzugszinsen. Du findest die Berechnung nun auf deinem Arbeitsplatz. Bitte mach die Mahnung für die Zalanko AG jetzt noch fertig.`,
                },

            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('pleaseCompleteTheReminderToZalanko'))
                setPage('image-cloze')
            }
        },
    }

    return missionEightData;
}
