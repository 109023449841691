import { Type } from "../../document";

export const AufstellungZurGewinnberechnungDone = () => {

    return {
        type: Type.ImageCloze,
        title: 'Lösung: Aufstellung zur Gewinnberechnung',
        src: require('static/images/tasks/mission6/mVe_AufstellungZurGewinnberechnung_done.png'),
    };
}
