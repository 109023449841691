import { Type } from "../../document";
import { useFunctions } from "../../functions";

export const CheckTheInvoiceAndOrderMatchDone = () => {
    const [{ getImage }] = useFunctions();
    const currentYear = new Date().getFullYear();
    const correct = getImage('icn_correct.svg', 40)
    const incorrect = getImage('icn_incorrect.svg', 40)

    return {
        type: Type.ImageCloze,
        title: `Lösung: Abweichungen auf Rechnung markieren`,
        src: require('static/images/tasks/mission9/mBp_AbweichungenMarkieren.png'),
        hotspots: {
            0: {
                x: '22%',
                y: '27.3%',
                labelXAlignment: 'center',
            },
            1: {
                x: '47%',
                y: '23%',
            },
            2: {
                x: '9.3%',
                y: '32%',
                labelXAlignment: 'center',
            },
            3: {
                x: '62%',
                y: '32%',
                labelXAlignment: 'center',
            },
            4: {
                x: '79%',
                y: '32%',
            },
            5: {
                x: '58%',
                y: '69.7%',
                labelXAlignment: 'center',
            },
            6: {
                x: '11%',
                y: '85%',
                labelXAlignment: 'center',
            },
            7: { x:'78%' , y:'19.9%' },
            8: { x:'78%' , y:'22.2%' },
            9: { x:'78%' , y:'24.4%' },
        },
        initialAnswers: {
            0: { label: 'Kein Fehler', alternativeLabel: correct, active: false},
            1: { label: 'Kein Fehler', alternativeLabel: correct, active: false},
            2: { label: 'Pos. 6: Falscher Artikel', alternativeLabel: incorrect, active: false},
            3: { label: 'Pos. 4: Falsche Menge', alternativeLabel: incorrect, active: false},
            4: { label: 'Pos. 1: Falscher Stückpreis', alternativeLabel: incorrect, active: false},
            5: { label: 'Lieferung sollte gratis erfolgen', alternativeLabel: incorrect, active: false},
            6: { label: 'Kein Fehler', alternativeLabel: correct, active: false},
            7: { label: `<b>13539/${currentYear}</b>`, active: false},
            8: { label: `${currentYear}-11-13`, active: false},
            9: { label: `85464/${currentYear}`, active: false},
        },
    };
}
