import { Type } from "../../document";
import {currentYear} from "../variables";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";

export const ZweiteMahnungLetterDone = () => {
    const { firstName, lastName } = useSelector((state: RootState) => state.user);

    return {
        type: Type.ImageCloze,
        title: 'Lösung: Zweite Mahnung verfassen',
        src: require('static/images/tasks/mission8/mMe_ZweiteMahnungVerfassen.png'),
        hotspots: {
            0: {
                x: '13%',
                y: '20%',
                labelXOffset: '-8%',
            },
            1: {
                x: '13%',
                y: '34%',
                labelXOffset: '-8%',
            },
            2: {
                x: '13%',
                y: '41%',
                labelXOffset: '-8%',
            },
            3: {
                x: '81.7%',
                y: '54.8%',
                labelXAlignment: 'center',
            },
            4: {
                x: '85%',
                y: '68%',
                labelXAlignment: 'right',
                labelXOffset: '3%',
            },
            5: {
                x: '85%',
                y: '71.8%',
                labelXAlignment: 'right',
                labelXOffset: '3%',
            },
            6: {
                x: '85%',
                y: '75.5%',
                labelXAlignment: 'right',
                labelXOffset: '3%',
            },
            7: {x: '76%', y: '31%',},
            8: {x: '5.5%', y: '84%',}
        },
        initialAnswers: {
            0: {label: 'Zalanka AG', active: false,
                children: [
                    { x: '5%', y: '22.8%', label: 'Wiedner Hauptstraße 118'},
                    { x: '4.8%', y: '26%', label: '1050 Wien'},
                ],
            },
            1: {label: '2. Mahnung', active: false},
            2: {label: 'Re-Nr.: 1835 [Zalanka AG]', active: false,
                alternativeLabel: `Unsere Rechnung Nr. 1835 vom 21.10.${currentYear} über 13.668 Euro ist bereits <br/>seit 4.11.${currentYear} fällig.`,
                children: [
                    { x: '5%', y: '48%', label: `Am 19.11.${currentYear} erinnerten wir Sie leider erfolglos an die ausständige <br/>Zahlung.`},
                ],
            },
            3: {label: `18.12.${currentYear}`, active: false},
            4: {label: `13.668,00`, active: false},
            5: {label: '11,00', active: false},
            6: {label: '13.679,00', active: false},
            7: {label: `04.12.${currentYear}`, active: false},
            8: {label: `${firstName} ${lastName}`, active: false},
        },
    };
}
