import React from 'react';
import ReactHtmlParser from "react-html-parser";

import styles from './Choice.module.scss';
import {Hotspot} from "../ImageCloze";

export type Option = {
  label: string;
  onClick?: (e: any) => void;
}

export type Props = {
  hasClipboard?: boolean;
  onClipboardClick?: () => void;
  secondAttempt?: Hotspot;
  secondAttemptOptions?: Option[];
  className?: string;
  options?: Option[];
}

const Choice = ({
  className = '',
  hasClipboard,
  onClipboardClick,
  options,
}: Props) => (
  <ul className={`${styles.container} ${className}`}>
    {options?.map((option, index) => <li key={index} onClick={option.onClick} className={styles.option}>{option.label}</li>)}
    {hasClipboard && <li key={'clipboard'} onClick={onClipboardClick} className={styles.option}>
      Aus {ReactHtmlParser('<i class="lnr lnr-clipboard-empty"/>')} einfügen
    </li>}
  </ul>
);

export default Choice;