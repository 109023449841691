import { Type } from "../../document";
import {currentYear} from "../variables";
import {setClipboardEntry} from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const K87Clipboard = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Beleg K 87`,
        src: require('static/images/tasks/mission12/mKe_K87.png'),
        hotspots: {
            0: {x: '22.7%', y: '22.5%'},
            1: { x: '52%', y: '78.6%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 87',
                        order: 1,
                        title: '7600 (Soll)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '5,81'}))
                } },
            2: {x: '48%', y: '78.9%'},
            3: { x: '52%', y: '81.6%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 87',
                        order: 2,
                        title: '7500 (Soll)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '1,16'}))
                } },
            4: {x: '48%', y: '81.9%'},
            5: { x: '70%', y: '84.6%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 87',
                        order: 3,
                        title: '2700 (Haben)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '6,97'}))
                } },
            6: {x: '65.3%', y: '84.9%'},
        },
        initialAnswers: {
            0: {label: `Datum: 12.11.${currentYear}`, active: false},
            2: {label: `<b>5,81</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            4: {label: `<b>1,16</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            6: {label: `<b>6,97</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
        }
    };
}
