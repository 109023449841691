import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import styles from './Calculator.module.scss';
import {computeCalculatorEnglishValue} from "../../helpers";
import ReactHtmlParser from "react-html-parser";
import {
  setAllowClipboardCopy,
  setClipboardCurrent,
  setClipboardType,
  setOverlay as setCurrentOverlay
} from "../../redux/slices/appSlice";
import {Type} from "../HotspotChoice";
import {RootState} from "../../redux/rootReducer";
import {emptyAnswerValue} from "../MiniCalculator";
import {Option} from "../Calendar";

export type Props = {
  className?: string;
  options?: Option[];
  onEnter?: (value: string) => void;
};

const Calculator = ({
  className,
  options,
  onEnter,
}: Props) => {
  const dispatch = useDispatch();
  const { clipboardCurrent } = useSelector((state: RootState) => state.app);
  const operators = ['/', '*', '-', '+'];
  const clipboardButton = 'aus <i class="lnr lnr-clipboard-empty"></i> einfügen'

  const buttons = [
    { label: 'AC', style: { gridArea: 'h' }, className: styles.dense },
    { label: clipboardButton,  style: { gridArea: 'i' }, className: styles.dense },

    { label: '+', className: styles.operators },
    { label: '-', className: styles.operators },
    { label: '/', className: styles.operators },
    { label: '*', className: styles.operators },

    { label: '7', className: styles.numblock },
    { label: '8', className: styles.numblock },
    { label: '9', className: styles.numblock },
    { label: '(', className: styles.brackets },

    { label: '4', className: styles.numblock },
    { label: '5', className: styles.numblock },
    { label: '6', className: styles.numblock },
    { label: ')', className: styles.brackets },

    { label: '1', className: styles.numblock },
    { label: '2', className: styles.numblock },
    { label: '3', className: styles.numblock },

    { label: '0', className: styles.numblock,  style: { gridArea: 'e' } },
    { label: ',', className: styles.numblock,  style: { gridArea: 'f' }  },
  ];

  const [value, setValue] = useState('');
  const valueRef = React.useRef(value);

  const calculate = (value: string) => {
    try {
      return parseFloat(Function(`return ${value}`)()).toFixed(2); // eslint-disable-line no-new-func
    } catch {
      return value;
    }
  };

  /**
   * Function for the equals button/enter button
   */
  const handleEquals = useCallback(() => {
    const sanitized = valueRef.current.replace(/[^-\d/*+,()]/g, '');
    const lastSanitizedChar = sanitized.slice(-1);

    if (!(operators.includes(lastSanitizedChar) || lastSanitizedChar === ',')) {
      const englishValue = computeCalculatorEnglishValue(valueRef.current);
      const calculatedValue = '' + calculate(englishValue.toString() !== '' ? englishValue.toString() : '0');

      const actualValue = Intl.NumberFormat('de-DE', {minimumFractionDigits: 2})
          .format(parseFloat(calculatedValue.toString().replace(/ /g, '\u00a0')))

      if (!isNaN(parseFloat(actualValue))) {
        setValue(actualValue);
        onEnter && onEnter(actualValue);
      } else {
        setValue('0,00')
        onEnter && onEnter('0,00');
      }
    }

    dispatch(setClipboardCurrent(null))
  }, [dispatch, onEnter, operators])

  useEffect(() => {
    if(clipboardCurrent !== null) {
      valueRef.current = clipboardCurrent
    }
    setValue(clipboardCurrent || "");
  }, [clipboardCurrent]);

  const handleClick = (newValue: string) => {
    switch (newValue) {
      case 'AC':
        window._paq.push(['trackEvent', 'Interaction', 'Calculator', 'Pressed Button']);
        setValue('');
        valueRef.current = ''
        break;

      case 'Betrag einfügen':
        handleEquals()
        break;

      case clipboardButton:
        window._paq.push(['trackEvent', 'Interaction', 'Calculator', 'Opened Clipboard']);
        dispatch(setClipboardCurrent(value))
        dispatch(setCurrentOverlay('clipboard'));
        dispatch(setAllowClipboardCopy(true))
        dispatch(setClipboardType(Type.Calculator))

        break;

      default:
        window._paq.push(['trackEvent', 'Interaction', 'Calculator', 'Pressed Button']);
        const lastChar = value.slice(-1);
        if (value === 'Infinity' || value === '') {
          setValue('' + newValue);
          valueRef.current = '' + newValue;
        } else if (operators.includes(lastChar) && operators.includes(newValue)) {
          setValue(value.slice(0, -1) + newValue);
          valueRef.current = value.slice(0, -1) + newValue
        } else if(['/', '*'].includes(newValue) && !value) {
          return;
        } else {
          setValue(value + newValue);
          valueRef.current = value + newValue
        }
    }
  }

  const addEmptyAnswers = () => {
    onEnter && onEnter(emptyAnswerValue)
  }

  useEffect(() => {
    const keyboardFunction = (event: KeyboardEvent) => {
      if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '(', ')', '*', '-', '+', '/', ','].includes(event.key)) {
        valueRef.current = valueRef.current + event.key;
        setValue(valueRef.current)

      } else if(event.key === 'Enter') {
        handleEquals()

      } else if(event.key === 'Backspace') {
        valueRef.current = valueRef.current.slice(0, -1);
        setValue(valueRef.current)
      }
    }

    document.addEventListener("keyup", keyboardFunction, false);

    return () => {
      document.removeEventListener("keyup", keyboardFunction, false);
    };
  }, [handleEquals]);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.display}>
        <input placeholder='0' value={value} readOnly />
      </div>
      <div className={styles.buttonsContainer}>
        {buttons.map(({label, className, style}, i) =>
          <button
            key={i}
            className={className || ''}
            style={style || {}}
            onClick={() => { handleClick(label); }}
          >
            {ReactHtmlParser(label)}
          </button>
        )}
      </div>
      <div className={styles.optionButtonsContainer}>
        {options?.map(({label, onClick}, i) =>
            <button
                key={`option-${i}`}
                className={styles.dense}
                style={{}}
                onClick={onClick}
            >
              {ReactHtmlParser(label)}
            </button>
        )}
        <button
            key={`option-empty`}
            className={styles.dense}
            style={{}}
            onClick={addEmptyAnswers}
        >
          Zelle leer lassen
        </button>
      </div>
      <button
          key={`option-submit`}
          className={styles.primary}
          style={{}}
          onClick={handleEquals}
      >
        Betrag einfügen
      </button>
    </div>
  );
};

export default Calculator;
