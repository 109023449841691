import React from 'react';

import styles from './SimpleFeedbackList.module.scss';

enum Status {
  Incorrect,
  Correct,
}

type Item = {
  label: string;
  status: Status;
};

type Props = {
  title: string;
  items: Item[];
  className?: string;
};

const SimpleFeedbackList = ({
  title,
  items,
  className = '',
}: Props) => {
  const status = {
    [Status.Correct]: `Gut gemacht, du hast den Beleg korrekt verbucht.`,
    [Status.Incorrect]: `Der Buchungssatz stimmt so leider nicht.`,
  };

  const isCorrect = () => {
    let wrongAnswers = 0;
    items.forEach(item => {
      if(item.status === Status.Incorrect) {
        wrongAnswers++;
      }
    })

    return wrongAnswers === 0 ? Status.Correct: Status.Incorrect;
  }

  return (
      <div className={`${styles.container} ${className}`}>
        <h2>{title}</h2>
        <p>{status[isCorrect()]}</p>
      </div>
  );
}

export default SimpleFeedbackList;