import React from 'react';

import styles from './Bubble.module.scss';

export type Props = {
  className?: string,
  title?: string,
  text: string,
}

const Bubble = ({
  className = '',
  title,
  text,
}: Props) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <span className={styles.title}>{title}</span>
      <p>{text}</p>
    </div>
  );
}

export default Bubble;