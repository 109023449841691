import { createSlice, ThunkAction, Action, PayloadAction } from '@reduxjs/toolkit';

import history from 'lib/history';
import { RootState } from 'redux/rootReducer';

type ReachedModuleScore = {
  [mission: string]: boolean;
}

const historyLimt = 10;

export const slice = createSlice({
  name: 'app',
  initialState: {
    history: ['/'],
    nextPage: null as string | null,
    module: 1,
    mission: 1,
    unlockedMissions: [] as number[],
    scene: 'workstation',
    dialogue: 'morning',
    notification: 'inquiry',
    tutorial: '',
    tutorialSlide: 0,
    feedback: '',
    unlockedDocuments: ['notepad'],
    unlockedMenu: ['home'], // home, library, notepad, joker, clipboard
    highlightMenu: [''],
    showDocumentButton: false,
    overlay: null as string | null,
    notes: '',
    showNotepad: false,
    wasJokerUsed: false,
    isFinished: false,
    isFinishedModule2: false,
    isOfflineStatusConfirmed: false,
    showHint: false,
    hint: 0,
    showSurveyPopUp: false,
    allowClipboardCopy: false,
    clipboardCurrent: null as string | null,
    clipboardType: '',
    moduleScore: 1,
    reachedModuleScore: {
      1: false,
      2: false
    } as ReachedModuleScore,
  },
  reducers: {
    goBackInHistory: (state) => {
      const entries = [...state.history];
      entries.splice(entries.length -1, 1);

      state.history = entries;
    },
    setHistory: (state, action: PayloadAction<string>) => {
      const entries = [...state.history, action.payload];
      if (entries.length > historyLimt) {
        entries.splice(0, 1);
      }

      state.history = entries;
    },
    setNextPage: (state, action: PayloadAction<string | null>) => { state.nextPage = action.payload; },
    setModule: (state, action: PayloadAction<number>) =>  { state.module = action.payload; },
    setMission: (state, action: PayloadAction<number>) =>  { state.mission = action.payload; },
    setScene: (state, action: PayloadAction<string>) => { state.scene = action.payload; },
    setDialogue: (state, action: PayloadAction<string>) => { state.dialogue = action.payload; },
    setNotification: (state, action: PayloadAction<string>) => { state.notification = action.payload; },
    setTutorial: (state, action: PayloadAction<string>) => { state.tutorial = action.payload; },
    setTutorialSlide: (state, action: PayloadAction<number>) => { state.tutorialSlide = action.payload; },
    setFeedback: (state, action: PayloadAction<string>) => { state.feedback = action.payload; },
    setNotes: (state, action: PayloadAction<string>) => { state.notes = action.payload; },
    setShowNotepad: (state, action: PayloadAction<boolean>) => { state.showNotepad = action.payload; },
    setOverlay: (state, action: PayloadAction<string | null>) => { state.overlay = action.payload; },
    setShowDocumentButton: (state, action: PayloadAction<boolean>) => { state.showDocumentButton = action.payload; },
    unlockDocument: (state, action: PayloadAction<string>) => {
      const entries = [...state.unlockedDocuments];

      if (entries.indexOf(action.payload) === -1) {
        entries.push(action.payload);
      }

      state.unlockedDocuments = entries; 
    },
    setMenu: (state, action: PayloadAction<string[]>) => { state.unlockedMenu = action.payload; },
    setHighlightMenu: (state, action: PayloadAction<string[]>) => { state.highlightMenu = action.payload; },
    setUnlockedMissions: (state, action: PayloadAction<number[]>) =>  {state.unlockedMissions = action.payload; },
    setWasJokerUsed: (state, action: PayloadAction<boolean>) => { state.wasJokerUsed = action.payload; },
    setFinished: (state, action: PayloadAction<boolean>) => { state.isFinished = action.payload; },
    setFinished2: (state, action: PayloadAction<boolean>) => { state.isFinishedModule2 = action.payload; },
    confirmOfflineStatus: (state, action: PayloadAction<boolean>) => { state.isOfflineStatusConfirmed = action.payload; },
    setShowHint: (state, action: PayloadAction<boolean>) => { state.showHint = action.payload; },
    setHint: (state, action: PayloadAction<number>) => { state.hint = action.payload; },
    setShowSurveyPopUp: (state, action: PayloadAction<boolean>) => { state.showSurveyPopUp = action.payload; },
    setAllowClipboardCopy: (state, action: PayloadAction<boolean>) => { state.allowClipboardCopy = action.payload; },
    setClipboardCurrent: (state, action: PayloadAction<string | null>) => { state.clipboardCurrent = action.payload; },
    setClipboardType: (state, action: PayloadAction<string>) => { state.clipboardType = action.payload; },
    setModuleScore: (state, action: PayloadAction<number>) => { state.moduleScore = action.payload; },
    setReachedModuleScore: (state, action:  PayloadAction<{module: number;}>) => {
      const entries = { ...state.reachedModuleScore };
      entries[action.payload.module] = true;

      state.reachedModuleScore = entries;
    },
  },
});

type CustomThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const goBack = (): CustomThunk => (dispatch) => {
  history.goBack();
  dispatch(goBackInHistory());
};

export const setPage = (page: string): CustomThunk => (dispatch) => {
  history.push(page);
  dispatch(setHistory(page));
};

// These are some cheat codes during development
// @ts-ignore;
window.__goBack = goBack;
// @ts-ignore;
window.__setPage = setPage;

export const {
  goBackInHistory,
  setHistory,
  setNextPage,
  setModule,
  setMission,
  setScene,
  setDialogue,
  setNotification,
  setTutorial,
  setTutorialSlide,
  setFeedback,
  setNotes,
  setShowNotepad,
  setOverlay,
  setShowDocumentButton,
  unlockDocument,
  setMenu,
  setHighlightMenu,
  setUnlockedMissions,
  setWasJokerUsed,
  setFinished,
  setFinished2,
  confirmOfflineStatus,
  setShowHint,
  setHint,
  setShowSurveyPopUp,
  setAllowClipboardCopy,
  setClipboardCurrent,
  setClipboardType,
  setModuleScore,
  setReachedModuleScore
} = slice.actions;

export default slice.reducer;
