import { Type } from "../../document";
import {currentYear} from "../variables";
import {setClipboardEntry} from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const Verzugszinsenberechnung2 = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Verzugszinsenberechnung - Teil 2`,
        src: require('static/images/tasks/mission8/mMe_VerzugszinsenBerechnen2.png'),
        hotspots: {
            1: {x: '74.5%', y: '10%'},
            2: {x: '78.5%', y: '43.5%'},
            3: {x: '78.9%', y: '49%'},
            4: {x: '79%', y: '89%'},
            5: { x: '88%', y: '88.5%', width: '18%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Verzugszinsenberechnung`,
                        order: 3,
                        title: 'Verzugszinsen',
                        isEnabled: true,
                        isPercentage: false,
                        value: '212,61'}))
                } },
        },
        initialAnswers: {
            1: {label: `Heute: 23.12.${currentYear}`, active: false},
            2: {label: `von: 05.11.${currentYear}`, active: false},
            3: {label: `bis: 30.12.${currentYear}`, active: false},
            4: {label: `<b>212,61 EUR</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
        }
    };
}
