import { Type } from "../../document";

export const GewinnBerechnenDone = () => {

    return {
        type: Type.ImageCloze,
        title: 'Gewinnberechnung',
        src: require('static/images/tasks/mission6/mVe_GewinnBerechnen_done.png'),
    };
}
