import React from 'react';

import styles from './DocumentHeader.module.scss';

type Props = {
  title: string;
  onBack?: () => void;
  onClose?: () => void;
}

const Header = ({
  title,
  onBack,
  onClose,
}: Props) => {
  const close = () => {
    onClose && onClose();
  }

  return (
    <div className={styles.container}>
      <i className={`lnr lnr-chevron-left ${onBack ? '' : styles.hide}`} onClick={onBack}/>
      <span>{title}</span>
      <i className={`lnr lnr-cross2 ${styles.closeButton}`} onClick={close}/>
    </div>
  );
};

export default Header;