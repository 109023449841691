import React from 'react';

import packageInfo from '../../../package.json';

import styles from './Privacy.module.scss';

const Page = () => {
  return (
    <div className={styles.container}>
      <h2>Datenschutz</h2>

      <h3>Datenschutzerklärung</h3>
      <p>
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. 
        Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). 
        In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung 
        im Rahmen unserer Website.
      </p>
      <p>
        Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst.
        Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar.
        Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht weiterverarbeitet
      </p>

      <h3>Kontakt mit uns</h3>
      <p>
        Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, 
        werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen
        sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      </p>

      <h3>Cookies, localStorage und indexedDB</h3>
      <p>
        Unsere Website verwendet so genannte Cookies sowie den localStorage und die indexedDB ihres Browsers.
        Dabei handelt es sich um Technologien, die mit Hilfe des Browsers auf Ihrem Endgerät Informationen speichern können.
        Sie richten keinen Schaden an.
      </p>
      <p>
        Wir nutzen diese Technologien, unser Angebot nutzerfreundlich zu gestalten.
        Einige dieser Informationen bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen.
        Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen 
        von Cookies informiert und Sie dies nur im Einzelfall erlauben.
      </p>
      <p>
        Bei der Deaktivierung von Cookies sowie des localStorage und der indexedDB kann die Funktionalität
        unserer Website eingeschränkt sein.
      </p>

      <h3>Ihre Rechte</h3>
      <p>
        Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft,
        Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu.
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre 
        datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, 
        können Sie sich bei uns (<a href='mailto:support.kompetentimoffice@ovos.at'>support.kompetentimoffice@ovos.at</a>) oder der Datenschutzbehörde beschweren.
      </p>

      <h3>Kontakt</h3>
      <ul>
        <li>ovos media gmbh</li>
        <li>Schottenfeldgasse 60/36-38, 1070 Wien</li>
        <li>Telefon: <a href='tel:+43 1 890 33 89'>+43 1 890 33 89</a></li>
        <li>E-Mail: <a href='mailto:support.kompetentimoffice@ovos.at'>support.kompetentimoffice@ovos.at</a></li>
        <li>Web: <a rel='noopener noreferrer' target='_blank' href='https://ovos.at/'>www.ovos.at</a></li>
      </ul>
      <p>Stand 11.2022</p>
      <p>App Version: {packageInfo.version}</p>
    </div>
  );
}

export default Page;
