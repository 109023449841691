import React from 'react';
import { useDispatch } from 'react-redux';

import {setMission, setNotification} from 'redux/slices/appSlice'

import Button from 'components/Button';

import styles from './Start.module.scss';
import useFunctions from "../../hooks/functions";

const Page = () => {
  const dispatch = useDispatch();
    const [{ setPage }] = useFunctions();

  const goToNextPage = () => {
    window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Started']);
    dispatch(setMission(0))
    dispatch(setNotification('intro'));
    setPage('notification');
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img alt='wkö-logo' src={require('static/images/logo.png') } />
      </div>
      <div className={styles.titleContainer}>
        <h1>Kompetent <br/>im Office</h1>
      </div>
      <img className={styles.portraits} alt='portraits' src={require('static/images/portraits.png')} />
      <Button className={styles.button} label={`Los geht's`} onClick={goToNextPage} />
    </div>
  );
};

export default Page;
