import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import AvatarSelection from 'components/AvatarSelection';
import Input from 'components/Input';

import { setTutorial, setTutorialSlide} from 'redux/slices/appSlice';
import { setFirstName, setLastName, setImage } from 'redux/slices/userSlice';
import { RootState } from 'redux/rootReducer';

import styles from './User.module.scss';
import useFunctions from "../../hooks/functions";

const Page = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, image } = useSelector((state: RootState) => state.user);
  const [{ setPage }] = useFunctions();

  const setUserFirstName = (name: string) => {
    dispatch(setFirstName(name));
  };

  const setUserLasttName = (name: string) => {
    dispatch(setLastName(name));
  };

  const setUserImage = (image: string) => {
    dispatch(setImage(image));
  };

  const goToNextPage = () => {
    if (firstName && lastName && image) {
      dispatch(setTutorial('main'));
      dispatch(setTutorialSlide(1));
      setPage('tutorial');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Erstelle deinen Charakter</h2>
        <AvatarSelection onChange={setUserImage} defaultAvatar={image} />
        <Input className={styles.input} label='Vorname' onChange={setUserFirstName} defaultValue={firstName} maxLength={12} />
        <Input className={styles.input} label='Nachname' onChange={setUserLasttName} defaultValue={lastName} maxLength={12} />
        <Button className={styles.button} label='Fertig'  locked={!firstName || !lastName || !image} onEnter={goToNextPage} onClick={goToNextPage} />
      </div>
    </div>
  );
}

export default Page;