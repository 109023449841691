import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFetch } from 'hooks/fetch';

import Button from 'components/Button';
import HighscoreList, { Item } from 'components/HighscoreList';
import LoadingScreen from 'components/LoadingScreen';
import PopUp from 'components/PopUp';

import { RootState } from 'redux/rootReducer';
import { setShowSurveyPopUp } from 'redux/slices/appSlice'

import styles from './Highscore.module.scss';

type Entry = Item & {
  id: number;
};

type HighscoreEntry = {
  id: number;
  username: string;
  score: number;
  time: number;
  module?: number
};

const Page = () => {
  const { moduleScore, module } = useSelector((state: RootState) => state.app);
  const { response, isLoading } = useFetch(`${process.env.REACT_APP_SERVICE_URL}/highscore?module=${moduleScore}`);

  const { id: userId } = useSelector((state: RootState) => state.user);
  const showSurveyPopUp = useSelector(({ app }: RootState) => app.showSurveyPopUp);

  const dispatch = useDispatch();

  const [unit] = useState('Punkte');
  const [items, setItems] = useState<Entry[]>([]);

  useEffect(() => {
    if (response) {
      setItems(sortItems());
    }
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortItems = () => {
    const data: HighscoreEntry[] = (response as any).data;

    // @ts-ignore
    return data.map(((element, i) => (
        {
          id: element.id,
          label: element.username,
          score:  element['score'],
          time: secondsToMinutes(element['time']) ,
          highlight: element.id === userId,
          rank: i + 1
        }))) as Entry[];
  }

  const getMe = () => {
    return items.filter(item => item.id === userId);
  };

  const secondsToMinutes = (seconds: number) => {
    let days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    let hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs*3600;
    hrs += days*24
    let mins = Math.floor(seconds / 60);
    return `${hrs}h ${mins}min`;
  }

  const me = getMe();

  const closePopUp = () => {
    dispatch(setShowSurveyPopUp(false));
  };

  const goToSurvey = () => {
    window._paq.push(['trackEvent', 'Survey', 'Survey Link Clicked']);
    closePopUp();
    if(module === 1){
      window.open('https://forms.gle/dkQWBKw3KduJ1DRHA', 'blank');
    } else if (module === 2){
      window.open('https://forms.gle/z9XJLDwqJWRsV3R78', 'blank');
    }

  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {
        showSurveyPopUp &&
        <PopUp
          icon='thumbs-up'
          title='Danke fürs Spielen!'
          text={`Bitte hilf uns „Kompetent im Office“ noch besser zu machen,
          indem du diesen kurzen Fragebogen ausfüllst. Das dauert maximal 2 Minuten, versprochen.`}
          portalId='container'
          label='Zum Fragebogen'
          onClick={goToSurvey}
        >
          <Button className={styles.button} label='Vielleicht Später' onClick={closePopUp} />
        </PopUp>
      }
      {me.length > 0 && <HighscoreList title='Dein Rang' unit={unit} items={me} className={styles.noShrink} />}
      <HighscoreList title='Rangliste' unit={unit} items={items} />
    </>
  );
}

export default Page;