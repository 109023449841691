import { useDispatch, useSelector } from "react-redux";

import { Page as Document } from "../library";
import { Dialogues } from "./datatypes";
import { RootState } from "../../redux/rootReducer";
import { teamleadImage, jokerImage } from "./variables";

import {
    setDialogue as setCurrentDialogue,
    setNextPage,
    setNotification,
    setShowDocumentButton,
    setHint,
    setShowHint,
    unlockDocument
} from "../../redux/slices/appSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";

import { useFunctions } from "../functions";

export const MissionFive = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [{ setPoints, setAnswer, setOverlay, setPage }] = useFunctions();

    const missionFiveData: Dialogues = {
        compareMemoBoardOffers: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Die Nachfrage nach eleganten Memoboards aus Glas steigt. Wir wollen daher ein entsprechendes Produkt ins Sortiment aufnehmen. Ich habe eine Anfrage nach einem geeigneten Produkt an verschiedene Lieferanten geschickt. Bitte prüfe, welches Angebot preislich das beste ist.`,
                    children: [1, 2, 3],
                },
                1: {
                    speaker: 'player',
                    text: 'Unsere Anfrage ansehen.',
                    alternativeText: 'Ich sehe mir unsere Anfrage an.',
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'unsere Anfrage ansehen', correct:true });
                        setPoints({ answer: '1-3', points: 1 });
                        dispatch(setTask('whatsNext'));
                        dispatch(setShowDocumentButton(true));
                        dispatch(setCurrentDialogue('whatsNext'))
                        dispatch(setNextPage('dialogue'))
                        dispatch(unlockDocument(Document.Produktanfrage));
                        setOverlay(Document.Produktanfrage);
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Die Bestellung vornehmen.',
                    alternativeText: 'Ich nehme die Bestellung vor.',
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Bestellung vornehmen' });
                        setPoints({ answer: '1-3', points: 0 });
                        dispatch(setNotification('itStillTakesAWhile'))
                        setPage('notification')
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Die Angebote der Lieferanten ansehen.',
                    alternativeText: 'Ich sehe mir die Angebote der Lieferanten an.',
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Angebote der Lieferanten ansehen' });
                        setPoints({ answer: '1-3', points: 0 });
                        dispatch(setNotification('firstLookAtRequirements'))
                        setPage('notification')
                    }
                },
            },
        },

        whatsNext: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Was machst du als Nächstes?`,
                    children: [1, 2],
                },
                1: {
                    speaker: 'player',
                    text: 'Die Einstandspreise kalkulieren.',
                    alternativeText: 'Ich werde nun die Einstandspreise kalkulieren.',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Einstandspreise kalkulieren' });
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Die Angebote der Lieferanten ansehen.',
                    alternativeText: 'Ich sehe mir nun die Angebote der Lieferanten an.',
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Angebote durchsehen',  correct: true });
                        setPoints({ answer: '1-2', points: 1});
                        dispatch(setHint(1));
                        dispatch(setShowHint(true));
                        dispatch(setCurrentDialogue('doesPutschMeetTheRequirements'))
                        dispatch(setTask('doesPutschMeetTheRequirements'));
                        dispatch(setShowDocumentButton(true));
                        dispatch(unlockDocument(Document.AngebotPutsch));
                        setOverlay(Document.AngebotPutsch);
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: 'Das machen wir später. Schau bitte zuerst, ob die Angebote überhaupt unseren Anforderungen entsprechen.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('doesPutschMeetTheRequirements'));
                dispatch(setHint(1));
                dispatch(setShowHint(true));
                dispatch(setCurrentDialogue('doesPutschMeetTheRequirements'))
                dispatch(setShowDocumentButton(true));
                dispatch(unlockDocument(Document.AngebotPutsch));
                setOverlay(Document.AngebotPutsch);
            }
        },

        doesPutschMeetTheRequirements: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                },
                joker: {
                    name: 'Teamkollegin',
                    image: jokerImage,
                    orientation: Orientation.Left,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Was machst du als Nächstes?`,
                    children: [1],
                },
                1: {
                    speaker: 'player',
                    text: 'Ich sehe mir nun die Angebote der Lieferanten an.',
                    children: [2],
                },
                2: {
                    speaker: 'teamlead',
                    text: 'Entspricht das Angebot der Firma Putsch unseren Anforderungen?',
                    children: [3]
                },
                3: {
                    speaker: 'joker',
                    text: 'Psst! Du kannst dir unsere Anfrage und das Angebot des Lieferanten jederzeit auf deinem Arbeitsplatz nochmal ansehen!',
                    children: [4, 5]
                },
                4: {
                    speaker: 'player',
                    text: 'Ja, das Angebot entspricht unseren Anforderungen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '4-5', label: 'Ja',  correct: true });
                        setPoints({ answer: '4-5', points: 1});
                    }
                },
                5: {
                    speaker: 'player',
                    text: 'Nein, das Angebot entspricht nicht unseren Anforderungen.',
                    children: [7],
                    onClick: () => {
                        setAnswer({ id: '4-5', label: 'Nein'});
                        setPoints({ answer: '4-5', points: 0});
                    }
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Du liegst richtig! Das Angebot der Firma Putsch entspricht unseren Anforderungen.',
                },
                7: {
                    speaker: 'teamlead',
                    text: 'Du liegst falsch! Das Angebot der Firma Putsch entspricht unseren Anforderungen.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('doesDoberMeetTheRequirements'));
                dispatch(setNextPage('dialogue'));
                dispatch(setCurrentDialogue('doesDoberMeetTheRequirements'))
                dispatch(unlockDocument(Document.AngebotDober));
                setOverlay(Document.AngebotDober);
            }
        },

        doesDoberMeetTheRequirements: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                },
                joker: {
                    name: 'Teamkollegin',
                    image: jokerImage,
                    orientation: Orientation.Left,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Entspricht das Angebot der Firma Dober unseren Anforderungen?`,
                    children: [1],
                },
                1: {
                    speaker: 'joker',
                    text: 'Vergiss nicht: Wenn du Hilfe brauchst, kannst du mich einmal pro Mission fragen. Klicke einfach auf mein Symbol in der Menüleiste.',
                    children: [2, 3]
                },
                2: {
                    speaker: 'player',
                    text: 'Ja, das Angebot entspricht unseren Anforderungen.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '2-3', label: 'Ja',  correct: true });
                        setPoints({ answer: '2-3', points: 1});
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Nein, das Angebot entspricht nicht unseren Anforderungen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-3', label: 'Nein'});
                        setPoints({ answer: '2-3', points: 0});
                    }
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Stimmt! Auch das Angebot der Firma Dober entspricht unseren Anforderungen.',
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Stimmt leider nicht! Auch das Angebot der Firma Dober entspricht unseren Anforderungen.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('doesMellerMeetTheRequirements'));
                dispatch(setNextPage('dialogue'));
                dispatch(setCurrentDialogue('doesMellerMeetTheRequirements'))
                dispatch(unlockDocument(Document.AngebotMeller));
                setOverlay(Document.AngebotMeller);
            }
        },

        doesMellerMeetTheRequirements: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Entspricht das Angebot der Firma Meller unseren Anforderungen?`,
                    children: [1,2],
                },
                1: {
                    speaker: 'player',
                    text: 'Ja, das Angebot entspricht unseren Anforderungen.',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Ja' });
                        setPoints({ answer: '1-2', points: 0});
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Nein, das Angebot entspricht nicht unseren Anforderungen.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Nein',  correct: true });
                        setPoints({ answer: '1-2', points: 1});
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: 'Falsch, das Angebot der Firma Meller entspricht nicht unseren Anforderungen.',
                    children: [5],
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Richtig, das Angebot der Firma Meller entspricht nicht unseren Anforderungen.',
                    children: [5],
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Was passt an dem Angebot nicht?',
                    children: [6, 7, 8],
                },
                6: {
                    speaker: 'player',
                    text: 'Das Produkt kann nicht rechtzeitig geliefert werden.',
                    children: [9],
                    onClick: () => {
                        setAnswer({ id: '6-8', label: 'Das Produkt kann nicht rechtzeitig geliefert werden.',  correct: true });
                        setPoints({ answer: '6-8', points: 1});
                    }
                },
                7: {
                    speaker: 'player',
                    text: 'Das Produkt entspricht nicht den Anforderungen.',
                    children: [10],
                    onClick: () => {
                        setAnswer({ id: '6-8', label: 'Das Produkt entspricht nicht den Anforderungen.' });
                        setPoints({ answer: '6-8', points: 0});
                    }
                },
                8: {
                    speaker: 'player',
                    text: 'Der Preis ist zu hoch.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '6-8', label: 'Der Preis ist zu hoch.' });
                        setPoints({ answer: '6-8', points: 0});
                    }
                },
                9: {
                    speaker: 'teamlead',
                    text: 'Ja, genau!',
                    children: [12]
                },
                10: {
                    speaker: 'teamlead',
                    text: 'Du liegst leider falsch. Das Produkt kann nicht rechtzeitig geliefert werden.',
                    children: [12]
                },
                11: {
                    speaker: 'teamlead',
                    text: 'Du liegst leider falsch. Das Produkt kann nicht rechtzeitig geliefert werden.',
                    children: [12]
                },
                12: {
                    speaker: 'teamlead',
                    text: 'Was machst du als Nächstes?',
                    children: [13, 14]
                },
                13: {
                    speaker: 'player',
                    text: 'Einstandspreise kalkulieren',
                    onClick: () => {
                        setAnswer({ id: '13-14', label: 'Einstandspreise kalkulieren',  correct: true });
                        setPoints({ answer: '13-14', points: 1});
                        dispatch(setHint(2));
                        dispatch(setShowHint(true));
                        dispatch(setTask('referenceSpreadsheet'));
                        setPage('image-cloze');
                    }
                },
                14: {
                    speaker: 'player',
                    text: 'Beschaffungsentscheidung treffen',
                    children: [15],
                    onClick: () => {
                        setAnswer({ id: '13-14', label: 'Beschaffungsentscheidung treffen' });
                        setPoints({ answer: '13-14', points: 0});
                    }
                },
                15: {
                    speaker: 'teamlead',
                    text: 'Ich kann nicht auf den ersten Blick erkennen, wer der günstigere Anbieter ist. Bitte berechne die Einstandspreise.',
                },

            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setHint(2));
                dispatch(setShowHint(true));
                dispatch(setTask('referenceSpreadsheet'));
                setPage('image-cloze');
            }
        },

        putschOrDober: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Die Bezugskalkulation ist nun fertig.`,
                    children: [1],
                },
                1: {
                    speaker: 'teamlead',
                    text: 'Bei welchem Anbieter sollen wir die Glasboards und Magnete bestellen?',
                    children: [2,3],
                },
                2: {
                    speaker: 'player',
                    text: 'Bei der Putsch GmbH.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '2-3', label: 'Bei der Putsch GmbH.' });
                        setPoints({ answer: '2-3', points: 0});
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Bei der Dober GmbH.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-3', label: 'Bei der Dober GmbH.',  correct: true });
                        setPoints({ answer: '2-3', points: 1});
                    }
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Die Leistungen sind bei beiden Angeboten vergleichbar, jedoch ist der Preis bei der Dober GmbH günstiger.',
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Du hast das richtige Angebot gewählt. Die Leistungen sind bei beiden Angeboten vergleichbar, jedoch ist der Preis bei der Dober GmbH günstiger.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setNotification('mission5Feedback'));
                setPage('notification');
            }
        },
    }

    return missionFiveData;
}
