import React, { useState } from 'react';

import Button from 'components/Button';
import { Option as ChoiceOption } from 'components/Choice';
import HotspotChoice, { Type } from 'components/HotspotChoice';
import LabelChoice from 'components/LabelChoice';
import { Option as TapTextOption } from 'components/TapText';

import styles from './TextCloze.module.scss';

export type Option = ChoiceOption & {
  children?: string[];
  correct?: boolean;
  points?: number;
};

type Hotspot = {
  title?: string;
  type?: Type;
  options?: TapTextOption[];
  correct: string[];
  points?: number;
  onSubmit: (answer: Answer) => void;
};

type Hotspots = {
  [id: string]: Option[] | Hotspot;
};

export type Answers = {
  [id: string]: Option;
};

export type Answer = Option & {
  id: string;
}

export type Props = {
  title?: string;
  text: string;
  hotspots?: Hotspots;
  initialAnswers?: Answers;
  hideContinueButton?: boolean;

  onContinue?: (answers: Answers) => void;
};

const TextCloze = ({
  title,
  text,
  hotspots = {},
  initialAnswers = {},
  hideContinueButton = false,
  onContinue,
}: Props) => {

  const parseText = (value: string) => {
    const regex = /(\$\(\w+\))|(%\(.*?\))|(\n)|(\*\*.*\*\*)|(---+)/g;

    return value.split(regex).filter(v => v !== undefined && v !== '');
  }

  const [parsedText] = useState(parseText(text));
  const [answers, setAnswers] = useState<Answers>(initialAnswers);

  const isEverythingAnswered = () => {
    const answered = Object.values(answers).filter(answer => answer !== null);
    return Object.keys(hotspots).length === answered.length;
  }

  const evaluateAnswers = () => {
    onContinue && onContinue(answers);
  }

  return (
    <div className={styles.container}>
      {title && <h2>{title}</h2>}
      {parsedText.map((element, i) => {
        const placeholder = /\$\((\w+)\)/g.exec(element);

        if (placeholder) {
          const id = placeholder[1];
          const hotspot = hotspots[id];

          let choices;
          let onSubmit;
          let type;
          let title;

          if (Array.isArray(hotspot)) {
              choices = hotspot.map(option => {
              return ({
                ...option,
                onClick: () => {
                  setAnswers({
                    ...answers,
                    [id]: option,
                  });
                  option.onClick && option.onClick({
                    id,
                    label: option.label,
                    correct: option.correct,
                    children: option.children,
                    points: option.points,
                  });
                },
              });
            });
          } else {
            type = hotspot.type;
            title = hotspot.title;

            if (type === Type.TapText) {
              choices = hotspot.options;
            }

            onSubmit = (value: string) => { 
              setAnswers({
                ...answers,
                [id]: {
                  label: value,
                  correct: hotspot.correct.indexOf(value) > -1,
                  points: hotspot.points,
                }
              });
  
              hotspot.onSubmit && hotspot.onSubmit({
                id,
                label: value,
                correct: hotspot.correct.indexOf(value) > -1,
                points: hotspot.points,
              });
            };
          }

          const answer = answers[id];

          if (answer) {
            return <LabelChoice type={type} key={i} title={title} options={choices} label={answer.label} onSubmit={onSubmit} />;
          }

          return <HotspotChoice type={type} key={i} title={title} options={choices} className={styles.hotspot} onSubmit={onSubmit} />;
        }

        const contextPlaceholder = /%\((.*?)\)/g.exec(element);

        if (contextPlaceholder) {
          const ids = contextPlaceholder[1].split('-');
          if (answers[ids[0]]) {
            const labels = answers[ids[0]].children;
            if (labels) {
              return <span key={i}>{labels[parseInt(ids[1], 10)]}</span>
            }
          }
          return '';
        }

        const newLine = /\n/g.exec(element);

        if (newLine) {
          return <br key={i} />;
        }

        const bold = /\*\*(.*)\*\*/g.exec(element);

        if (bold) {
          return <strong key={i}>{bold[1]}</strong>;
        }

        const horizontalLine = /---+/g.exec(element);

        if (horizontalLine) {
          return <hr key={i} />;
        }

        return element;
      })}
      {
        isEverythingAnswered() && !hideContinueButton
        && <Button className={styles.button} label='Weiter' onClick={evaluateAnswers} portalId={'container'} />
      }
    </div>
  );
};

export default TextCloze;