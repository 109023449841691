import {Message} from "./datatypes";

export const teamleadImage = require('static/images/avatars/team-lead-happy.png');
export const clockImage = require('static/images/clock.svg');

export const messages : Message = {
    0: 'Das kannst du besser!',
    61: 'Gar nicht so schlecht!',
    76: 'Das ist schon recht gut!',
    90: 'Super, das hast du gut gemacht!'
};