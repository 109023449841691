import React from 'react';

import styles from './LoadingScreen.module.scss';

const LoadingScreen = () => {
  return (
    <div className={styles.container}>
      <div className={styles.spinner}>
        <div className={styles.bounce1}/>
        <div className={styles.bounce2}/>
        <div className={styles.bounce3}/>
      </div>
    </div>
  );
};

export default LoadingScreen;