import { useEffect, useState } from "react"

export const useFetch = (url: RequestInfo, options?: RequestInit) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(url, options);
        const json = await response.json();
        setResponse(json);
        setIsLoading(false);
      } catch (error) {
        // @ts-ignore
        setError(error);
      }
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { response, error, isLoading };
}