import React, {useEffect, useState} from 'react';

import styles from './Calendar.module.scss';
import ReactHtmlParser from "react-html-parser";

export type Option = {
  label: string;
  onClick?: (e: any) => void;
}

export type Props = {
  className?: string;
  options?: Option[];
  onEnter?: (value: string) => void;
  hasYear?: boolean;
  monthNumber?: number;
  year?: number;
};

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
]

const Calendar = ({
  className,
  hasYear,
  year,
  monthNumber,
  options,
  onEnter,
}: Props) => {

  let month: any;

  if(monthNumber === undefined){
    const date = new Date()
    month = months[date.getMonth()];
  } else {
    month = months[monthNumber]
  }


  const buttonsList = [
    { label: '1', className: styles.primary },
    { label: '2', className: styles.primary },
    { label: '3', className: styles.primary },
    { label: '4', className: styles.primary },
    { label: '5', className: styles.primary },
    { label: '6', className: styles.primary },
    { label: '7', className: styles.primary },

    { label: '8', className: styles.primary },
    { label: '9', className: styles.primary },
    { label: '10', className: styles.primary },
    { label: '11', className: styles.primary },
    { label: '12', className: styles.primary },
    { label: '13', className: styles.primary },
    { label: '14', className: styles.primary },

    { label: '15', className: styles.primary },
    { label: '16', className: styles.primary },
    { label: '17', className: styles.primary },
    { label: '18', className: styles.primary },
    { label: '19', className: styles.primary },
    { label: '20', className: styles.primary },
    { label: '21', className: styles.primary },

    { label: '22', className: styles.primary },
    { label: '23', className: styles.primary },
    { label: '24', className: styles.primary },
    { label: '25', className: styles.primary },
    { label: '26', className: styles.primary },
    { label: '27', className: styles.primary },
    { label: '28', className: styles.primary },

    { label: '29', className: styles.primary },
    { label: '30', className: styles.primary },
    { label: '31', className: styles.primary },
  ];

  const [value, setValue] = useState('');
  const [day, setDayValue] = useState('');
  const [buttons, setButtons] = useState(buttonsList)

  useEffect(() => {
    setValue(month)
  }, [month]);

  useEffect(() => {
    updateButtons()
  }, [])

  const previousMonth = () => {
    const index = months.indexOf(value);
    if(index > 0) {
      month = months[index - 1];
    } else {
      month = months[11];
    }
    setValue(month)
    setDayValue('')
    clearButtonClasses()
    updateButtons()
  }

  const nextMonth = () => {
    const index = months.indexOf(value);
    if(index < 11) {
      month = months[index + 1];
    } else {
      month = months[0];
    }
    setValue(month)
    setDayValue('')
    clearButtonClasses()
    updateButtons()
  }

  const updateButtons = () => {
    if(month === 'Januar' || month === 'März' || month === 'Mai' || month === 'Juli' || month === 'August'
        || month === 'Oktober' || month === 'Dezember') {
      setButtons(buttonsList)
    } else if (month === 'Februar') {
      setButtons(buttonsList.slice(0, 28));
    } else {
      setButtons(buttonsList.slice(0, 30));
    }
  }

  const setDay = (event: React.MouseEvent, label: string) => {
    setDayValue(label)
    clearButtonClasses()
    event.currentTarget.classList.add(styles.selected);
  }

  const submitHandler = () => {
    const monthIndex = months.indexOf(value)
    let resultYear = (hasYear && year) ? `.${year}` : '';

    if(day !== '') {
      onEnter && onEnter(`${String(day).padStart(2, '0')}.${String(monthIndex + 1).padStart(2, '0')}${resultYear}`);
    }
  }

  const clearButtonClasses = () => {
    const buttons = document.querySelectorAll(`.${styles.primary}`)
    buttons.forEach(button => {
      button.classList.remove(styles.selected)
    })
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.display}>
        <i className={`lnr lnr-chevron-left ${styles.chevrons}`} onClick={previousMonth}></i>
        <div className={styles.month}>{ value }</div>
        <i className={`lnr lnr-chevron-right ${styles.chevrons}`} onClick={nextMonth}></i>
      </div>
      <div className={styles.buttonsContainer}>
        {buttons.map(({label, className}, i) =>
          <button
            key={i}
            className={className || ''}
            style={{}}
            onClick={event => { setDay(event, label); }}
          >
            {ReactHtmlParser(label)}
          </button>
        )}
      </div>
      {options?.map(({label, onClick}, i) =>
          <button
              key={`option-${i}`}
              className={styles.optionButton}
              style={{}}
              onClick={onClick}
          >
            {ReactHtmlParser(label)}
          </button>
      )}
      <button
          key={'submitDate'}
          className={styles.submitButton}
          style={{}}
          onClick={submitHandler}
      >
        BESTÄTIGEN
      </button>
    </div>
  );
};

export default Calendar;