import {useDispatch, useSelector} from "react-redux";

import {Dialogues} from "./datatypes";
import {RootState} from "../../redux/rootReducer";
import { teamleadImage } from "./variables";

import { setNotification, unlockDocument } from "../../redux/slices/appSlice";

import { setTask } from "../../redux/slices/taskSlice";
import {Orientation} from "../../components/Dialogue/SpeechBubble";
import {Page as Document} from "../library";
import useFunctions from "../functions";

export const MissionSeven = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [{ setPoints, setAnswer, setOverlay, setPage }] = useFunctions();

    const missionSevenData: Dialogues = {
        weShouldCleanUpOurAssortment: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Wir sollen unser Sortiment bereinigen. Dafür brauche ich deine Unterstützung.`,
                    children: [1],
                },
                1: {
                    speaker: 'teamlead',
                    text: `Weißt du, was bei der Sortimentsbereinigung zu tun ist?`,
                    children: [2, 3, 4],
                },
                2: {
                    speaker: 'player',
                    text: 'Produkte sind dem Sortiment hinzuzufügen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Produkte sind dem Sortiment hinzuzufügen.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Produkte sind aus dem Sortiment zu nehmen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Produkte sind aus dem Sortiment zu nehmen.', correct: true });
                        setPoints({ answer: '2-4', points: 1 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Produkte aus dem Sortiment sind für die Inventur zu erfassen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Produkte aus dem Sortiment sind für die Inventur zu erfassen.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Das ist nicht richtig. Bei einer Sortimentsbereinigung wird ein Produkt bzw. werden mehrere Produkte aus dem Sortiment ausgeschieden. Konkret sollen wir eines der vier MDF-Memoboards natur auslisten.',
                    children: [7]
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Genau! Eines der vier „MDF-Memoboards natur“ soll aus dem Sortiment genommen werden. Wähle eines der Produkte aus.',
                    children: [7]
                },
                7: {
                    speaker: 'teamlead',
                    text: 'Was siehst du dir für die Auswahl als Erstes an?',
                    children: [8, 9, 10]
                },
                8: {
                    speaker: 'player',
                    text: 'Den Umsatz.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Den Umsatz.' });
                        setPoints({ answer: '8-10', points: 0 });
                    }
                },
                9: {
                    speaker: 'player',
                    text: 'Den Einstandspreis.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Den Einstandspreis.' });
                        setPoints({ answer: '8-10', points: 0 });
                    }
                },
                10: {
                    speaker: 'player',
                    text: 'Den Deckungsbeitrag.',
                    children: [12],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Den Deckungsbeitrag.', correct: true });
                        setPoints({ answer: '8-10', points: 1 });
                    }
                },
                11: {
                    speaker: 'teamlead',
                    text: 'Das ist nicht richtig. Zuerst ist es sinnvoll, anzuschauen, wie hoch der Deckungsbeitrag pro Stück ist.',
                    children: [13]
                },
                12: {
                    speaker: 'teamlead',
                    text: 'Ja, super. Als Erstes siehst du dir den Deckungsbeitrag an.',
                    children: [13]
                },
                13: {
                    speaker: 'teamlead',
                    text: 'Bitte berechne jetzt die Stückdeckungsbeiträge.',
                },

            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('enterItemList'));
                dispatch(unlockDocument(Document.EinkaufArtikelubersicht));
                setPage('image-cloze');
            }
        },
        whoDoYouSuggestDelisting: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Deine Berechnung ist nun auf deinem Arbeitsplatz abgelegt. Welches Memoboard würdest du auf Basis der Berechnung aus dem Sortiment entfernen?`,
                    children: [1,2,3,4],
                },
                1: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578912, MDF-Memoboard natur, 42 x 29 cm',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578912, MDF-Memoboard natur, 42 x 29 cm', correct: true });
                        setPoints({ answer: '1-4', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578913, MDF-Memoboard natur, 45 x 60 cm',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578913, MDF-Memoboard natur, 45 x 60 cm' });
                        setPoints({ answer: '1-4', points: 0 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578914, MDF-Memoboard natur, 60 x 90 cm',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578914, MDF-Memoboard natur, 60 x 90 cm' });
                        setPoints({ answer: '1-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578915, MDF-Memoboard natur, 100 x 200 cm',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578915, MDF-Memoboard natur, 100 x 200 cm' });
                        setPoints({ answer: '1-4', points: 0 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Ja, anhand des Deckungsbeitrags je Stück müssten wir dieses Board ausscheiden.',
                    children: [7]
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Nein, es müsste das Produkt mit dem geringsten Deckungsbeitrag je Stück ausgeschieden werden. Das ist der Artikel Nr. 578912.',
                    children: [7]
                },
                7: {
                    speaker: 'teamlead',
                    text: 'Wir sind aber noch nicht fertig! Was sollten wir uns noch ansehen, bevor wir eine finale Entscheidung treffen?',
                    children: [8, 9]
                },
                8: {
                    speaker: 'player',
                    text: 'Den Lagerbestand der Artikel.',
                    children: [10],
                    onClick: () => {
                        setAnswer({ id: '8-9', label: 'Den Lagerbestand der Artikel.' });
                        setPoints({ answer: '8-9', points: 0 });
                    }
                },
                9: {
                    speaker: 'player',
                    text: 'Die Absatzzahlen der Artikel.',
                    alternativeText: 'Die Absatzzahlen der Artikel.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '8-9', label: 'Die Absatzzahlen der Artikel.', correct: true});
                        setPoints({ answer: '8-9', points: 1 });
                    }
                },
                10: {
                    speaker: 'teamlead',
                    text: 'Da liegst du falsch. Es ist wichtig, zu wissen, wie hoch der gesamte Deckungsbeitrag aller verkauften Produkte ist. Schau dir deshalb bitte die Absatzzahlen an.',
                },
                11: {
                    speaker: 'teamlead',
                    text: 'Genau, bitte schau dir die Absatzzahlen an.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(unlockDocument(Document.Absatzstatistik));
                dispatch(setNotification('calculateTotalContributionMarginMessage'));
                setPage('notification');
                setOverlay(Document.Absatzstatistik);
            }
        },
        whichMdfDoYouSuggestForListing: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Welches MDF-Memoboard natur schlägst du nun zur Auslistung vor?`,
                    children: [1,2,3,4],
                },
                1: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578912, MDF-Memoboard natur, 42 x 29 cm',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578912, MDF-Memoboard natur, 42 x 29 cm' });
                        setPoints({ answer: '1-4', points: 0 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578913, MDF-Memoboard natur, 45 x 60 cm',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578913, MDF-Memoboard natur, 45 x 60 cm' });
                        setPoints({ answer: '1-4', points: 0 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578914, MDF-Memoboard natur, 60 x 90 cm',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578914, MDF-Memoboard natur, 60 x 90 cm' });
                        setPoints({ answer: '1-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Art.-Nr. 578915, MDF-Memoboard natur, 100 x 200 cm',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '1-4', label: 'Art.-Nr. 578915, MDF-Memoboard natur, 100 x 200 cm', correct: true});
                        setPoints({ answer: '1-4', points: 1 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Nein, diesen Artikel werden wir nicht auslisten. Wir nehmen den mit dem niedrigsten Gesamtdeckungsbeitrag, also das MDF-Memoboard natur, 100 x 200 cm mit der Art.-Nr. 578915.',
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Ja, genau. Dieser Artikel hat den niedrigsten Gesamtdeckungsbeitrag. Daher werden wir ihn auslisten.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask(''))
                dispatch(setNotification('mission7Feedback'));
                setPage('notification');
            }
        },
    }

    return missionSevenData;
}
