import React, {useState} from 'react';

import styles from './CopyHotspot.module.scss';
import ChoiceBox from "../ChoiceBox";
import {useDispatch} from "react-redux";
import {setClipboardCurrent} from "../../redux/slices/appSlice";

export type Position = {
  x: string;
  y: string;
};

export type Option = {
  label: string;
  onClick?: (e: any) => void;
}

export type Props = {
  position?: Position;
  width?: string;
  onClick?: () => void;
};

const CopyHotspot = ({
  position,
  width,
  onClick,
}: Props) => {
  const dispatch = useDispatch();
  const [isElementVisible, setElementVisibilty] = useState(false);

  const containerStyle: React.CSSProperties = {};

  const hideElement = () => {
    dispatch(setClipboardCurrent(null))
    setElementVisibilty(false);
  }

  if (position) {
    containerStyle.position = 'absolute';
    containerStyle.left = position.x;
    containerStyle.top = position.y;
    containerStyle.transform = 'translate(-50%, -50%)';
    containerStyle.margin = 0;
  }

  if (width) {
    containerStyle.width = width
  }

  const options: Option[] = [
    { label: 'Ja', onClick: onClick },
    { label: 'Nein', onClick: hideElement },
  ]

  const title = ''
  const element = <ChoiceBox title={title} options={options} onOverlayClick={hideElement}/>;

  const newOnClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick();

      // @ts-ignore
      let icon = event.target.querySelector('span');
      let menuClipboardItemEl = document.getElementById('menu-clipboard-item');
      icon.style.visibility = 'visible'
      icon.style.opacity = 1;
      if(menuClipboardItemEl){
        menuClipboardItemEl.classList.add(styles.menuClipboardItemSelected);
      }

      setTimeout(function () {
        icon.style.visibility = 'hidden'
        icon.style.opacity = 0;
        if(menuClipboardItemEl){
          menuClipboardItemEl.classList.remove(styles.menuClipboardItemSelected);
        }
      }, 1200);
    }
  }

  return (
    <>
      {isElementVisible && element}
      <section className={`${styles.container} tooltip`} style={containerStyle} onClick={newOnClick}>
        <span style={{pointerEvents: 'none'}} className={styles.tooltiptext}>Kopiert <span className="lnr lnr-clipboard-empty"></span></span>
      </section>
    </>
  );
}

export default CopyHotspot;
