import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'user',
  initialState: {
    id: null as number | null,
    firstName: '',
    lastName: '',
    image: '',
    anonymous: true,
  },
  reducers: {
    setId: (state, action: PayloadAction<number | null>) => { state.id = action.payload; },
    setFirstName: (state, action: PayloadAction<string>) => { state.firstName = action.payload; },
    setLastName: (state, action: PayloadAction<string>) => { state.lastName = action.payload; },
    setImage: (state, action: PayloadAction<string>) => { state.image = action.payload; },
    setAnonymous: (state, action: PayloadAction<boolean>) => { state.anonymous = action.payload; },
  }
});

export const { setFirstName, setLastName, setImage, setId, setAnonymous } = slice.actions;

export default slice.reducer;