import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { setNotes, setShowNotepad } from 'redux/slices/appSlice';

import Header from 'components/DocumentHeader';
import Notepad from 'components/Notepad';

import styles from './Notepad.module.scss';

const Page = () => {
  const dispatch = useDispatch();
  const { notes } = useSelector((state: RootState) => state.app);

  const updateNotes = (value: string) => {
    dispatch(setNotes(value));
  };

  const close = () => {
    dispatch(setShowNotepad(false));
  }

  return (
    <div className={styles.container}>
      <Header title='Notizblock' onClose={close} />
      <div className={styles.content}>
        <Notepad initialValue={notes} onClick={updateNotes} />;
      </div>
    </div>
  )
};

export default Page;