import { Type } from "../../document";
import {currentYear} from "../variables";
import {setClipboardEntry} from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const K88Clipboard = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Beleg K 88`,
        src: require('static/images/tasks/mission12/mKe_K88.png'),
        hotspots: {
            0: {x: '22.7%', y: '24%'},
            1: { x: '52%', y: '74%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 88',
                        order: 1,
                        title: '7640 (Soll)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '173,33'}))
                } },
            2: {x: '46%', y: '74.3%'},
            3: { x: '52.5%', y: '77%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 88',
                        order: 2,
                        title: '2500 (Soll)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '34,67'}))
                } },
            4: {x: '47.5%', y: '77.3%'},
            5: { x: '68.3%', y: '80.4%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 88',
                        order: 3,
                        title: '2700 (Haben)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '208,00'}))
                } },
            6: {x: '62.5%', y: '80.7%'},
        },
        initialAnswers: {
            0: {label: `Datum: 12.11.${currentYear}`, active: false},
            2: {label: `<b>173,33</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            4: {label: `<b>34,67</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            6: {label: `<b>208,00</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
        }
    };
}
