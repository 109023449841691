import { Type } from "../../document";
import {currentYear} from "../variables";
import {setClipboardEntry} from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const ER743Clipboard = () => {
    const dispatch = useDispatch();

    const [{ currentMission }] = useFunctions();
    return {
        type: Type.ImageCloze,
        title: `Beleg ER 743`,
        src: require('static/images/tasks/mission13/mZv_ER743.png'),
        hotspots: {
            0: {x: '74.5%', y: '30%'},
            1: {x: '71.2%', y: '33.6%'},
            2: {x: '78.5%', y: '40%'},
            3: {x: '23.5%', y: '46.4%'},
            4: { x: '27%', y: '46.2%', width: '7%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg ER743',
                        order: 1,
                        title: 'Skonto',
                        isEnabled: true,
                        isPercentage: true,
                        value: '3,00%'}))
                } },
            5: {x: '81.2%', y: '61%'},
            6: { x: '88.5%', y: '60.8%', width: '15.4%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg ER743',
                        order: 2,
                        title: 'Gesamtbetrag inkl. USt',
                        isEnabled: true,
                        isPercentage: false,
                        value: '338,40'}))
                } },
            7: {x: '81.2%', y: '66.5%'},
            8: { x: '88.5%', y: '66.2%', width: '15.4%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg ER743',
                        order: 3,
                        title: 'Gesamtbetrag exkl. USt',
                        isEnabled: true,
                        isPercentage: false,
                        value: '282,00'}))
                } },
        },
        initialAnswers: {
            0: {label: `${currentYear}-11-12`, active: false},
            1: {label: `1.November ${currentYear}`, active: false},
            2: {label: `${currentYear}-11-12`, active: false},
            3: {label: `3% <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            5: {label: `338,40 € <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            7: {label: `282,00 € <i class="lnr lnr-clipboard-empty"></i>`, active: false},
        }
    };
}
