import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useLibrary } from 'hooks/library';

import { setOverlay } from 'redux/slices/appSlice';

import Header from 'components/DocumentHeader';
import List from 'components/List';

import styles from './Library.module.scss';

const Page = () => {
  const [documents] = useLibrary();
  const dispatch = useDispatch();

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const close = () => {
    dispatch(setOverlay(null));
  };

  return (
    <div className={`${styles.container} ${animate ? styles.slideIn : ''}`}>
      <Header title='Mein Arbeitsplatz' onClose={close} />
      <div className={styles.content}>
        <List items={documents}/>
      </div>
    </div>
  );
}

export default Page;