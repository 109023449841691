import { useDispatch, useSelector } from "react-redux";

import { Dialogues } from "./datatypes";
import { RootState } from "../../redux/rootReducer";
import { teamleadImage } from "./variables";
import { Page as Document } from "../library";

import { setNotification, unlockDocument } from "../../redux/slices/appSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";
import useFunctions from "../functions";

export const MissionTwelve = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [{ setPoints, setAnswer, setOverlay, setPage }] = useFunctions();

    const missionTwelveData: Dialogues = {
        pleaseManagePettyCashRegister: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Bitte führe ab sofort unsere Handkassa.`,
                    children: [1]
                },
                1: {
                    speaker: 'teamlead',
                    text: `Was machst du als Erstes?`,
                    children: [2,3,4]
                },
                2: {
                    speaker: 'player',
                    text: 'Ich überprüfe, welche Beträge in den Kassabericht übernommen werden müssen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Ich überprüfe, welche Beträge in den Kassabericht übernommen werden müssen.', correct:true });
                        setPoints({ answer: '2-4', points: 1 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Ich trage die Beträge in den Kassabericht ein.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Ich trage die Beträge in den Kassabericht ein.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Ich eröffne einen neuen Kassabericht für den heutigen Tag.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Ich eröffne einen neuen Kassabericht für den heutigen Tag.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Das ist richtig. Bitte mach das gleich.',
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Du bist etwas zu schnell. Überprüfe zuerst, ob wir alle Belege in das Kassabuch übernehmen ' +
                        'können. Bitte entscheide für jeden Beleg, ob dieser in den Kassabericht aufgenommen werden kann.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                setOverlay(Document.ER743)
                dispatch(setTask('canER743BeIncluded'))
                dispatch(setNotification('canER743BeIncluded'))
                setPage('notification')
            }
        },
        whatAreYouDoingNext: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Du hast nun alle relevanten Belege für den Kassabericht ausgewählt. Was machst du als Nächstes?`,
                    children: [1,2,3]
                },
                1: {
                    speaker: 'player',
                    text: 'Ich eröffne einen neuen Kassabericht für den heutigen Tag.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Ich eröffne einen neuen Kassabericht für den heutigen Tag.', correct:true });
                        setPoints({ answer: '1-3', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Ich trage die Rechnungsbeträge der relevanten Belege in den Kassabericht ein.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Ich trage die Rechnungsbeträge der relevanten Belege in den Kassabericht ein.' });
                        setPoints({ answer: '1-3', points: 0 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Ich berechne die Summe der Rechnungsbeträge.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Ich berechne die Summe der Rechnungsbeträge.' });
                        setPoints({ answer: '1-3', points: 0 });
                    }
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Das ist richtig.',
                    children: [6]
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Du bist etwas zu schnell. Zuerst müssen wir einen neuen Kassabericht für den heutigen Tag eröffnen.',
                    children: [6]
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Was machst du im neuen Kassabericht zuerst?',
                    children: [7,8,9]
                },
                7: {
                    speaker: 'player',
                    text: 'Ich trage die Beträge der relevanten Belege in den Kassabericht ein.',
                    children: [10],
                    onClick: () => {
                        setAnswer({ id: '7-9', label: 'Ich trage die Beträge der relevanten Belege in den Kassabericht ein.', correct:true });
                        setPoints({ answer: '7-9', points: 1 });
                    }
                },
                8: {
                    speaker: 'player',
                    text: 'Ich berechne die Summe der Ein- und Ausgänge.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '7-9', label: 'Ich berechne die Summe der Ein- und Ausgänge.' });
                        setPoints({ answer: '7-9', points: 0 });
                    }
                },
                9: {
                    speaker: 'player',
                    text: 'Ich übertrage den Saldo vom Kassabericht vom Vortag in den heutigen Kassabericht.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '7-9', label: 'Ich übertrage den Saldo vom Kassabericht vom Vortag in den heutigen Kassabericht.' });
                        setPoints({ answer: '7-9', points: 0 });
                    }
                },
                10: {
                    speaker: 'teamlead',
                    text: 'Das ist richtig. Bitte mach das gleich. Der Buchungstext ist bereits eingetragen, die Beträge fehlen jedoch noch.',
                },
                11: {
                    speaker: 'teamlead',
                    text: 'Du bist etwas zu schnell. Zuerst musst du die Rechnungsbeträge der ausgewählten Belege in den Kassabericht eintragen.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('beleg87'))
                setPage('image-cloze')
            }
        },
        nowWeCanStartConclusion: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Das hast du gut gemacht. Nun können wir uns dem Abschluss des Kassaberichts widmen.`,
                    children: [1]
                },
                1: {
                    speaker: 'teamlead',
                    text: `Was machst du als Nächstes?`,
                    children: [2,3,4]
                },
                2: {
                    speaker: 'player',
                    text: 'Saldo des Kassabestands berechnen, das Geld in der Kassa zählen und den Saldo des Vortages in den heutigen Kassabericht übertragen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Saldo des Kassabestands berechnen, das Geld in der Kassa zählen und den Saldo des Vortages in den heutigen Kassabericht übertragen.', correct:true });
                        setPoints({ answer: '2-4', points: 1 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Beträge aus den restlichen Belegen in den Kassabericht übertragen, das Geld in der Kassa zählen und die Ausgänge abziehen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Beträge aus den restlichen Belegen in den Kassabericht übertragen, das Geld in der Kassa zählen und die Ausgänge abziehen.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Saldo des Kassabestands berechnen, den Saldo des Vortages in den heutigen Kassabericht übertragen und Uberschusss/Manko ermitteln.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Saldo des Kassabestands berechnen, den Saldo des Vortages in den heutigen Kassabericht übertragen und Uberschusss/Manko ermitteln.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Ja genau, das stimmt.',
                    children: [7]
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Das ist falsch. Wir müssen den Saldo des Kassabestands berechnen, das Geld in der Kassa zählen und den Saldo es Vortages in den heutigen Kassabericht übertragen.',
                    children: [7]
                },
                7: {
                    speaker: 'teamlead',
                    text: 'Lass uns damit beginnen den Saldo des Kassabestands zu berechnen.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('saldoBberechnen'))
                setPage('image-cloze')
            }
        },
        pleaseTransferBalanceFromPreviousDay: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Sehr gut.`,
                    children: [1]
                },
                1: {
                    speaker: 'teamlead',
                    text: `Bitte übertrage nun den Saldo des Vortages in den heutigen Kassabericht.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(unlockDocument(Document.Kassabericht))
                dispatch(setTask('balancePreviousDays'))
                setPage('image-cloze')
            }
        },
        howIsItGoingNow: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Perfekt. Was meinst du, wie geht es nun weiter?`,
                    children: [1,2]
                },
                1: {
                    speaker: 'player',
                    text: 'Den Kassaendbestand und gegebenfalls Überschuss/Manko ermitteln.',
                    alternativeText: 'Jetzt können wir den Kassaendbestand und gegebenfalls einen Überschuss oder ein Manko ermitteln.',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Den Kassaendbestand und gegebenfalls Überschuss/Manko ermitteln.', correct:true });
                        setPoints({ answer: '1-2', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Alle Beträge sind eingetragen, der Kassabericht ist fertig.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Alle Beträge sind eingetragen, der Kassabericht ist fertig.' });
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: 'Das ist richtig. Bitte mach das gleich.'
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Das ist leider falsch. Du musst jetzt noch den Endbestand ermitteln. Anschließend erkennen wir, ob es gegebenfalls einen Überschuss oder ein Manko gibt.'
                }
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('currentCashBalance2'))
                setPage('image-cloze')
            }
        },
        whatDoYouSee: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Was erkennst du, wenn du die beiden Endbestände miteinander vergleichst?`,
                    children: [1,2,3]
                },
                1: {
                    speaker: 'player',
                    text: 'Die Beträge stimmen überein.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Die Beträge stimmen überein.' });
                        setPoints({ answer: '1-3', points: 0 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Wir haben einen Überschuss in unserer Kassa.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Wir haben einen Überschuss in unserer Kassa.' });
                        setPoints({ answer: '1-3', points: 0 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Wir haben einen Fehlbetrag in unserer Kassa.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Wir haben einen Fehlbetrag in unserer Kassa.', correct:true });
                        setPoints({ answer: '1-3', points: 1 });
                    }
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Das ist falsch! Es gibt ein Manko in der Höhe von EUR 2,-. Der Kassabericht ist aber trotzdem korrekt abgeschlossen.'
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Das stimmt. Es gibt ein Manko in der Höhe von EUR 2,-. Der Kassabericht ist aber trotzdem korrekt abgeschlossen.'
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask(''))
                setPage('notification')
                dispatch(setNotification('mission12Feedback'))
            }
        },
    }

    return missionTwelveData;
}
