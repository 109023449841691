import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';

import { Page as Document } from 'hooks/library'

import {
  unlockDocument,
  setDialogue,
  setNotification,
  setOverlay as setCurrentOverlay,
  setNextPage
} from 'redux/slices/appSlice';
import { addPoints, setPointsDetails } from 'redux/slices/scoreSlice';
import { setTask, setAnsweredTasks } from 'redux/slices/taskSlice';

import { Option } from 'components/Choice';
import { Position } from 'components/Hotspot';
import { Answer } from 'components/ImageCloze';
import useFunctions from "./functions";

type Missions = {
  [key: number]: Scenes;
};

type Scenes = {
  [key: string]: Scene;
};

type Scene = {
  title: string;
  background: string;
  options: Option[];
  portrait?: string;
  hotspotPosition: Position;
};

export const useScene = () => {
  const { mission: currentMission, scene: currentScene } = useSelector((state: RootState) => state.app);
  const { task } = useSelector((state: RootState) => state.task);
  const { pointsDetails } = useSelector((state: RootState) => state.score);
  const dispatch = useDispatch();
  const [{ setPage }] = useFunctions();
  const setPoints = ({ task, answer, points }: { task: string, answer: string, points: number }) => {
    dispatch(setPointsDetails({ mission: currentMission, task, answer, points}));

    if (
      !pointsDetails[currentMission][task] || 
      (pointsDetails[currentMission][task] && pointsDetails[currentMission][task][answer] === undefined)
    ) {
      dispatch(addPoints({ mission: currentMission, points }));
    }
  };

  const setOverlay = (value: string) => {
    dispatch(setCurrentOverlay(`document:${value}`));
  };

  const setAnswer = (answer: Answer) => {
    dispatch(setAnsweredTasks({ mission: currentMission, task, answer: answer.id, option: answer }));
  };

  const missions: Missions = {
    1: {
      computer: {
        title: 'Was machst du jetzt?',
        background: require('static/images/background-computer.jpg'),
        options: [
          { 
            label: 'Lagerstand checken',
            onClick: () => {
              setAnswer({ id: '0', label: 'Lagerstand checken', correct: true });
              setPoints({ task: 'computer', answer: '0', points: 1 }); 
              dispatch(setTask('doWeHaveEverything'));
              dispatch(setDialogue('doWeHaveEverything'));
              dispatch(setNextPage('dialogue'));
              dispatch(unlockDocument(Document.Stocklist));
              setOverlay(Document.Stocklist);
            },
          },
          { 
            label: 'Angebot schreiben',
            onClick: () => {
              setAnswer({ id: '0', label: 'Angebot schreiben' });
              setPoints({ task: 'computer', answer: '1-2', points: 0 }); 
              dispatch(setNotification('checkStockList'));
              setPage('notification');
            }, 
          },
        ],
        hotspotPosition: { x: '50%', y: '30%' },
      },
    },
    3: {
      postitCloseup: {
        title: 'Was machst du als Nächstes?',
        background: require('static/images/background-postit.jpg'),
        options: [
          { 
            label: 'Auftragsbestätigung ansehen',
            onClick: () => {
              setAnswer({ id: '0', label: 'Auftragsbestätigung ansehen', correct: true });
              setPoints({ task: 'postitCloseup', answer: '0', points: 1 }); 
              dispatch(setTask('deliveryNote'));
              dispatch(setNextPage('image-cloze'));
              setOverlay(Document.OrderConfirmation);
            }
          },
          { 
            label: 'Lieferschein erstellen',
            onClick: () => { 
              setAnswer({ id: '0', label: 'Lieferschein erstellen', correct: true });
              setPoints({ task: 'postitCloseup', answer: '0', points: 1 }); 
              dispatch(setTask('deliveryNote'));
              setPage('image-cloze');
            }
          },
          {
            label: 'Posteingang öffnen',
            onClick: () => {
              setPoints({ task: 'postitCloseup', answer: '0', points: 0 }); 
              dispatch(setTask(''));
              dispatch(setNotification('createDeliveryNote'));
              setPage('notification')
            }
          }
        ],
        hotspotPosition: { x: '49%', y: '42%' },
      },
    },
    4: {
      computer: {
        title: 'Was machst du jetzt?',
        background: require('static/images/background-computer.jpg'),
        options: [
          { 
            label: 'Rechnung erstellen',
            onClick: () => {
              setAnswer({ id: '0', label: 'Rechnung erstellen', correct: true });
              setPoints({ task: 'computer', answer: '0', points: 1 }); 
              dispatch(unlockDocument(Document.DeliveryNotes));
              dispatch(setTask('bill'));
              setPage('image-cloze');
            }
          },
          { 
            label: 'Lieferung vorbereiten',
            onClick: () => {
              setAnswer({ id: '0', label: 'Lieferung vorbereiten' });
              setPoints({ task: 'computer', answer: '0', points: 0 }); 
              dispatch(setTask(''));
              dispatch(setNotification('createBill'));
              setPage('notification');
            }
          },
        ],
        hotspotPosition: { x: '50%', y: '30%' },
      }
    }
  };

  const [scene, setScene] = useState(missions[currentMission][currentScene]);

  useEffect(() => {
    setScene(missions[currentMission][currentScene]); 
  }, [currentMission, currentScene, task, pointsDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return [scene];
}