import { Type } from "../../document";
import {currentYear} from "../variables";
import {setClipboardEntry} from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const K89Clipboard = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Beleg K 89`,
        src: require('static/images/tasks/mission12/mKe_K89.png'),
        hotspots: {
            0: {x: '27%', y: '38%'},
            1: { x: '52%', y: '65.8%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 89',
                        order: 1,
                        title: '2700 (Soll)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '500,00'}))
                } },
            2: {x: '46%', y: '66.1%'},
            3: { x: '69%', y: '68.3%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 89',
                        order: 2,
                        title: '2800 (Haben)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '500,00'}))
                } },
            4: {x: '63%', y: '68.6%'},
        },
        initialAnswers: {
            0: {label: `12.11.${currentYear}`, active: false},
            2: {label: `<b>500,00</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            4: {label: `<b>500,00</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
        }
    };
}
