import { Type } from "../../document";
import {currentYear} from "../variables";

export const B112 = () => {

    return {
        type: Type.ImageCloze,
        title: `Beleg B 112`,
        src: require('static/images/tasks/mission12/mKe_B112.png'),
        hotspots: {
            0: {x: '39.8%', y: '20.8%'},
        },
        initialAnswers: {
            0: {label: `13.11.${currentYear}`, active: false},
        }
    };
}