import { Type } from "../../document";
import {currentYear} from "../variables";
import {setClipboardEntry} from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const K90Clipboard = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Beleg K 90`,
        src: require('static/images/tasks/mission12/mKe_K90.png'),
        hotspots: {
            0: {x: '22.6%', y: '25%'},
            1: { x: '52%', y: '73.4%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 90',
                        order: 1,
                        title: '7320 (Soll)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '132,00'}))
                } },
            2: {x: '46%', y: '73.7%'},
            3: { x: '69%', y: '76%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Beleg K 90',
                        order: 2,
                        title: '2700 (Haben)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '132,00'}))
                } },
            4: {x: '63%', y: '76.3%'},
        },
        initialAnswers: {
            0: {label: `Datum: 12.11.${currentYear}`, active: false},
            2: {label: `<b>132,00</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            4: {label: `<b>132,00</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
        }
    };
}
