import { Type } from "../../document";
import {currentYear} from "../variables";

export const FillOutTransferFormDone = () => {

    return {
        type: Type.ImageCloze,
        title: `Lösung: Überweisungsformular ausfüllen`,
        src: require('static/images/tasks/mission13/mZv_UeberweisungsformularAusfuellen.png'),
        hotspots: {
            0: {
                x: '38.5%',
                y: '18%',
                labelXOffset: '-2.5%',
            },
            1: {
                x: '38.5%',
                y: '27%',
                labelXOffset: '-2.5%',
            },
            2: {
                x: '38.5%',
                y: '36%',
                labelXOffset: '-2.5%',
            },
            3: {
                x: '38.5%',
                y: '45%',
                labelXOffset: '-2.5%',
            },
            4: {
                x: '38.5%',
                y: '55%',
                labelXOffset: '-2.5%',
            },
            5: {
                x: '38.5%',
                y: '83%',
                labelXOffset: '-2.5%',
            },
            6: { x: '35.5%', y: '74.1%'}
        },
        initialAnswers: {
            0: { label: `Karl Lehner & Co`, active: false},
            1: { label: `AT55 9900 0004 7153 3712`,
                alternativeLabel: `AT55`,
                children: [
                    { x: '46.7%', y: '27%', label: `9900`},
                    { x: '57%', y: '27%', label: `0004`},
                    { x: '68%', y: '27%', label: `7153`},
                    { x: '78.5%', y: '27%', label: `3712`},
                ], active: false},
            2: { label: `328,25`, active: false},
            3: { label: ``, active: false},
            4: { label: `Rechnung Nr. 3876`, active: false},
            5: { label: `Memoboard GmbH`, active: false},
            6: { label: `25-11-${currentYear}`, active: false},
        },
    };
}
