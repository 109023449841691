import { useDispatch } from "react-redux";

import { Page as Document } from "../library";
import { useFunctions } from "../functions";
import { Tasks } from "./datatypes";
import { Type } from "../../components/HotspotChoice";

import {
    setAllowClipboardCopy, setFeedback,
    setOverlay as setCurrentOverlay,
} from "../../redux/slices/appSlice";
import {setClipboardEntry, setTask as setCurrentTask, setTaskSkippable } from "../../redux/slices/taskSlice";
import styles from "../../pages/ImageCloze/ImageCloze.module.scss";

export const MissionSeven = () => {
    const [{ setOverlay, setImageClozeAnswer, setImageClozePoints, setPage, currentMission }] = useFunctions();
    const dispatch = useDispatch();

    const missionSevenData: Tasks = {
        enterItemList: {
            title: 'Artikel aus Artikelübersicht übertragen',
            subtitle: '<span class="tip-label">Tipp:</span> Es soll eines der „MDF-Memoboards natur“ aus dem Sortiment genommen werden.',
            src: require('static/images/tasks/mission7/mKb_DeckungsbeitragMemoboardBerechnen.png'),
            hotspots: {
                0: {
                    x: '60.3%',
                    y: '10%',
                    labelXAlignment: 'center',
                    title: 'Welche Produkte möchtest du auswählen?',
                    className: `${styles.gochi}`,
                    options: [
                        { label: 'Produkte der Firma Putsch GmbH',
                            alternativeLabel: 'MDF-Memoboard weiß',
                            children: [
                                { x: '36.5%', y: '18.3%', label: '42 x 29 cm', labelXAlignment: 'center', className: `${styles.gochi}` },
                                { x: '52.5%', y: '18.3%', label: '45 x 60 cm', labelXAlignment: 'center', className: `${styles.gochi}` },
                                { x: '68.4%', y: '18.3%', label: '60 x 90 cm', labelXAlignment: 'center', className: `${styles.gochi}` },
                                { x: '86.3%', y: '18.3%', label: '100 x 200 cm', labelXAlignment: 'center', className: `${styles.gochi}` }
                            ],
                            onClick: setImageClozeAnswer },
                        { label: 'Produkte der Firma Meller OG',
                            alternativeLabel: 'MDF-Memoboard natur',
                            children: [
                                { x: '36.5%', y: '18.3%', label: '42 x 29 cm', labelXAlignment: 'center', className: `${styles.gochi}` },
                                { x: '52.5%', y: '18.3%', label: '45 x 60 cm', labelXAlignment: 'center', className: `${styles.gochi}` },
                                { x: '68.4%', y: '18.3%', label: '60 x 90 cm', labelXAlignment: 'center', className: `${styles.gochi}` },
                                { x: '86.3%', y: '18.3%', label: '100 x 200 cm', labelXAlignment: 'center', className: `${styles.gochi}` }
                            ],
                            correct: true, onClick: setImageClozeAnswer },
                        { label: 'Im Einkauf-Artikelübersicht nachsehen...', onClick: () => { setOverlay(Document.EinkaufArtikelubersicht); }, noAnswer: true },
                    ],
                },
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('enterItemList'));
                setPage('feedback');
            },
        },
        calculateContributionMargin: {
            title: 'Deckungsbeitrag je Stück berechnen',
            subtitle: '<span class="tip-label">Tipp:</span> Kopiere die richtigen Beträge aus der Artikelübersicht in deine Zwischenablage. Die Artikelübersicht findest du am Arbeitsplatz.',
            src: require('static/images/tasks/mission7/mKb_DeckungsbeitragMemoboardBerechnen2.png'),
            hotspots: {
                0: {
                    x: '36.6%',
                    y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['12,20'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '36.6%',
                    y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['8,20'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '36.6%',
                    y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['4,00', '4', '4,0'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '52.4%',
                    y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['17,17'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '52.4%',
                    y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['12,60'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '52.4%',
                    y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['4,57'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '68.3%',
                    y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['19,30'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '68.3%',
                    y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['8,70'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '68.3%',
                    y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['10,60'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                9: {
                    x: '85.5%',
                    y: '30%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['26,70'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                10: {
                    x: '85.5%',
                    y: '42%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['16,30'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                11: {
                    x: '85.5%',
                    y: '55%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['10,40'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true));
                dispatch(setFeedback('calculateContributionMargin'));
                setPage('feedback');
            },
        },

        calculateTotalContributionMargin: {
            title: 'Gesamtdeckungsbeitrag berechnen',
            src: require('static/images/tasks/mission7/mKb_GesamtdeckungsbeitragBerechnen.png'),
            hotspots: {
                0: { x: '34%', y: '53.3%', className: `${styles.gochi}` },
                1: { x: '49.9%', y: '53.3%', className: `${styles.gochi}` },
                2: { x: '64.7%', y: '53.3%', className: `${styles.gochi}` },
                3: { x: '83%', y: '53.3%', className: `${styles.gochi}` },
                4: {
                    x: '36.6%',
                    y: '64.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['5.560,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '36.6%',
                    y: '77.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['22.240,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '52.4%',
                    y: '64.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['3.859,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '52.4%',
                    y: '77.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['17.635,63'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '68.3%',
                    y: '64.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['1.315,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                9: {
                    x: '68.3%',
                    y: '77.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['13.939,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                10: {
                    x: '85.5%',
                    y: '64.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['1.327,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                11: {
                    x: '85.5%',
                    y: '77.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '7%',
                    className: `${styles.gochi}`,
                    title: '',
                    type: Type.Calculator,
                    correct: ['13.800,80'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                12: { x: '38.8%', y: '53%', width: '11%', className: `${styles.gochi}`, isClipboard: true, onClick: () => {
                        dispatch(setClipboardEntry({
                            mission: currentMission,
                            document: 'Aufgabe: Gesamtdeckungsbeitrag berechnen',
                            order: 1,
                            title: 'MDF-Memoboard Natur 42 x 29 cm (Deckungsbeitrag/Stk.)',
                            isEnabled: true,
                            isPercentage: false,
                            value: '4,00'}))
                    }
                },
                13: { x: '54.5%', y: '53%', width: '11%', className: `${styles.gochi}`, isClipboard: true, onClick: () => {
                        dispatch(setClipboardEntry({
                            mission: currentMission,
                            document: 'Aufgabe: Gesamtdeckungsbeitrag berechnen',
                            order: 2,
                            title: 'MDF-Memoboard Natur 45 x 60 cm (Deckungsbeitrag/Stk.)',
                            isEnabled: true,
                            isPercentage: false,
                            value: '4,57'}))
                    }
                },
                14: { x: '70.3%', y: '53%', width: '12%', className: `${styles.gochi}`, isClipboard: true, onClick: () => {
                        dispatch(setClipboardEntry({
                            mission: currentMission,
                            document: 'Aufgabe: Gesamtdeckungsbeitrag berechnen',
                            order: 3,
                            title: 'MDF-Memoboard Natur 60 x 90 cm (Deckungsbeitrag/Stk.))',
                            isEnabled: true,
                            isPercentage: false,
                            value: '10,60'}))
                    }
                },
                15: { x: '88.6%', y: '53%', width: '12%', className: `${styles.gochi}`, isClipboard: true, onClick: () => {
                        dispatch(setClipboardEntry({
                            mission: currentMission,
                            document: 'Aufgabe: Gesamtdeckungsbeitrag berechnen',
                            order: 4,
                            title: 'MDF-Memoboard Natur 100 x 200 cm (Deckungsbeitrag/Stk.)',
                            isEnabled: true,
                            isPercentage: false,
                            value: '10,40'}))
                    }
                },
            },
            initialAnswers: {
                0: { label: `<b>4,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
                1: { label: `<b>4,57</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
                2: { label: `<b>10,60</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
                3: { label: `<b>10,40</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
                12: { label: `` , active: false },
                13: { label: `` , active: false },
                14: { label: `` , active: false },
                15: { label: `` , active: false },
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true))
                dispatch(setFeedback('calculateTotalContributionMargin'));
                setPage('feedback');
            },
        },
    }

    return missionSevenData;
}
