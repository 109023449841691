import { Type } from "../../document";

export const NeuerZahlungsbetragLetzteMahnungDone = () => {

    return {
        type: Type.ImageCloze,
        title: 'Lösung: Neuen Zahlungsbetrag für letzte Mahnung berechnen',
        src: require('static/images/tasks/mission8/mMe_ZahlungsbetragLetzteMahnungBerechnen_done.png'),
    };
}
