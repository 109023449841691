import React from 'react';

import { useDialogue } from 'hooks/dialogue';

import Dialogue from 'components/Dialogue';
import Header from 'components/NavigationHeader';

import styles from './Dialogue.module.scss';

const Page = () => {
  const [dialogue] = useDialogue();

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Dialogue
          key={dialogue.id}
          speakers={dialogue.speakers}
          initialBubbles={dialogue.initialBubbles}
          bubbles={dialogue.bubbles}
          onContinueLabel={dialogue.onContinueLabel}
          onContinue={dialogue.onContinue}
        />
      </div>
    </div>
  );
}

export default Page;