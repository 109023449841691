import React, { useEffect, useState } from 'react';

import Portrait from 'components/Portrait';
import shuffle from 'lib/shuffle';

import styles from './AvatarSelection.module.scss';

type Props = {
  defaultAvatar?: string;
  onChange: (avatar: string) => void;
};

const AvatarSelection = ({
  defaultAvatar = '',
  onChange
}: Props) => {

  const getAvatarURLs = (type: string, amount: number) => {
    const urls = [];
    for (let i = 0; i < amount; i++) {
      urls[i] = require(`static/images/avatars/avatar-${type}-${i + 1}.png`);
    }
    return urls;
  }

  const [avatars] = useState<string[]>(shuffle([
    ...getAvatarURLs('neutral', 2),
    ...getAvatarURLs('female', 26),
    ...getAvatarURLs('male', 25),
  ]));

  const [index, setIndex] = useState(0);
  const [image, setImage] = useState(defaultAvatar);

  useEffect(() => {
    if (!defaultAvatar) {
      onChange(avatars[index]);
      setImage(avatars[index]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const previous = () => {
    let newIndex = index - 1;
    if (index === 0) {
      newIndex = avatars.length - 1;
    }
    setIndex(newIndex);
    setImage(avatars[newIndex]);
    onChange(avatars[newIndex]);
  }

  const next = () => {
    let newIndex = index + 1;
    if (index === avatars.length -1) {
      newIndex = 0;
    }
    setIndex(newIndex);
    setImage(avatars[newIndex]);
    onChange(avatars[newIndex]);
  }

  return (
    <div className={styles.container}>
      <i className='lnr lnr-chevron-left-circle' onClick={previous} />
      <Portrait src={image} size='128px' />
      <i className='lnr lnr-chevron-right-circle' onClick={next} />
    </div>
  );
}

export default AvatarSelection;