import React from 'react';

import styles from './ClipboardList.module.scss';
import { toggleChildren } from '../../helpers'
import ClipboardListItem from "../ClipboardListItem";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/rootReducer";

type Props = {
  items: any
};

const List = ({
  items
}: Props) => {
  const { allowClipboardCopy } = useSelector((state: RootState) => state.app);

  return (
    <div className={styles.list}>
      {
        Object.keys(items).map(key => {
          return (
              <div>
                <div onClick={toggleChildren}>
                  <ClipboardListItem title={key} key={key} />
                </div>

                <div className={` ${ styles.panel } ${allowClipboardCopy ? '': styles.disabled}`}>
                  { Object.keys(items[key]).map(itemKey => (
                    <ClipboardListItem item={items[key][itemKey]} />
                  ))}
                </div>
              </div>
          )
        })
      }
    </div>
  );
}

export default List;