import { Type } from "../../document";

export const NeuerZahlungsbetragZweiteMahnungDone = () => {

    return {
        type: Type.ImageCloze,
        title: 'Lösung: Neuen Zahlungsbetrag für zweite Mahnung berechnen',
        src: require('static/images/tasks/mission8/mMe_ZahlungsbetragZweiteMahnungBerechnen_done.png'),
    };
}
