import { Type } from "../../document";
import styles from "../../../pages/ImageCloze/ImageCloze.module.scss";

export const AccountForReceiptWurmDone = () => {
    const currentYear = new Date().getFullYear();

    return {
        type: Type.ImageCloze,
        title: `Lösung: Beleg K 87 vorkontieren`,
        src: require('static/images/tasks/mission11/mBv_Wurm.png'),
        hotspots: {
            0: {
                x: '32%',
                y: '78.8%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            1: {
                x: '50%',
                y: '78.8%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            2: {
                x: '67%',
                y: '78.8%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            3: {
                x: '32%',
                y: '81.9%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            4: {
                x: '50%',
                y: '81.9%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            5: {
                x: '67%',
                y: '81.9%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            6: {
                x: '32%',
                y: '84.8%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            7: {
                x: '50%',
                y: '84.8%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            8: {
                x: '67%',
                y: '84.8%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            9: {x: '22.5%', y: '23%'},
        },
        initialAnswers: {
            0: { label: '7600', active: false},
            1: { label: '5,81', active: false},
            2: { label: '', active: false},
            3: { label: '2500', active: false},
            4: { label: '1,16', active: false},
            5: { label: '', active: false},
            6: { label: '2700', active: false},
            7: { label: '', active: false},
            8: { label: '6,97', active: false},
            9: { label: `Datum: 12.11.${currentYear}`, active: false},
        },
    };
}
