import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { reset } from 'redux/rootReducer';
import useFunctions from "../../hooks/functions";

const Debug = () => {
  const dispatch = useDispatch();
  const [{ setPage }] = useFunctions();

  useEffect(() => {
    if (window.location.pathname.indexOf('__clear') > -1) {
      setPage('/');
      dispatch(reset());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Debug;