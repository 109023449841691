import { Type } from "../../document";

export const Verkaufspreis = () => {

    return {
        type: Type.ImageCloze,
        title: 'Verkaufspreisberechnung',
        src: require('static/images/tasks/mission6/mVe_VerkaufspreisBerechnen.png'),
        hotspots: {
            0: { x: '26.9%', y: '24%', labelXAlignment: 'right' },
            1: { x: '88%', y: '23.5%', labelXAlignment: 'right' },
            2: { x: '88%', y: '33.5%', labelXAlignment: 'right' },
            3: { x: '88%', y: '44%', labelXAlignment: 'right' },
            4: { x: '26.9%', y: '54.7%', labelXAlignment: 'right' },
            5: { x: '88%', y: '54.7%', labelXAlignment: 'right' },
            6: { x: '88%', y: '64.7%', labelXAlignment: 'right' },
            7: { x: '88%', y: '75.5%', labelXAlignment: 'right' },
            8: { x: '88%', y: '86.5%', labelXAlignment: 'right' },
            9: { x: '88%', y: '29%', labelXAlignment: 'right' },
            10: { x: '88%', y: '40%', labelXAlignment: 'right' },
            11: { x: '88%', y: '50.3%', labelXAlignment: 'right' },
            12: { x: '88%', y: '60.5%', labelXAlignment: 'right' },
            13: { x: '88%', y: '71%', labelXAlignment: 'right' },
            14: { x: '88%', y: '81.5%', labelXAlignment: 'right' },
            15: { x: '88%', y: '92%', labelXAlignment: 'right' },
        },
        initialAnswers: {
            0: { label: `20,00 %` , active: false },
            1: { label: `19,86` , active: false },
            2: { label: `2,38` , active: false },
            3: { label: `23,12` , active: false },
            4: { label: `10,00 %` , active: false },
            5: { label: `10,02` , active: false },
            6: { label: `2,25` , active: false },
            7: { label: `12,49` , active: false },
            8: { label: `24,99` , active: false },
            9: { label: `79,44` , active: false },
            10: { label: `77,06` , active: false },
            11: { label: `100,17` , active: false },
            12: { label: `110,19` , active: false },
            13: { label: `112,44` , active: false },
            14: { label: `124,93` , active: false },
            15: { label: `149,92` , active: false },
        }
    };
}
