import React from 'react';
import ReactDOM from 'react-dom';

import TapText, { Props as TapTextProps } from 'components/TapText';

import styles from './TapTextBox.module.scss';

export type Props = TapTextProps & {
  onOverlayClick?: () => void;
};

const TapTextBox = ({
  title,
  options,
  onOverlayClick,
  onEnter,
}: Props) => {
  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={onOverlayClick}>
      <div onClick={e => { e.stopPropagation(); }}>
        <TapText className={styles.tapText} title={title} onEnter={onEnter} options={options} />
      </div>
    </div>,
    document.getElementById('container') as HTMLElement
  );
};

export default TapTextBox;