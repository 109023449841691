import { Type } from "../../document";
import styles from "../../../pages/ImageCloze/ImageCloze.module.scss";

export const AccountForReceiptFinanzbankDone = () => {
    const currentYear = new Date().getFullYear();

    return {
        type: Type.ImageCloze,
        title: `Lösung: Beleg B 112 vorkontieren`,
        src: require('static/images/tasks/mission11/mBv_Finanzbank.png'),
        hotspots: {
            0: {
                x: '32%',
                y: '61.5%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            1: {
                x: '50%',
                y: '61.5%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            2: {
                x: '67%',
                y: '61.5%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            3: {
                x: '32%',
                y: '64.7%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            4: {
                x: '50%',
                y: '64.7%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            5: {
                x: '67%',
                y: '64.7%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            6: {
                x: '32%',
                y: '68.1%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            7: {
                x: '50%',
                y: '68.1%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            8: {
                x: '67%',
                y: '68.1%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            9: {
                x: '32%',
                y: '71.5%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            10: {
                x: '50%',
                y: '71.5%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            11: {
                x: '67%',
                y: '71.5%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            12: {
                x: '32%',
                y: '84.7%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            13: {
                x: '50%',
                y: '84.7%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            14: {
                x: '67%',
                y: '84.7%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            15: {
                x: '32%',
                y: '87.9%',
                labelXAlignment: 'right',
                labelXOffset: '4%',
                className: `${styles.gochi}`,
            },
            16: {
                x: '50%',
                y: '87.9%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            17: {
                x: '67%',
                y: '87.9%',
                labelXAlignment: 'right',
                labelXOffset: '6.5%',
                className: `${styles.gochi}`,
            },
            18: {x: '40%', y: '19%'},
        },
        initialAnswers: {
            0: { label: '2800', active: false},
            1: { label: '2449,22', active: false},
            2: { label: '', active: false},
            3: { label: '4410', active: false},
            4: { label: '41,65', active: false},
            5: { label: '', active: false},
            6: { label: '3500', active: false},
            7: { label: '8,33', active: false},
            8: { label: '', active: false},
            9: { label: '2000', active: false},
            10: { label: '', active: false},
            11: { label: '2499,20', active: false},
            12: { label: '7700', active: false},
            13: { label: '1560,00', active: false},
            14: { label: '', active: false},
            15: { label: '2800', active: false},
            16: { label: '', active: false},
            17: { label: '1560,00', active: false},
            18: { label: `13.11.${currentYear}`, active: false},
        },
    };
}
