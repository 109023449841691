import { Type } from "../../document";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import { useDispatch } from "react-redux";
import useFunctions from "../../functions";

export const EinkaufArtikelubersicht = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: 'Einkauf: Artikelübersicht',
        src: require('static/images/tasks/mission7/mKb_Artikeluebersicht.png'),
        hotspots: {
            0: { x: '57%', y: '24%' },
            1: { x: '70%', y: '24%' },
            2: { x: '84%', y: '24%' },
            3: { x: '56%', y: '31.5%' },
            4: { x: '68.7%', y: '31.5%' },
            5: { x: '84%', y: '31.5%' },
            6: { x: '57%', y: '38.5%' },
            7: { x: '70.5%', y: '38.5%' },
            8: { x: '84%', y: '38.5%' },
            9: { x: '56%', y: '45%' },
            10: { x: '68.7%', y: '45%' },
            11: { x: '84%', y: '45%' },
            12: { x: '56.5%', y: '71%' },
            13: { x: '70%', y: '71%' },
            14: { x: '84%', y: '71%' },
            15: { x: '55.5%', y: '78.5%' },
            16: { x: '71%', y: '78.5%' },
            17: { x: '84%', y: '78.5%' },
            18: { x: '56.5%', y: '85.5%' },
            19: { x: '70%', y: '85.5%' },
            20: { x: '84%', y: '85.5%' },
            21: { x: '56%', y: '92%' },
            22: { x: '68.7%', y: '92%' },
            23: { x: '84%', y: '92%' },
            24: { x: '61.5%', y: '23.5%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 1,
                        title: 'MDF-Memoboard Weiß 42 x 29 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '7,30'}))
                } },
            25: { x: '74%', y: '23.5%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 2,
                        title: 'MDF-Memoboard Weiß 42 x 29 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '757'}))
                } },
            26: { x: '89.2%', y: '23.5%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 3,
                        title: 'MDF-Memoboard Weiß 42 x 29 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '14,20'}))
                } },
            27: { x: '61.5%', y: '31%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 4,
                        title: 'MDF-Memoboard Weiß 45 x 60 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '11,80'}))
                } },
            28: { x: '74%', y: '31%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 5,
                        title: 'MDF-Memoboard Weiß 45 x 60 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '4544'}))
                } },
            29: { x: '89.2%', y: '31%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 6,
                        title: 'MDF-Memoboard Weiß 45 x 60 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '19,17'}))
                } },
            30: { x: '61.5%', y: '38%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 7,
                        title: 'MDF-Memoboard Weiß 60 x 90 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '8,50'}))
                } },
            31: { x: '74.5%', y: '38%', width: '9%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 8,
                        title: 'MDF-Memoboard Weiß 60 x 90 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '904'}))
                } },
            32: { x: '89.2%', y: '38%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 9,
                        title: 'MDF-Memoboard Weiß 60 x 90 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '21,30'}))
                } },
            33: { x: '61%', y: '44.5%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 10,
                        title: 'MDF-Memoboard Weiß 100 x 200 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '22,40'}))
                } },
            34: { x: '74%', y: '44.5%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 11,
                        title: 'MDF-Memoboard Weiß 100 x 200 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '1215'}))
                } },
            35: { x: '89.2%', y: '44.5%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 12,
                        title: 'MDF-Memoboard Weiß 100 x 200 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '28,70'}))
                } },
            36: { x: '61%', y: '70.5%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 13,
                        title: 'MDF-Memoboard Natur 42 x 29 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '8,20'}))
                } },
            37: { x: '74%', y: '70.5%', width: '9%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 14,
                        title: 'MDF-Memoboard Natur 42 x 29 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '385'}))
                } },
            38: { x: '89.2%', y: '70.5%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 15,
                        title: 'MDF-Memoboard Natur 42 x 29 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '12,20'}))
                } },
            39: { x: '61%', y: '78%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 16,
                        title: 'MDF-Memoboard Natur 45 x 60 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '12,60'}))
                } },
            40: { x: '74%', y: '78%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 17,
                        title: 'MDF-Memoboard Natur 45 x 60 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '55'}))
                } },
            41: { x: '89.2%', y: '78%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 18,
                        title: 'MDF-Memoboard Natur 45 x 60 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '17,17'}))
                } },
            42: { x: '61%', y: '85%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 19,
                        title: 'MDF-Memoboard Natur 60 x 90 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '8,70'}))
                } },
            43: { x: '74%', y: '85%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 20,
                        title: 'MDF-Memoboard Natur 60 x 90 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '510'}))
                } },
            44: { x: '89.2%', y: '85%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 21,
                        title: 'MDF-Memoboard Natur 60 x 90 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '19,30'}))
                } },
            45: { x: '61%', y: '91.5%', width: '11%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 22,
                        title: 'MDF-Memoboard Natur 100 x 200 cm (Einstandspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '16,30'}))
                } },
            46: { x: '74%', y: '91.5%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 23,
                        title: 'MDF-Memoboard Natur 100 x 200 cm (Bestand)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '1125'}))
                } },
            47: { x: '89.2%', y: '91.5%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Einkauf Artikelübersicht',
                        order: 24,
                        title: 'MDF-Memoboard Natur 100 x 200 cm (Verkaufspreis)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '26,70'}))
                } },
        },
        initialAnswers: {
            0: { label: `<b>7,30</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            1: { label: `<b>757</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            2: { label: `<b>14,20</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            3: { label: `<b>11,80</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            4: { label: `<b>4,544</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            5: { label: `<b>19,17</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            6: { label: `<b>8,50</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            7: { label: `<b>904</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            8: { label: `<b>21,30</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            9: { label: `<b>22,40</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            10: { label: `<b>1.215</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            11: { label: `<b>28,70</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            12: { label: `<b>8,20</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            13: { label: `<b>385</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            14: { label: `<b>12,20</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            15: { label: `<b>12,60</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            16: { label: `<b>55</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            17: { label: `<b>17,17</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            18: { label: `<b>8,70</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            19: { label: `<b>510</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            20: { label: `<b>19,30</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            21: { label: `<b>16,30</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            22: { label: `<b>1.125</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            23: { label: `<b>26,70</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
        }
    };
}