import { Type } from "../../document";
import {currentYear} from "../variables";
import {setClipboardEntry} from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const Kassabericht = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Kassabericht 11.11.${currentYear}`,
        src: require('static/images/tasks/mission12/mKe_KassaberichtVortag.png'),
        hotspots: {
            0: {x: '15%', y: '16%'},
            1: { x: '89%', y: '70.5%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Kassabericht 11.11',
                        order: 1,
                        title: 'Übertrag (Vortag)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '270,80'}))
                } },
            2: {x: '83.5%', y: '70.8%'},
            3: { x: '89%', y: '76.5%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Kassabericht 11.11',
                        order: 2,
                        title: 'Soll-Endbestand',
                        isEnabled: true,
                        isPercentage: false,
                        value: '172,35'}))
                } },
            4: {x: '83.5%', y: '76.7%'},
            5: { x: '89%', y: '82.8%', width: '12%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Kassabericht 11.11',
                        order: 3,
                        title: 'Ist-Endbestand',
                        isEnabled: true,
                        isPercentage: false,
                        value: '170,35'}))
                } },
            6: {x: '83.5%', y: '83.1%'},
            7: { x: '89.7%', y: '89%', width: '10.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Kassabericht 11.11',
                        order: 3,
                        title: 'Manko',
                        isEnabled: true,
                        isPercentage: false,
                        value: '-2,00'}))
                } },
            8: {x: '84.9%', y: '89.4%'},
        },
        initialAnswers: {
            0: {label: `<span style="color:white">11.11.${currentYear}</span>`, active: false},
            2: {label: `<b>270,80</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            4: {label: `<b>172,35</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            6: {label: `<b>170,35</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
            8: {label: `<b>-2,00</b> <i class="lnr lnr-clipboard-empty"></i>`, active: false},
        }
    };
}
