import {useDispatch, useSelector} from 'react-redux';

import {addPoints, setPointsDetails} from "../redux/slices/scoreSlice";
import {RootState} from "../redux/rootReducer";
import {Answer, Answers} from "../components/ImageCloze";
import {setAnsweredTasks} from "../redux/slices/taskSlice";
import { setOverlay as setCurrentOverlay, setPage as setCurrentPage} from "../redux/slices/appSlice";
import {messages} from "./notification/variables";
import {setAnonymous, setId as setUserId} from "../redux/slices/userSlice";
import {HighscoreEntry} from "./notification/datatypes";

export const useFunctions = () => {
    const { mission: currentMission, module: currentModule} = useSelector((state: RootState) => state.app);
    const { task } = useSelector((state: RootState) => state.task);
    const { task: currentTask } = useSelector((state: RootState) => state.task);
    const { firstName, lastName, id: userId } = useSelector((state: RootState) => state.user);
    const { pointsDetails, points: currentPoints, time, missionCompleted } = useSelector((state: RootState) => state.score);
    const dispatch = useDispatch();
    const _ = require("lodash");

    const missionPoints = currentPoints[currentMission].points;
    const missionMaxPoints = currentPoints[currentMission].maxPoints;

    /**
     * @param answer
     * @param points
     */
    const setPoints = ({ answer, points }: { answer: string, points: number }) => {
        dispatch(setPointsDetails({ mission: currentMission, task, answer, points}));

        if (
            !pointsDetails[currentMission][task] ||
            (pointsDetails[currentMission][task] && pointsDetails[currentMission][task][answer] === undefined)
        ) {
            dispatch(addPoints({mission: currentMission, points }));
        }
    }

    /**
     * @param answers
     */
    const setImageClozePoints = (answers: Answers) => {
        window._paq.push(['trackEvent', 'Interaction', 'Task', 'Submit']);
        Object.keys(answers).forEach(key => {
            const answer = answers[key];
            if (answer.correct && !pointsDetails[currentMission][currentTask]) {
                dispatch(addPoints({ mission: currentMission, points: answer.points || 1 }));
            }
            dispatch(setPointsDetails({ mission: currentMission, task: currentTask, answer: key, points: answer.correct ? (answer.points || 1) : 0 }))
        });
    };

    /**
     * @param answer
     */
    const setAnswer = (answer: Answer) => {
        window._paq.push(['trackEvent', 'Interaction', 'Task', 'Answer']);
        dispatch(setAnsweredTasks({ mission: currentMission, task, answer: answer.id, option: answer }));
    }

    /**
     * @param answer
     */
    const setImageClozeAnswer = (answer: Answer) => {
        window._paq.push(['trackEvent', 'Interaction', 'Task', 'Answer']);
        dispatch(setAnsweredTasks({ mission: currentMission, task: currentTask, answer: answer.id, option: answer }));
    };

    /**
     * @param value
     */
    const setOverlay = (value: string) => {
        window._paq.push(['trackEvent', 'Interaction', 'Document', 'Open']);
        dispatch(setCurrentOverlay(`document:${value}`));
    };

    /**
     *
     */
    const getMessage = () => {
        const missionPercentage = (missionPoints / missionMaxPoints) * 100;

        const breakpoints = Object.keys(messages);
        for (let i = breakpoints.length - 1; i >= 0; i--) {
            if (missionPercentage >= parseInt(breakpoints[i], 10)) {
                return messages[breakpoints[i]];
            }
        }
    };

    /**
     * @param image
     * @param width
     */
    const getImage = (image: string, width: number) => {
        return `<div>
              <img style="width: ${width}px;" 
                src="${require('static/images/' + image )}" alt="correct">
            </div>`;
    }

    /**
     * @param user
     */
    const saveHighscoreEntry = async (user: HighscoreEntry) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVICE_URL}/highscore`,
                {
                    headers: {'Content-Type': 'application/json'},
                    method: 'PATCH',
                    body: JSON.stringify([user])
                }
            );

            const json = await response.json();

            dispatch(setUserId(json.data[0].id));
        } catch (error) {
            console.error('Could not save highscore:', error);
        }
    };

    /**
     * @param module
     */
    const saveHighscoreAnonymous = (module?: number) => {
        let user;
        if(module) {
            user = {
                id: userId || undefined,
                username: 'anonym',
                score: getTotalPoints(),
                time: getTotalTime(),
                module: module,
            };
        } else {
            user = {
                id: userId || undefined,
                username: 'anonym',
                score: getTotalPoints(),
                time: getTotalTime(),
            }
        }
        saveHighscoreEntry(user)
            .then(() => {
                dispatch(setAnonymous(true));
                setPage('game-over');
                dispatch(setCurrentOverlay('menuPage:highscore'));
            });
    }

    /**
     * @param module
     */
    const saveHighscoreWithName = (module?: number) => {
        let user;
        if(module) {
            user = {
                id: userId || undefined,
                username: `${firstName} ${lastName}`,
                score: getTotalPoints(),
                time: getTotalTime(),
                module: module,
            };
        } else {
            user = {
                id: userId || undefined,
                username: `${firstName} ${lastName}`,
                score: getTotalPoints(),
                time: getTotalTime(),
            }
        }

        saveHighscoreEntry(user)
        .then(() => {
            dispatch(setAnonymous(false));
            setPage('game-over');
            dispatch(setCurrentOverlay('menuPage:highscore'));
        });
    }

    /**
     *
     */
    const getTotalPoints = () => {
        let points;
        if(currentModule === 1){
            points = _.pick(currentPoints ,'1', '2', '3', '4');
        } else if (currentModule === 2){
            points = _.pick(currentPoints ,'5', '6', '7', '8', '9', '10', '12', '13');
        }

        return Object.keys(points).reduce((acc, value) => {
            return acc + currentPoints[value].points
        }, 0);
    };

    /**
     *
     */
    const getTotalTime = () => {
        let points;
        if(currentModule === 1){
            points = _.pick(currentPoints ,'1', '2', '3', '4');
        } else if (currentModule === 2){
            points = _.pick(currentPoints ,'5', '6', '7', '8', '9', '10', '12', '13');
        }

        return Object.keys(points).reduce((acc, value) => {
            return acc + time[value];
        }, 0);
    };

    const missionsCompleted = (missions: number[]) => {
        let completed = true;
        missions.forEach(mission => {
            if (missionCompleted[mission] === false){
                completed = false;
            }
        })

        return completed;
    }

    const setPage = (page: string) => {
        // @ts-ignore
        dispatch(setCurrentPage(page))
    }

    return [{ setPoints, setAnswer, setOverlay, getMessage, setImageClozeAnswer, setImageClozePoints, getImage,
        saveHighscoreAnonymous, saveHighscoreWithName, missionsCompleted, setPage,
        missionPoints, missionMaxPoints, pointsDetails, currentMission }];
}

export default useFunctions;