import React from "react";

import { Notifications } from "./datatypes";
import { teamleadImage } from "./variables";
import {
    setHighlightMenu,
    setMenu, setMission,
    setModule,
    setNotification as setCurrentNotification,
    setTutorial,
    setTutorialSlide
} from "../../redux/slices/appSlice";
import {useDispatch, useSelector} from "react-redux";
import useFunctions from "../functions";
import {RootState} from "../../redux/rootReducer";

export const Others = () => {
    const dispatch = useDispatch()
    const [{setPage}] = useFunctions();
    const { firstName} = useSelector((state: RootState) => state.user);

    const otherData: Notifications = {
        intro: {
            children: (
                <>
                    <p>Schön, dass du <strong>„Kompetent im Office“</strong> gestartet hast!</p>
                    <p>Hier kannst du ausprobieren, wie gut du alltägliche Office-Aufgaben im Griff hast. Du kannst dich dabei auch mit anderen messen.</p>
                </>
            ),
            style: { justifyContent: 'center' },
            onContinueLabel: 'Weiter',
            showHighscore: false,
            onContinue: () => {
                dispatch(setCurrentNotification('intro2'));
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
            },
        },

        intro2: {
            text: `„Kompetent im Office“ bietet dir auch eine gute Vorbereitung auf deine Lehrabschlussprüfung.`,
            style: { justifyContent: 'center' },
            onContinueLabel: 'Weiter',
            showHighscore: false,
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setPage('user');
            },
        },
        moduleSelection:{
            image: teamleadImage,
            text: `Hallo ${firstName}, heute ist dein erster Tag. Bist du schon aufgeregt? Mit welchen Aufgaben möchtest du dich befassen?`,
            minified: true,
            modules: [
                {
                    title: 'Auftragsabwicklung',
                    description: 'Bearbeite einen konkreten Kundenauftrag von der Angebotserstellung über die Ausfolgerung der Ware bis zum Zahlungseingang.',
                    onClick: () => {
                        dispatch(setModule(1));
                        dispatch(setTutorial('module1'));
                        dispatch(setTutorialSlide(1));
                        setPage('tutorial');
                        dispatch(setMenu(['home', 'library']))
                        dispatch(setHighlightMenu(['library']))
                        window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Started']);
                    }
                },
                {
                    title: 'Rechnungswesen',
                    description: 'Berechne einfache Kennzahlen, vergleiche Angebote, erfasse Mahnungen und löse weitere interessante Aufgaben im Rechnungswesen.',
                    onClick: () => {
                        dispatch(setModule(2));
                        dispatch(setTutorial('module2'));
                        dispatch(setTutorialSlide(1));
                        dispatch(setMission(5))
                        setPage('tutorial');
                        dispatch(setMenu(['home', 'library']))
                        dispatch(setHighlightMenu(['library']))
                        window._paq.push(['trackEvent', 'Tutorial', 'Tutorial Started']);
                    }
                }
            ],
        },

        youDidNotFinishAllMissions: {
            image: teamleadImage,
            text: `Bitte beende zuerst alle Arbeitsaufträge des aktuellen Moduls, damit ich dir eine Gesamtbewertung geben kann. Folgende Arbeitsaufträge fehlen noch:`,
            missions: true
        }
    }
    return otherData;
}
