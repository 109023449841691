import { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { setOverlay as setCurrentOverlay } from 'redux/slices/appSlice';

import { Item } from 'components/List';
import {currentYear} from "./dialogue/variables";

type Documents = {
  [key: string]: Item;
};

export enum Page {
  Inquiry = 'inquiry',
  Stocklist = 'stocklist',
  Offer = 'offer',
  Pricelist = 'pricelist',
  ClientDatabase = 'clientDatabase',
  OrderConfirmation = 'orderConfirmation',
  DeliveryNote = 'deliveryNote',
  DeliveryNotes = 'deliveryNotes',
  Bill = 'bill',
  ClientAccount = 'clientAccount',
  BackPayment = 'backPayment',
  Produktanfrage = 'orderInquiry',
  AngebotPutsch = 'angebotPutsch',
  AngebotDober = 'angebotDober',
  AngebotMeller = 'angebotMeller',
  AufstellungBezugskalkulationDone = 'aufstellungBezugskalkulationDone',
  Bezugskalkulation = 'bezugskalkulation',
  AufstellungZurGewinnberechnungDone = 'aufstellungZurGewinnberechnungDone',
  GewinnBerechnenDone = 'gewinnBerechnenDone',
  Verkaufspreis = 'verkaufspreis',
  EinkaufArtikelubersicht = 'einkaufArtikelübersicht',
  DeckungsbeitragMDF = 'deckungsbeitragMDF',
  Absatzstatistik = 'absatzstatistik',
  Gesamtdeckungsbeitrag = 'gesamtdeckungsbeitrag',
  Ausgangsrechnungen = 'ausgangsrechnungen',
  Mahnplan = 'mahnplan',
  NeuerZahlungsbetragZweiteMahnungDone = 'neuerZahlungsbetragZweiteMahnungDone',
  ZweiteMahnungLetterDone = 'zweiteMahnungLetterDone',
  Ausgangsrechnungen2 = 'ausgangsrechnungen2',
  Verzugszinsenberechnung1 = 'verzugszinsenberechnung1',
  Verzugszinsenberechnung2 = 'verzugszinsenberechnung2',
  NeuerZahlungsbetragLetzteMahnungDone = 'neuerZahlungsbetragLetzteMahnungDone',
  LetzteMahnungLetterDone = 'letzteMahnungLetterDone',
  MesinerRechnung = 'mesinerRechnung',
  MesinerConfirmation = 'mesinerConfirmation',
  CheckTheInvoiceAndOrderMatchDone = 'checkTheInvoiceAndOrderMatchDone',
  HinweiseFurBelegverbuchung = 'hinweiseFürBelegverbuchung',
  Kontenplan = 'kontenplan',
  Musterbeleg = 'musterbeleg',
  AccountForReceiptDone = 'accountForReceiptDone',
  AccountForReceiptWurmDone = 'accountForReceiptWurmDone',
  AccountForReceiptMemoboardDone = 'accountForReceiptMemoboardDone',
  AccountForReceiptFinanzbankDone = 'accountForReceiptFinanzbankDone',
  AccountForReceiptEarthMagDone = 'accountForReceiptEarthMagDone',
  ER743 = 'er743',
  K87 = 'k87',
  K87Clipboard = 'k87Clipboard',
  AR703 = 'ar703',
  K88 = 'k88',
  K88Clipboard = 'k88Clipboard',
  B112 = 'b112',
  K89 = 'k89',
  K89Clipboard = 'k89Clipboard',
  S32 = 's32',
  K90 = 'k90',
  K90Clipboard = 'k90Clipboard',
  Kassabericht = 'kassabericht',
  KassaberichtDone = 'kassaberichtDone',
  ER743Clipboard = 'er743Clipboard',
  FillOutTransferFormDone = 'fillOutTransferFormDone',
}

export const useLibrary = () => {
  const dispatch = useDispatch();
  const { unlockedDocuments } = useSelector((state: RootState) => state.app);

  const setOverlay = (value: string) => {
    dispatch(setCurrentOverlay(`document:${value}`));
  };

  const savedDocuments = useRef<Documents>({
    myDocuments: { label: 'Modul: Auftragsabwicklung', isSeperator: true, },
    [Page.Inquiry]: { label: 'Anfrage', onClick: () => { setOverlay(Page.Inquiry); }},
    [Page.Offer]: { label: 'Angebot', onClick: () => { setOverlay(Page.Offer); }},
    [Page.OrderConfirmation]: { label: 'Auftragsbestätigung', onClick : () => { setOverlay(Page.OrderConfirmation); }},
    [Page.DeliveryNote]: { label: 'Lieferschein Nig GmbH', onClick : () => { setOverlay(Page.DeliveryNote); }},
    [Page.Bill]: { label: 'Rechnung', onClick : () => { setOverlay(Page.Bill); }},
    [Page.BackPayment]: { label: 'Nachzahlung', onClick : () => { setOverlay(Page.BackPayment); }},
    [Page.Stocklist]: { label: 'Lagerbestand', onClick: () => { setOverlay(Page.Stocklist); }},
    [Page.Pricelist]: { label: 'Artikel', onClick: () => { setOverlay(Page.Pricelist); }},
    [Page.ClientDatabase]: { label: 'Kundendatenbank', onClick: () => { setOverlay(Page.ClientDatabase); }},
    [Page.DeliveryNotes]: { label: 'Lieferscheine', onClick : () => { setOverlay(Page.DeliveryNotes); }},
    [Page.ClientAccount]: { label: 'Zahlungseingänge', onClick : () => { setOverlay(Page.ClientAccount); }},
    myApps: { label: 'Modul: Rechnungswesen', isSeperator: true, },
    [Page.Produktanfrage]: { label: 'Produktanfrage', onClick : () => { setOverlay(Page.Produktanfrage); }},
    [Page.AngebotPutsch]: { label: 'Angebot Putsch', onClick : () => { setOverlay(Page.AngebotPutsch); }},
    [Page.AngebotDober]: { label: 'Angebot Dober', onClick : () => { setOverlay(Page.AngebotDober); }},
    [Page.AngebotMeller]: { label: 'Angebot Meller', onClick : () => { setOverlay(Page.AngebotMeller); }},
    [Page.Bezugskalkulation]: { label: 'Bezugskalkulation', onClick : () => { setOverlay(Page.Bezugskalkulation); }},
    [Page.GewinnBerechnenDone]: { label: 'Gewinnberechnung', onClick : () => { setOverlay(Page.GewinnBerechnenDone); }},
    [Page.Verkaufspreis]: { label: 'Verkaufspreisberechnung', onClick : () => { setOverlay(Page.Verkaufspreis); }},
    [Page.EinkaufArtikelubersicht]: { label: 'Artikelübersicht', onClick : () => { setOverlay(Page.EinkaufArtikelubersicht); }},
    [Page.DeckungsbeitragMDF]: { label: 'Berechnung der Stückdeckungsbeiträge', onClick : () => { setOverlay(Page.DeckungsbeitragMDF); }},
    [Page.Absatzstatistik]: { label: `Absatzstatistik ${currentYear-1}`, onClick : () => { setOverlay(Page.Absatzstatistik); }},
    [Page.Gesamtdeckungsbeitrag]: { label: 'Berechnung der Gesamtdeckungsbeiträge', onClick : () => { setOverlay(Page.Gesamtdeckungsbeitrag); }},
    [Page.Ausgangsrechnungen]: { label: 'Offene Posten 4.12', onClick : () => { setOverlay(Page.Ausgangsrechnungen); }},
    [Page.Mahnplan]: { label: 'Mahnplan', onClick : () => { setOverlay(Page.Mahnplan); }},
    [Page.Ausgangsrechnungen2]: { label: 'Offene Posten 23.12', onClick : () => { setOverlay(Page.Ausgangsrechnungen2); }},
    [Page.Verzugszinsenberechnung2]: { label: 'Verzugszinsenberechnung', onClick : () => { setOverlay(Page.Verzugszinsenberechnung2); }},
    [Page.MesinerRechnung]: { label: 'Mesiner Rechnung/Lieferschein', onClick : () => { setOverlay(Page.MesinerRechnung); }},
    [Page.MesinerConfirmation]: { label: 'Mesiner Bestell-/Auftragsbestätigung', onClick : () => { setOverlay(Page.MesinerConfirmation); }},
    [Page.HinweiseFurBelegverbuchung]: { label: 'Hinweise für Belegverbuchung', onClick : () => { setOverlay(Page.HinweiseFurBelegverbuchung); }},
    [Page.Kontenplan]: { label: 'Kontenplan (Auszug)', onClick : () => { setOverlay(Page.Kontenplan); }},
    [Page.Musterbeleg]: { label: 'Musterbeleg', onClick : () => { setOverlay(Page.Musterbeleg); }},
    [Page.Kassabericht]: { label: `Kassabericht 11.11.${currentYear}`, onClick : () => { setOverlay(Page.Kassabericht); }},
    [Page.KassaberichtDone]: { label: `Kassabericht 12.11.${currentYear}`, onClick : () => { setOverlay(Page.KassaberichtDone); }},
    [Page.ER743Clipboard]: { label: `ER 743`, onClick : () => { setOverlay(Page.ER743Clipboard); }},
  });

  const prepareDocuments = useCallback(() => {
    return Object.keys(savedDocuments.current).map(key => ({ 
      ...savedDocuments.current[key],
      locked: unlockedDocuments.indexOf(key) === -1 
    }));
  }, [unlockedDocuments])

  const [documents, setDocuments] = useState(prepareDocuments());

  useEffect(() => {
    setDocuments(prepareDocuments());
  }, [prepareDocuments]);

  return [documents];
}
