import { Type } from "../../document";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import { useDispatch } from "react-redux";
import useFunctions from "../../functions";
import {currentYear} from "../variables";

export const Absatzstatistik = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Absatzstatistik ${currentYear-1}`,
        src: require('static/images/tasks/mission7/mKb_Absatzstatistik.png'),
        hotspots: {
            0: { x: '48%', y: '23.5%' },
            1: { x: '48.5%', y: '33.5%' },
            2: { x: '48.5%', y: '43.5%' },
            3: { x: '52.5%', y: '53.5%' },
            4: { x: '53%', y: '63.5%' },
            5: { x: '55.5%', y: '73.5%' },
            6: { x: '72%', y: '83.5%' },
            7: { x: '55.2%', y: '23%', width: '15.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Absatzstatistik',
                        order: 1,
                        title: 'MDF-Memoboard Weiß 100 x 200 cm (Absatz)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '1245,00'}))
                } },
            8: { x: '56%', y: '33.1%', width: '15.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Absatzstatistik',
                        order: 2,
                        title: 'MDF-Memoboard Natur 60 x 90 cm (Absatz)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '1315,00'}))
                } },
            9: { x: '56%', y: '43.1%', width: '15.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Absatzstatistik',
                        order: 3,
                        title: 'MDF-Memoboard Natur 100 x 200 cm (Absatz)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '1327,00'}))
                } },
            10: { x: '59.7%', y: '53.2%', width: '15.2%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Absatzstatistik',
                        order: 4,
                        title: 'MDF-Memoboard Weiß 45 x 60 cm (Absatz)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '3584,00'}))
                } },
            11: { x: '60%', y: '63.2%', width: '15.2%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Absatzstatistik',
                        order: 5,
                        title: 'MDF-Memoboard Weiß 42 x 29 cm (Absatz)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '3658,00'}))
                } },
            12: { x: '62.5%', y: '73.2%', width: '15.2%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Absatzstatistik',
                        order: 6,
                        title: 'MDF-Memoboard Natur 45 x 60 cm (Absatz)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '3859,00'}))
                } },
            13: { x: '79%', y: '83.2%', width: '15.2%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Absatzstatistik',
                        order: 7,
                        title: 'MDF-Memoboard Natur 42 x 29 cm (Absatz)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '5560,00'}))
                } },
        },
        initialAnswers: {
            0: { label: `<b>1.245,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            1: { label: `<b>1.315,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            2: { label: `<b>1.327,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            3: { label: `<b>3.584,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            4: { label: `<b>3.658,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            5: { label: `<b>3.859,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
            6: { label: `<b>5.560,00</b> <i class="lnr lnr-clipboard-empty"/>` , active: false },
        }
    };
}