import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setClipboardCurrent, setOverlay, setAllowClipboardCopy} from "../../redux/slices/appSlice";

import styles from './ClipboardListItem.module.scss';
import {RootState} from "../../redux/rootReducer";
import {Type} from "../HotspotChoice";
import {computeEnglishValue} from "../../helpers";

export type Item = {
  title: string;
  value: string;
  isEnabled: boolean;
  isPercentage: boolean;
}

type Props = {
  item?: Item;
  title?: string;
};

const ListItem = ({
  item,
  title
}: Props) => {
  const dispatch = useDispatch();
  const { clipboardType, clipboardCurrent } = useSelector((state: RootState) => state.app);

  const setInputValue = () => {
    switch (clipboardType) {
      case Type.Input:
        window._paq.push(['trackEvent', 'Interaction', 'Clipboard', 'Button Click']);
        if (!title && item) {
          dispatch(setClipboardCurrent(item.value))
          dispatch(setAllowClipboardCopy(false))
          dispatch(setOverlay(null));
        }
        break;

      case Type.MiniCalculator:
        window._paq.push(['trackEvent', 'Interaction', 'Clipboard', 'Button Click']);
        if (!title && item) {
          dispatch(setClipboardCurrent(`${clipboardCurrent}${item.value}`))
          dispatch(setAllowClipboardCopy(false))
          dispatch(setOverlay(null));
        }
        break;

      case Type.Calculator:
        window._paq.push(['trackEvent', 'Interaction', 'Clipboard', 'Button Click']);
        if (!title && item) {
          if(!item.isPercentage) {
            dispatch(setClipboardCurrent(`${clipboardCurrent}${item.value}`))
          } else {
            let decimalValue = computeEnglishValue(item.value)/100;
            dispatch(setClipboardCurrent(`${clipboardCurrent}${decimalValue.toString().replace('.', ',')}`))
          }
          dispatch(setAllowClipboardCopy(false))
          dispatch(setOverlay(null));
        }
        break;

      case Type.Choice:
        // window._paq.push(['trackEvent', 'Interaction', 'Clipboard', 'Button Click']);
        if (!title && item) {
          // dispatch(setAnsweredTasks({ mission: currentMission, task, answer: answer.id, option: answer }));
          dispatch(setOverlay(null));
        }
        break;

      default:
        dispatch(setAllowClipboardCopy(false))
        dispatch(setOverlay('null'))
    }
  }
  return (
    <div className={`
        ${title ? styles.accordion : ''}`}
        onClick={item ? setInputValue : ()=>{}}>
      <div className={`${styles.item}
        ${title ? styles.accordion : ''}`}>

        <div>
          <span>{item && item.value}</span>
          <span>{title}</span>
          {item && item.title && <span className={styles.caption}>{item.title}</span>}
        </div>
        { title && <i className={`lnr lnr-plus`}/> }
      </div>
    </div>
  );
}

export default ListItem;