import { useDispatch } from "react-redux";

import { useFunctions } from "../../functions";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import { Type } from "../../document";
import { currentYear } from "../variables";

export const AngebotMeller = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: 'Angebot Meller GmbH',
        src: require('static/images/tasks/mission5/mAV_AngebotMeller.png'),
        hotspots: {
            0: { x: '5%', y: '18%' },
            1: { x: '5%', y: '22%' },
            2: { x: '5%', y: '26%' },
            3: { x: '70%', y: '41.2%' },
            4: { x: '80%', y: '57%' },
            5: { x: '78%', y: '61.5%' },
            6: { x: '35%', y: '66.5%' },
            7: { x: '65.5%', y: '70%' },
            8: { x: '45%', y: '76.5%' },
            9: { x: '73%', y: '81%' },
            10: { x: '43%', y: '86%' },
            11: { x: '81.8%', y: '41.2%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Meller',
                        order: 1,
                        title: 'Artikel: Glas-Whiteboard Groß (Preis/Stk.)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '105,90'}))
                } },
            12: { x: '84%', y: '56.8%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Meller',
                        order: 2,
                        title: 'Artikel: Glas-Whiteboard Groß (Anzahl)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '100'}))
                } },
            13: { x: '82.8%', y: '61%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Meller',
                        order: 3,
                        title: 'Rabatt',
                        isEnabled: true,
                        isPercentage: true,
                        value: '20,00 %'}))
                } },
            14: { x: '84%', y: '76%', width: '9.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Meller',
                        order: 4,
                        title: 'Zustellpauschale (Euro)',
                        isEnabled: true,
                        isPercentage: false,
                        value: '19,90'}))
                } },
            15: { x: '84.7%', y: '80.5%', width: '9.5%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Meller',
                        order: 5,
                        title: 'Zustellpauschale (USt)',
                        isEnabled: true,
                        isPercentage: true,
                        value: '20,00 %'}))
                } },
            16: { x: '57%', y: '85.5%', width: '8%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: 'Angebot Meller',
                        order: 6,
                        title: 'Skonto',
                        isEnabled: true,
                        isPercentage: true,
                        value: '3,00 %'}))
                } },
        },
        initialAnswers: {
            0: { label: `Wir freuen uns über Ihre Anfrage und können Ihnen folgendes Angebot,` , active: false },
            1: { label: `gültig bis 31. August ${currentYear}, unterbreiten:` , active: false },
            2: { label: `` , active: false },
            3: { label: `<b>EUR 105,90</b><span class="lnr lnr-clipboard-empty"></span>` , active: false },
            4: { label: `<b>100</b> <span class="lnr lnr-clipboard-empty"></span> Stück` , active: false },
            5: { label: `<b>20 %</b> <span class="lnr lnr-clipboard-empty"></span> Rabatt` , active: false },
            6: { label: `derzeit nicht vorrätig, lieferbar voraussichtlich` , active: false },
            7: { label: `ab Anfang September` , active: false },
            8: { label: `Transportkostenpauschale <b>19,90</b> <span class="lnr lnr-clipboard-empty"></span> Euro` , active: false },
            9: { label: `(exkl. <b>20 %</b> <span class="lnr lnr-clipboard-empty"></span> USt)` , active: false },
            10: { label: `14 Tage <b>3 %</b> <span class="lnr lnr-clipboard-empty"></span> Skonto, 30 Tage netto Kassa` , active: false },
        }
    };
}
