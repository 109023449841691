import React from 'react';

const Page = () => {
  return (
    <>
      <h2>Offenlegung nach § 25 Mediengesetz</h2>

      <h3>Wirtschaftskammer Österreich</h3>
      <ul>
        <li>Wiedner Hauptstraße 63, 1045 Wien</li>
        <li>Telefon: <a href='tel:+43 5 90 900'>+43 5 90 900</a></li>
        <li>Fax: <a href='tel:+43 5 90 900 250'>+43 5 90 900 250</a></li>
        <li>Kontakt: <a rel='noopener noreferrer' target='_blank' href='https://wko.at/kontaktformular'>www.wko.at/kontaktformular</a></li>
        <li>Web: <a rel='noopener noreferrer' target='_blank' href='https://wko.at/'>www.wko.at</a></li>
      </ul>

      <h3>Vertretungsbefugte Organe</h3>
      <ul>
        <li>Präsident: Dr. Harald Mahrer</li>
      </ul>

      <h3>Vertretungsbefugte Organe</h3>
      <p>
        Interessenvertretung sowie Information, Beratung und Unterstützung 
        der jeweiligen Mitglieder als gesetzliche Interessenvertretung
      </p>

      <h3>Richtung der Website ("Blattlinie")</h3>
      <p>Förderung der Ziele des Tätigkeitsbereiches</p>


      <h2>Entwicklerteam</h2>

      <h3>ibw - Institut für Bildungsforschung der Wirtschaft</h3>
      <ul>
        <li>Rainergasse 38, 1050 Wien</li>
        <li>Telefon: <a href='tel:+43 1 545 16 71-0'>+43 1 545 16 71-0</a></li>
        <li>E-Mail: <a href='mailto:info@ibw.at'>info@ibw.at</a></li>
        <li>Web: <a rel='noopener noreferrer' target='_blank' href='https://ibw.at/'>www.ibw.at</a></li>
      </ul>

      <h3>ovos media gmbh</h3>
      <ul>
        <li>Schottenfeldgasse 60/36-38, 1070 Wien</li>
        <li>Telefon: <a href='tel:+43 1 890 33 89'>+43 1 890 33 89</a></li>
        <li>E-Mail: <a href='mailto:office@ovos.at'>office@ovos.at</a></li>
        <li>Web: <a rel='noopener noreferrer' target='_blank' href='https://ovos.at/'>www.ovos.at</a></li>
      </ul>


      <h2>Hinweise und Haftungsausschluss</h2>
      <p>WKO.at ist ein Gemeinschaftsprojekt aller Wirtschaftskammern und ihrer Fachorganisationen.</p>
      <p>
        Im Hinblick auf die technischen Eigenschaften des Internet kann keine Gewähr für die Authentizität, 
        Richtigkeit und Vollständigkeit der im Internet zur Verfügung gestellten Informationen übernommen werden.
        Es wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der gegenständlichen Homepage 
        und ihrer Inhalte übernommen.
      </p>
      <p>
        Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, 
        die aus der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Homepage erwachsen, 
        wird, soweit rechtlich zulässig, ausgeschlossen.
      </p>
      <p>
        Der Inhalt dieser Homepage ist urheberrechtlich geschützt. Die Informationen sind nur für die
        persönliche Verwendung bestimmt. Jede weitergehende Nutzung insbesondere die Speicherung in Datenbanken,
        Vervielfältigung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte - auch in Teilen
        oder in überarbeiteter Form - ohne Zustimmung der jeweiligen Organisation ist untersagt.
      </p>
      <p>
        Hyperlinks auf WKO.at und die Startseiten der Landeskammern und Fachorganisationen sind willkommen. 
        Jede Einbindung einzelner Seiten unseres Angebotes in fremde Frames ist zu unterlassen.
      </p>
      <p>
        Soweit im Content personenbezogene Bezeichnungen nur in männlicher Form angeführt sind,
        beziehen sie sich auf Frauen und Männer in gleicher Weise.
      </p>
    </>
  );
}

export default Page;