import { useDispatch } from "react-redux";

import { useFunctions } from "../functions";
import { Tasks } from "./datatypes";

import {
    setAllowClipboardCopy,
    setFeedback,
    setOverlay as setCurrentOverlay,
} from "../../redux/slices/appSlice";
import { setTask as setCurrentTask, setTaskSkippable } from "../../redux/slices/taskSlice";
import { Type } from "../../components/HotspotChoice";
import styles from "../../pages/ImageCloze/ImageCloze.module.scss";
import { Page as Document } from "../library";
import { emptyAnswerValue } from "../../components/MiniCalculator";

export const MissionEleven = () => {
    const [{ setImageClozeAnswer, setImageClozePoints, setOverlay, setPage }] = useFunctions();
    const dispatch = useDispatch();

    const currentYear = new Date().getFullYear();

    const missionElevenData: Tasks = {
        accountForReceipt: {
            title: 'Beleg ER 743 vorkontieren',
            subtitle: '<span class="tip-label">Tipp:</span> Beginne immer mit der Buchung auf der Soll-Seite. Falls mehrere Konten auf einer Seite stehen, beginne bitte mit dem Konto mit dem höheren Betrag.',
            src: require('static/images/tasks/mission11/mBv_Lehner.png'),
            hotspots: {
                0: {
                    x: '32%',
                    y: '78.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    className: `${styles.gochi}`,
                    options: [
                        {label: `0660`, onClick: setImageClozeAnswer },
                        {label: `5010`, onClick: setImageClozeAnswer },
                        {label: `7030`, correct: true, onClick: setImageClozeAnswer },
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '50%',
                    y: '78.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['282', '282,0', '282,00'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '67%',
                    y: '78.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '32%',
                    y: '81.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2500', '2500,0', '2500,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '50%',
                    y: '81.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['56,4', '56,40'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '67%',
                    y: '81.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '32%',
                    y: '84.2%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['3300', '3300,0', '3300,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '50%',
                    y: '84.2%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '67%',
                    y: '84.2%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['338,4', '338,40'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                9: {x: '74.8%', y: '30%'},
                10: {x: '72.4%', y: '33.5%'},
                11: {x: '79%', y: '40%'},
            },
            initialAnswers: {
                9: { label: `${currentYear}-11-12`, active: false},
                10: { label: `1.November ${currentYear}`, active: false},
                11: { label: `${currentYear}-11-12`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true))
                dispatch(setFeedback('accountForReceipt'));
                setPage('feedback');
            },
        },
        accountForReceiptWurm: {
            title: 'Beleg K 87 vorkontieren',
            subtitle: '<span class="tip-label">Tipp:</span> Beginne immer mit der Buchung auf der Soll-Seite. Falls mehrere Konten auf einer Seite stehen, beginne bitte mit dem Konto mit dem höheren Betrag.',
            src: require('static/images/tasks/mission11/mBv_Wurm.png'),
            hotspots: {
                0: {
                    x: '32%',
                    y: '78.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['7600', '7600,0', '7600,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '50%',
                    y: '78.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['5,81'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '67%',
                    y: '78.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '32%',
                    y: '81.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2500', '2500,0', '2500,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '50%',
                    y: '81.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['1,16'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '67%',
                    y: '81.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '32%',
                    y: '84.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2700', '2700,0', '2700,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '50%',
                    y: '84.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '67%',
                    y: '84.8%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['6,97'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                9: {x: '22.5%', y: '23%'},
            },
            initialAnswers: {
                9: { label: `Datum: 12.11.${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('accountForReceiptWurm'));
                setPage('feedback');
                dispatch(setTaskSkippable(true))
            },
        },
        accountForReceiptMemoboard: {
            title: 'Beleg AR 703 vorkontieren',
            subtitle: '<span class="tip-label">Tipp:</span> Beginne immer mit der Buchung auf der Soll-Seite. Falls mehrere Konten auf einer Seite stehen, beginne bitte mit dem Konto mit dem höheren Betrag.',
            src: require('static/images/tasks/mission11/mBv_Memoboard.png'),
            hotspots: {
                0: {
                    x: '32%',
                    y: '81%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2000', '2000,0', '2000,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '50%',
                    y: '81%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['3.752,40', '3.752,4', '3752,40', '3752,4'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '67%',
                    y: '81%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '32%',
                    y: '83.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['4000', '4000,0', '4000,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '50%',
                    y: '83.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '67%',
                    y: '83.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['3127,00', '3127,0', '3127', '3.127,00', '3.127,0', '3.127'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '32%',
                    y: '86.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['3500', '3500,0', '3500,00', '3.500', '3.500,0', '3.500,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '50%',
                    y: '86.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '67%',
                    y: '86.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['625,4', '625,40'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                9: {x: '4%', y: '30%'},
            },
            initialAnswers: {
                9: { label: `<b>Rechnung Nr. 15718-${currentYear.toString().substr(-2)}</b> vom ${currentYear}-11-12 (Rechnungsdatum = Lieferdatum)`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('accountForReceiptMemoboard'));
                setPage('feedback');
                dispatch(setTaskSkippable(true))
            },
        },
        accountForReceiptFinanzbank: {
            title: 'Beleg B 112 vorkontieren',
            subtitle: '<span class="tip-label">Tipp:</span> Beginne immer mit der Buchung auf der Soll-Seite. Falls mehrere Konten auf einer Seite stehen, beginne bitte mit dem Konto mit dem höheren Betrag.',
            src: require('static/images/tasks/mission11/mBv_Finanzbank.png'),
            hotspots: {
                0: {
                    x: '32%',
                    y: '61.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2800', '2800,0', '2800,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '50%',
                    y: '61.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: ['2.449,22', '2449,22'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '67%',
                    y: '61.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '32%',
                    y: '64.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['4410', '4410,0', '4410,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '50%',
                    y: '64.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: ['41,65'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '67%',
                    y: '64.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '32%',
                    y: '68.1%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['3500', '3500,0', '3500,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '50%',
                    y: '68.1%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: ['8,33'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '67%',
                    y: '68.1%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                9: {
                    x: '32%',
                    y: '71.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2000', '2000,0', '2000,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                10: {
                    x: '50%',
                    y: '71.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                11: {
                    x: '67%',
                    y: '71.5%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: ['2499,20', '2499,2', '2.499,20', '2.499,2'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                12: {
                    x: '32%',
                    y: '84.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['7700', '7700,0', '7700,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                13: {
                    x: '50%',
                    y: '84.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: ['1.560,00', '1.560,0', '1.560','1560,00', '1560,0', '1560'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                14: {
                    x: '67%',
                    y: '84.7%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                15: {
                    x: '32%',
                    y: '87.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2800', '2800,0', '2800,00','2.800', '2.800,0', '2.800,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                16: {
                    x: '50%',
                    y: '87.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                17: {
                    x: '67%',
                    y: '87.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: ['1.560,00', '1.560,0', '1.560','1560,00', '1560,0', '1560'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                18: {x: '40%', y: '19%'},
            },
            initialAnswers: {
                18: { label: `13.11.${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('accountForReceiptFinanzbank'));
                setPage('feedback');
                dispatch(setTaskSkippable(true))
            },
        },
        accountForReceiptEarthMag: {
            title: 'Beleg S 32 vorkontieren',
            subtitle: '<span class="tip-label">Tipp:</span> Beginne immer mit der Buchung auf der Soll-Seite. Falls mehrere Konten auf einer Seite stehen, beginne bitte mit dem Konto mit dem höheren Betrag.',
            src: require('static/images/tasks/mission11/mBv_EarthMag.png'),
            hotspots: {
                0: {
                    x: '48.5%',
                    y: '78.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['5010', '5010,0', '5010,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                1: {
                    x: '65.5%',
                    y: '78.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['370,00', '370,0', '370'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                2: {
                    x: '82.5%',
                    y: '78.3%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '48.5%',
                    y: '80.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['2500', '2500,0', '2500,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                4: {
                    x: '65.5%',
                    y: '80.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['74,00', '74,0', '74'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                5: {
                    x: '82.5%',
                    y: '80.9%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                6: {
                    x: '48.5%',
                    y: '83.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '4%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: ['3190', '3190,0', '3190,00'],
                    hasNoDecimals: true,
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    options: [
                        {label: 'Im Kontenplan nachsehen...', noAnswer: true, onClick: () => {
                                setOverlay(Document.Kontenplan)
                            }},
                    ],
                    onSubmit: setImageClozeAnswer,
                },
                7: {
                    x: '65.5%',
                    y: '83.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.MiniCalculator,
                    className: `${styles.gochi}`,
                    correct: [emptyAnswerValue],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                8: {
                    x: '82.5%',
                    y: '83.4%',
                    labelXAlignment: 'right',
                    labelXOffset: '6.5%',
                    type: Type.Calculator,
                    className: `${styles.gochi}`,
                    correct: ['444,00', '444,0', '444'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 0,
                    onSubmit: setImageClozeAnswer,
                },
                9: {x: '78%', y: '21.6%'},
                10: {x: '7.3%', y: '53.7%'},
            },
            initialAnswers: {
                9: { label: `12.11.${currentYear}`, active: false},
                10: { label: `Lieferdatum: 15.11.${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setFeedback('accountForReceiptEarthMag'));
                setPage('feedback');
                dispatch(setTaskSkippable(true))
            },
        },
    }

    return missionElevenData;
}
