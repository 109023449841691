import React from 'react';

import styles from './MissionChoiceBox.module.scss';

export type Props = {
  title?: string;
  missionUnlocked?: boolean;
  onClick?: (e: any) => void;
};

const MissionChoiceBox = ({
  title,
  missionUnlocked,
  onClick,
}: Props) => {
    console.log(missionUnlocked);
    if (missionUnlocked){
        return null;
    }
    return (
        <div className={styles.container} onClick={onClick}>

            <div className={styles.content}>
                {title && <div className={styles.title}><strong>{title}</strong></div>}
            </div>

            <div className={styles.arrow}>
                <span className="lnr lnr-chevron-right" />
            </div>
        </div>
    )

};

export default MissionChoiceBox;