import React from 'react';
import { useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";

import Router from 'containers/Router';

import ConnectionOverlay from 'components/ConnectionOverlay';
import Debug from 'components/Debug';
import HintOverlay from 'components/HintOverlay';
import Overlay from 'components/Overlay';
import NotepadOverlay from 'components/NotepadOverlay';
import Timer from 'components/Timer';

import Start from 'pages/Start';
import User from 'pages/User';
import Tutorial from 'pages/Tutorial';
import Scene from 'pages/Scene';
import Feedback from 'pages/Feedback';
import EndFeedback from 'pages/EndFeedback';
import Dialogue from 'pages/Dialogue';
import TextCloze from 'pages/TextCloze';
import ImageCloze from 'pages/ImageCloze';
import Notification from 'pages/Notification';
import GameOver from 'pages/GameOver';

import { persistor } from 'redux/store';

import './styles.scss';
import {reset} from "./redux/rootReducer";

import { useFunctions } from "hooks/functions";

const App = () => {
  const dispatch = useDispatch();
  const [{ setPage }] = useFunctions();

  const resetApp = () => {
    setPage('/');
    dispatch(reset());
    window.location.reload();
  }

  // @ts-ignore
  const ErrorFallback = () => {
    return (
        <div role="alert">
          <p>Eine neue Version von Kompetent im Office ist verfügbar. Bitte klicken Sie auf den Button um die neue Version zu laden.</p>
          <button onClick={resetApp}>App neu laden</button>
        </div>
    )
  }

  return(
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Router>
            <div id='container'>
              <Debug />
              <Timer />
              <Overlay />
              <NotepadOverlay />
              <HintOverlay />
              <ConnectionOverlay />
              <Switch>
                <Route path='/user'><User /></Route>
                <Route path='/tutorial'><Tutorial /></Route>
                <Route path='/scene'><Scene /></Route>
                <Route path='/dialogue'><Dialogue /></Route>
                <Route path='/notification'><Notification /></Route>
                <Route path='/feedback'><Feedback /></Route>
                <Route path='/end-feedback'><EndFeedback /></Route>
                <Route path='/game-over'><GameOver /></Route>

                <Route path='/image-cloze'><ImageCloze /></Route>
                <Route path='/text-cloze'><TextCloze /></Route>

                <Route path='/'><Start /></Route>
              </Switch>
            </div>
          </Router>
        </ErrorBoundary>
      </PersistGate>

  );
}

export default App;
