import {useDispatch, useSelector} from "react-redux";

import {Dialogues} from "./datatypes";
import {RootState} from "../../redux/rootReducer";
import { teamleadImage } from "./variables";

import { setNotification } from "../../redux/slices/appSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";
import useFunctions from "../functions";

export const MissionSix = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();
    const [{ setPoints, setAnswer, setPage }] = useFunctions();

    const missionSixData: Dialogues = {
        weMustDetermineSalesPrice: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Bevor wir die Glasboards und Magnetsets der Firma Dober bestellen, müssen wir uns noch unseren Verkaufspreis für dieses Produkt
                            anschauen. Aus Konkurrenzgründen darf der Bruttoverkaufspreis maximal 150 Euro betragen. Wir wollen einen Gewinn von 10 % erwirtschaften. 
                            Schau dir bitte an, ob wir das schaffen.`,
                    children: [1],
                },
                1: {
                    speaker: 'teamlead',
                    text: `Was machst du als Erstes?`,
                    children: [2, 3],
                },
                2: {
                    speaker: 'player',
                    text: 'Die Kalkulationsgrundlagen erfragen.',
                    alternativeText: 'Ich brauche zunächst noch die Kalkulationsgrundlagen.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '2-3', label: 'Die Kalkulationsgrundlagen erfragen.', correct:true });
                        setPoints({ answer: '2-3', points: 1 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Die Kalkulation durchführen.',
                    alternativeText: 'Ich werde gleich mit der Kalkulation beginnen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Die Kalkulation durchführen.' });
                        setPoints({ answer: '2-3', points: 0 });
                    }
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Unsere üblichen Sätze sind: 30 % Regien (Gemeinkosten), 2 % Kundenskonto und 10 % Kundenrabatt.',
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Dir fehlen ja noch die Kalkulationsgrundlagen.',
                    children: [6]
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Unsere üblichen Sätze sind: 30 % Regien (Gemeinkosten), 2 % Kundenskonto und 10 % Kundenrabatt.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('prepareCalculationProfits'));
                setPage('image-cloze');
            }
        },
        canWeCoverOurProfitClaims: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Danke für die Berechnung. Können wir mit dem Produkt unseren Gewinnanspruch von 10 % abdecken? Erinnere dich, aus Konkurrenzgründen darf unser Bruttoverkaufspreis maximal 150 Euro betragen.`,
                    children: [1, 2],
                },
                1: {
                    speaker: 'player',
                    text: 'Ja',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Ja' });
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Nein',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Nein', correct:true });
                        setPoints({ answer: '1-2', points: 1 });
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: 'Du hast leider nicht recht, wir sind deutlich unter 10 % Gewinn. Überlege bitte, welche Möglichkeiten wir jetzt haben.',
                    children: [5]
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Du hast recht, wir sind leider deutlich unter 10 %. Überlege bitte, welche Möglichkeiten wir jetzt haben.',
                    children: [5]
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Was schlägst du vor?',
                    children: [6,7,8]
                },
                6: {
                    speaker: 'player',
                    text: 'Den USt-Satz reduzieren.',
                    alternativeText: 'Wir sollten den USt-Satz reduzieren!',
                    children: [9],
                    onClick: () => {
                        setAnswer({ id: '6-8', label: 'Wir sollten den USt-Satz reduzieren!' });
                        setPoints({ answer: '6-8', points: 0 });
                    }
                },
                7: {
                    speaker: 'player',
                    text: 'Den Gewinnsatz reduzieren.',
                    alternativeText: 'Wir könnten unseren Gewinnsatz reduzieren!',
                    children: [10],
                    onClick: () => {
                        setAnswer({ id: '6-8', label: 'Wir könnten unseren Gewinnsatz reduzieren!', correct: true });
                        setPoints({ answer: '6-8', points: 1 });
                    }
                },
                8: {
                    speaker: 'player',
                    text: 'Den Preis mit Lieferanten verhandeln.',
                    alternativeText: 'Wir sollten den Preis mit unseren Lieferanten verhandeln!',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '5-7', label: 'Wir sollten den Preis mit Lieferanten verhandeln!', correct: true });
                        setPoints({ answer: '6-8', points: 2 });
                    }
                },
                9: {
                    speaker: 'teamlead',
                    text: 'Der USt-Satz von 20 % ist gesetzlich vorgegeben und kann nicht reduziert werden. Ich werde den Lieferanten kontaktieren und dich dann über das Ergebnis informieren.',
                },
                10: {
                    speaker: 'teamlead',
                    text: 'Ja, das ist eine Möglichkeit. Bevor wir das tun, verhandle ich aber noch mit dem Lieferanten über einen besseren Preis.',
                },
                11: {
                    speaker: 'teamlead',
                    text: 'Das ist eine gute Idee. Ich werde den Lieferanten kontaktieren und dich dann über das Ergebnis informieren.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('weGetAdditionalDiscount'));
                dispatch(setNotification('weGetAdditionalDiscount'))
                setPage('notification');
            }
        },
        weGetAdditionalDiscount: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Ich habe soeben mit der Firma Dober telefoniert. 
                    Ich habe in Aussicht gestellt, dass wir zukünftig größere Mengen des Produkts bestellen werden. 
                    Aus diesem Grund erhalten wir auf das ursprüngliche Angebot noch einen zusätzlichen Rabatt von 5 %.`,
                    children: [1],
                },
                1: {
                    speaker: 'teamlead',
                    text: 'Was sollten wir deiner Meinung nach als Nächstes tun?',
                    children: [2,3,4],
                },
                2: {
                    speaker: 'player',
                    text: 'Das Angebot der Firma Dober ablehnen.',
                    alternativeText: 'Wir sollten das Angebot der Firma Dober ablehnen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Wir sollten das Angebot der Firma Dober ablehnen.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: 'Das Produkt bestellen.',
                    alternativeText: 'Wir können die Bestellung nun durchführen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Wir können die Bestellung nun durchführen.' });
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: 'Den Verkaufspreis berechnen.',
                    alternativeText: 'Jetzt muss der neue Verkaufspreis berechnet werden.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Jetzt muss der neue Verkaufspreis berechnet werden.', correct: true });
                        setPoints({ answer: '2-4', points: 1 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: 'Du bist zu voreilig. Wir müssen erst schauen, ob es sich jetzt ausgeht, dass das Glasboard mit unseren Vorgaben im Verkauf maximal 150 Euro kostet. Bitte führe die entsprechende Berechnung durch.',
                },
                6: {
                    speaker: 'teamlead',
                    text: 'Genau! Rechne bitte nach, ob das Glasboard nach unseren Vorgaben nun maximal 150 Euro im Verkauf kosten kann.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('sellingPriceCalculation'));
                setPage('image-cloze');
            }
        },
        canWeNowMeetSalesPriceAndProfitTargets: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Können wir die Vorgaben zum Verkaufspreis und Gewinn nun einhalten?`,
                    children: [1,2],
                },
                1: {
                    speaker: 'player',
                    text: 'Ja',
                    children: [3],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Ja', correct: true });
                        setPoints({ answer: '1-2', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: 'Nein',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-2', label: 'Nein' });
                        setPoints({ answer: '1-2', points: 0 });
                    }
                },
                3: {
                    speaker: 'teamlead',
                    text: 'Stimmt. Mit dem zusätzlichen Rabatt, können wir die Vorgaben einhalten.',
                },
                4: {
                    speaker: 'teamlead',
                    text: 'Stimmt nicht. Mit dem zusätzlichen Rabatt, können wir die Vorgaben einhalten.',
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setNotification('mission6Feedback'));
                dispatch(setTask(''));
                setPage('notification');
            }
        },
    }

    return missionSixData;
}
