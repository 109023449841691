import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/rootReducer';

import Menu from 'pages/Menu';
import MenuPage from 'pages/MenuPage';
import Library from 'pages/Library';
import Joker from 'pages/Joker';
import Document from 'pages/Document';
import Clipboard from 'pages/Clipboard';

import styles from './Overlay.module.scss';

const Overlay = () => {
  const { overlay } = useSelector((state: RootState) => state.app);

  let element = null;

  if (overlay) {
    switch (overlay) {
      case 'menu':
        element = <Menu />;
        break;
      case 'library':
        element = <Library />;
        break;
      case 'joker':
        element = <Joker />;
        break;
      case 'clipboard':
        element = <Clipboard />;
        break;
    }
  
    if (overlay.indexOf('document') > -1) {
      element = <Document />;
    } else if (overlay.indexOf('menuPage') > -1) {
      element = <MenuPage />;
    }
  }

  return element && <div className={styles.container}>{element}</div>;
};

export default Overlay;