import React from 'react';
import ReactDOM from 'react-dom';

import styles from './CalendarBox.module.scss';
import Calendar, {Option, Props as CalendarProps} from "../Calendar";

export type Props = CalendarProps & {
    options?: Option[];
    onOverlayClick?: () => void;
};

const CalendarBox = ({
  onOverlayClick,
  hasYear,
  year,
  monthNumber,
  options,
  onEnter,
}: Props) => {
  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={onOverlayClick}>
      <div onClick={e => { e.stopPropagation(); }}>
        <Calendar onEnter={onEnter} hasYear={hasYear} year={year} monthNumber={monthNumber} options={options} className={styles.calendar} />
      </div>
    </div>,
    document.getElementById('container') as HTMLElement
  );
};

export default CalendarBox;