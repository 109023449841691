import { Type } from "../../document";
import {currentYear} from "../variables";

export const MesinerConfirmation = () => {

    return {
        type: Type.ImageCloze,
        title: `Mesiner Bestell-/Auftragsbestätigung`,
        src: require('static/images/tasks/mission9/mBp_Auftragsbestaetigung.png'),
        hotspots: {
            0: {x: '77%', y: '23%'},
            1: {x: '77%', y: '25.6%'},
            2: {x: '77%', y: '28.2%'},
        },
        initialAnswers: {
            0: {label: `85464/${currentYear}`, active: false},
            1: {label: `${currentYear}-11-03`, active: false},
            2: {label: `${currentYear}-11-01`, active: false},
        }
    };
}