import { useDispatch, useSelector } from "react-redux";

import { Dialogues } from "./datatypes";
import { RootState } from "../../redux/rootReducer";
import { teamleadImage } from "./variables";

import { setMission, setNotification as setCurrentNotification, setNotification, setWasJokerUsed, setUnlockedMissions } from "../../redux/slices/appSlice";
import { pauseTimer } from "../../redux/slices/scoreSlice";

import { setTask } from "../../redux/slices/taskSlice";
import { Orientation } from "../../components/Dialogue/SpeechBubble";
import useFunctions from "../functions";

export const MissionTen = () => {
    const { image: userImage } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [{ setPoints, setAnswer, setPage }] = useFunctions();

    const missionTenData: Dialogues = {
        pleasePrepareTheReceipts: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Bitte bereite die Belege für die Verbuchung vor.`,
                    children: [1],
                },
                1: {
                    speaker: 'teamlead',
                    text: `Was machst du als Erstes?`,
                    children: [2,3,4],
                },
                2: {
                    speaker: 'player',
                    text: `Das Datum des Belegeingangs vermerken.`,
                    alternativeText: 'Ich werde das Datum des Belegeingangs vermerken.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Das Datum des Belegeingangs vermerken.', correct: true});
                        setPoints({ answer: '2-4', points: 1 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: `Die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.`,
                    alternativeText: 'Ich werde die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.'});
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                4: {
                    speaker: 'player',
                    text: `Die Belege verbuchen.`,
                    alternativeText: 'Ich werde die Belege gleich verbuchen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '2-4', label: 'Die Belege verbuchen.'});
                        setPoints({ answer: '2-4', points: 0 });
                    }
                },
                5: {
                    speaker: 'teamlead',
                    text: `Richtig!`,
                    children: [7],
                },
                6: {
                    speaker: 'teamlead',
                    text: `Du bist ein wenig zu schnell. Zuerst muss das Datum des Belegeingangs vermerkt werden.`,
                    children: [7],
                },
                7: {
                    speaker: 'teamlead',
                    text: `Was ist als Nächstes zu tun, nachdem das Eingangsdatum vermerkt wurde?`,
                    children: [8,9,10],
                },
                8: {
                    speaker: 'player',
                    text: `Die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.`,
                    alternativeText: 'Jetzt müssen die Belege nach Belegkreisen sortiert und fortlaufend nummeriert werden.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.' });
                        setPoints({ answer: '8-10', points: 0 });
                    }
                },
                9: {
                    speaker: 'player',
                    text: `Die Belege verbuchen.`,
                    alternativeText: 'Jetzt müssen die Belege verbucht werden.',
                    children: [11],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Die Belege verbuchen.'});
                        setPoints({ answer: '8-10', points: 0 });
                    }
                },
                10: {
                    speaker: 'player',
                    text: `Die Belege auf Richtigkeit und Vollständigkeit prüfen.`,
                    alternativeText: 'Jetzt müssen die Belege auf Richtigkeit und Vollständigkeit geprüft werden.',
                    children: [12],
                    onClick: () => {
                        setAnswer({ id: '8-10', label: 'Die Belege auf Richtigkeit und Vollständigkeit prüfen.', correct: true});
                        setPoints({ answer: '8-10', points: 1 });
                    }
                },
                11: {
                    speaker: 'teamlead',
                    text: `Das ist leider falsch. Zuerst sind die Belege auf Richtigkeit und Vollständigkeit zu prüfen. Heute übernimmt das deine Kollegin.`,
                    children: [13],
                },
                12: {
                    speaker: 'teamlead',
                    text: `Genau, heute übernimmt diese Aufgabe deine Kollegin.`,
                    children: [13],
                },
                13: {
                    speaker: 'teamlead',
                    text: `Alle Belege in der Mappe auf deinem Tisch sind jetzt schon von deiner Kollegin auf Richtigkeit und Vollständigkeit kontrolliert.`,
                    children: [14],
                },
                14: {
                    speaker: 'teamlead',
                    text: `Was machst du jetzt?`,
                    children: [15,16,17],
                },
                15: {
                    speaker: 'player',
                    text: `Den Buchungsvermerk anbringen.`,
                    alternativeText: 'Jetzt werde ich den Buchungsvermerk anbringen.',
                    children: [18],
                    onClick: () => {
                        setAnswer({ id: '15-17', label: 'Den Buchungsvermerk anbringen.' });
                        setPoints({ answer: '15-17', points: 0 });
                    }
                },
                16: {
                    speaker: 'player',
                    text: `Die Belege verbuchen.`,
                    alternativeText: 'Jetzt werde ich die Belege verbuchen.',
                    children: [18],
                    onClick: () => {
                        setAnswer({ id: '15-17', label: 'Die Belege verbuchen.'});
                        setPoints({ answer: '15-17', points: 0 });
                    }
                },
                17: {
                    speaker: 'player',
                    text: `Die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.`,
                    alternativeText: 'Jetzt werde ich die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.',
                    children: [19],
                    onClick: () => {
                        setAnswer({ id: '15-17', label: 'Die Belege nach Belegkreisen sortieren und fortlaufend nummerieren.', correct: true});
                        setPoints({ answer: '15-17', points: 1 });
                    }
                },
                18: {
                    speaker: 'teamlead',
                    text: `Du bist zu schnell. Ordne die Belege zunächst nach Belegkreisen und nummeriere sie fortlaufend.`,
                },
                19: {
                    speaker: 'teamlead',
                    text: `Richtig, du kannst gleich damit beginnen.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask('lehnerFurnitureDocument'))
                setPage('image-cloze')
            }
        },
        whatToDoNext: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Was ist als Nächstes zu tun?`,
                    children: [1,2,3],
                },
                1: {
                    speaker: 'player',
                    text: `Die Belege vorkontieren.`,
                    alternativeText: 'Nun müssen die Belege vorkontiert werden.',
                    children: [4],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Die Belege vorkontieren.', correct: true});
                        setPoints({ answer: '1-3', points: 1 });
                    }
                },
                2: {
                    speaker: 'player',
                    text: `Den Buchungsvermerk anbringen.`,
                    alternativeText: 'Jetzt ist der Buchungsvermerk anzubringen.',
                    children: [5],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Den Buchungsvermerk anbringen.'});
                        setPoints({ answer: '1-3', points: 0 });
                    }
                },
                3: {
                    speaker: 'player',
                    text: `Die Belege prüfen.`,
                    alternativeText: 'Ich sollte die Belege prüfen.',
                    children: [6],
                    onClick: () => {
                        setAnswer({ id: '1-3', label: 'Die Belege prüfen.'});
                        setPoints({ answer: '1-3', points: 0 });
                    }
                },
                4: {
                    speaker: 'teamlead',
                    text: `Genau, jetzt sind die Belege für die Vorkontierung bereit.`,
                },
                5: {
                    speaker: 'teamlead',
                    text: `Das stimmt nicht. Der Buchungsvermerk kann erst angebracht werden, wenn die Belege auch tatsächlich verbucht worden sind. Jetzt müssen die Belege also vorkontiert werden.`,
                },
                6: {
                    speaker: 'teamlead',
                    text: `Die Belege wurden schon von deiner Kollegin auf Vollständigkeit und Richtigkeit überprüft. Jetzt müssen die Belege vorkontiert werden.`,
                },
            },
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Dialogue', 'Button Click']);
                dispatch(setTask(''))
                dispatch(setNotification('mission10Feedback'))
                setPage('notification')
            }
        },
        youCanPreAssignAndPost: {
            speakers: {
                teamlead: {
                    name: 'Teamleiterin',
                    image: teamleadImage,
                    orientation: Orientation.Left,
                },
                player: {
                    name: 'Du',
                    image: userImage,
                    orientation: Orientation.Right,
                }
            },
            bubbles: {
                0: {
                    speaker: 'teamlead',
                    text: `Wenn du es dir zutraust, kannst du die Belege, die du gerade bearbeitet hast, vorkontieren und verbuchen. Hast du Lust, es zu versuchen?`,
                    children: [1,2],
                },
                1: {
                    speaker: 'player',
                    text: `Ja, ich möchte die Belege vorkontieren und verbuchen.`,
                    children: [3],
                    onClick: () => {
                        dispatch(setMission(11));
                        // dispatch(setUnlockedMissions([1,5,6,7,8,9,10]));
                        dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                        dispatch(setWasJokerUsed(false));
                        dispatch(setCurrentNotification('mission11'));
                        setPage('notification')
                        window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 2_6: Belege organisieren']);
                        dispatch(pauseTimer());
                    }
                },
                2: {
                    speaker: 'player',
                    text: `Nein, ich fühle mich dafür noch nicht bereit.`,
                    children: [3],
                    onClick: () => {
                        dispatch(setMission(12));
                        // dispatch(setUnlockedMissions([1,5,6,7,8,9,10]));
                        dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                        dispatch(setWasJokerUsed(false));
                        dispatch(setCurrentNotification('mission12'));
                        setPage('notification')
                        window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 2_6: Belege organisieren']);
                        dispatch(pauseTimer());
                    }
                },
            },
            onContinue: () => {}
        },
    }

    return missionTenData;
}
