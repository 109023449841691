import { Type } from "../../document";
import { setClipboardEntry } from "../../../redux/slices/taskSlice";
import {useDispatch} from "react-redux";
import useFunctions from "../../functions";

export const Mahnplan = () => {
    const dispatch = useDispatch();
    const [{ currentMission }] = useFunctions();

    return {
        type: Type.ImageCloze,
        title: `Mahnplan`,
        src: require('static/images/tasks/mission8/mMe_Mahnplan.png'),
        hotspots: {
            0: { x: '35.8%', y: '40%' },
            1: { x: '35.8%', y: '44%' },
            2: { x: '35.8%', y: '66%' },
            3: { x: '35.8%', y: '78%' },
            4: { x: '65%', y: '39.7%', width: '14%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Mahnplan`,
                        order: 1,
                        title: 'Mahnstufe 2: Mahnspesen',
                        isEnabled: true,
                        isPercentage: false,
                        value: '11,00'}))
                } },
            5: { x: '55.5%', y: '67.6%', width: '14%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Mahnplan`,
                        order: 2,
                        title: 'Mahnstufe 3: Mahnspesen',
                        isEnabled: true,
                        isPercentage: false,
                        value: '11,00'}))
                } },
            6: { x: '77.5%', y: '71.9%', width: '10%', isClipboard: true, onClick: () => {
                    dispatch(setClipboardEntry({
                        mission: currentMission,
                        document: `Mahnplan`,
                        order: 3,
                        title: 'Mahnstufe 3: Verzugszinsen',
                        isEnabled: true,
                        isPercentage: true,
                        value: '10 %'}))
                } },
        },
        initialAnswers: {
            0: { label: `Verrechnung von <b>11 Euro</b> <i class="lnr lnr-clipboard-empty"></i> Mahnspesen gemäß` , active: false },
            1: { label: `geltender AGB` , active: false },
            2: { label: `Verrechnung von Mahnspesen: ab 2. Mahnung je Mahnung <b>11 Euro</b> <i class="lnr lnr-clipboard-empty"></i>` , active: false },
            3: { label: `Verzugszinsenverrechnung ( <b>10 %</b> <i class="lnr lnr-clipboard-empty"></i> p.a.) ab dem auf die Fälligkeit der Rechnung folgenden Tag bis zum Ende der Zahlungsfrist der 3. Mahnung (Tageberechnung 30/360)  Einschreiben` , active: false },
        }
    };
}
