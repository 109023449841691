import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Points = {
  points: number;
  maxPoints: number;
}

type PointScore = {
  [mission: string] : Points;
}

type TimeScore = {
  [mission: string]: number;
}

type PointsDetails = {
  [mission: string]: Tasks;
}

type Tasks = {
  [task: string]: Task;
}

type Task = {
  [answer: string]: number;
}

type MissionCompleted = {
  [mission: string]: boolean;
}

export const slice = createSlice({
  name: 'score',
  initialState: {
    points: {
      0: { points: 0, maxPoints: 0 },
      1: { points: 0, maxPoints: 15 },
      2: { points: 0, maxPoints: 11 },
      3: { points: 0, maxPoints: 7 },
      4: { points: 0, maxPoints: 20 },
      5: { points: 0, maxPoints: 27 },
      6: { points: 0, maxPoints: 36 },
      7: { points: 0, maxPoints: 26 },
      8: { points: 0, maxPoints: 34 },
      9: { points: 0, maxPoints: 10 },
      10: { points: 0, maxPoints: 9 },
      11: { points: 0, maxPoints: 0 },
      12: { points: 0, maxPoints: 28 },
      13: { points: 0, maxPoints: 8 },
    } as PointScore,
    time: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
    } as TimeScore,
    pointsDetails: {
      0: {},
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      10: {},
      11: {},
      12: {},
      13: {},
    } as PointsDetails,
    missionCompleted: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
    } as MissionCompleted,
    isTimerRunning: false,
  },
  reducers: {
    addPoints: (state, action: PayloadAction<{mission: number; points: number;}>) => {
      const entries = { ...state.points };
      entries[action.payload.mission].points += action.payload.points;

      state.points = entries;
    },
    setPoints: (state, action: PayloadAction<{mission: number; points: number;}>) => {
      const entries = { ...state.points };
      entries[action.payload.mission].points = action.payload.points;

      state.points = entries;
    },
    setPointsDetails: (state, action: PayloadAction<{mission: number; task: string; answer: string; points: number;}>) => {
      const entries = { ...state.pointsDetails };
      if (!entries[action.payload.mission][action.payload.task]) {
        entries[action.payload.mission][action.payload.task] = {};
      }
      entries[action.payload.mission][action.payload.task][action.payload.answer] = action.payload.points;

      state.pointsDetails = entries; 
    },
    resetPointsDetails: (state, action: PayloadAction<{mission: number;}>) => {
      const entries = { ...state.pointsDetails };
      entries[action.payload.mission] = {};

      state.pointsDetails = entries;
    },
    addTime: (state, action:  PayloadAction<{mission: number; time: number;}>) => {
      const entries = { ...state.time };
      entries[action.payload.mission] += action.payload.time;

      state.time = entries;
    },
    setTime: (state, action: PayloadAction<{mission: number; time: number;}>) => {
      const entries = { ...state.time };
      entries[action.payload.mission] = action.payload.time;

      state.time = entries;
    },
    startTimer: (state) => {
      state.isTimerRunning = true;
    },
    pauseTimer: (state) => {
      state.isTimerRunning = false;
    },
    setMissionCompleted: (state, action: PayloadAction<{mission: number; completed: boolean;}>) => {
      const entries = { ...state.missionCompleted };
      entries[action.payload.mission] = action.payload.completed;

      state.missionCompleted = entries;
    }
  }
})

export const { addPoints,
  setPoints,
  setPointsDetails,
  resetPointsDetails,
  addTime,
  setTime,
  startTimer,
  pauseTimer,
  setMissionCompleted
} = slice.actions;

export default slice.reducer;
