import { combineReducers, PayloadAction } from '@reduxjs/toolkit';

import appReducer from './slices/appSlice';
import scoreReducer from './slices/scoreSlice';
import taskReducer from './slices/taskSlice';
import userReducer from './slices/userSlice';

const reducers = combineReducers({
  app: appReducer,
  score: scoreReducer,
  task: taskReducer,
  user: userReducer,
});

const rootReducer = (state: any, action: PayloadAction) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return reducers(state, action);
};

export const reset = () => ({ type: 'RESET' });

export type RootState = ReturnType<typeof reducers>;

export default rootReducer;