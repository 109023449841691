import { Type } from "../../document";
import {currentYear} from "../variables";

export const AR703 = () => {

    return {
        type: Type.ImageCloze,
        title: `Beleg AR 703`,
        src: require('static/images/tasks/mission12/mKe_AR703.png'),
        hotspots: {
            0: {x: '4%', y: '30%'},
        },
        initialAnswers: {
            0: {label: `<small><b>Rechnung Nr. 15718-${currentYear.toString().substr(-2)}</b> vom ${currentYear}-11-12 (Rechnungsdatum = Lieferdatum)</small>`, active: false},
        }
    };
}