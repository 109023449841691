import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/rootReducer';

import Notepad from 'pages/Notepad';

import styles from './NotepadOverlay.module.scss';

const NotepadOverlay = () => {
  const { showNotepad } = useSelector((state: RootState) => state.app);

  if (showNotepad) {
    return <div className={styles.container}><Notepad /></div>;
  }

  return null;
};

export default NotepadOverlay;