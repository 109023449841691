import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/rootReducer';
import { confirmOfflineStatus } from 'redux/slices/appSlice';

import PopUp from 'components/PopUp';

const ConnectionOverlay = () => {
  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isOfflineStatusConfirmed = useSelector((state: RootState) => state.app.isOfflineStatusConfirmed)
  const dispatch = useDispatch();
  const duration = 3000;
  const displayTimeout = useRef(0);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const setOnline = () => { setConnectionStatus(true); };
  const setOffline = () => { setConnectionStatus(false); };

  const setConnectionStatus = (value: boolean) => {
    window.clearTimeout(displayTimeout.current);

    displayTimeout.current = window.setTimeout(() => {
      setIsOnline(value);
    }, duration);
  }

  const confirm = () => {
    dispatch(confirmOfflineStatus(true));
  };

  const text = `Deine Internetverbindung scheint nicht stabil zu sein. 
  Falls Probleme bei der Darstellung mancher Inhalte auftauchen, stelle bitte sicher, 
  dass deine Internetverbindung stabil ist und lade die Seite im Browser neu.`;

  if (!isOnline && !isOfflineStatusConfirmed) {
    return <PopUp icon="wifi-blocked" title="Ups!" text={text} onClick={confirm} />;
  }

  return null;
};

export default ConnectionOverlay;