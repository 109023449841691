import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { Notifications } from "./datatypes";
import { teamleadImage } from "./variables";
import { Page as Document } from "../library";

import {
    setDialogue, setFinished2,
    setMission, setModuleScore,
    setNotification as setCurrentNotification, setShowSurveyPopUp,
    setWasJokerUsed,
    setUnlockedMissions
} from "../../redux/slices/appSlice";
import {addPoints, pauseTimer, setMissionCompleted, setPointsDetails, startTimer} from "../../redux/slices/scoreSlice";
import { setTask } from "redux/slices/taskSlice";
import useFunctions from "../functions";
import {RootState} from "../../redux/rootReducer";

export const MissionTwelve = () => {
    const dispatch = useDispatch();

    const { isFinishedModule2, reachedModuleScore } = useSelector((state: RootState) => state.app);
    const { anonymous } = useSelector((state: RootState) => state.user);
    const [{ setOverlay, getMessage, saveHighscoreAnonymous, saveHighscoreWithName, setPage, missionsCompleted,
        missionPoints, missionMaxPoints, pointsDetails, currentMission }] = useFunctions();

    const setPoints = ({ task, answer, points }: { task: string, answer: string, points: number }) => {
        dispatch(setPointsDetails({ mission: currentMission, task, answer, points}));

        if (
            !pointsDetails[currentMission][task] ||
            (pointsDetails[currentMission][task] && pointsDetails[currentMission][task][answer] === undefined)
        ) {
            dispatch(addPoints({ mission: currentMission, points }));
        }
    };

    const missionTwelveData: Notifications = {
        mission12: {
            children: <><p>Auftrag:</p><h2>Kassabericht erstellen</h2></>,
            style: {justifyContent: 'center'},
            onContinueLabel: 'Weiter',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Mission', 'Mission Started', 'Mission 1_1: Ein Angebot erstellen']);
                dispatch(setTask('pleaseManagePettyCashRegister'));
                dispatch(setDialogue('pleaseManagePettyCashRegister'));
                setPage('dialogue');
                dispatch(startTimer());
            },
        },

        canER743BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        setPoints({ task: 'canER743BeIncluded', answer: 'Ja', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('ER743ThatIsWrong'));
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        setPoints({ task: 'canER743BeIncluded', answer: 'Nein', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('ER743ThatIsRight'));
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        setOverlay(Document.ER743)
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                    }
                }
            ],
        },
        ER743ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg dürfen wir nicht in den Kassabericht aufnehmen.',
            onContinue: () => {
                setOverlay(Document.K87)
                dispatch(setTask('canK87BeIncluded'));
                dispatch(setCurrentNotification('canK87BeIncluded'));
            }
        },
        ER743ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig.',
            onContinue: () => {
                setOverlay(Document.K87)
                dispatch(setTask('canK87BeIncluded'));
                dispatch(setCurrentNotification('canK87BeIncluded'));
            }
        },

        canK87BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        setPoints({ task: 'canK87BeIncluded', answer: 'Ja', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K87ThatIsRight'));
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        setPoints({ task: 'canK87BeIncluded', answer: 'Nein', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K87ThatIsWrong'));
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        setOverlay(Document.K87)
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                    }
                }
            ],
        },
        K87ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg müssen wir in das Kassabuch aufnehmen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.AR703)
                dispatch(setTask('canAR703BeIncluded'));
                dispatch(setCurrentNotification('canAR703BeIncluded'));
            }
        },
        K87ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig. Du kannst diesen Beleg für später markieren.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.AR703)
                dispatch(setTask('canAR703BeIncluded'));
                dispatch(setCurrentNotification('canAR703BeIncluded'));
            }
        },

        canAR703BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canAR703BeIncluded', answer: 'Ja', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('AR703ThatIsWrong'));
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canAR703BeIncluded', answer: 'Nein', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('AR703ThatIsRight'));
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setOverlay(Document.AR703)
                    }
                }
            ],
        },
        AR703ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg dürfen wir nicht in den Kassabericht aufnehmen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.K88)
                dispatch(setTask('canK88BeIncluded'));
                dispatch(setCurrentNotification('canK88BeIncluded'));
            }
        },
        AR703ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.K88)
                dispatch(setTask('canK88BeIncluded'));
                dispatch(setCurrentNotification('canK88BeIncluded'));
            }
        },

        canK88BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canK88BeIncluded', answer: 'Ja', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K88ThatIsRight'));
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canK88BeIncluded', answer: 'Nein', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K88ThatIsWrong'));
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setOverlay(Document.K88)
                    }
                }
            ],
        },
        K88ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg müssen wir in das Kassabuch aufnehmen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.B112)
                dispatch(setTask('canB112BeIncluded'));
                dispatch(setCurrentNotification('canB112BeIncluded'));
            }
        },
        K88ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig. Du kannst diesen Beleg für später markieren.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.B112)
                dispatch(setTask('canB112BeIncluded'));
                dispatch(setCurrentNotification('canB112BeIncluded'));
            }
        },

        canB112BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canB112BeIncluded', answer: 'Ja', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('B112ThatIsWrong'));
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canB112BeIncluded', answer: 'Nein', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('B112ThatIsRight'));
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setOverlay(Document.B112)
                    }
                }
            ],
        },
        B112ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg dürfen wir nicht in den Kassabericht aufnehmen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.K89)
                dispatch(setTask('canK89BeIncluded'));
                dispatch(setCurrentNotification('canK89BeIncluded'));
            }
        },
        B112ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.K89)
                dispatch(setTask('canK89BeIncluded'));
                dispatch(setCurrentNotification('canK89BeIncluded'));
            }
        },

        canK89BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canK89BeIncluded', answer: 'Ja', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K89ThatIsRight'));
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canK89BeIncluded', answer: 'Nein', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K89ThatIsWrong'));
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setOverlay(Document.K89)
                    }
                }
            ],
        },
        K89ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg müssen wir in das Kassabuch aufnehmen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.S32)
                dispatch(setTask('canS32BeIncluded'));
                dispatch(setCurrentNotification('canS32BeIncluded'));
            }
        },
        K89ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig. Du kannst diesen Beleg für später markieren.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.S32)
                dispatch(setTask('canS32BeIncluded'));
                dispatch(setCurrentNotification('canS32BeIncluded'));
            }
        },

        canS32BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canS32BeIncluded', answer: 'Ja', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('S32ThatIsWrong'));
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canS32BeIncluded', answer: 'Nein', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('S32ThatIsRight'));
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setOverlay(Document.S32)
                    }
                }
            ],
        },
        S32ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg dürfen wir nicht in den Kassabericht aufnehmen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.K90)
                dispatch(setTask('canK90BeIncluded'));
                dispatch(setCurrentNotification('canK90BeIncluded'));
            }
        },
        S32ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                setOverlay(Document.K90)
                dispatch(setTask('canK90BeIncluded'));
                dispatch(setCurrentNotification('canK90BeIncluded'));
            }
        },

        canK90BeIncluded: {
            image: teamleadImage,
            text: 'Darf dieser Beleg in den Kassabericht übernommen werden?',
            options: [
                {
                    label: 'Ja',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canK90BeIncluded', answer: 'Ja', points: 1 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K90ThatIsRight'));
                    }
                },
                {
                    label: 'Nein',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setPoints({ task: 'canK90BeIncluded', answer: 'Nein', points: 0 });
                        dispatch(setTask(''));
                        dispatch(setCurrentNotification('K90ThatIsWrong'));
                    }
                },
                {
                    label: 'Beleg nochmal ansehen...',
                    onClick: () => {
                        window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                        setOverlay(Document.K90)
                    }
                }
            ],
        },
        K90ThatIsWrong: {
            image: teamleadImage,
            text: 'Das ist falsch. Diesen Beleg dürfen wir nicht in den Kassabericht aufnehmen.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('whatAreYouDoingNext'))
                dispatch(setDialogue('whatAreYouDoingNext'))
                setPage('dialogue')
            }
        },
        K90ThatIsRight: {
            image: teamleadImage,
            text: 'Das ist richtig. Du kannst diesen Beleg für später markieren.',
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setTask('whatAreYouDoingNext'))
                dispatch(setDialogue('whatAreYouDoingNext'))
                setPage('dialogue')
            }
        },
        mission12Feedback: {
            image: teamleadImage,
            text: `Du hast ${missionPoints} von ${missionMaxPoints} Punkten bei diesem Auftrag erreicht. ${getMessage()}`,
            onContinue: () => {
                window._paq.push(['trackEvent', 'Interaction', 'Notification Page', 'Button Click']);
                dispatch(setCurrentNotification('mission12PauseInfo'));
                setPage('notification');
                window._paq.push(['trackEvent', 'Mission', 'Mission Completed', 'Mission 2_3: Kennzahlen berechnen']);
                dispatch(pauseTimer());
            },
        },
        mission12PauseInfo: {
            icon: 'checkmark-circle',
            text: 'Möchtest du gleich mit dem nächsten Arbeitsauftrag weitermachen oder eine Pause einlegen? Dein Fortschritt wird auf diesem Gerät gespeichert, sodass du jederzeit weitermachen kannst.',
            onContinueLabel: 'Nächster Auftrag',
            onContinue: () => {
                dispatch(setMissionCompleted({mission: 12, completed: true}))

                if (reachedModuleScore[2]) {

                    if (missionsCompleted([5,6,7,8,9,10,13])){
                        if (isFinishedModule2) {
                            dispatch(setModuleScore(2));
                            if (anonymous) {
                                saveHighscoreAnonymous(2);
                            } else {
                                saveHighscoreWithName(2);
                            }
                            // dispatch(setUnlockedMissions([5]));
                            dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                        } else {
                            dispatch(setFinished2(true));
                            dispatch(setMission(13))
                            dispatch(setShowSurveyPopUp(true));
                            setPage('end-feedback');
                        }
                        window._paq.push(['trackEvent', 'Scenario', 'Scenario Completed', 'Scenario 1']);

                    } else {
                        dispatch(setMission(0));
                        dispatch(setCurrentNotification('youDidNotFinishAllMissions'));
                        setPage('notification')
                    }
                } else {
                    dispatch(setMission(13));
                    dispatch(setWasJokerUsed(false));
                    dispatch(setUnlockedMissions([1,5,6,7,8,9,10,11,12,13]));
                    dispatch(setCurrentNotification('mission13'));
                }
            },
        },
    }

    return missionTwelveData;
}
