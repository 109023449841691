import { useDispatch } from "react-redux";

import { useFunctions } from "../functions";
import { Tasks } from "./datatypes";

import {
    setAllowClipboardCopy,
    setFeedback,
    setOverlay as setCurrentOverlay,
} from "../../redux/slices/appSlice";
import {setTask as setCurrentTask, setTaskSkippable} from "../../redux/slices/taskSlice";
import { Type } from "../../components/HotspotChoice";

import { Page as Document } from "../library";
import { emptyAnswerValue } from "../../components/MiniCalculator";

export const MissionThirteen = () => {
    const [{ setImageClozeAnswer, setOverlay, setImageClozePoints, setPage }] = useFunctions();
    const dispatch = useDispatch();
    const currentYear = new Date().getFullYear();

    const missionThirteenData: Tasks = {
        fillOutTransferForm: {
            title: 'Überweisungsformular ausfüllen',
            src: require('static/images/tasks/mission13/mZv_UeberweisungsformularAusfuellen.png'),
            hotspots: {
                0: {
                    x: '38.5%',
                    y: '18%',
                    labelXOffset: '-2.5%',
                    options: [
                        { label: `Zalanka AG`, onClick: setImageClozeAnswer },
                        { label: `Karl Lehner & Co`, correct: true, onClick: setImageClozeAnswer  },
                        { label: `Memoboard GmbH`, onClick: setImageClozeAnswer  },
                        { label: `Auf Eingangsrechnung nachsehen...`, noAnswer: true, onClick: () => {
                                setOverlay(Document.ER743Clipboard)
                        }},
                    ],
                },
                1: {
                    x: '38.5%',
                    y: '27%',
                    labelXOffset: '-2.5%',
                    options: [
                        { label: `ATI 76299813`,
                            alternativeLabel: `ATI`,
                            children: [
                                { x: '47%', y: '27%', label: `76`},
                                { x: '57%', y: '27%', label: `29`},
                                { x: '68%', y: '27%', label: `98`},
                                { x: '78%', y: '27%', label: `13`},
                            ],
                            onClick: setImageClozeAnswer
                        },
                        { label: `FNBKATWW367`,
                            alternativeLabel: `FNB`,
                            children: [
                                { x: '47%', y: '27%', label: `KA`},
                                { x: '57%', y: '27%', label: `TW`},
                                { x: '68%', y: '27%', label: `W3`},
                                { x: '78%', y: '27%', label: `67`},
                            ],
                            onClick: setImageClozeAnswer
                        },
                        { label: `AT55 9900 0004 7153 3712`,
                            alternativeLabel: `AT55`,
                            children: [
                                { x: '46.7%', y: '27%', label: `9900`},
                                { x: '57%', y: '27%', label: `0004`},
                                { x: '68%', y: '27%', label: `7153`},
                                { x: '78.5%', y: '27%', label: `3712`},
                            ],
                            correct: true,
                            onClick: setImageClozeAnswer
                        },
                        { label: `Auf Eingangsrechnung nachsehen...`, noAnswer: true, onClick: () => {
                            setOverlay(Document.ER743Clipboard)
                        }}
                    ],
                },
                2: {
                    x: '38.5%',
                    y: '36%',
                    labelXOffset: '-2.5%',
                    type: Type.Calculator,
                    correct: ['328,25'],
                    hasClipboard: true,
                    onClipboardClick: () => {
                        dispatch(setCurrentOverlay('clipboard'));
                        dispatch(setAllowClipboardCopy(true))
                    },
                    points: 1,
                    onSubmit: setImageClozeAnswer,
                },
                3: {
                    x: '38.5%',
                    y: '45%',
                    labelXOffset: '-2.5%',
                    options: [
                        { label: `2 Stk. Schreibtischsessel JX`, onClick: setImageClozeAnswer },
                        { label: `Kunden Nr. 200436`, onClick: setImageClozeAnswer  },
                        { label: `Leer lassen`, alternativeLabel:`${emptyAnswerValue}`, correct: true, onClick: setImageClozeAnswer  },
                    ],
                },
                4: {
                    x: '38.5%',
                    y: '55%',
                    labelXOffset: '-2.5%',
                    options: [
                        { label: `Kunden Nr. 200436`, onClick: setImageClozeAnswer },
                        { label: `Rechnung Nr. 3876`, correct: true, onClick: setImageClozeAnswer  },
                        { label: `Leer lassen`, alternativeLabel:`${emptyAnswerValue}`, onClick: setImageClozeAnswer  },
                    ],
                },
                5: {
                    x: '38.5%',
                    y: '83%',
                    labelXOffset: '-2.5%',
                    options: [
                        { label: `Karl Lehner & Co`, onClick: setImageClozeAnswer },
                        { label: `Zalanka AG`, onClick: setImageClozeAnswer  },
                        { label: `Memoboard GmbH`, correct: true, onClick: setImageClozeAnswer  },
                        { label: `Auf Eingangsrechnung nachsehen...`, noAnswer: true, onClick: () => {
                            setOverlay(Document.ER743Clipboard)
                        }}
                    ],
                },
                6: { x: '35.5%', y: '74.1%'}
            },
            initialAnswers: {
                6: { label: `25-11-${currentYear}`, active: false},
            },
            onContinue: (answers) => {
                setImageClozePoints(answers);
                dispatch(setCurrentTask(''));
                dispatch(setTaskSkippable(true))
                dispatch(setFeedback('fillOutTransferForm'));
                setPage('feedback');
            },
        },
    }

    return missionThirteenData;
}
