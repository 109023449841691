import React from 'react';
import ReactDOM from 'react-dom';

import Calculator, { Props as CalculatorProps } from 'components/Calculator';

import styles from './CalculatorBox.module.scss';
import {Option} from "../Calendar";

export type Props = CalculatorProps & {
    options?: Option[];
    onOverlayClick?: () => void;
};

const CalculatorBox = ({
    onOverlayClick,
    options,
    onEnter,
}: Props) => {
    const hideCalculator = () => {
        const calculator = document.querySelector<HTMLElement>(`.${styles.calculator}`);
        const overlay = document.querySelector<HTMLElement>(`.${styles.overlay}`);
        if(calculator && overlay){
            if(calculator.style.visibility === 'hidden'){
                calculator.style.removeProperty('visibility');
                calculator.style.removeProperty('overflow');
                calculator.style.removeProperty('height');
                calculator.style.removeProperty('padding');
                overlay.style.backgroundColor = 'rgba(51, 51, 51, 0.5)'
            } else {
                calculator.style.visibility = 'hidden';
                calculator.style.overflow = 'hidden';
                calculator.style.height = '0';
                calculator.style.padding = '0';
                overlay.style.backgroundColor = 'rgba(51, 51, 51, 0.1)'
            }
        }
    }

    return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={onOverlayClick}>
      <div onClick={e => { e.stopPropagation(); }}>
        <Calculator onEnter={onEnter} options={options} className={styles.calculator} />
        <button onClick={hideCalculator} className={styles.hideButton}><i className={'lnr lnr-eye'}></i></button>
      </div>
    </div>,
    document.getElementById('container') as HTMLElement
    );
};

export default CalculatorBox;
