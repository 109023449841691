import React, { useState } from 'react';

import Button from 'components/Button';

import styles from './Notepad.module.scss';

type Props = {
  initialValue?: string;
  onChange?: (value: string) => void;
  onClick?: (value: string) => void;
};

const Notepad = ({
  initialValue = '',
  onChange,
  onClick,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const [showButton, setShowButton] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);

    onChange && onChange(e.currentTarget.value);

    !showButton && setShowButton(true);
  }

  const handleClick = () => {
    onClick && onClick(value);
    setShowButton(false);
  }

  return (
    <div className={styles.container}>
      <textarea placeholder='Klicke hier, um dir Notizen zu machen...' value={value} onChange={handleChange} />
      {showButton && <Button className={styles.button} label='Speichern' onClick={handleClick} />}
    </div>
  );
}

export default Notepad;